<div
  class="modal"
  *ngIf="render_dialog"
  tabindex="-1"
  role="dialog"
  style="display: block"
>
  <div
    class="modal-dialog"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Set-up Meter</h4>
        <button
          type="button"
          class="btn"
          (click)="closePopup()"
        >
          <img
            src="assets/images/cancel.svg"
            alt="arrow"
          />
        </button>
      </div>
      <div class="modal-body">
        <div
          class="bg-lightgrey"
          style="height: 90%"
        >
          <div
            class="bg-lightgrey"
            style="background-color: white; padding: 10px; height: 90%"
          >
            <div *ngIf="page1">
              <h5 style="border-bottom: 1px solid #f3f3f3; padding: 10px">
                Before Proceeding
              </h5>
              <div
                style="
                  padding: 10px;
                  margin: 16px;
                  background: #f5f7fb;
                  border-radius: 6px;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  gap: 16px;
                  display: inline-flex;
                "
              >
                <div
                  style="
                    align-self: stretch;
                    background: #f5f7fb;
                    border-top-left-radius: 2px;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    border-bottom-left-radius: 2px;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 12px;
                    display: inline-flex;
                  "
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 10.5C12.5523 10.5 13 10.9477 13 11.5V16.5C13 17.0523 12.5523 17.5 12 17.5C11.4477 17.5 11 17.0523 11 16.5V11.5C11 10.9477 11.4477 10.5 12 10.5Z"
                      fill="#6285FB"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.679 6.75577C13.0895 7.12525 13.1227 7.75754 12.7533 8.16803L12.7433 8.17914C12.3738 8.58963 11.7415 8.62288 11.331 8.2534C10.9205 7.88393 10.8873 7.25163 11.2567 6.84114L11.2667 6.83003C11.6362 6.41954 12.2685 6.38629 12.679 6.75577Z"
                      fill="#6285FB"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92486 23 12C23 18.0751 18.0751 23 12 23C5.92486 23 1 18.0751 1 12ZM12 3C7.02943 3 3 7.02943 3 12C3 16.9705 7.02944 21 12 21C16.9705 21 21 16.9705 21 12C21 7.02944 16.9705 3 12 3Z"
                      fill="#6285FB"
                    />
                  </svg>
                  <div
                    style="
                      flex: 1 1 0;
                      color: #0d1758;
                      font-size: 19px;
                      font-weight: 600;
                      word-wrap: break-word;
                    "
                  >
                    Important Notice
                  </div>
                </div>
                <div
                  style="
                    align-self: stretch;
                    color: rgba(13, 23, 88, 0.6);
                    font-size: 17px;
                    font-weight: 600;
                    line-height: 24px;
                    word-wrap: break-word;
                  "
                >
                  The dashboard setup is the final stage of the meter
                  installation process. <br />Kindly refrain from proceeding
                  until you have completed on-site installation, mapped the
                  meter, and connected it to either the internet or ethernet.
                  This step is crucial for the successful completion of the
                  process, <br />and progression without these prerequisites
                  will not be possible.”
                </div>
              </div>
            </div>
            <div *ngIf="page2">
              <h5 style="border-bottom: 1px solid #f3f3f3; padding: 10px">
                What do you want to Monitor?
              </h5>

              <div
                *ngFor="let meterType of meterTypes; let i = index"
                class="radio-container"
                (click)="meterTypeSelected = i"
                [style.pointerEvents]="meterType.disabled ? 'none' : false"
                [style.opacity]="meterType.disabled ? 0.6 : 1"
              >
                <input
                  type="radio"
                  [checked]="meterTypeSelected === i"
                />
                <span class="radio-label">{{ meterType.name }}</span>

                <svg
                  style="margin-left: 10px; margin-right: 10px"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_8750_8072)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.00065 7C8.36884 7 8.66732 7.29848 8.66732 7.66667V11C8.66732 11.3682 8.36884 11.6667 8.00065 11.6667C7.63246 11.6667 7.33398 11.3682 7.33398 11V7.66667C7.33398 7.29848 7.63246 7 8.00065 7Z"
                      fill="#AFB6CE"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.45332 4.50387C8.72699 4.75019 8.74915 5.17171 8.50283 5.44537L8.49617 5.45278C8.24985 5.72644 7.82832 5.74861 7.55466 5.50229C7.281 5.25597 7.25883 4.83444 7.50515 4.56078L7.51182 4.55338C7.75813 4.27971 8.17966 4.25755 8.45332 4.50387Z"
                      fill="#AFB6CE"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.666992 8.00002C0.666992 3.94993 3.95024 0.666687 8.00033 0.666687C12.0504 0.666687 15.3337 3.94993 15.3337 8.00002C15.3337 12.0501 12.0504 15.3334 8.00033 15.3334C3.95023 15.3334 0.666992 12.0501 0.666992 8.00002ZM8.00033 2.00002C4.68661 2.00002 2.00033 4.68631 2.00033 8.00002C2.00033 11.3137 4.68662 14 8.00033 14C11.314 14 14.0003 11.3137 14.0003 8.00002C14.0003 4.68631 11.314 2.00002 8.00033 2.00002Z"
                      fill="#AFB6CE"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8750_8072">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <span class="radio-label-right">{{ meterType.subName }}</span>
              </div>
            </div>

            <div
              *ngIf="page3"
              class="panelsOptionsContainer"
              [ngClass]="{
                under_64: meterTypeSelected === 1 || meterTypeSelected === 2
              }"
            >
              <h5
                style="
                  border-bottom: 1px solid #f3f3f3;
                  padding: 10px;
                  margin: unset;
                "
              >
                Select the Panel / MCC that will be Monitored:
              </h5>

              <div
                class="panelsOptions"
                *ngIf="panels.length !== 0"
              >
                <div
                  *ngFor="let panel of panels; let i = index"
                  class="radio-container"
                  (click)="handlePrimaryPanelSelectedChange(i)"
                >
                  <input
                    type="radio"
                    [checked]="primaryPanelSelected === i"
                  />
                  <span class="radio-label">{{ panel.panel_name }}</span>
                  <svg
                    style="margin-left: 10px; margin-right: 10px"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_8750_8072)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.00065 7C8.36884 7 8.66732 7.29848 8.66732 7.66667V11C8.66732 11.3682 8.36884 11.6667 8.00065 11.6667C7.63246 11.6667 7.33398 11.3682 7.33398 11V7.66667C7.33398 7.29848 7.63246 7 8.00065 7Z"
                        fill="#AFB6CE"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.45332 4.50387C8.72699 4.75019 8.74915 5.17171 8.50283 5.44537L8.49617 5.45278C8.24985 5.72644 7.82832 5.74861 7.55466 5.50229C7.281 5.25597 7.25883 4.83444 7.50515 4.56078L7.51182 4.55338C7.75813 4.27971 8.17966 4.25755 8.45332 4.50387Z"
                        fill="#AFB6CE"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.666992 8.00002C0.666992 3.94993 3.95024 0.666687 8.00033 0.666687C12.0504 0.666687 15.3337 3.94993 15.3337 8.00002C15.3337 12.0501 12.0504 15.3334 8.00033 15.3334C3.95023 15.3334 0.666992 12.0501 0.666992 8.00002ZM8.00033 2.00002C4.68661 2.00002 2.00033 4.68631 2.00033 8.00002C2.00033 11.3137 4.68662 14 8.00033 14C11.314 14 14.0003 11.3137 14.0003 8.00002C14.0003 4.68631 11.314 2.00002 8.00033 2.00002Z"
                        fill="#AFB6CE"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8750_8072">
                        <rect
                          width="16"
                          height="16"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>

              <div
                *ngIf="panels.length == 0"
                class="radio-container"
              >
                No Panels available
              </div>

              <!-- <ng-container *ngIf="meterTypeSelected === 2">
                <h5
                  style="
                    border-bottom: 1px solid #f3f3f3;
                    padding: 10px;
                    margin: unset;
                    margin-top: 10px;
                  "
                >
                  Select Secondary Panel:
                </h5>

                <div
                  class="panelsOptions"
                  *ngIf="panels.length !== 0"
                >
                  <div
                    *ngFor="let panel of panels; let i = index"
                    class="radio-container"
                    [style.pointerEvents]="
                      primaryPanelSelected === i ? 'none' : 'unset'
                    "
                    [style.opacity]="primaryPanelSelected === i ? 0.6 : 1"
                    (click)="handleSecondaryPanelSelectedChange(i)"
                  >
                    <input
                      type="radio"
                      [checked]="secondaryPanelSelected === i"
                    />
                    <span class="radio-label">{{ panel.panel_name }}</span>
                    <svg
                      style="margin-left: 10px; margin-right: 10px"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_8750_8072)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.00065 7C8.36884 7 8.66732 7.29848 8.66732 7.66667V11C8.66732 11.3682 8.36884 11.6667 8.00065 11.6667C7.63246 11.6667 7.33398 11.3682 7.33398 11V7.66667C7.33398 7.29848 7.63246 7 8.00065 7Z"
                          fill="#AFB6CE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.45332 4.50387C8.72699 4.75019 8.74915 5.17171 8.50283 5.44537L8.49617 5.45278C8.24985 5.72644 7.82832 5.74861 7.55466 5.50229C7.281 5.25597 7.25883 4.83444 7.50515 4.56078L7.51182 4.55338C7.75813 4.27971 8.17966 4.25755 8.45332 4.50387Z"
                          fill="#AFB6CE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.666992 8.00002C0.666992 3.94993 3.95024 0.666687 8.00033 0.666687C12.0504 0.666687 15.3337 3.94993 15.3337 8.00002C15.3337 12.0501 12.0504 15.3334 8.00033 15.3334C3.95023 15.3334 0.666992 12.0501 0.666992 8.00002ZM8.00033 2.00002C4.68661 2.00002 2.00033 4.68631 2.00033 8.00002C2.00033 11.3137 4.68662 14 8.00033 14C11.314 14 14.0003 11.3137 14.0003 8.00002C14.0003 4.68631 11.314 2.00002 8.00033 2.00002Z"
                          fill="#AFB6CE"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8750_8072">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>

                <div
                  *ngIf="panels.length == 0"
                  class="radio-container"
                >
                  No Panels available
                </div>
              </ng-container> -->
            </div>
          </div>
        </div>

        <div>
          <button
            (click)="nextPage()"
            style="
              background: #22b14c;
              margin: 10px;
              float: right;
              width: 138px;
            "
            type="button"
            class="btn btn-success"
          >
            {{ nextButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
