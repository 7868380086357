<div class="dt-main">
  <app-sidebar></app-sidebar>
  <div class="dt-content-wrapper overflow-y-scroll">
    <div class="dt-content">
      <div class="col-md-12 spc-padding">
        <div class="headerContainer">
          <img
            src="assets/images/back-button.png"
            routerLink="/insights/energy-efficiency"
          />
          <h2 class="pageHeading heading">Explore Savings Potential</h2>
        </div>
        <div class="bg-lightgrey overflow-y-scroll">
          <div class="row">
            <div class="col-md-6 mb-4">
              <div class="card border-radius5 p-3">
                <div class="energy-savings-card">
                  <h2>13</h2>
                  <p>Oppurtunities Identified</p>
                </div>
                <div class="cardfoot border-top">
                  <a routerLink="/insights/energy-efficiency/explore-energy-potential/savings-scan">
                    Scan #1
                    <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="card border-radius5 p-3">
                <div class="energy-savings-card">
                  <h2>5</h2>
                  <p>Oppurtunities Identified</p>
                </div>
                <div class="cardfoot border-top">
                  <a routerLink="/insights/energy-efficiency/explore-energy-potential/savings-scan">
                    Scan #2
                    <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="card border-radius5 p-3">
                <div class="energy-savings-card">
                  <h2>7</h2>
                  <p>Oppurtunities Identified</p>
                </div>
                <div class="cardfoot border-top">
                  <a routerLink="/insights/energy-efficiency/explore-energy-potential/savings-scan">
                    Scan #3
                    <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="card border-radius5 p-3">
                <div class="energy-savings-card">
                  <h2>17</h2>
                  <p>Oppurtunities Identified</p>
                </div>
                <div class="cardfoot border-top">
                  <a routerLink="/insights/energy-efficiency/explore-energy-potential/savings-scan">
                    Scan #4
                    <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
