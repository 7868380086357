<div class="meter-installation-container">
  <mat-card>
    <mat-card-title>Meter Installation</mat-card-title>
    <mat-card-content>
      <form [formGroup]="installationForm" (ngSubmit)="onSubmit()">
          <mat-form-field appearance="outline" class="full-width">
            <input matInput formControlName="meterSerialId" placeholder="Enter meter serial ID">
            <mat-error *ngIf="installationForm.get('meterSerialId')?.hasError('required')">
              Meter Serial ID is required
            </mat-error>
            <mat-error *ngIf="installationForm.get('meterSerialId')?.hasError('minlength')">
              Meter Serial ID must be at least 3 characters
            </mat-error>
          </mat-form-field>
          <button mat-raised-button color="primary" type="submit" [disabled]="installationForm.invalid || loading">
            <span>Submit</span>
            <mat-spinner *ngIf="loading" diameter="20" class="button-spinner"></mat-spinner>
          </button>
      </form>

      <mat-stepper [linear]="true" orientation="vertical" [selectedIndex]="currentStep" #stepper class="installation-stepper" hideDefaultLabels>
        <!-- Connection Check Step -->
        <mat-step [completed]="stepStatus['connection'] === 'success'" [editable]="false" state="connection">
          <ng-template matStepLabel>
            <div class="step-label">
              <span>Check Connection</span>
              <div class="step-icons">
                <button mat-icon-button *ngIf="stepStatus['connection'] !== 'success'" 
                        [disabled]="loadingSteps['connection'] || installationForm.invalid"
                        (click)="checkConnection()"
                        matTooltip="Check meter connection status"
                        class="action-button">
                  <mat-icon *ngIf="!loadingSteps['connection']" class="action-icon">play_circle</mat-icon>
                  <mat-spinner *ngIf="loadingSteps['connection']" diameter="24" class="action-spinner"></mat-spinner>
                </button>
                <button mat-icon-button *ngIf="stepStatus['connection'] === 'success'" 
                        class="success-button"
                        matTooltip="Connection successful">
                  <mat-icon class="success-icon">check_circle</mat-icon>
                </button>
                <button mat-icon-button *ngIf="stepStatus['connection'] === 'success'"
                        class="retry-button"
                        (click)="retryStep('connection')"
                        matTooltip="Restart connection check">
                  <mat-icon class="retry-icon">restart_alt</mat-icon>
                </button>
                <mat-icon *ngIf="stepStatus['connection'] === 'failure'" 
                         class="error-icon"
                         matTooltip="Connection failed">error</mat-icon>
              </div>
            </div>
          </ng-template>
        </mat-step>

        <!-- Calibration Step -->
        <mat-step [completed]="stepStatus['calibration'] === 'success'" [editable]="false">
          <ng-template matStepLabel>
            <div class="step-label">
              <span>Calibrate Meter</span>
              <div class="step-icons">
                <button mat-icon-button *ngIf="stepStatus['calibration'] !== 'success'"
                        [disabled]="loadingSteps['calibration'] || stepStatus['connection'] !== 'success'"
                        (click)="calibrateMeter()"
                        matTooltip="Start meter calibration"
                        class="action-button">
                  <mat-icon *ngIf="!loadingSteps['calibration']" class="action-icon">play_circle</mat-icon>
                  <mat-spinner *ngIf="loadingSteps['calibration']" diameter="24" class="action-spinner"></mat-spinner>
                </button>
                <button mat-icon-button *ngIf="stepStatus['calibration'] === 'success'"
                        class="success-button"
                        matTooltip="Calibration successful">
                  <mat-icon class="success-icon">check_circle</mat-icon>
                </button>
                <button mat-icon-button *ngIf="stepStatus['calibration'] === 'success'"
                        class="retry-button"
                        (click)="retryStep('calibration')"
                        matTooltip="Restart calibration">
                  <mat-icon class="retry-icon">restart_alt</mat-icon>
                </button>
                <mat-icon *ngIf="stepStatus['calibration'] === 'failure'"
                         class="error-icon"
                         matTooltip="Calibration failed">error</mat-icon>
              </div>
            </div>
          </ng-template>
        </mat-step>

        <!-- Signal Strength Step -->
        <mat-step [completed]="stepStatus['signalStrength'] === 'success'" [editable]="false">
          <ng-template matStepLabel>
            <div class="step-label">
              <div class="step-label-content">
                <span>Check Signal Strength</span>
                <div class="signal-info" *ngIf="signalStrength">
                  <div class="signal-value" [style.color]="getSignalColor(signalStrength.quality)">
                    {{signalStrength.value}}%
                  </div>
                  <div class="signal-bars">
                    <div class="signal-bar" 
                         *ngFor="let bar of [1,2,3,4]; let i = index"
                         [class.active]="signalStrength.value >= i * 25"
                         [style.background-color]="signalStrength.value >= i * 25 ? getSignalColor(signalStrength.quality) : '#e0e0e0'">
                    </div>
                  </div>
                  <div class="signal-quality" [style.color]="getSignalColor(signalStrength.quality)">
                    {{signalStrength.quality | titlecase}}
                  </div>
                </div>
              </div>
              <div class="step-icons">
                <button mat-icon-button *ngIf="stepStatus['signalStrength'] !== 'success'"
                        [disabled]="loadingSteps['signalStrength'] || stepStatus['calibration'] !== 'success'"
                        (click)="checkSignalStrength()"
                        [matTooltip]="signalStrength ? signalStrength.message : 'Check signal strength'"
                        class="action-button">
                  <mat-icon *ngIf="!loadingSteps['signalStrength']" class="action-icon">play_circle</mat-icon>
                  <mat-spinner *ngIf="loadingSteps['signalStrength']" diameter="24" class="action-spinner"></mat-spinner>
                </button>
                <button mat-icon-button *ngIf="stepStatus['signalStrength'] === 'success'"
                        class="success-button"
                        [matTooltip]="signalStrength ? signalStrength.message : 'Signal strength good'">
                  <mat-icon class="success-icon">check_circle</mat-icon>
                </button>
                <button mat-icon-button *ngIf="stepStatus['signalStrength'] === 'success'"
                        class="retry-button"
                        (click)="retryStep('signalStrength')"
                        matTooltip="Restart signal strength check">
                  <mat-icon class="retry-icon">restart_alt</mat-icon>
                </button>
                <mat-icon *ngIf="stepStatus['signalStrength'] === 'failure'"
                         class="error-icon"
                         [matTooltip]="signalStrength ? signalStrength.message : 'Signal strength too weak'">
                  error
                </mat-icon>
              </div>
            </div>
          </ng-template>
        </mat-step>

        <!-- Live Readings Step -->
        <mat-step [completed]="stepStatus['liveReadings'] === 'success'" [editable]="false">
          <ng-template matStepLabel>
            <div class="step-label">
              <div class="step-label-content">
                <span>Live Readings</span>
                <div class="readings-info" *ngIf="liveReadings">
                  <div class="readings-section">
                    <div class="readings-title">Current</div>
                    <div class="readings-grid">
                      <div class="reading-item">
                        <span class="reading-label">I1:</span>
                        <span class="reading-value">{{formatValue(liveReadings.I1, 'current')}}</span>
                      </div>
                      <div class="reading-item">
                        <span class="reading-label">I2:</span>
                        <span class="reading-value">{{formatValue(liveReadings.I2, 'current')}}</span>
                      </div>
                      <div class="reading-item">
                        <span class="reading-label">I3:</span>
                        <span class="reading-value">{{formatValue(liveReadings.I3, 'current')}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="readings-section">
                    <div class="readings-title">Voltage</div>
                    <div class="readings-grid">
                      <div class="reading-item">
                        <span class="reading-label">U1:</span>
                        <span class="reading-value">{{formatValue(liveReadings.U1, 'voltage')}}</span>
                      </div>
                      <div class="reading-item">
                        <span class="reading-label">U2:</span>
                        <span class="reading-value">{{formatValue(liveReadings.U2, 'voltage')}}</span>
                      </div>
                      <div class="reading-item">
                        <span class="reading-label">U3:</span>
                        <span class="reading-value">{{formatValue(liveReadings.U3, 'voltage')}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="step-icons">
                <button mat-icon-button *ngIf="stepStatus['liveReadings'] !== 'success'"
                        [disabled]="loadingSteps['liveReadings'] || stepStatus['signalStrength'] !== 'success'"
                        (click)="getLiveReadings()"
                        matTooltip="Get live meter readings"
                        class="action-button">
                  <mat-icon *ngIf="!loadingSteps['liveReadings']" class="action-icon">play_circle</mat-icon>
                  <mat-spinner *ngIf="loadingSteps['liveReadings']" diameter="24" class="action-spinner"></mat-spinner>
                </button>
                <button mat-icon-button *ngIf="stepStatus['liveReadings'] === 'success'"
                        class="success-button"
                        matTooltip="Readings received successfully">
                  <mat-icon class="success-icon">check_circle</mat-icon>
                </button>
                <button mat-icon-button *ngIf="stepStatus['liveReadings'] === 'success'"
                        class="retry-button"
                        (click)="retryStep('liveReadings')"
                        matTooltip="Refresh readings">
                  <mat-icon class="retry-icon">restart_alt</mat-icon>
                </button>
                <mat-icon *ngIf="stepStatus['liveReadings'] === 'failure'"
                         class="error-icon"
                         matTooltip="Failed to get readings">error</mat-icon>
              </div>
            </div>
          </ng-template>
        </mat-step>
      </mat-stepper>

      <div class="disconnect-section">
        <button mat-raised-button class="danger-button"
                (click)="disconnectMeter()"
                [disabled]="loading || !installationForm.valid"
                matTooltip="Disconnect this meter">
          Disconnect Meter
          <mat-spinner *ngIf="loading" diameter="20" class="button-spinner"></mat-spinner>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div> 