import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardDataService } from '../../services/dashboard-data/dashboard-data.service';
import { NgToastService } from 'ng-angular-popup';
import { Panel } from 'src/app/models/panel';

@Component({
  selector: 'app-panel-meter-component',
  templateUrl: './panel-meter-component.component.html',
  styleUrls: ['./panel-meter-component.component.scss']
})
export class PanelMeterComponentComponent implements OnInit {
  activeId = 1;
  public activeIdGateway = 10;
  public activeIdExpansions = 10;
  public panel_name = this.data.panel.panel_name;
  public fetchLive = this.data.fetch_live_meter;
  public panels = this.data.panels.filter((panel: Panel) => {
    return (
      panel.meter_id != null &&
      panel.meter_id != (this.data.panel.meter_id ?? null)
    );
  });
  public referencePanels: Panel[] = [];
  public meterName: string = this.data.panel.meter_name ?? '';
  public current_meter_id: number = this.data.panel.meter_id ?? null;
  public current_dialog_panel_id = this.data.panel.panel_id;
  public isPanelConnected = false;

  public EMPTY_PORT = 'Empty Port';

  public panel_expansions: any = {};
  public expansion_A: any = [];
  public expansion_B: any = [];
  public expansion_C: any = [];
  public expansion_D: any = [];

  public render_dialog: boolean = false;
  public userCheckA: boolean = true;
  public userCheckB: boolean = false;
  public userCheckC: boolean = false;
  public userCheckD: boolean = false;
  public editborderEnabled = 'none';
  public editpointerEnabled = 'none';
  public phaseEnabled = 'none';
  public metereditborderEnabled = 'none';
  public metereditpointerEnabled = 'none';
  public editHandle: boolean = false;
  public saveDisabledOpacity = '100%';
  public saveDisabled = '';
  public editDisabledOpacity = '100%';
  public editDisabled = '';
  public enableCircuitPatchSave: boolean = false;
  public patchExpansion: any = []; //array used to save the updated responses of circuit tag
  public currentCircuitTagExpansion: any = [];
  public noneEdit: boolean = false; // flag to check if user only edit and do nothing and click save
  public fineuserCheckA: boolean = true;
  public fineuserCheckB: boolean = false;
  public fineuserCheckC: boolean = false;
  public fineuserCheckD: boolean = false;
  public wattageFlag: boolean = true;
  public currentFlag: boolean = false;
  public voltageSectionList: any = ['0.00', '0.00', '0.00'];
  public currentSectionList: any = ['0.00a', '0.00a', '0.00a'];
  public wattSectionList: any = ['0.00w', '0.00w', '0.00w'];
  public currentSectionTotal: number = 0.0;
  public wattageSectionTotal: number = 0.0;
  public dropdownDisabled: any = 'none';
  public selectedValue: any;
  public meterNameIsChanged: boolean = false;
  public radioPhase: any = [
    { id: 0, value: false },
    { id: 1, value: false },
    { id: 2, value: false }
  ];
  public selectedOptionPort1 = '';
  public selectedOptionPort2 = '';
  public selectedOptionPort3 = '';
  public isLoading: boolean = true;

  public selectedvoltageReferencePanel: string | null = null;
  public voltageReferenceCurrentMeter: boolean = true;
  public isVoltageReferenceSource: boolean = true;
  public isVoltageReferenceType: boolean = false;

  public voltageTypeList: any = [];
  public currentCtClamps: any = [];
  public selectedVoltageType: any = '';
  public selectedCurrentCtClamp: any = '';
  public selectedCurrentCtClamp2: any = '';
  public selectedCurrentCtClamp3: any = '';

  public fineTuningVoltageToggle = true;
  public fineTuningSensorToggle = false;

  public expansionTitle: string = 'Live Feed';
  public expansionColumn: string = 'Fine Tuning';
  public expansionEditAllowed: boolean = false;

  public network: string = '-';
  public connectivity: string = '-'; // ethernet, wifi, offline
  public gateway: string = '5';
  public meterPhase: string = '1';

  public device_commands: Record<string, number> = {};
  public calibrationStage = 0;
  private calibrationCount = 0;
  private componentDestroyed = false;

  private voltage_coefficients: Record<string, number> = {};
  public voltage_transformers: Record<string, string> = {};

  public transformerOptions = [
    { id: null, value: null, label: 'Select a Transformer', disabled: true },
    {
      id: '9V Transformer',
      value: '9V Transformer',
      label: '9V Transformer',
      disabled: false
    },
    { id: 'X', value: '', label: 'X', disabled: false }
  ];

  public voltageOptions = [
    { id: 'live_readings', label: 'Live Readings' },
    { id: 'source_&_mapping', label: 'Source & Mapping' },
    { id: 'customize_coeficient', label: 'Customize Co-Efficient' }
  ];
  public selectedVoltageOption = 0;

  public liveVoltageReadings = [
    {
      id: 'v1',
      label: 'Port V1',
      value: () => this.voltageSectionList[0],
      isActive: () => this.voltage_transformers?.['V1'] ?? false
    },
    {
      id: 'v2',
      label: 'Port V2',
      value: () => this.voltageSectionList[1],
      isActive: () => this.voltage_transformers?.['V2'] ?? false
    },
    {
      id: 'v3',
      label: 'Port V3',
      value: () => this.voltageSectionList[2],
      isActive: () => this.voltage_transformers?.['V3'] ?? false
    }
  ];

  public sourceAndMapping = [
    {
      id: 'use_this_gateway',
      label: 'Use this gateway',
      type: 'select',
      fields: [
        {
          label: 'Voltage Type',
          options: () =>
            [
              {
                id: '',
                value: '',
                label: 'Select Voltage Type',
                disabled: true
              },
              ...this.voltageTypeList.map((d: any) => ({
                id: d.name,
                value: d.name,
                label: d.name,
                disabled: false
              }))
            ] as typeof this.transformerOptions,
          value: () => this.data.panel['voltage_type'] ?? '',
          change: ($event: Event) => this.handleGatewayVoltageTypeUpdate($event)
        },
        {
          label: 'Transformer Type',
          options: () => this.transformerOptions,
          value: () => this.data.panel['voltage_refrence_type'] ?? '',
          change: ($event: Event) =>
            this.handleGatewayVoltageReferenceTypeUpdate($event)
        }
      ]
    },
    {
      id: 'artificial_voltage',
      label: 'Set Artificial Voltage',
      type: 'text'
    },
    {
      id: 'use_another_gateway',
      label: 'Use another gateway',
      type: 'select',
      fields: [
        {
          label: '',
          options: () =>
            [
              { id: '', value: '', label: 'Select Panel', disabled: true },
              ...(
                this.data.panels.filter(
                  (d: Panel) => d.panel_id !== this.current_dialog_panel_id
                ) as any[]
              ).map(d => ({
                id: d.meter_id,
                value: d.meter_id,
                label: d.panel_name,
                disabled: false
              }))
            ] as typeof this.transformerOptions,
          value: () =>
            (!!this.data.panel.reference_meter &&
              this.panels.filter(
                (d: any) => d.meter_name === this.data.panel.reference_meter
              )[0]?.meter_id) ||
            '',
          change: ($event: Event) =>
            this.handleAnotherGatewayMeterChange($event)
        }
      ]
    }
  ];

  public isArtificalVoltageEditing = false;
  public artificalVoltageValue = () =>
    this.panel_expansions['artificial_voltage']?.['V1'] ?? '';

  public sourceAndMappingPorts = [
    {
      id: 'V1',
      label: 'Port V1',
      value: () => this.voltage_transformers?.['V1'] ?? '',
      isActive: () => this.voltage_transformers?.['V1'] ?? false
    },
    {
      id: 'V2',
      label: 'Port V2',
      value: () => this.voltage_transformers?.['V2'] ?? '',
      isActive: () => this.voltage_transformers?.['V2'] ?? false
    },
    {
      id: 'V3',
      label: 'Port V3',
      value: () => this.voltage_transformers?.['V3'] ?? '',
      isActive: () => this.voltage_transformers?.['V3'] ?? false
    }
  ];

  public selectedSourceAndMapping = 0;

  public portCoefficient = [
    {
      id: 'V1',
      label: 'Port V1',
      subLabel: () => this.voltage_transformers?.['V1'] ?? '',
      value: () => this.voltage_coefficients?.['V1'] ?? '',
      isActive: () => this.voltage_transformers?.['V1'] ?? false
    },
    {
      id: 'V2',
      label: 'Port V2',
      subLabel: () => this.voltage_transformers?.['V2'] ?? '',
      value: () => this.voltage_coefficients?.['V2'] ?? '',
      isActive: () => this.voltage_transformers?.['V2'] ?? false
    },
    {
      id: 'V3',
      label: 'Port V3',
      subLabel: () => this.voltage_transformers?.['V3'] ?? '',
      value: () => this.voltage_coefficients?.['V3'] ?? '',
      isActive: () => this.voltage_transformers?.['V3'] ?? false
    }
  ];

  public portsCoefficientEditedValue: Record<string, string | number> = {};
  public isPortsCoefficientEditing: string | null = null;

  public generateCofficientVoltageSourceOptions: {
    id: string;
    label: string;
    value: string;
    panel: Panel | null;
    disabled: boolean;
    voltage_reference: string;
  }[] = [
    {
      id: '',
      value: '',
      label: 'Select a voltage source',
      panel: null,
      voltage_reference: '',
      disabled: true
    }
  ];
  public generateCofficientLiveVoltageReading: number = 0;
  public generateCofficientVoltageCoefficient: number = 1;
  public userVoltageReading = '';
  public calculatedCoEfficient: number = 0;
  public panelDeviceCommands: any[] = [];

  private reference_panel_voltage_transformers: Record<
    string,
    typeof this.voltage_transformers
  > = {};

  public connectivityOptions = [
    { label: 'Wifi', isSelected: () => this.connectivity === 'wifi' },
    {
      label: 'Ethernet Cable',
      isSelected: () => this.connectivity === 'ethernet'
    }
  ];

  public wifiPower = {
    fill: '#1FAB3D',
    text: 'Excellent',
    value: 0,
    wifiStrength: 4
  };

  public selectedPanelKey = 'panel_id';

  public loadBalancingOptions = [
    {
      label: 'No Balancing',
      subLabel: 'No Loads Balancing on this Panel',
      src: 'assets/images/no_balancing.png',
      isSelected: () => this.loadBalancingOption === null,
      onChange: () => this.handleLoadBalancingOptionChange(null)
    },
    {
      label: 'Mains Balancing',
      subLabel: 'The mains will be balancing out circuits',
      src: 'assets/images/mains_balancing.png',
      isSelected: () => this.loadBalancingOption === 'mains_balancing',
      onChange: () => this.handleLoadBalancingOptionChange('mains_balancing')
    },
    {
      label: 'Circuits Balancing',
      subLabel: 'The circuits will balance out mains',
      src: 'assets/images/circuits_balancing.png',
      isSelected: () => this.loadBalancingOption === 'circuits_balancing',
      onChange: () => this.handleLoadBalancingOptionChange('circuits_balancing')
    }
  ];

  public loadBalancingOption: null | string = null;

  public setupOption = [
    { id: 'initial_setup', label: 'Inital Setup', disabled: false },
    { id: 'calibration_history', label: 'Calibration History', disabled: false }
  ];
  public setupOptionSelectionIndex = 0;

  public overTheAirUpdateOption = [
    { id: 'calibration', label: 'Calibration', disabled: false },
    { id: 'software_updates', label: 'Software Updates', disabled: true }
  ];
  public overTheAirUpdateOptionIndex = 0;

  constructor(
    private toast: NgToastService,
    public dialogRef: MatDialogRef<PanelMeterComponentComponent>,
    private dashboardDataService: DashboardDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getPanelData();
    this.loadBalancingOption = this.data.panel.load_balancing;
  }

  getPanelData() {
    this.isPortsCoefficientEditing = null;
    this.userVoltageReading = '';
    this.generateCofficientLiveVoltageReading = 0;
    this.generateCofficientVoltageCoefficient = 1;
    this.calculatedCoEfficient = 0;
    this.reference_panel_voltage_transformers = {};
    this.portsCoefficientEditedValue = {};
    this.device_commands = {};
    this.voltage_coefficients = {};
    this.network = '-';
    this.wifiPower = {
      fill: '#ED1C24',
      text: 'Unusable',
      wifiStrength: 0,
      value: -100
    };
    this.voltage_transformers = {};
    this.connectivity = '-';
    this.panelDeviceCommands = [];

    this.referencePanels = this.panels.filter(
      (d: Panel) =>
        d.meter_name !== this.data.panel.meter_name &&
        d.meter_name === this.data.panel.reference_meter
    );

    if (this.data.panel.reference_meter) {
      this.selectedSourceAndMapping = 2;
      this.getReferencePanelExpansion();
    }

    this.getPanelExpansion();

    this.isPanelConnected = this.panels[0]?.panelIsconnected ?? false;

    const subs = this.dashboardDataService
      .getPanelDeviceCommandByMeter(this.current_meter_id)
      .subscribe(response => {
        subs.unsubscribe();

        const results = response.results;

        this.panelDeviceCommands = results;

        if (results.length > 0) {
          if (results[0].is_successful) {
            this.calibrationStage = 2;
          } else {
            this.calibrationStage = 1;
            this.getPanelDeviceCommandsStatus(results[0].meter);
          }
        }
      });
  }

  getLiveMeterData() {
    if (this.componentDestroyed) return;
    this.liveMeterAPI();
    setTimeout(() => this.getLiveMeterData(), 2000);
  }

  ngOnInit(): void {
    this.getLiveMeterData();

    this.dashboardDataService.getVoltageType().subscribe((result: any) => {
      this.voltageTypeList = result.results;
    });

    this.dashboardDataService.getCurrentCTClamp().subscribe((result: any) => {
      this.currentCtClamps = result.results;
    });
  }

  ngOnDestroy(): void {
    this.fetchLive = false;
    this.componentDestroyed = true;
  }

  getGenerateCoefficientVoltageSourceOptions() {
    this.generateCofficientVoltageSourceOptions = [
      {
        id: '',
        value: '',
        label: 'Select a voltage source',
        panel: null,
        voltage_reference: '',
        disabled: true
      }
    ];

    this.portCoefficient.forEach(d => {
      this.generateCofficientVoltageSourceOptions.push({
        id: `${this.data.panel.panel_name}-${d.id}`,
        label: `${this.data.panel.panel_name}-${d.id}`,
        value: `${this.data.panel.panel_name}-${d.id}`,
        panel: this.data.panel,
        voltage_reference: d.id,
        disabled: false
      });
    });

    this.portCoefficient.forEach(d =>
      this.referencePanels.forEach(e => {
        this.generateCofficientVoltageSourceOptions.push({
          id: `${e.panel_name}-${d.id}`,
          label: `${e.panel_name}-${d.id}`,
          value: `${e.panel_name}-${d.id}`,
          voltage_reference: d.id,
          panel: e,
          disabled: false
        });
      })
    );
  }

  getPanelExpansion() {
    this.isLoading = true;

    this.dashboardDataService
      .getPanelExpansion(this.current_dialog_panel_id)
      .subscribe(
        (expansions: any) => {
          this.current_meter_id = expansions.meter_id;

          if (expansions.connectivity) {
            this.connectivity = expansions.connectivity.network_type;

            if (expansions.connectivity.network_params) {
              this.network = expansions.connectivity.network_params.ssid;
              const { rssi } = expansions.connectivity.network_params;

              if (rssi >= -30) {
                this.wifiPower = {
                  fill: '#1FAB3D',
                  text: 'Excellent',
                  wifiStrength: 3,
                  value: rssi
                };
              } else if (rssi >= -60) {
                this.wifiPower = {
                  fill: '#1FAB3D',
                  text: 'Very Good',
                  wifiStrength: 3,
                  value: rssi
                };
              } else if (rssi >= -70) {
                this.wifiPower = {
                  fill: '#3649A8',
                  text: 'Okay',
                  wifiStrength: 2,
                  value: rssi
                };
              } else if (rssi >= -90) {
                this.wifiPower = {
                  fill: '#FF7F27',
                  text: 'Not Good',
                  wifiStrength: 1,
                  value: rssi
                };
              } else {
                this.wifiPower = {
                  fill: '#ED1C24',
                  text: 'Unusable',
                  wifiStrength: 0,
                  value: rssi
                };
              }
            }
          }

          if (expansions.voltage_coefficients) {
            this.voltage_coefficients = expansions.voltage_coefficients;
          }

          if (expansions.voltage_transformers) {
            this.voltage_transformers = expansions.voltage_transformers;
          }

          this.panel_expansions = expansions;

          if (expansions.artificial_voltage?.['V1']) {
            this.selectedSourceAndMapping = 1;
          }

          this.selectedVoltageType = expansions.voltage_type;
          this.selectedCurrentCtClamp = expansions.current_ct_clamp ?? 'null';
          this.selectedCurrentCtClamp2 = expansions.current_ct_clamp2 ?? 'null';
          this.selectedCurrentCtClamp3 = expansions.current_ct_clamp3 ?? 'null';

          let current_expansions: any = expansions.results ?? [];
          if (expansions.number_of_phases == '1') {
            this.radioPhase[0].value = true;
            this.radioPhase[1].value = false;
            this.radioPhase[2].value = false;
          } else if (expansions.number_of_phases == '2') {
            this.radioPhase[0].value = false;
            this.radioPhase[1].value = true;
            this.radioPhase[2].value = false;
          } else if (expansions.number_of_phases == '3') {
            this.radioPhase[0].value = false;
            this.radioPhase[1].value = false;
            this.radioPhase[2].value = true;
          }
          // fetch document by element id voltage_port_ and populate option of the select.
          if (expansions.voltage_port_number == '1') {
            this.selectedOptionPort1 = expansions.voltage_refrence_type;
          } else if (expansions.voltage_port_number == '2') {
            this.selectedOptionPort2 = expansions.voltage_refrence_type;
          } else if (expansions.voltage_port_number == '3') {
            this.selectedOptionPort3 = expansions.voltage_refrence_type;
          }
          this.selectedvoltageReferencePanel = expansions.reference_meter;
          if (expansions.reference_meter != null) {
            this.isVoltageReferenceType = true;
            this.isVoltageReferenceSource = false;
            this.selectedvoltageReferencePanel = expansions.reference_meter;
          }

          this.voltageReferenceCurrentMeter =
            expansions.reference_meter == null ? true : false;

          if (expansions.meter_name && expansions.meter_name.length > 0) {
            this.meterName = expansions.meter_name;
          }

          this.expansion_A = [];
          this.expansion_B = [];
          this.expansion_C = [];
          this.expansion_D = [];

          for (let i = 0; i < current_expansions.length; i++) {
            let expansion = current_expansions[i];
            let keyValue = {
              id: expansion.id,
              expansion_type: expansion.expansion_type,
              expansion_number: expansion.expansion_number,
              circuit_name: '',
              circuit_number: '',
              colour: '',
              amps: '0.0a',
              voltage_reference: expansion.voltage_refrence_type ?? 'V1',
              watts: '0.00w',
              current_ct_clamp: expansion.current_ct_clamp
            };
            if (expansion.circuit != null) {
              keyValue.circuit_name = expansion.circuit.circuit_name;
              keyValue.circuit_number = '#' + expansion.circuit.circuit_number;

              if (expansion.circuit.category != null) {
                keyValue.colour = expansion.circuit.category.colour;
              }
            }

            if (i < 16) {
              this.expansion_A.push(keyValue);
            } else if (i > 15 && i < 32) {
              this.expansion_B.push(keyValue);
            } else if (i > 31 && i < 48) {
              this.expansion_C.push(keyValue);
            } else if (i > 47 && i < 64) {
              this.expansion_D.push(keyValue);
            }
          }

          this.selectedValue = 'V3';
          this.saveDisabled = 'none';
          this.saveDisabledOpacity = '30%';
          this.render_dialog = true;

          this.getGenerateCoefficientVoltageSourceOptions();
          this.isLoading = false;
        },
        (err: any) => {
          this.fetchLive = false; //fetch api live when meter is not created
          this.metereditborderEnabled = '';
          this.metereditpointerEnabled = '';
          this.editDisabled = 'none';
          this.editDisabledOpacity = '30%';
          this.render_dialog = true;
          this.isLoading = false;
        }
      );
  }

  liveMeterAPI() {
    if (this.fetchLive && this.meterName) {
      const subs = this.dashboardDataService
        .fetchLatestMeterData(this.meterName)
        .subscribe(result => {
          subs.unsubscribe();

          if (Object.keys(result).length > 0) {
            if (result['ext1'] == undefined || result['ext1'] == null) {
              result['ext1'] = { data: {} };
            }
            if (result['ext2'] == undefined || result['ext2'] == null) {
              result['ext2'] = { data: {} };
            }
            if (result['ext3'] == undefined || result['ext3'] == null) {
              result['ext3'] = { data: {} };
            }
            if (result['ext4'] == undefined || result['ext4'] == null) {
              result['ext4'] = { data: {} };
            }
            for (let i = 0; i < 16; i++) {
              let id = `I${i + 1}`;
              this.expansion_A[i].amps =
                parseFloat(result['ext1']['data'][id] ?? 0).toFixed(2) + 'a';
              this.expansion_B[i].amps =
                parseFloat(result['ext2']['data'][id] ?? 0).toFixed(2) + 'a';
              this.expansion_C[i].amps =
                parseFloat(result['ext3']['data'][id] ?? 0).toFixed(2) + 'a';
              this.expansion_D[i].amps =
                parseFloat(result['ext4']['data'][id] ?? 0).toFixed(2) + 'a';
            }

            result = result['main'];

            this.voltageSectionList = [];
            this.currentSectionList = [];
            this.wattSectionList = [];

            this.voltageSectionList.push(result['data']['U1'].toFixed(2));
            this.voltageSectionList.push(result['data']['U2'].toFixed(2));
            this.voltageSectionList.push(result['data']['U3'].toFixed(2));

            this.currentSectionList.push(
              this.selectedCurrentCtClamp !== 'null'
                ? result['data']['I1'].toFixed(2)
                : this.EMPTY_PORT
            );
            this.currentSectionList.push(
              this.selectedCurrentCtClamp2 !== 'null'
                ? result['data']['I2'].toFixed(2)
                : this.EMPTY_PORT
            );
            this.currentSectionList.push(
              this.selectedCurrentCtClamp3 !== 'null'
                ? result['data']['I3'].toFixed(2)
                : this.EMPTY_PORT
            );
            this.wattSectionList.push(
              (
                parseFloat(result['data']['U1']) *
                parseFloat(result['data']['I1'])
              ).toFixed(2) + 'w'
            );
            this.wattSectionList.push(
              (
                parseFloat(result['data']['U2']) *
                parseFloat(result['data']['I2'])
              ).toFixed(2) + 'w'
            );
            this.wattSectionList.push(
              (
                parseFloat(result['data']['U3']) *
                parseFloat(result['data']['I3'])
              ).toFixed(2) + 'w'
            );
            this.currentSectionTotal =
              parseFloat(result['data']['I1']) +
              parseFloat(result['data']['I2']) +
              parseFloat(result['data']['I3']);
            this.wattageSectionTotal =
              parseFloat(result['data']['U1']) *
                parseFloat(result['data']['I1']) +
              parseFloat(result['data']['U2']) *
                parseFloat(result['data']['I2']) +
              parseFloat(result['data']['U3']) *
                parseFloat(result['data']['I3']);
            for (const element of this.expansion_A) {
              if (element.voltage_reference == 'V1') {
                element.watts =
                  (
                    parseFloat(result['data']['U1']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              } else if (element.voltage_reference == 'V2') {
                this.expansion_A.watts =
                  (
                    parseFloat(result['data']['U2']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              } else if (element.voltage_reference == 'V3') {
                this.expansion_A.watts =
                  (
                    parseFloat(result['data']['U3']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              }
            }
            for (const element of this.expansion_B) {
              if (element.voltage_reference == 'V1') {
                element.watts =
                  (
                    parseFloat(result['data']['U1']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              } else if (element.voltage_reference == 'V2') {
                element.watts =
                  (
                    parseFloat(result['data']['U2']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              } else if (element.voltage_reference == 'V3') {
                element.watts =
                  (
                    parseFloat(result['data']['U3']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              }
            }
            for (const element of this.expansion_C) {
              if (element.voltage_reference == 'V1') {
                element.watts =
                  (
                    parseFloat(result['data']['U1']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              } else if (element.voltage_reference == 'V2') {
                element.watts =
                  (
                    parseFloat(result['data']['U2']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              } else if (element.voltage_reference == 'V3') {
                element.watts =
                  (
                    parseFloat(result['data']['U3']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              }
            }
            for (const element of this.expansion_D) {
              if (element.voltage_reference == 'V1') {
                element.watts =
                  (
                    parseFloat(result['data']['U1']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              } else if (element.voltage_reference == 'V2') {
                element.watts =
                  (
                    parseFloat(result['data']['U2']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              } else if (element.voltage_reference == 'V3') {
                element.watts =
                  (
                    parseFloat(result['data']['U3']) * parseFloat(element.amps)
                  ).toFixed(2) + 'w';
              }
            }
          }
        });
    }
  }
  editEnabled() {
    this.enableCircuitPatchSave = true;
  }

  selectRowChange(
    event: any,
    inputId: any,
    circuitId: any,
    current_ct_clamp: any
  ) {
    var circuitvalueInput: any = (
      document.getElementById(inputId) as HTMLInputElement
    ).value.replace(/#/g, '');
    if (circuitvalueInput.length > 0) {
      var voltageRef;
      if (event == 'V1') {
        voltageRef = this.voltageSectionList[0].replace(/V/g, '');
      } else if (event == 'V2') {
        voltageRef = this.voltageSectionList[1].replace(/V/g, '');
      } else if (event == 'V3') {
        voltageRef = this.voltageSectionList[2].replace(/V/g, '');
      }
      var keyPair = [
        {
          expansion_number: inputId,
          circuit_no: circuitvalueInput,
          voltage_refrence_type: event,
          voltage_refrence_value: voltageRef,
          current_ct_clamp: current_ct_clamp
        }
      ];
      this.dashboardDataService
        .updateMeterCircuit(circuitId, this.current_dialog_panel_id, keyPair)
        .subscribe(
          result => {
            this.toast.success({
              detail: 'Success',
              summary: 'Voltage is Updated',
              sticky: true,
              position: 'tr'
            });
            this.updateResponse();
          },
          (err: any) => {
            this.toast.error({
              detail: 'Error',
              summary: err.error[0],
              sticky: true,
              position: 'tr'
            });
          }
        );
    } else {
      this.toast.error({
        detail: 'Error',
        summary: 'Circuit is not tag on this expansion',
        sticky: true,
        position: 'tr'
      });
    }
  }

  handleMeterSave() {
    if (this.current_meter_id == undefined || !this.editHandle) {
      this.noneEdit = false;
      this.saveDisabled = 'none';
      this.saveDisabledOpacity = '50%';
      // var meterPhase = ''
      var meter_name = (
        document.getElementById('meter-name') as HTMLInputElement
      ).value;
      // var checkmeterPhase: any = document.getElementsByClassName("number-of-phase-radio-single")

      // if (checkmeterPhase[0].checked) {
      //   meterPhase = '1'
      // } else if (checkmeterPhase[1].checked) {
      //   meterPhase = '2'
      // } else if (checkmeterPhase[2].checked) {
      //   meterPhase = '3'
      // }
      this.dashboardDataService
        .createMeter(this.current_dialog_panel_id, meter_name, this.meterPhase)
        .subscribe(
          events => {
            // this.closePopup()
            this.meterNameIsChanged = true;
            this.updateResponse();
            this.toast.success({
              detail: 'Success',
              summary: `${meter_name} succesfully created`,
              sticky: true,
              position: 'tr'
            });
            this.dashboardDataService
              .fetchLiveMeterAPI('ext1', meter_name)
              .subscribe(result => {
                if (Object.keys(result).length === 0) {
                  this.fetchLive = false; //fetch api live when meter is not created
                  this.toast.info({
                    detail: 'Info',
                    summary: `${meter_name} does not have live meter reading`,
                    sticky: true,
                    position: 'tr'
                  });
                } else if (Object.keys(result).length > 0) {
                  this.fetchLive = true; //fetch api live when meter is not created
                }
              });
            this.editDisabled = '';
            this.editDisabledOpacity = '100%';
            this.saveDisabled = 'none';
            this.saveDisabledOpacity = '30%';
            this.dropdownDisabled = '';
            this.metereditborderEnabled = 'none';
            this.metereditpointerEnabled = 'none';
            this.expansionEditAllowed = false;
          },
          (err: any) => {
            console.log(err);
            this.saveDisabled = '';
            this.saveDisabledOpacity = '100%';
            this.fetchLive = false;
            this.toast.error({
              detail: 'Error',
              summary: 'We are not able to attach to this meter',
              sticky: true,
              position: 'tr'
            });
          }
        );
    } else {
      if (document.getElementById('meter-name') as HTMLInputElement) {
        var meter_name = (
          document.getElementById('meter-name') as HTMLInputElement
        ).value;
        if (this.panel_expansions.meter_name != meter_name) {
          this.noneEdit = false;
          this.saveDisabled = 'none';
          this.saveDisabledOpacity = '50%';
          // var meterphase = ''
          // var checkmeterPhase: any = document.getElementsByClassName("number-of-phase-radio-single")
          // if (checkmeterPhase[0].checked) {
          //   meterphase = '1'
          // } else if (checkmeterPhase[1].checked) {
          //   meterphase = '2'
          // } else if (checkmeterPhase[2].checked) {
          //   meterphase = '3'
          // }
          this.dashboardDataService
            .updateMeterName(
              this.current_dialog_panel_id,
              meter_name,
              this.current_meter_id,
              this.meterPhase
            )
            .subscribe(
              update => {
                this.meterNameIsChanged = true;
                this.toast.success({
                  detail: 'Success',
                  summary: `${meter_name} succesfully Updated`,
                  sticky: true,
                  position: 'tr'
                });
                this.dashboardDataService
                  .fetchLiveMeterAPI('ext1', meter_name)
                  .subscribe(result => {
                    if (Object.keys(result).length == 0) {
                      this.updateResponse();
                      this.fetchLive = false; //fetch api live when meter is not created
                      this.toast.info({
                        detail: 'Info',
                        summary: `${meter_name} does not have live meter reading`,
                        sticky: true,
                        position: 'tr'
                      });
                    } else if (Object.keys(result).length > 0) {
                      this.updateResponse();
                      this.fetchLive = true; //fetch api live when meter is not created
                    }
                  });
                this.editDisabled = '';
                this.editDisabledOpacity = '100%';
                this.editHandle = false;
                this.editborderEnabled = 'none';
                this.editpointerEnabled = 'none';
                this.metereditborderEnabled = 'none';
                this.metereditpointerEnabled = 'none';
                this.saveDisabled = 'none';
                this.dropdownDisabled = '';
                this.phaseEnabled = 'none';
                this.saveDisabledOpacity = '30%';
                this.expansionEditAllowed = true;
              },
              (err: any) => {
                this.saveDisabled = '';
                this.saveDisabledOpacity = '100%';
                this.toast.error({
                  detail: 'Error',
                  summary: 'Meter already exist',
                  sticky: true,
                  position: 'tr'
                });
              }
            );
        }
      }
    }
  }

  handleSave() {
    if (this.enableCircuitPatchSave) {
      this.noneEdit = false;
      let expanse = this.userCheckA
        ? this.expansion_A
        : this.userCheckB
        ? this.expansion_B
        : this.userCheckC
        ? this.expansion_C
        : this.expansion_D;
      var inputCollection: any = document.getElementsByClassName(
        'circuit-number-txt'
      ) as HTMLCollection;
      var updatedcollection: any = [];
      var nonUpdatedcollection: any = [];
      var unmappedCollection: any = [];

      if (inputCollection) {
        for (let j = 0; j < inputCollection.length; j++) {
          let value = inputCollection[j].value.trim();

          if (value.toLowerCase() === 'x') {
            value = '';
          }

          const keyValue = {
            id: inputCollection[j].id,
            value: inputCollection[j].classList[0],
            name: inputCollection[j].name,
            circuit: value
          };
          
          if (value != '') {
            if (
              expanse.some(
                (item: any) =>
                  item.expansion_number == keyValue.id &&
                  item.circuit_number != keyValue.circuit
              )
            ) {
              updatedcollection.push(keyValue);
            } else {
              nonUpdatedcollection.push(keyValue);
            }
          } else {
            if (
              expanse.some(
                (item: any) =>
                  item.expansion_number == keyValue.id &&
                  item.circuit_number != keyValue.circuit
              )
            ) {
              unmappedCollection.push(keyValue);
            }
          }
        }
        // updatedcollection shoudl not have duplicate circuit values
        updatedcollection.forEach((element: any) => {
          if (
            updatedcollection.some(
              (item: any) =>
                item.circuit == element.circuit && item.id != element.id
            )
          ) {
            updatedcollection = [];

            this.toast.error({
              detail: 'Error',
              summary: `${element.circuit} is repeated in edited value`,
              sticky: true,
              position: 'tr'
            });
          }
        });

        updatedcollection.forEach((element: any) => {
          if (
            nonUpdatedcollection.some(
              (item: any) =>
                item.circuit == element.circuit ||
                item.circuit.replace(/#/g, '') == element.circuit
            )
          ) {
            updatedcollection = [];
            this.toast.error({
              detail: 'Error',
              summary: `${element.circuit} is already assigned`,
              sticky: true,
              position: 'tr'
            });
          }
        });

        for (let i = 0; i < updatedcollection.length; i++) {
          if (
            this.currentCircuitTagExpansion.some(
              (item: any) => item.id == updatedcollection[i].id
            )
          ) {
            this.updateCircuit(
              updatedcollection[i].id,
              updatedcollection[i].value,
              updatedcollection[i].name
            );
          } else {
            try {
              this.updateCircuit(
                updatedcollection[i].id,
                updatedcollection[i].value,
                updatedcollection[i].name
              );
            } catch (error) {
              console.log(error);
            }
          }
        }
        for (let i = 0; i < unmappedCollection.length; i++) {
          this.updateCircuit(
            unmappedCollection[i].id,
            unmappedCollection[i].value,
            unmappedCollection[i].name
          );
        }
      }
      this.editDisabled = '';
      this.editDisabledOpacity = '100%';
      this.editHandle = false;
      this.editborderEnabled = 'none';
      this.editpointerEnabled = 'none';
      this.metereditborderEnabled = 'none';
      this.metereditpointerEnabled = 'none';
      this.saveDisabled = 'none';
      this.dropdownDisabled = 'none';
      this.saveDisabledOpacity = '30%';
      this.phaseEnabled = 'none';
      this.expansionEditAllowed = false;
    }
    if (this.noneEdit) {
      this.phaseEnabled = 'none';
      this.editDisabled = '';
      this.editDisabledOpacity = '100%';
      this.editHandle = false;
      this.editborderEnabled = 'none';
      this.editpointerEnabled = 'none';
      this.metereditborderEnabled = 'none';
      this.metereditpointerEnabled = 'none';
      this.saveDisabled = 'none';
      this.saveDisabledOpacity = '30%';
      this.dropdownDisabled = 'none';
      this.expansionEditAllowed = false;
    }
  }

  ampEdit() {
    (document.getElementById('wattageRadio') as HTMLInputElement).checked =
      false;
    (
      document.getElementById('current-amperRadio') as HTMLInputElement
    ).checked = true;
    // this.handleEdit();
    this.activeId = 2;
  }

  quickEdit() {
    this.handleEdit();
    this.activeId = 2;
  }
  update_voltage_reference(voltage_port_number: string) {
    let voltage_refrence_type = (
      document.getElementById(
        'voltage_port_' + voltage_port_number
      ) as HTMLInputElement
    ).value;
    this.selectedOptionPort1 = voltage_refrence_type;
    if (voltage_port_number == '1') {
      this.selectedOptionPort1 = voltage_refrence_type;
      this.selectedOptionPort2 = '';
      this.selectedOptionPort3 = '';
    } else if (voltage_port_number == '2') {
      this.selectedOptionPort2 = voltage_refrence_type;
      this.selectedOptionPort1 = '';
      this.selectedOptionPort3 = '';
    } else if (voltage_port_number == '3') {
      this.selectedOptionPort3 = voltage_refrence_type;
      this.selectedOptionPort1 = '';
      this.selectedOptionPort2 = '';
    }
    this.dashboardDataService
      .updateMeterVoltageReference(
        this.current_meter_id,
        voltage_port_number,
        voltage_refrence_type
      )
      .subscribe(update => {
        this.toast.info({
          detail: 'Info',
          summary: `voltage reference updated`,
          sticky: true,
          position: 'tr'
        });
      });
  }

  updateVoltageExternalReferencePanel() {
    this.dashboardDataService
      .updateMeterVoltageReferencePanel(
        this.current_meter_id,
        this.selectedvoltageReferencePanel
      )
      .subscribe(update => {
        this.selectedvoltageReferencePanel;
        this.toast.info({
          detail: 'Info',
          summary: `Reference panel updated`,
          sticky: true,
          position: 'tr'
        });
      });
  }

  updateVoltageReferencePanel() {
    if (
      this.voltageReferenceCurrentMeter == false &&
      this.selectedvoltageReferencePanel != null &&
      this.current_meter_id != null
    ) {
      // this.toast.success({ detail: 'Success', summary: `${meter_name} succesfully Updated`, sticky: true, position: 'tr' })
      this.dashboardDataService
        .updateMeterVoltageReferencePanel(
          this.current_meter_id,
          this.selectedvoltageReferencePanel
        )
        .subscribe(update => {
          this.toast.info({
            detail: 'Info',
            summary: `Reference panel updated`,
            sticky: true,
            position: 'tr'
          });
        });
    } else {
      this.toast.info({
        detail: 'Info',
        summary: `Attach a meter to update reference panel`,
        sticky: true,
        position: 'tr'
      });
    }
    if (
      this.voltageReferenceCurrentMeter == true &&
      this.current_meter_id != null &&
      this.selectedvoltageReferencePanel != null
    ) {
      this.selectedvoltageReferencePanel = null;
      this.dashboardDataService
        .updateMeterVoltageReferencePanel(this.current_meter_id, null)
        .subscribe(update => {
          this.toast.info({
            detail: 'Info',
            summary: `Reference panel updated`,
            sticky: true,
            position: 'tr'
          });
        });
    } else {
      this.toast.info({
        detail: 'Info',
        summary: `Attach a meter to update reference panel`,
        sticky: true,
        position: 'tr'
      });
    }
  }
  updateVoltageType() {
    if (this.selectedVoltageType != null && this.current_meter_id != null) {
      this.dashboardDataService
        .updateMeterVoltageType(this.current_meter_id, this.selectedVoltageType)
        .subscribe(update => {
          this.toast.info({
            detail: 'Info',
            summary: `Voltage type updated`,
            sticky: true,
            position: 'tr'
          });

          this.data.panel['voltage_type'] = this.selectedVoltageType;
        });
    } else {
      this.toast.info({
        detail: 'Info',
        summary: `Attach a meter to update voltage type`,
        sticky: true,
        position: 'tr'
      });
    }
  }
  updateCTType() {
    if (this.current_meter_id != null) {
      this.dashboardDataService
        .updateMeterCtClamp(
          this.current_meter_id,
          this.selectedCurrentCtClamp === 'null'
            ? null
            : this.selectedCurrentCtClamp,
          this.selectedCurrentCtClamp2 === 'null'
            ? null
            : this.selectedCurrentCtClamp2,
          this.selectedCurrentCtClamp3 === 'null'
            ? null
            : this.selectedCurrentCtClamp2
        )
        .subscribe(update => {
          this.toast.info({
            detail: 'Info',
            summary: `Current CT Clamp updated`,
            sticky: true,
            position: 'tr'
          });
        });
    } else {
      this.toast.info({
        detail: 'Info',
        summary: `Attach a meter to update ct clamps`,
        sticky: true,
        position: 'tr'
      });
    }
  }

  handleEdit() {
    this.dropdownDisabled = '';
    this.noneEdit = true;
    this.phaseEnabled = '';
    this.editDisabled = 'none';
    this.editDisabledOpacity = '30%';
    this.saveDisabled = '';
    this.saveDisabledOpacity = '100%';
    this.editHandle = true;
    this.editborderEnabled = '';
    this.editpointerEnabled = '';
    this.metereditborderEnabled = '';
    this.metereditpointerEnabled = '';
    this.expansionEditAllowed = true;
  }

  radioEdit(event: any) {
    if (event.target.id == 'numberOfPhase1') {
      (document.getElementById('numberOfPhase1') as HTMLInputElement).checked =
        true;
      (document.getElementById('numberOfPhase2') as HTMLInputElement).checked =
        false;
      (document.getElementById('numberOfPhase3') as HTMLInputElement).checked =
        false;
    } else if (event.target.id == 'numberOfPhase2') {
      (document.getElementById('numberOfPhase1') as HTMLInputElement).checked =
        false;
      (document.getElementById('numberOfPhase2') as HTMLInputElement).checked =
        true;
      (document.getElementById('numberOfPhase3') as HTMLInputElement).checked =
        false;
    } else if (event.target.id == 'numberOfPhase3') {
      (document.getElementById('numberOfPhase1') as HTMLInputElement).checked =
        false;
      (document.getElementById('numberOfPhase2') as HTMLInputElement).checked =
        false;
      (document.getElementById('numberOfPhase3') as HTMLInputElement).checked =
        true;
    }
    var checkmeterPhase: any = document.getElementsByClassName(
      'number-of-phase-radio-single'
    );
    var meterphase = '';
    if (checkmeterPhase[0].checked) {
      meterphase = '1';
    } else if (checkmeterPhase[1].checked) {
      meterphase = '2';
    } else if (checkmeterPhase[2].checked) {
      meterphase = '3';
    }
    var meter_name = (document.getElementById('meter-name') as HTMLInputElement)
      .value;
    this.dashboardDataService
      .updateMeterName(
        this.current_dialog_panel_id,
        meter_name,
        this.current_meter_id,
        meterphase
      )
      .subscribe(update => {
        this.toast.info({
          detail: 'Info',
          summary: `${meter_name} Phase updated`,
          sticky: true,
          position: 'tr'
        });
        this.updateResponse();
      });
  }

  voltageReferenceRadioEdit(event: any) {
    if (event.target.id == 'voltageReferenceSource') {
      (
        document.getElementById('voltageReferenceSource') as HTMLInputElement
      ).checked = true;
      (
        document.getElementById('voltageReferenceType') as HTMLInputElement
      ).checked = false;
      this.isVoltageReferenceSource = true;
      this.isVoltageReferenceType = false;
    } else if (event.target.id == 'voltageReferenceType') {
      (
        document.getElementById('voltageReferenceSource') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('voltageReferenceType') as HTMLInputElement
      ).checked = true;
      this.isVoltageReferenceSource = false;
      this.isVoltageReferenceType = true;
    } else if (event.target.id == 'voltageReferenceCurrentMeter') {
      (
        document.getElementById(
          'voltageReferenceCurrentMeter'
        ) as HTMLInputElement
      ).checked = true;
      (
        document.getElementById(
          'voltageReferenceAnotherMeter'
        ) as HTMLInputElement
      ).checked = false;
      this.voltageReferenceCurrentMeter = true;
      this.updateVoltageReferencePanel();
    } else if (event.target.id == 'voltageReferenceAnotherMeter') {
      (
        document.getElementById(
          'voltageReferenceCurrentMeter'
        ) as HTMLInputElement
      ).checked = false;
      (
        document.getElementById(
          'voltageReferenceAnotherMeter'
        ) as HTMLInputElement
      ).checked = true;
      this.voltageReferenceCurrentMeter = false;
    }
  }

  updateCircuit(expressionId: any, circuitid: any, voltage_ref: any) {
    let element = document.getElementById(expressionId) as HTMLInputElement;
    // in some cases, if circuit name is B-1, then its id is B1 in parent dashboard page and that is retrieved as A tag
    // so we need to get the input elements and find the one with id as expressionId
    if (element?.tagName === 'A') {
      var elements = document.getElementsByTagName('input');
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === expressionId) {
          element = elements[i] as HTMLInputElement;
          break;
        }
      }
    }
    let circuitvalueInput: any = element.value.trim();

    if (circuitvalueInput.toLowerCase() === 'x') {
      circuitvalueInput = '';
    } else {
      circuitvalueInput = circuitvalueInput.replace(/#/g, '');
    }

    element.style.removeProperty('borderColor');

    const keyPair = [
      {
        expansion_number: expressionId,
        circuit_no: circuitvalueInput,
        voltage_refrence_type: voltage_ref,
        voltage_refrence_value: 0
      }
    ];

    this.dashboardDataService
      .updateMeterCircuit(circuitid, this.current_dialog_panel_id, keyPair)
      .subscribe(
        result => {
          this.updateResponse();
          (document.getElementById(expressionId) as HTMLInputElement).disabled =
            false;
          (
            document.getElementById(expressionId) as HTMLInputElement
          ).style.color = '#7F7F7F';
          this.toast.success({
            detail: 'Success',
            summary: 'Circuits are Updated',
            sticky: true,
            position: 'tr'
          });
        },
        (err: any) => {
          let displayError =
            err.error[0] ??
            `${expressionId} - ${circuitvalueInput} is not a valid Circuit`;
          this.toast.error({
            detail: 'Error',
            summary: displayError,
            sticky: true,
            position: 'tr'
          });
          (
            document.getElementById(expressionId) as HTMLInputElement
          ).style.borderColor = 'red';
          // ((document.getElementById(expressionId) as HTMLInputElement).style.color = '#fd3636');

          (document.getElementById(expressionId) as HTMLInputElement).disabled =
            false;
        }
      );
  }

  closePopup() {
    this.fetchLive = false;
    this.dialogRef.close({
      meterNameIsChanged: this.meterNameIsChanged,
      meterName: this.meterName
    });
  }

  meterTabName() {
    this.wattageFlag = true;
    this.currentFlag = false;
    this.userCheckB = false;
    this.userCheckA = true;
    this.userCheckC = false;
    this.userCheckD = false;
    this.currentCircuitTagExpansion = [];
    for (let i = 0; i < this.expansion_A.length; i++) {
      if (this.expansion_A[i].circuit_number != '') {
        var updatedKeys = {
          id: this.expansion_A[i].expansion_number,
          value: this.expansion_A[i].circuit_number
        };
        this.currentCircuitTagExpansion.push(updatedKeys);
      }
    }
  }
  // meterclick(){
  //   this.fineuserCheckA = true;
  //   this.fineuserCheckB = false;
  //   this.fineuserCheckC = false;
  //   this.fineuserCheckD = false;
  // }

  meterTunningTab() {
    this.fineuserCheckA = true;
    this.fineuserCheckB = false;
    this.fineuserCheckC = false;
    this.fineuserCheckD = false;
    this.currentCircuitTagExpansion = [];
    for (let i = 0; i < this.expansion_A.length; i++) {
      if (this.expansion_A[i].circuit_number != '') {
        var updatedKeys = {
          id: this.expansion_A[i].expansion_number,
          value: this.expansion_A[i].circuit_number
        };
        this.currentCircuitTagExpansion.push(updatedKeys);
      }
    }
  }

  toggleA() {
    this.userCheckB = false;
    this.userCheckA = true;
    this.userCheckC = false;
    this.userCheckD = false;
    (document.getElementById('clickA') as HTMLInputElement).checked = true;
    (document.getElementById('clickB') as HTMLInputElement).checked = false;
    (document.getElementById('clickC') as HTMLInputElement).checked = false;
    (document.getElementById('clickD') as HTMLInputElement).checked = false;
    this.currentCircuitTagExpansion = [];
    for (let i = 0; i < this.expansion_A.length; i++) {
      if (this.expansion_A[i].circuit_number != '') {
        var updatedKeys = {
          id: this.expansion_A[i].expansion_number,
          value: this.expansion_A[i].circuit_number
        };
        this.currentCircuitTagExpansion.push(updatedKeys);
      }
    }
  }

  toggleB() {
    (document.getElementById('clickA') as HTMLInputElement).checked = false;
    (document.getElementById('clickB') as HTMLInputElement).checked = true;
    (document.getElementById('clickC') as HTMLInputElement).checked = false;
    (document.getElementById('clickD') as HTMLInputElement).checked = false;
    this.userCheckA = false;
    this.userCheckB = true;
    this.userCheckC = false;
    this.userCheckD = false;
    this.currentCircuitTagExpansion = [];
    for (let i = 0; i < this.expansion_B.length; i++) {
      if (this.expansion_B[i].circuit_number != '') {
        var updatedKeys = {
          id: this.expansion_B[i].expansion_number,
          value: this.expansion_B[i].circuit_number
        };
        this.currentCircuitTagExpansion.push(updatedKeys);
      }
    }
  }

  toggleC() {
    (document.getElementById('clickA') as HTMLInputElement).checked = false;
    (document.getElementById('clickB') as HTMLInputElement).checked = false;
    (document.getElementById('clickC') as HTMLInputElement).checked = true;
    (document.getElementById('clickD') as HTMLInputElement).checked = false;
    this.userCheckA = false;
    this.userCheckB = false;
    this.userCheckC = true;
    this.userCheckD = false;
    this.currentCircuitTagExpansion = [];
    for (let i = 0; i < this.expansion_C.length; i++) {
      if (this.expansion_C[i].circuit_number != '') {
        var updatedKeys = {
          id: this.expansion_C[i].expansion_number,
          value: this.expansion_C[i].circuit_number
        };
        this.currentCircuitTagExpansion.push(updatedKeys);
      }
    }
  }

  toggleD() {
    (document.getElementById('clickA') as HTMLInputElement).checked = false;
    (document.getElementById('clickB') as HTMLInputElement).checked = false;
    (document.getElementById('clickC') as HTMLInputElement).checked = false;
    (document.getElementById('clickD') as HTMLInputElement).checked = true;
    this.userCheckA = false;
    this.userCheckB = false;
    this.userCheckC = false;
    this.userCheckD = true;
    this.currentCircuitTagExpansion = [];
    for (let i = 0; i < this.expansion_D.length; i++) {
      if (this.expansion_D[i].circuit_number != '') {
        var updatedKeys = {
          id: this.expansion_D[i].expansion_number,
          value: this.expansion_D[i].circuit_number
        };
        this.currentCircuitTagExpansion.push(updatedKeys);
      }
    }
  }

  finetoggleA() {
    (document.getElementById('fineclickA') as HTMLInputElement).checked = true;
    (document.getElementById('fineclickB') as HTMLInputElement).checked = false;
    (document.getElementById('fineclickC') as HTMLInputElement).checked = false;
    (document.getElementById('fineclickD') as HTMLInputElement).checked = false;
    this.fineuserCheckA = true;
    this.fineuserCheckB = false;
    this.fineuserCheckC = false;
    this.fineuserCheckD = false;
    this.currentCircuitTagExpansion = [];
    for (let i = 0; i < this.expansion_A.length; i++) {
      if (this.expansion_A[i].circuit_number != '') {
        var updatedKeys = {
          id: this.expansion_A[i].expansion_number,
          value: this.expansion_A[i].circuit_number
        };
        this.currentCircuitTagExpansion.push(updatedKeys);
      }
    }
  }

  finetoggleB() {
    (document.getElementById('fineclickA') as HTMLInputElement).checked = false;
    (document.getElementById('fineclickB') as HTMLInputElement).checked = true;
    (document.getElementById('fineclickC') as HTMLInputElement).checked = false;
    (document.getElementById('fineclickD') as HTMLInputElement).checked = false;
    this.fineuserCheckA = false;
    this.fineuserCheckB = true;
    this.fineuserCheckC = false;
    this.fineuserCheckD = false;
    this.currentCircuitTagExpansion = [];
    for (let i = 0; i < this.expansion_B.length; i++) {
      if (this.expansion_B[i].circuit_number != '') {
        var updatedKeys = {
          id: this.expansion_B[i].expansion_number,
          value: this.expansion_B[i].circuit_number
        };
        this.currentCircuitTagExpansion.push(updatedKeys);
      }
    }
  }

  finetoggleC() {
    (document.getElementById('fineclickA') as HTMLInputElement).checked = false;
    (document.getElementById('fineclickB') as HTMLInputElement).checked = false;
    (document.getElementById('fineclickC') as HTMLInputElement).checked = true;
    (document.getElementById('fineclickD') as HTMLInputElement).checked = false;
    this.fineuserCheckA = false;
    this.fineuserCheckB = false;
    this.fineuserCheckC = true;
    this.fineuserCheckD = false;
    this.currentCircuitTagExpansion = [];
    for (let i = 0; i < this.expansion_C.length; i++) {
      if (this.expansion_C[i].circuit_number != '') {
        var updatedKeys = {
          id: this.expansion_C[i].expansion_number,
          value: this.expansion_C[i].circuit_number
        };
        this.currentCircuitTagExpansion.push(updatedKeys);
      }
    }
  }

  finetoggleD() {
    (document.getElementById('fineclickA') as HTMLInputElement).checked = false;
    (document.getElementById('fineclickB') as HTMLInputElement).checked = false;
    (document.getElementById('fineclickC') as HTMLInputElement).checked = false;
    (document.getElementById('fineclickD') as HTMLInputElement).checked = true;
    this.fineuserCheckA = false;
    this.fineuserCheckB = false;
    this.fineuserCheckC = false;
    this.fineuserCheckD = true;
    this.currentCircuitTagExpansion = [];
    for (let i = 0; i < this.expansion_D.length; i++) {
      if (this.expansion_D[i].circuit_number != '') {
        var updatedKeys = {
          id: this.expansion_D[i].expansion_number,
          value: this.expansion_D[i].circuit_number
        };
        this.currentCircuitTagExpansion.push(updatedKeys);
      }
    }
  }

  handlewattageToggle() {
    (document.getElementById('wattage') as HTMLInputElement).checked = true;
    (document.getElementById('current-amper') as HTMLInputElement).checked =
      false;
    (document.getElementById('clickA') as HTMLInputElement).checked = true;
    (document.getElementById('clickB') as HTMLInputElement).checked = false;
    (document.getElementById('clickC') as HTMLInputElement).checked = false;
    (document.getElementById('clickD') as HTMLInputElement).checked = false;
    this.wattageFlag = true;
    this.currentFlag = false;
    this.userCheckB = false;
    this.userCheckA = true;
    this.userCheckC = false;
    this.userCheckD = false;
    this.expansionIconClick(this.expansionTitle);
  }
  handleFineTuningToggle(type: string) {
    if (type == 'sensor') {
      this.fineTuningSensorToggle = true;
      this.fineTuningVoltageToggle = false;
      (document.getElementById('sensor') as HTMLInputElement).checked = true;
      (
        document.getElementById('fine-tuning-voltage') as HTMLInputElement
      ).checked = false;
    } else {
      this.fineTuningSensorToggle = false;
      this.fineTuningVoltageToggle = true;
      (document.getElementById('sensor') as HTMLInputElement).checked = false;
      (
        document.getElementById('fine-tuning-voltage') as HTMLInputElement
      ).checked = true;
    }
    // this.userCheckB = false;
    // this.userCheckA = true;
    // this.userCheckC = false;
    // this.userCheckD = false;
    // (document.getElementById('fineclickA') as HTMLInputElement).checked = true;
    // (document.getElementById('fineclickB') as HTMLInputElement).checked = false;
    // (document.getElementById('fineclickC') as HTMLInputElement).checked = false;
    // (document.getElementById('fineclickD') as HTMLInputElement).checked = false;
  }

  handlecurrentToggle() {
    (document.getElementById('wattage') as HTMLInputElement).checked = false;
    (document.getElementById('current-amper') as HTMLInputElement).checked =
      true;
    this.wattageFlag = false;
    this.currentFlag = true;
    this.userCheckB = false;
    this.userCheckA = true;
    this.userCheckC = false;
    this.userCheckD = false;
    (document.getElementById('clickA') as HTMLInputElement).checked = true;
    (document.getElementById('clickB') as HTMLInputElement).checked = false;
    (document.getElementById('clickC') as HTMLInputElement).checked = false;
    (document.getElementById('clickD') as HTMLInputElement).checked = false;
    this.expansionIconClick(this.expansionTitle);
  }

  updateResponse() {
    this.dashboardDataService
      .getPanelExpansion(this.current_dialog_panel_id)
      .forEach((expansions: any) => {
        this.current_meter_id = expansions.meter_id;
        this.connectivity = expansions.connectivity;
        this.expansion_A = [];
        this.expansion_B = [];
        this.expansion_C = [];
        this.expansion_D = [];
        this.panel_expansions = expansions;
        let current_expansions: any = expansions.results;
        if (expansions.number_of_phases == '1') {
          this.radioPhase[0].value = true;
          this.radioPhase[1].value = false;
          this.radioPhase[2].value = false;
        } else if (expansions.number_of_phases == '2') {
          this.radioPhase[0].value = false;
          this.radioPhase[1].value = true;
          this.radioPhase[2].value = false;
        } else if (expansions.number_of_phases == '3') {
          this.radioPhase[0].value = false;
          this.radioPhase[1].value = false;
          this.radioPhase[2].value = true;
        }
        if (expansions.meter_name.length > 0) {
          this.meterName = expansions.meter_name;
        }
        for (let i = 0; i < current_expansions.length; i++) {
          var keyValue = {};
          keyValue = {
            id: current_expansions[i].id,
            expansion_type: current_expansions[i].expansion_type,
            expansion_number: current_expansions[i].expansion_number,
            circuit_name: '',
            circuit_number: '',
            colour: '',
            amps: '0.00a',
            voltage_reference:
              current_expansions[i].voltage_refrence_type != null
                ? current_expansions[i].voltage_refrence_type
                : 'V1',
            watts: '0.00w',
            current_ct_clamp: current_expansions[i].current_ct_clamp
          };
          if (current_expansions[i].circuit != null) {
            keyValue = {
              id: current_expansions[i].id,
              expansion_type: current_expansions[i].expansion_type,
              expansion_number: current_expansions[i].expansion_number,
              circuit_name: current_expansions[i].circuit.circuit_name,
              circuit_number:
                '#' + current_expansions[i].circuit.circuit_number,
              colour: '',
              amps: '0.00a',
              voltage_reference:
                current_expansions[i].voltage_refrence_type != null
                  ? current_expansions[i].voltage_refrence_type
                  : 'V1',
              watts: '0.00w',
              current_ct_clamp: current_expansions[i].current_ct_clamp
            };
            if (current_expansions[i].circuit.category != null) {
              keyValue = {
                id: current_expansions[i].id,
                expansion_type: current_expansions[i].expansion_type,
                expansion_number: current_expansions[i].expansion_number,
                circuit_name: current_expansions[i].circuit.circuit_name,
                circuit_number:
                  '#' + current_expansions[i].circuit.circuit_number,
                colour: current_expansions[i].circuit.category.colour,
                amps: '0.00a',
                voltage_reference:
                  current_expansions[i].voltage_refrence_type != null
                    ? current_expansions[i].voltage_refrence_type
                    : 'V1',
                watts: '0.00w',
                current_ct_clamp: current_expansions[i].current_ct_clamp
              };
            }
          }

          if (i < 16) {
            this.expansion_A.push(keyValue);
          } else if (i > 15 && i < 32) {
            this.expansion_B.push(keyValue);
          } else if (i > 31 && i < 48) {
            this.expansion_C.push(keyValue);
          } else if (i > 47 && i < 64) {
            this.expansion_D.push(keyValue);
          }
        }
      });
  }

  expansionIconClick(type: string) {
    this.expansionTitle = type;
    if (type == 'Mapping' || type == 'Live Feed') {
      if (this.wattageFlag) {
        this.expansionColumn = 'Watts';
      } else if (this.currentFlag) {
        this.expansionColumn = 'Current / Amps (a)';
      }
    } else if (type == 'Current') {
      this.expansionColumn = 'CT Type';
    } else if (type == 'Voltage') {
      this.expansionColumn = 'Voltage Type';
    }
  }

  gatewayIconClick(type: string) {
    this.gateway = type;
  }

  getDeviceCommands(cb: Function) {
    if (!Object.keys(this.device_commands).length) {
      const subs = this.dashboardDataService
        .getDeviceCommands()
        .subscribe(response => {
          response.results.forEach(
            (d: { command_name: string; id: number }) => {
              this.device_commands[d.command_name] = d.id;
            }
          );

          subs.unsubscribe();
          cb();
        });
    } else {
      cb();
    }
  }

  getPanelDeviceCommandsStatus(id: number) {
    if (this.componentDestroyed) return;

    const subs = this.dashboardDataService
      .getPanelDeviceCommandByMeter(id)
      .subscribe(response => {
        subs.unsubscribe();

        this.calibrationCount += 1;

        if (response.results[0].is_successful) {
          this.calibrationStage = 2;
        } else if (this.calibrationCount * 2 >= 30) {
          this.calibrationStage = 3;
        } else {
          setTimeout(() => this.getPanelDeviceCommandsStatus(id), 2000);
        }
      });
  }

  onRunClick(_: Event, command: string) {
    this.calibrationStage = 1;
    this.calibrationCount = 0;

    this.getDeviceCommands(() => {
      const id = this.device_commands[command];

      if (id) {
        const subs = this.dashboardDataService
          .updatePanelDeviceCommand(id, this.current_meter_id)
          .subscribe(response => {
            this.getPanelDeviceCommandsStatus(response.meter);
            subs.unsubscribe();
          });
      } else {
        this.calibrationStage = 2;
      }
    });
  }

  calculateFlexOrder(i: number, arr: any[]): number {
    const orderLen = arr.length / 2;

    if (i < orderLen) {
      return i + 1 + i;
    } else {
      return 2 * (i - orderLen + 1);
    }
  }

  handleSourceAndMappingChange(i: number) {
    if (i !== this.selectedSourceAndMapping) {
      this.selectedSourceAndMapping = i;

      if (i === 0) {
        const subs = this.dashboardDataService
          .updateMeterVoltageReferencePanel(this.current_meter_id, null)
          .subscribe(() => {
            this.data.panel.reference_meter = null;
            this.voltage_transformers =
              this.panel_expansions.voltage_transformers;

            this.panel_expansions.artificial_voltage = {};

            this.referencePanels = this.panels.filter(
              (d: Panel) =>
                d.meter_name !== this.data.panel.meter_name &&
                d.meter_name === this.data.panel.reference_meter
            );

            this.getGenerateCoefficientVoltageSourceOptions();

            subs.unsubscribe();
          });
      }
    }
  }

  handleSourceMappingPortsValueChange($event: Event, id: string) {
    const { value } = $event.target as HTMLSelectElement;

    if (this.current_meter_id) {
      const payload = this.sourceAndMappingPorts.reduce((acc: any, curr) => {
        if (curr.value()) {
          acc[curr.id] = curr.value();
        }

        return acc;
      }, {});

      if (value) {
        payload[id] = value;
      } else {
        delete payload[id];
      }

      const subs = this.dashboardDataService
        .updatePanelMeterPortVoltageTransformers(this.current_meter_id, payload)
        .subscribe(() => {
          if (!this.panel_expansions.voltage_transformers) {
            this.panel_expansions.voltage_transformers = {};
          }

          this.panel_expansions.voltage_transformers[id] = value;
          this.voltage_transformers = payload;

          subs.unsubscribe();
        });
    }
  }

  getReferencePanelExpansion() {
    if (this.data.panel.reference_meter) {
      const referencePanel = this.data.panels.find(
        (d: Panel) => d.meter_name === this.data.panel.reference_meter
      ) as Panel;

      if (referencePanel) {
        const { panel_id } = referencePanel;

        if (!this.reference_panel_voltage_transformers[panel_id]) {
          const subs = this.dashboardDataService
            .getPanelExpansion(panel_id)
            .subscribe(response => {
              this.reference_panel_voltage_transformers[panel_id] =
                response.voltage_transformers;

              this.voltage_transformers =
                this.reference_panel_voltage_transformers[panel_id];

              subs.unsubscribe();
            });
        } else {
          this.voltage_transformers =
            this.reference_panel_voltage_transformers[panel_id];
        }
      }
    }
  }

  handleAnotherGatewayMeterChange($event: Event) {
    const { value } = $event.target as HTMLSelectElement;

    const subs = this.dashboardDataService
      .updateMeterVoltageReferencePanel(this.current_meter_id, value)
      .subscribe(() => {
        this.data.panel.reference_meter = this.data.panels.filter(
          (d: Panel) => d.meter_id === Number(value)
        )[0].meter_name;

        this.referencePanels = this.panels.filter(
          (d: Panel) =>
            d.meter_name !== this.data.panel.meter_name &&
            d.meter_name === this.data.panel.reference_meter
        );

        this.getReferencePanelExpansion();
        this.getGenerateCoefficientVoltageSourceOptions();

        subs.unsubscribe();
      });
  }

  handleGatewayVoltageTypeUpdate($event: Event) {
    const { value } = $event.target as HTMLSelectElement;

    const subs = this.dashboardDataService
      .updateMeterVoltageType(this.current_meter_id, value)
      .subscribe(() => {
        this.data.panel.voltage_type = value;
        subs.unsubscribe();
      });
  }

  handleGatewayVoltageReferenceTypeUpdate($event: Event) {
    const { value } = $event.target as HTMLSelectElement;

    const subs = this.dashboardDataService
      .updateMeterVoltageType(this.current_meter_id, value)
      .subscribe(() => {
        this.data.panel.voltage_type = value;
        subs.unsubscribe();
      });
  }

  togglePortsCofficientEdit(id: string | null, value: number | null) {
    if (id !== this.isPortsCoefficientEditing) {
      if (id && value) {
        this.portsCoefficientEditedValue[id] = value;
      }

      this.isPortsCoefficientEditing = id;
    }
  }

  handlePortsCoefficientValueChange($event: Event, id: string) {
    const { value } = $event.target as HTMLInputElement;
    this.portsCoefficientEditedValue[id] = value;
  }

  handlePortsCofficientUpdate($event: Event, id: string) {
    const value = Number(this.portsCoefficientEditedValue[id]);

    if (this.current_meter_id) {
      const prev = this.voltage_coefficients[id];
      this.voltage_coefficients[id] = value;

      const payload = this.portCoefficient.reduce((acc: any, curr) => {
        if (curr.value()) {
          acc[curr.id] = curr.value();
        }

        return acc;
      }, {});

      payload[id] = value;

      const subs = this.dashboardDataService
        .updatePanelMeterPortVoltageCoefficient(this.current_meter_id, payload)
        .subscribe(
          () => {
            if (!this.panel_expansions.voltage_coefficients) {
              this.panel_expansions.voltage_coefficients = {};
            }

            this.panel_expansions.voltage_coefficients[id] = value;

            this.togglePortsCofficientEdit(null, null);

            this.toast.success({
              detail: 'Success',
              summary: `Successfully updated Port ${id}`,
              sticky: true,
              position: 'tr'
            });

            subs.unsubscribe();
          },
          (err: any) => {
            this.voltage_coefficients[id] = prev;

            this.toast.error({
              detail: 'Error',
              summary: err.error[0],
              sticky: true,
              position: 'tr'
            });
          }
        );
    }
  }

  handleGenerateCoefficientVoltageSourceChange($event: Event) {
    const { value } = $event.target as HTMLSelectElement;

    const x = this.generateCofficientVoltageSourceOptions.find(
      d => d.id === value
    );

    if (x) {
      const subs = this.dashboardDataService
        .fetchLatestMeterData(this.meterName)
        .subscribe(response => {
          if (response['main']) {
            this.generateCofficientLiveVoltageReading =
              response['main']['data'][x.voltage_reference.replace('V', 'U')];
          }

          if (this.voltage_coefficients) {
            this.generateCofficientVoltageCoefficient =
              this.voltage_coefficients[x.voltage_reference] ?? 1;
          }
          subs.unsubscribe();
        });
    }
  }

  handleUserVoltageReadingChange($event: Event) {
    const { value } = $event.target as HTMLInputElement;
    this.userVoltageReading = value;
  }

  handleCalculateCoefficientChange() {
    this.calculatedCoEfficient =
      (Number(this.userVoltageReading) *
        this.generateCofficientVoltageCoefficient) /
      this.generateCofficientLiveVoltageReading;
  }

  handleCurrentDialogPanelIdChange(key: string) {
    if (key !== this.selectedPanelKey) {
      this.selectedPanelKey = key;

      const id = this.data.panel[key];

      if (id && id !== this.current_dialog_panel_id) {
        this.current_dialog_panel_id = id;

        const p: Panel = this.data.panels.find((d: Panel) => d.panel_id === id);

        if (p) {
          this.current_meter_id = p.meter_id;
          this.panel_name = p.panel_name;
          this.panels = this.data.panels.filter((panel: Panel) => {
            return (
              panel.meter_id != null && panel.meter_id != (p.meter_id ?? null)
            );
          });

          this.meterName = p.meter_name;
        }

        this.getPanelData();
      }
    }
  }

  handleLoadBalancingOptionChange(key: typeof this.loadBalancingOption) {
    if (this.loadBalancingOption !== key) {
      const prev = this.loadBalancingOption;
      this.loadBalancingOption = key;

      this.dashboardDataService
        .updatePanelLoadBalancing(
          this.current_dialog_panel_id,
          this.loadBalancingOption
        )
        .subscribe(
          result => {
            this.toast.success({
              detail: 'Success',
              summary: 'Load Balancer updated',
              sticky: true,
              position: 'tr'
            });
          },
          (err: any) => {
            this.loadBalancingOption = prev;

            this.toast.error({
              detail: 'Error',
              summary: err.error[0],
              sticky: true,
              position: 'tr'
            });
          }
        );
    }
  }

  getDate(created_at: any) {
    return new Date(created_at);
  }

  handleArtificalVolatageSave() {
    const { value } = document.getElementById(
      'artificalVoltageInput'
    ) as HTMLInputElement;

    const prev = this.panel_expansions['artificial_voltage'];

    if (this.panel_expansions['artificial_voltage']?.['V1'] !== value) {
      if (value) {
        this.panel_expansions['artificial_voltage'] = {
          V1: value,
          V2: value,
          V3: value
        };
      } else {
        this.panel_expansions['artificial_voltage'] = {};
      }

      const subs = this.dashboardDataService
        .updatePanelMeterArtificialVoltage(
          this.current_meter_id,
          this.panel_expansions['artificial_voltage']
        )
        .subscribe(
          () => {
            this.toast.success({
              detail: 'Success',
              summary: 'Artificial Voltage Updated',
              sticky: true,
              position: 'tr',
              duration: 300
            });

            subs.unsubscribe();
          },
          err => {
            this.panel_expansions['artificial_voltage'] = prev;

            this.toast.error({
              detail: 'Error',
              summary: err.error[0],
              sticky: true,
              position: 'tr'
            });
          }
        );
    }

    this.isArtificalVoltageEditing = false;
  }
}
