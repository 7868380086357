<div class="container-fluid py-3">
  <div class="card">
    <div class="card-header">
      <h5 class="card-title mb-0">Meter Installation</h5>
    </div>
    <div class="card-body">
      <form [formGroup]="installationForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
          <input type="text" class="form-control" 
                 formControlName="meterSerialId" 
                 placeholder="Enter meter serial ID"
                 [class.is-invalid]="installationForm.get('meterSerialId')?.invalid && installationForm.get('meterSerialId')?.touched">
          <div class="invalid-feedback" *ngIf="installationForm.get('meterSerialId')?.hasError('required')">
            Meter Serial ID is required
          </div>
          <div class="invalid-feedback" *ngIf="installationForm.get('meterSerialId')?.hasError('minlength')">
            Meter Serial ID must be at least 3 characters
          </div>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="installationForm.invalid || loading">
          Submit
          <div class="spinner-border spinner-border-sm ms-2" *ngIf="loading" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </form>

      <div class="installation-steps mt-4">
        <!-- Connection Check Step -->
        <div class="step mb-3" [class.completed]="stepStatus['connection'] === 'success'">
          <div class="d-flex justify-content-between align-items-center p-3 bg-light rounded">
            <div class="step-content">
              <span class="step-title">Check Connection</span>
            </div>
            <div class="step-actions">
              <button class="btn btn-link p-0 me-2" *ngIf="stepStatus['connection'] !== 'success'"
                      [disabled]="loadingSteps['connection'] || installationForm.invalid"
                      (click)="checkConnection()"
                      title="Check meter connection status">
                <img src="assets/images/expand_right.svg" alt="Start" class="icon" *ngIf="!loadingSteps['connection']">
                <div class="spinner-border spinner-border-sm" *ngIf="loadingSteps['connection']" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
              <img src="assets/images/Vector.svg" alt="Success" class="icon success" *ngIf="stepStatus['connection'] === 'success'"
                   title="Connection successful">
              <button class="btn btn-link p-0 ms-2" *ngIf="stepStatus['connection'] === 'success'"
                      (click)="retryStep('connection')"
                      title="Restart connection check">
                <img src="assets/images/recal.svg" alt="Retry" class="icon">
              </button>
              <img src="assets/images/cancel.svg" alt="Error" class="icon error" *ngIf="stepStatus['connection'] === 'failure'"
                   title="Connection failed">
            </div>
          </div>
        </div>

        <!-- Calibration Step -->
        <div class="step mb-3" [class.completed]="stepStatus['calibration'] === 'success'">
          <div class="d-flex justify-content-between align-items-center p-3 bg-light rounded">
            <div class="step-content">
              <span class="step-title">Calibrate Meter</span>
            </div>
            <div class="step-actions">
              <button class="btn btn-link p-0 me-2" *ngIf="stepStatus['calibration'] !== 'success'"
                      [disabled]="loadingSteps['calibration'] || stepStatus['connection'] !== 'success'"
                      (click)="calibrateMeter()"
                      title="Start meter calibration">
                <img src="assets/images/expand_right.svg" alt="Start" class="icon" *ngIf="!loadingSteps['calibration']">
                <div class="spinner-border spinner-border-sm" *ngIf="loadingSteps['calibration']" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
              <img src="assets/images/Vector.svg" alt="Success" class="icon success" *ngIf="stepStatus['calibration'] === 'success'"
                   title="Calibration successful">
              <button class="btn btn-link p-0 ms-2" *ngIf="stepStatus['calibration'] === 'success'"
                      (click)="retryStep('calibration')"
                      title="Restart calibration">
                <img src="assets/images/recal.svg" alt="Retry" class="icon">
              </button>
              <img src="assets/images/cancel.svg" alt="Error" class="icon error" *ngIf="stepStatus['calibration'] === 'failure'"
                   title="Calibration failed">
            </div>
          </div>
        </div>

        <!-- Signal Strength Step -->
        <div class="step mb-3" [class.completed]="stepStatus['signalStrength'] === 'success'">
          <div class="d-flex justify-content-between align-items-center p-3 bg-light rounded">
            <div class="step-content">
              <span class="step-title">Check Signal Strength</span>
              <div class="signal-info mt-2" *ngIf="signalStrength">
                <div class="d-flex align-items-center">
                  <span class="signal-value me-2" [style.color]="getSignalColor(signalStrength.quality)">
                    {{signalStrength.value}}%
                  </span>
                  <div class="signal-bars d-flex align-items-end me-2">
                    <div class="signal-bar" 
                         *ngFor="let bar of [1,2,3,4]; let i = index"
                         [class.active]="signalStrength.value >= i * 25"
                         [style.background-color]="signalStrength.value >= i * 25 ? getSignalColor(signalStrength.quality) : '#e0e0e0'">
                    </div>
                  </div>
                  <span class="signal-quality" [style.color]="getSignalColor(signalStrength.quality)">
                    {{signalStrength.quality | titlecase}}
                  </span>
                </div>
              </div>
            </div>
            <div class="step-actions">
              <button class="btn btn-link p-0 me-2" *ngIf="stepStatus['signalStrength'] !== 'success'"
                      [disabled]="loadingSteps['signalStrength'] || stepStatus['calibration'] !== 'success'"
                      (click)="checkSignalStrength()"
                      [title]="signalStrength ? signalStrength.message : 'Check signal strength'">
                <img src="assets/images/expand_right.svg" alt="Start" class="icon" *ngIf="!loadingSteps['signalStrength']">
                <div class="spinner-border spinner-border-sm" *ngIf="loadingSteps['signalStrength']" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
              <img src="assets/images/Vector.svg" alt="Success" class="icon success" *ngIf="stepStatus['signalStrength'] === 'success'"
                   [title]="signalStrength ? signalStrength.message : 'Signal strength good'">
              <button class="btn btn-link p-0 ms-2" *ngIf="stepStatus['signalStrength'] === 'success'"
                      (click)="retryStep('signalStrength')"
                      title="Restart signal strength check">
                <img src="assets/images/recal.svg" alt="Retry" class="icon">
              </button>
              <img src="assets/images/cancel.svg" alt="Error" class="icon error" *ngIf="stepStatus['signalStrength'] === 'failure'"
                   [title]="signalStrength ? signalStrength.message : 'Signal strength too weak'">
            </div>
          </div>
        </div>

        <!-- Live Readings Step -->
        <div class="step mb-3" [class.completed]="stepStatus['liveReadings'] === 'success'">
          <div class="d-flex justify-content-between align-items-center p-3 bg-light rounded">
            <div class="step-content">
              <span class="step-title">Live Readings</span>
              <div class="readings-info mt-3" *ngIf="liveReadings">
                <div class="readings-section mb-3">
                  <h6 class="readings-title">Current</h6>
                  <div class="row g-3">
                    <div class="col-md-4">
                      <div class="reading-item">
                        <span class="reading-label">I1:</span>
                        <span class="reading-value">{{formatValue(liveReadings.I1, 'current')}}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="reading-item">
                        <span class="reading-label">I2:</span>
                        <span class="reading-value">{{formatValue(liveReadings.I2, 'current')}}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="reading-item">
                        <span class="reading-label">I3:</span>
                        <span class="reading-value">{{formatValue(liveReadings.I3, 'current')}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="readings-section">
                  <h6 class="readings-title">Voltage</h6>
                  <div class="row g-3">
                    <div class="col-md-4">
                      <div class="reading-item">
                        <span class="reading-label">U1:</span>
                        <span class="reading-value">{{formatValue(liveReadings.U1, 'voltage')}}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="reading-item">
                        <span class="reading-label">U2:</span>
                        <span class="reading-value">{{formatValue(liveReadings.U2, 'voltage')}}</span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="reading-item">
                        <span class="reading-label">U3:</span>
                        <span class="reading-value">{{formatValue(liveReadings.U3, 'voltage')}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="step-actions">
              <button class="btn btn-link p-0 me-2" *ngIf="stepStatus['liveReadings'] !== 'success'"
                      [disabled]="loadingSteps['liveReadings'] || stepStatus['signalStrength'] !== 'success'"
                      (click)="getLiveReadings()"
                      title="Get live meter readings">
                <img src="assets/images/expand_right.svg" alt="Start" class="icon" *ngIf="!loadingSteps['liveReadings']">
                <div class="spinner-border spinner-border-sm" *ngIf="loadingSteps['liveReadings']" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
              <img src="assets/images/Vector.svg" alt="Success" class="icon success" *ngIf="stepStatus['liveReadings'] === 'success'"
                   title="Readings received successfully">
              <button class="btn btn-link p-0 ms-2" *ngIf="stepStatus['liveReadings'] === 'success'"
                      (click)="retryStep('liveReadings')"
                      title="Refresh readings">
                <img src="assets/images/recal.svg" alt="Retry" class="icon">
              </button>
              <img src="assets/images/cancel.svg" alt="Error" class="icon error" *ngIf="stepStatus['liveReadings'] === 'failure'"
                   title="Failed to get readings">
            </div>
          </div>
        </div>
      </div>

      <div class="text-end mt-4">
        <button class="btn btn-danger"
                (click)="disconnectMeter()"
                [disabled]="loading || !installationForm.valid"
                title="Disconnect this meter">
          Disconnect Meter
          <div class="spinner-border spinner-border-sm ms-2" *ngIf="loading" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</div> 