<div class="popup-main">
    <div class="popup-main-inner">
        <div class="popup-header">
            <h3>Change Password</h3>
            <button class="close-popup" (click)="closePopup()">
                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="30" viewBox="0 0 29 30"
                    fill="none">
                    <path
                        d="M14.4993 2.91699C7.81727 2.91699 2.41602 8.31824 2.41602 15.0003C2.41602 21.6824 7.81727 27.0837 14.4993 27.0837C21.1814 27.0837 26.5827 21.6824 26.5827 15.0003C26.5827 8.31824 21.1814 2.91699 14.4993 2.91699ZM20.541 19.3382L18.8373 21.042L14.4993 16.7041L10.1614 21.042L8.45768 19.3382L12.7956 15.0003L8.45768 10.6624L10.1614 8.95866L14.4993 13.2966L18.8373 8.95866L20.541 10.6624L16.2031 15.0003L20.541 19.3382Z"
                        fill="#0D1758" />
                </svg>
            </button>
        </div>
        <div class="popup-inner-main">
            <div class="popup-inner">
                <div class="inner-info">
                        <div class="upper-fields">
                            <form (ngSubmit)="changePassword()">
                                <div class="form-controller">
                                <label for="oldPassword" class="text-name">Old Password:</label>
                                <input type="password" [(ngModel)]="oldPassword" required />
                                </div>
                                <div class="form-controller">

                                <label for="newPassword">New Password:</label>
                                <input type="password" [(ngModel)]="newPassword" required />
                            </div>
                            <div class="form-controller">

                                <label for="confirmPassword">Confirm Password:</label>
                                <input type="password" [(ngModel)]="confirmPassword" required />
                            </div>
                            <div *ngIf="error" class="error-message">
                                {{ error }}
                              </div>
                            <button class="popup-footer-btn" type="submit">Confirm</button>
                              </form>
                        </div>                    
                </div>
            </div>
        </div>
    </div>
</div>
