import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { SettingService } from 'src/app/services/settings-service';
import { DataService } from 'src/app/services/data-sharing-service';
import { Facilities } from 'src/app/models/account-settings/facility';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { NgToastService } from 'ng-angular-popup';
import { FacilitiesInnerInfo } from 'src/app/models/account-settings/facility-inner';

@Component({
  selector: 'app-facility-detaill',
  templateUrl: './facility-detaill.component.html',
  styleUrls: ['./facility-detaill.component.scss']
})
export class FacilityDetaillComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  shared: any;
  isEditingname: boolean = false;
  isEditingid: boolean = false;
  isEditingtype: boolean = false;
  isEditingyear_built: boolean = false;
  isEditingsquare_footage: boolean = false;
  isEditingaddress: boolean = false;
  isEditingcity: boolean = false;
  isEditingprovince: boolean = false;
  isEditingpostal_code: boolean = false;
  dataLoading: boolean = true;

  facility: Facilities;
  updateinfo: Facilities;
  facilitiesInnerInfo: FacilitiesInnerInfo[];
  facilitiesTypes: string[];
  facilitiesSubTypes: string[];
  selectedFacilityType: string = '';
  selectedFacilitySubType: string = '';


  constructor(private settingService: SettingService, private dataService: DataService, private router: Router,
  private route: ActivatedRoute, private toast: NgToastService) { 
    this.shared = this.dataService.getClickedFacilityId();
    if ('facility' in this.route.snapshot.queryParams){
      this.facility = JSON.parse(this.route.snapshot.queryParams['facility'])
      this.facilitiesInnerInfo = JSON.parse(this.route.snapshot.queryParams['facilitiesInnerInfo'])
      this.updateinfo = this.facility;
      this.dataLoading = false;

      this.router.navigate([], {
        // relativeTo: this.route,
        // queryParams: {},
        replaceUrl: true,
    });
    }
    else{
      this.settingService.getFacilityDetailById(this.shared).subscribe((data: any) => {
        this.facility = data;
        this.updateinfo = this.facility;
        this.dataLoading = false;
      });
      this.settingService.getFacilities().subscribe((data: any) => {
        this.facilitiesInnerInfo = data.results;
      });
    }
  }

  // facilityTypeSelected(): void {
  //   this.facilitiesSubTypes = [...new Set( this.facilitiesInnerInfo.filter((facility) => facility.type === this.selectedFacilityType).map((facility) => facility.sub_type)) ];
  //   if (this.facilitiesSubTypes.length == 0) {
  //     this.selectedFacilitySubType = '';
  //     let facilitySelected = this.facilitiesInnerInfo.find((facility) => facility.type === this.selectedFacilityType)?.id;
  //     if (!facilitySelected) {
  //       return;
  //     }
  //     this.facility. = facilitySelected
  //   }
  // }

  // facilitySubTypeSelected(): void {
  //   let facilitySelected = this.facilitiesInnerInfo.find((facility) => facility.type === this.selectedFacilityType && facility.sub_type === this.selectedFacilitySubType)?.id;
  //   if (!facilitySelected) {
  //     return;
  //   }
  //   this.newFacility.facility = facilitySelected;
  // }


  toggleEdit(field: string): void {

    if (field === 'name') this.isEditingname = field === 'name' ? !this.isEditingname : false;;
    if (field === 'id') this.isEditingid = !this.isEditingid;
    // if (field === 'type') this.isEditingtype = !this.isEditingtype;
    if (field === 'year_built') this.isEditingyear_built = !this.isEditingyear_built;
    if (field === 'squarefootage') this.isEditingsquare_footage = !this.isEditingsquare_footage;
    if (field === 'address') this.isEditingaddress = !this.isEditingaddress;
    if (field === 'city') this.isEditingcity = !this.isEditingcity;
    if (field === 'province') this.isEditingprovince = !this.isEditingprovince;
    if (field === 'postal_code') this.isEditingpostal_code = !this.isEditingpostal_code;
  }

  isDisabled(field: string): boolean {
    if (field === 'name' && (this.isEditingid || this.isEditingtype || this.isEditingyear_built || this.isEditingsquare_footage || this.isEditingprovince || this.isEditingaddress || this.isEditingcity || this.isEditingpostal_code)) return true;
    if (field === 'id' && (this.isEditingname || this.isEditingtype || this.isEditingyear_built || this.isEditingsquare_footage || this.isEditingprovince || this.isEditingaddress || this.isEditingcity || this.isEditingpostal_code)) return true;
    if (field === 'type' && (this.isEditingid || this.isEditingname || this.isEditingyear_built || this.isEditingsquare_footage || this.isEditingprovince || this.isEditingaddress || this.isEditingcity || this.isEditingpostal_code)) return true;
    if (field === 'year_built' && (this.isEditingid || this.isEditingtype || this.isEditingname || this.isEditingsquare_footage || this.isEditingprovince || this.isEditingaddress || this.isEditingcity || this.isEditingpostal_code)) return true;
    if (field === 'square_footage' && (this.isEditingid || this.isEditingtype || this.isEditingyear_built || this.isEditingname || this.isEditingprovince || this.isEditingaddress || this.isEditingcity || this.isEditingpostal_code)) return true;
    if (field === 'address' && (this.isEditingid || this.isEditingtype || this.isEditingyear_built || this.isEditingsquare_footage || this.isEditingprovince || this.isEditingname || this.isEditingcity || this.isEditingpostal_code)) return true;
    if (field === 'city' && (this.isEditingid || this.isEditingtype || this.isEditingyear_built || this.isEditingsquare_footage || this.isEditingprovince || this.isEditingaddress || this.isEditingname || this.isEditingpostal_code)) return true;
    if (field === 'province' && (this.isEditingid || this.isEditingtype || this.isEditingyear_built || this.isEditingsquare_footage || this.isEditingname || this.isEditingname || this.isEditingcity || this.isEditingpostal_code)) return true;
    if (field === 'postal_code' && (this.isEditingid || this.isEditingtype || this.isEditingyear_built || this.isEditingsquare_footage || this.isEditingprovince || this.isEditingname || this.isEditingcity || this.isEditingname)) return true;
    return false;
  }


  ngOnInit(): void {
    
    
    }

  updateFacility(facilityId:string, field: string, value: string): void {

    let reqBody = { [field]: value }
    // const facilityId = localStorage.getItem("facilityId") // Will Remove later
    console.log(facilityId)
    this.settingService.updateFacility(reqBody, this.shared || facilityId)
      .subscribe(
        success => {
          if (success) {
            this.facility = this.updateinfo;
            this.toggleEdit(field);
            this.toast.success({ detail: 'Success', summary: 'Field updated successfully!', sticky: true, position: 'tr' })
          } else {
            this.toast.error({ detail: 'Error', summary: 'Field update was unsuccessfull!', sticky: true, position: 'tr' })
          }
        },
        error => {
          this.toast.error({ detail: 'Error', summary: 'Field update was unsuccessfull!', sticky: true, position: 'tr' })
          console.error('Error updating user:', error);
        }
      );

  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.facility.facility.image = e.target?.result as string;
      };
      reader.readAsDataURL(file);
      this.uploadImage(file);
    }
  }

  uploadImage(file: File): void {
    this.settingService.uploadBuildingLogo(file, this.updateinfo.idbuildings).subscribe(
      response => {
        this.updateinfo.image = response.image;
        this.toast.success({ detail: 'Success', summary: 'Image updated successfully!', sticky: true, position: 'tr' })
      },
      error => {
        this.toast.error({ detail: 'Error', summary: 'Image update was unsuccessfull!', sticky: true, position: 'tr' })
        console.error('Error uploading image:', error);
      }
    );
  }

}
