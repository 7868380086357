<div class="dt-main">
  <app-sidebar></app-sidebar>

  <div class="dt-content-wrapper">
    <div class="dt-content dt-content-overwrite">
      <div class="col-md-12 spc-padding">
        <div class="headerContainer">
          <img
            src="assets/images/back-button.png"
            routerLink="/insights/energy-efficiency/explore-energy-potential/energy-savings-snapshot"
            [queryParams]="{ scan_id: scan_id, timeperiod: timeperiod, startFullDate: startFullDate, endFullDate: endFullDate, energyLossCount: energyLossCount, ecmCircuitCount: ecmCircuitCount }"
          />
          <h2 class="pageHeading heading" *ngIf="!isExploreEnergyLoss">ECM SCAN #1</h2>
          <h2 class="pageHeading heading" *ngIf="isExploreEnergyLoss">Energy Loss Insights</h2>
        </div>

        <div class="bg-lightgrey card-container">
          
          <div class="left-container">
            <div class="tabs-container">
              <img [src]="selectedTab == 0 ? 'assets/images/homeActive.png' : 'assets/images/home-icon.png'" alt="home icon" (click)="handleTabChange(0)" />
              <img [src]="selectedTab == 1 ? 'assets/images/ecmdetectedActive.png' : 'assets/images/energysnapshot.png'" alt="ecm breakdown" (click)="handleTabChange(1)" />
              <img [src]="selectedTab == 2 ? 'assets/images/snapshotActive.png' : 'assets/images/ecmbreakdown.png'" alt="energy savings snapshot" (click)="handleTabChange(2)" />
              <img [src]="selectedTab == 3 ? 'assets/images/rejectedActive.png' : 'assets/images/rejectedecm.png'" alt="rejected ecm" (click)="handleTabChange(3)" />
            </div>
  
            <div
              class="card-content secondaryCard"
              style="background-color: #fff"
            >
            <!-- tab 1 -->
              <ng-container *ngIf="!isLoading && selectedTab == 0">
  
                <div class="heading p-0">
                  <h3>Overview</h3>
                </div>
                <div class="ecm-metrics">
                  <div
                    class="bill-breakdown-pie currentPie"
                    style="place-content: center"
                  >
                    <div class="byfees" *ngIf="!isExploreEnergyLoss">
                      <div class="chartdv">
                        <apx-chart
                          [series]="chartOptions.series"
                          [chart]="chartOptions.chart"
                          [labels]="chartOptions.labels"
                          [responsive]="chartOptions.responsive"
                          [legend]="chartOptions.legend"
                          [dataLabels]="chartOptions.dataLabels"
                          [colors]="chartOptions.colors"
                          [stroke]="chartOptions.stroke"
                        ></apx-chart>
                      </div>
                    </div>
                    <div class="byfees" *ngIf="isExploreEnergyLoss">
                      <div class="chartdv">
                        <apx-chart
                          [series]="chartOptionsExploreEnergy.series"
                          [chart]="chartOptionsExploreEnergy.chart"
                          [labels]="chartOptionsExploreEnergy.labels"
                          [responsive]="chartOptionsExploreEnergy.responsive"
                          [legend]="chartOptionsExploreEnergy.legend"
                          [dataLabels]="chartOptionsExploreEnergy.dataLabels"
                          [colors]="chartOptionsExploreEnergy.colors"
                          [stroke]="chartOptionsExploreEnergy.stroke"
                        ></apx-chart>
                      </div>
                    </div>
                    <div class="ecm-metrics-info" *ngIf="!isExploreEnergyLoss">
                      <br>
                      <h1 class="heading-opportunity-number"> {{suggestedECMsList.length}}</h1>
                      <h6>Energy Savings Oppurtunities</h6>
                    </div>
                  </div>
                  <div class="estimated-savings-annual" *ngIf="!isExploreEnergyLoss">
                    <br>
                    <h4>Est. Annual<br>Savings (kWh)</h4>
                    <svg 
                     width="264" height="16" viewBox="0 0 264 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0H85.5V16H8.5C4.08172 16 0.5 12.4183 0.5 8Z" fill="#BFF2CE"/>
                      <rect x="89.5" width="85" height="16" fill="#47DC74"/>
                      <path d="M178.5 0H255.5C259.918 0 263.5 3.58172 263.5 8C263.5 12.4183 259.918 16 255.5 16H178.5V0Z" fill="#1FAB3D"/>
                      </svg>
                    <div class="ecm-metrics-range">
                      <h2>{{total_accepted_min_savings_formatted}}</h2>
                      <h2>{{total_accepted_max_savings_formatted}}</h2>
                    </div>
                  </div>
                  <div class="estimated-savings-annual energy-loss" *ngIf="isExploreEnergyLoss">
                   <h1>
                    {{ suggestedECMsList.length}}
                   </h1>
                   <p>Potential Energy Loss Insights (kWh)</p>
                  </div>
                </div>
              </ng-container>

              <!-- tab 2 -->
              <ng-container *ngIf="!isLoading && selectedTab == 1">
                <div class="sidebartab ecm-breakdown">
                  <div class="Bill-Breakdown">
                    <p class="billbreakdown-side">Energy Loss Breakdown</p>
                    <div class="bill-breakdown-pie">
                      <div  *ngIf="chartOptions.series" class="byfees">
                        <div class="chartdv">
                          <apx-chart [series]="chartOptionsECMBreakdown.series" [chart]="chartOptionsECMBreakdown.chart" [labels]="chartOptionsECMBreakdown.labels"
                            [responsive]="chartOptionsECMBreakdown.responsive" [legend]="chartOptionsECMBreakdown.legend" [dataLabels]="chartOptionsECMBreakdown.dataLabels"
                            [colors]="chartOptionsECMBreakdown.colors" [stroke]="chartOptionsECMBreakdown.stroke"></apx-chart>
                        </div>
                      </div>                
                    </div>
                   <div class="sidetab">
                      <span>Energy Loss Category</span>
                      <div class="sidetab-selector">
                        <a (click)="sidetab('category')" [ngClass]="
                          sidebarCategoryActive ? 'sidetablink active' : 'sidetablink'
                        ">Category</a>
                        <a (click)="sidetab('type')" [ngClass]="
                          sidebarPanelActive ? 'sidetablink active' : 'sidetablink'
                        ">Loss Type</a>
                      </div>

                    </div>
                    <div class="ecm-catergory-bottom">
                      <div class="cat-opt-box" *ngIf="sidebarCategoryActive">
                        <div *ngFor=" let circuitCategory of circuitCategoryMain;
                                                                let i = index
                                                        ">
                          <div class="greybxWhite">
                            <div class="cat-opt">
                              <input type="checkbox" checked value="{{ circuitCategory.name }}" />
                              <div class="d-flex" style="justify-content: space-between">
                                <div width="75%" class="inner-cat-opts">
                                  <div class="check blue-text">
                                    <svg [ngStyle]="{ fill: circuitCategory.colour }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                      width="24px" height="24px">
                                      <path
                                        d="M11,16.4l-4.7-4.7l1.4-1.4l3.3,3.3l8.4-8.4C17.5,3.3,14.9,2,12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10 c0-1.9-0.5-3.6-1.4-5.1L11,16.4z">
                                      </path>
                                    </svg>
                                  </div>
                                  <div class="name" [ngStyle]="{ color: '#0D1758' }">
                                    {{ circuitCategory.name }}
                                    <a href="javascript: void(0);" aria-disabled="false" [attr.id]="circuitCategory.name">
                                      <svg [ngStyle]="{ fill: '#ccc' }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px"
                                        height="18px">
                                        <path
                                          d="M 12 2  C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z">
                                        </path>
                                      </svg>
                                    </a>
                                  </div>
                                </div>
                                <div width="25%" class="percent" [ngStyle]="{ color: '#0D1758' }">
                                  {{
                                  calculatePercentageECMs(circuitCategory)
                                  }}%
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="cat-opt-box" *ngIf="sidebarPanelActive">
                        <ng-container *ngFor="let circuitECMType of circuitECMTypeMappingArr ; let i = index">
                          <div>
                            <div class="greybxWhite">
                              <div class="cat-opt">
                                <input type="checkbox" checked />
                                <div class="d-flex" style="justify-content: space-between">
                                  <div width="75%" class="inner-cat-opts">
                                    <div class="check blue-text">
                                      <svg [ngStyle]="{ fill: circuitECMType.color }" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24" width="24px" height="24px">
                                        <path
                                          d="M11,16.4l-4.7-4.7l1.4-1.4l3.3,3.3l8.4-8.4C17.5,3.3,14.9,2,12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10 c0-1.9-0.5-3.6-1.4-5.1L11,16.4z">
                                        </path>
                                      </svg>
                                    </div>
                                    <div class="name" [ngStyle]="{ color: '#0D1758' }">
                                      {{ circuitECMType.name }}
                                      <div style="display: inline">
                                        <a href="javascript: void(0);" aria-disabled="false" 
                                          >
                                          <svg fill="#ccc" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18px"
                                            height="18px">
                                            <path
                                              d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z">
                                            </path>
                                          </svg>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div width="25%" class="percent" [ngStyle]="{ color: '#0D1758' }">
                                    {{
                                      calculatecircuitECMTypeMappingArrPercent(circuitECMType.res)
                                    }}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- tab 3 -->
              <ng-container *ngIf="!isLoading && selectedTab == 2">
                <div class="energy-savings-snapshot container-card overview-container"> 
                  <h3 class="overview-title mb-0" *ngIf="!rejected_scan">
                      Energy Savings Snapshot
                  </h3>
                  <h3 class="overview-title mb-0" *ngIf="rejected_scan">
                    Overview
                  </h3>
      
                  <p class="overview-heading mb-3 mt-4" *ngIf="rejected_scan">What are Rejected Detections?</p>
      
                  <p *ngIf="rejected_scan">Rejected detections are instances where the system identified potential areas of energy loss or inefficiencies within your 
                    facility, such as excessive energy consumption, off-hour usage, or peak demand contributions. These detections have been 
                    reviewed and marked as inapplicable, inaccurate, or unnecessary for your operations.</p>
                  
                  <p class="mb-0 mt-4" *ngIf="!rejected_scan">
                      This analysis highlights inefficiencies and offers actionable recommendations to optimize energy usage. Using 
                      real-time data from individual electrical circuits, it uncovers patterns and behaviors affecting overall 
                      consumption, enabling cost savings and sustainable operations.
                  </p>
      
                  <div class="scan-details-container" *ngIf="!rejected_scan">
                      <h4 class="scan-details-title mb-3">Scan Details</h4>
                      <div class="scan-details">
                          <div class="details"><span>Start Date:</span><span class="scan-result">{{ startFullDate }}</span></div>
                          <div class="details"><span>End Date:</span><span class="scan-result">{{ endFullDate }}</span></div>
                          <div class="details"><span>Duration:</span><span class="scan-result">{{ timeperiod }} days</span></div>
                      </div>
                      <p>The scan identifies opportunities to enhance efficiency by analyzing energy use and operational patterns,
                          providing a clear path to reduce costs and improve sustainability.</p>
                  </div>
              </div>
              </ng-container>

              <!-- tab 4 -->
              <ng-container *ngIf="!isLoading && selectedTab == 3">
                <div class="rejected-ecm-container">
                    <h2 class="mb-0 rejected-ecm-container-header">
                      Rejected ECM's
                    </h2>

                    <p class="rejected-ecm-container-subheader">
                      ECM Validation & Rejection
                    </p>

                    <p class="mb-4">
                      Easily reject suggested Energy Conservation Measures (ECMs) to align recommendations with your facility's
                       actual conditions. The algorithm provides suggestions based on available data, but you can refine these 
                       insights to ensure accuracy.
                    </p>

                    <p>
                      For example, if an ECM recommends replacing an exit light with an LED but the light is already LED, 
                      simply reject the ECM. This feature empowers you to curate a list of feasible and relevant measures tailored 
                      to your facility's unique setup and operational needs.
                    </p>
                </div>
              </ng-container>
  
              <ng-container *ngIf="isLoading">
                <div class="loaderContainer">
                  <div class="loader"></div>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="card-content circuitUsageContainer">
            <div class="circuitUsageController" *ngIf="!isExploreEnergyLoss">
              <span>ECM Type</span>
              <span>Savings Potential</span>
              <span>Savings Range</span>
            </div>
            <div class="circuitUsageController" *ngIf="isExploreEnergyLoss && selectedTab != 3">
              <div class="circuitUsageContainer">
                <div class="loss-type">
                  Loss Type 
                </div>
                <div class="circuit">
                  Circuit
                </div>
              </div>
              <div class="circuitUsageContainer">
                <div class="total-energy-used">
                  Total Energy Used 
                </div>
                <div class="loss-type">
                  Energy Loss Detected
                </div>
              </div>
            </div>
            <div class="circuitUsageController" *ngIf="isExploreEnergyLoss && selectedTab == 3">
              <span>ECM Type</span>
              <span>Savings Potential</span>
              <span>Savings Range</span>
            </div>

            <div [class]="isExploreEnergyLoss && selectedTab == 3 ? 'ecmUsageListContainer energy-loss-rejected-container' : 'ecmUsageListContainer'">
              <ng-container *ngIf="!isLoading">
                <div *ngFor="let circuitUsage of (selectedTab == 0 ? suggestedECMsList : selectedTab == 3 ? rejectedECMsList : suggestedECMsList) ; let i = index" 
                (click)="openCircuitModal(circuitUsage)" [class]="selectedTab == 3 ? 'ecm-info-container energy-loss-rejected' : 'ecm-info-container'">
                    <div  class="ecmInfo ecmNameType">
                      <div [class]="isExploreEnergyLoss ? '' : 'ecmName-and-type d-flex'">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" *ngIf="selectedTab != 3">
                          <path
                            d="M7 0C3.14035 0 0 3.14035 0 7C0 10.8596 3.14035 14 7 14C10.8596 14 14 10.8596 14 7C14 3.14035 10.8596 0 7 0ZM10.9123 5.15789L6.4386 9.59649C6.17544 9.85965 5.75439 9.87719 5.47368 9.61403L3.10526 7.45614C2.82456 7.19298 2.80702 6.75439 3.05263 6.47368C3.31579 6.19298 3.75439 6.17544 4.03509 6.4386L5.91228 8.1579L9.91228 4.15789C10.193 3.87719 10.6316 3.87719 10.9123 4.15789C11.193 4.4386 11.193 4.87719 10.9123 5.15789Z"
                            fill="#1FAB3D" />
                        </svg>
                        <img *ngIf="selectedTab == 3"
                              src="assets/images/rejectedecmred.png"
                              ait="rejected ecm scan"
                        />
                        <div *ngIf="!isExploreEnergyLoss">
                          <div class="ecmNumber">ECM #{{ i+1 }}</div>
                          <div>{{ circuitUsage.circuit.circuit_name }}</div>
                        </div>
                      
                      </div>
                       <div *ngIf="isExploreEnergyLoss && selectedTab != 3">
                        <div class="ecmNumber losetype">{{ circuitUsage.ecm.name }}</div>  
                        <div class="ecmName-explore-loss">{{ circuitUsage.circuit.circuit_name }}</div>
                       </div>

                       <div *ngIf="isExploreEnergyLoss && selectedTab == 3">
                        <div class="ecmNumber" >ECM #{{ i+1 }}</div>
                        <div class="ecmNameRejected">{{ circuitUsage.circuit.circuit_name }}</div>
                       </div>
                    </div>
                    
                    <div class="ecmInfo ecmWidthbar" *ngIf="!isExploreEnergyLoss">
                      <div
                      class="ecmWidth"
                      *ngIf="!isExploreEnergyLoss"
                      [ngStyle]="{
                        'width.px': totalEnergySavedPotential(
                          circuitUsage,0
                        ),
                        'min-width.px': 1
                      }"
                    ></div>
                    </div>

                    <div [class]="isExploreEnergyLoss ? 'ecmInfo ecmWidthbar isExploreEnergyLoss' : 'ecmInfo ecmWidthbar'" *ngIf="isExploreEnergyLoss && selectedTab != 3">
                      <div
                      class="ecmWidth"
                      [ngStyle]="{
                        'width.px': toEnergyInsightsPower(
                          circuitUsage,0
                        ),
                        'min-width.px': 0
                      }"
                    ></div>
                    <div
                      class="ecmWidthLoss"
                      [ngStyle]="{
                        'width.px': toEnergyInsightsPower(
                          circuitUsage,1
                        ),
                        'min-width.px': 0
                      }"
                    ></div>
                    </div>

                    <div [class]="isExploreEnergyLoss ? 'ecmInfo ecmWidthbar isExploreEnergyLoss' : 'ecmInfo ecmWidthbar'" *ngIf="isExploreEnergyLoss && selectedTab == 3">
                      <div
                      class="ecmWidth"
                      [ngStyle]="{
                        'width.px': totalEnergySavedPotential(
                          circuitUsage,0
                        ),
                        'min-width.px': 1
                      }"
                    ></div>
                    </div>

                    <div class="ecmInfo total-energy-data" *ngIf="!isExploreEnergyLoss">
                      <div>
                        {{
                          totalEnergySavedPotential(
                          circuitUsage,1
                        )
                        }}
                      </div>
                    </div>
                    <div class="ecmInfo total-energy-data" *ngIf="isExploreEnergyLoss && selectedTab != 3">
                      <div>
                        {{
                          toEnergyInsightsTotalPower(circuitUsage)
                        }}
                      </div>
                      <div class="losetype">
                        {{
                          toEnergyInsightsPowerPercentage(circuitUsage)
                        }}%
                      </div>
                    </div>
                    <div class="ecmInfo total-energy-data" *ngIf="isExploreEnergyLoss && selectedTab == 3">
                      <div>
                        {{
                          totalEnergySavedRejected(circuitUsage)
                        }}
                      </div>
                    </div>
                </div>
              </ng-container>

              <ng-container *ngIf="isLoading">
                <div class="loaderContainer">
                  <div class="loader"></div>
                </div>
              </ng-container>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
