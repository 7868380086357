<div class="dt-main">
  <app-sidebar></app-sidebar>

  <div class="dt-content-wrapper">
    <div class="dt-content dt-content-overwrite">
      <div class="col-md-12 spc-padding">
        <div class="headerContainer">
          <img
            src="assets/images/back-button.png"
            routerLink="/insights/energy-efficiency"
          />
          <h2 class="pageHeading heading">Total Energy Consumers</h2>
        </div>

        <div class="bg-lightgrey overflow-y-scroll card-container">
          <div class="card-content circuitUsageContainer">
            <div class="circuitUsageController">
              <ng-container *ngFor="let mode of modes; let i = index">
                <label
                  (click)="selectedMode = i"
                  class="radioInput"
                >
                  <input
                    type="radio"
                    [checked]="i === selectedMode"
                  />

                  <span>{{ mode.label }}</span>
                </label>
              </ng-container>
            </div>

            <div class="circuitUsageListContainer">
              <ng-container *ngIf="!isLoading">
                <div *ngFor="let circuitUsage of circuit_list">
                  <div class="circuitUsageInfo">
                    <div
                      class="circuitColor"
                      [ngStyle]="{
                        color: circuitUsage.circuit_category_colour
                      }"
                    ></div>
                    <div>{{ circuitUsage.circuit_name }}</div>
                  </div>
                  <div  style="display: grid; grid-auto-flow: column;">
                    <a
                      href="javascript: void(0);"
                      aria-disabled="false"
                      (click)="openCircuitModal(circuitUsage)"
                    >
                      <svg
                        fill="#B0BFE5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18px"
                        height="18px"
                      >
                        <path
                          d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"
                        ></path>
                      </svg>
                    </a>
  <div
                    class="circuitUsageWidth"
                    [ngStyle]="{
                      'width.px': toCircuitPercentage(
                        circuitUsage
                      ),
                      'min-width.px': 1
                    }"
                  ></div>
                  </div>
                  

                  <div>
                    {{
                      modes[selectedMode].getValue(
                        sortOptions[selectedSort].selector(circuitUsage)
                      )
                    }}
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="isLoading">
                <div class="loaderContainer">
                  <div class="loader"></div>
                </div>
              </ng-container>
            </div>
          </div>

          <div
            class="card-content secondaryCard"
            style="background-color: #fff"
          >
            <div class="dateSectionContainer">
              <div class="dateSelected">
                {{ formattedDate | date : dateFormat }}
              </div>

              <div class="dateContainer">
                <div class="dateRangeSelector">
                  <div
                    id="rangeSelectorDay"
                    (click)="rangeSelected($event)"
                    [attr.data-selected]="rangeSelectedDay === true"
                  >
                    Day
                  </div>

                  <div
                    id="rangeSelectorMonth"
                    (click)="rangeSelected($event)"
                    [attr.data-selected]="rangeSelectedMonth === true"
                  >
                    Month
                  </div>
                </div>

                <div class="dateActionContainer">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    width="20"
                    style="fill: white; transform: scale(0.6)"
                    (click)="selectPrevious()"
                  >
                    <path d="m8 18-8-8 8-8 1.417 1.417L2.833 10l6.584 6.583Z" />
                  </svg>

                  <div class="formattedDate">
                    {{ formattedDate | date : dateFormat }}
                  </div>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    width="20"
                    style="fill: white; transform: scale(0.6) rotate(180deg)"
                    (click)="selectNext()"
                  >
                    <path d="m8 18-8-8 8-8 1.417 1.417L2.833 10l6.584 6.583Z" />
                  </svg>
                </div>
              </div>
            </div>

            <ng-container *ngIf="!isLoading">
              <div class="sortByContainer">
                <div class="sortByHeader">Sort By:</div>

                <div class="sortByActions">
                  <ng-container
                    *ngFor="let option of sortOptions; let i = index"
                  >
                    <div
                      (click)="handleSortChange(i)"
                      [attr.date-selected]="selectedSort === i"
                    >
                      {{ option.label }}
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="infoContainer">
                <div>
                  <div class="infoContentHeader">Total Energy Used</div>

                  <div class="totalEneryUsedContent">
                    <span>{{
                      toKwH(this.sortOptions[this.selectedSort].getTotal())
                    }}</span>
                    <span>kWh</span>
                  </div>
                </div>

                <div class="operatingHoursContents">
                  <div class="infoContentHeader">Operating Hours</div>

                  <div class="operatingHoursContainer">
                    <div>
                      <div>{{ getDateSlice(operatingHours[0], 0) }}</div>

                      <div>
                        <span>:{{ getDateSlice(operatingHours[0], 1) }}</span>
                        <span>{{ getAmOrPm(operatingHours[0]) }}</span>
                      </div>
                    </div>

                    <div>-</div>

                    <div>
                      <div>{{ getDateSlice(operatingHours[1], 0) }}</div>

                      <div>
                        <span>:{{ getDateSlice(operatingHours[1], 1) }}</span>
                        <span>{{ getAmOrPm(operatingHours[1]) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="infoContainer"
                style="flex: 1"
              >
                <div
                  class="bill-breakdown-pie currentPie"
                  style="height: 100%; display: grid; place-content: center"
                >
                  <div class="byfees">
                    <div class="chartdv">
                      <apx-chart
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [labels]="chartOptions.labels"
                        [responsive]="chartOptions.responsive"
                        [legend]="chartOptions.legend"
                        [dataLabels]="chartOptions.dataLabels"
                        [colors]="chartOptions.colors"
                        [stroke]="chartOptions.stroke"
                      ></apx-chart>
                      <span class="chartper">100%</span>
                    </div>
                  </div>
                </div>

                <div
                  style="
                    display: grid;
                    gap: 12px;
                    grid-template-rows: auto 1fr;
                    height: 100%;
                    align-items: start;
                  "
                >
                  <div class="sidetabOptionsHeaders">
                    <div
                      *ngFor="let option of sidetabOptions; let i = index"
                      (click)="handleSidetabOptionsChange(i)"
                      [attr.data-selected]="selectedSidetabOptions === i"
                    >
                      {{ option.label }}
                    </div>
                  </div>

                  <div class="sidetabOptionsContainer">
                    <div class="sidetabOptionsLabel">
                      {{ sidetabOptions[selectedSidetabOptions].label }}
                    </div>

                    <div class="sidetabOptions">
                      <div
                        *ngFor="
                          let option of sidetabOptions[
                            selectedSidetabOptions
                          ].getOptions()
                        "
                      >
                        <div class="assetsInfo">
                          <svg
                            width="14"
                            height="13"
                            viewBox="0 0 14 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M0.583984 6.49967C0.583984 2.95585 3.45682 0.0830078 7.00065 0.0830078C10.5444 0.0830078 13.4173 2.95584 13.4173 6.49967C13.4173 10.0435 10.5445 12.9163 7.00065 12.9163C3.45682 12.9163 0.583984 10.0435 0.583984 6.49967ZM7.00065 1.24967C4.10115 1.24967 1.75065 3.60018 1.75065 6.49967C1.75065 9.39914 4.10116 11.7497 7.00065 11.7497C9.90012 11.7497 12.2507 9.39914 12.2507 6.49967C12.2507 3.60018 9.90012 1.24967 7.00065 1.24967ZM10.3298 4.04553C10.5576 4.27333 10.5576 4.64268 10.3298 4.87049L6.24646 8.95382C6.01866 9.18163 5.64931 9.18163 5.42151 8.95382L3.67151 7.20382C3.4437 6.97601 3.4437 6.60667 3.67151 6.37886C3.89931 6.15106 4.26866 6.15106 4.49646 6.37886L5.83398 7.71638L9.50484 4.04553C9.73265 3.81772 10.102 3.81772 10.3298 4.04553Z"
                              [ngStyle]="{ fill: option[1].color }"
                            />
                          </svg>

                          <div>{{ option[0] }}</div>
                        </div>

                        <div>{{ toPercentage(option[1].value) }}%</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="isLoading">
              <div class="loaderContainer">
                <div class="loader"></div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
