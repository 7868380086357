import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Building } from '../models/building';
import { DashboardDataService } from '../services/dashboard-data/dashboard-data.service';
import { DatePipe } from '@angular/common';
import { CalendarOptions } from '@fullcalendar/angular';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit {
  private currentBuildingSubscription: Subscription;
  currentBuilding: Building;
  weatherData: any;
  weatherEvents: any[];
  selectedDate: any;
  constructor(
    private dashboardDataService: DashboardDataService,
    public datepipe: DatePipe
  ) {
    this.currentBuildingSubscription = this.dashboardDataService
      .watchCurrentBuilding()
      .subscribe((building: Building) => {
        if (building != null) {
          this.currentBuilding = building;
        }
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.currentBuildingSubscription.unsubscribe();
  }

  getWeatherSchedule(start_date: String, end_date: String) {
    const location =
      (this.currentBuilding.weather_station &&
      this.currentBuilding.weather_station.length > 0
        ? this.currentBuilding.weather_station
        : this.currentBuilding.city) +
      ', ' +
      this.currentBuilding.province +
      ', Canada';

    this.dashboardDataService
      .getWeatherForecast(location!, start_date, end_date)
      .subscribe((events: any) => {
        this.weatherData = events.forecast.forecastday;
        this.generateCalendarEvents();
        this.setCalendar();
      });
  }

  generateCalendarEvents() {
    let events = [];
    for (let i in this.weatherData) {
      let event = this.weatherData[i];

      let max_temp = event.day.maxtemp_c;
      let min_temp = event.day.mintemp_c;

      let max_str = max_temp + '&deg;';
      let min_str = min_temp + '&deg;';

      let duration = event.day.condition.text;
      let iconUrl = event.day.condition.icon;

      let obj = {
        date: event.date,
        title: max_str + ' : ' + min_str,
        className: 'eventPresent',
        iconUrl: iconUrl,
        description: duration + ' hours'
      };
      events.push(obj);
    }
    this.weatherEvents = events;
  }

  calendarOptions: CalendarOptions = {
    headerToolbar: { left: '', center: '', right: 'prev title next' },
    initialView: 'dayGridMonth',
    weekends: true,
    showNonCurrentDates: false,
    editable: false,
    displayEventTime: true,
    selectable: false,
    selectMirror: true,
    dayMaxEvents: true,
    contentHeight: 'auto',
    fixedWeekCount: false,
    datesSet: dateInfo => {
      let startDate = new Date(dateInfo.startStr);
      let nextMonthStartDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        1
      );

      // converting dates into YYYY-MM-DD format
      let formattedStartDate = `${startDate.getFullYear()}-${(
        '0' +
        (startDate.getMonth() + 1)
      ).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`;
      let formattedNextMonthStartdate = `${nextMonthStartDate.getFullYear()}-${(
        '0' +
        (nextMonthStartDate.getMonth() + 1)
      ).slice(-2)}-01`;

      this.getWeatherSchedule(formattedStartDate, formattedNextMonthStartdate);
    },
    select: () => {},
    eventClick: () => {}
  };

  setCalendar() {
    this.calendarOptions.events = this.weatherEvents;
    this.calendarOptions.eventContent = function (arg) {
      var event = arg.event;
      let newNode = document.createElement('div');
      let arrayOfDomNodes: any = [];

      var title: any = event.title.trim().split(':');
      const max_temp = title[0];
      const min_temp = title[1];
      newNode.className = 'weather';
      newNode.innerHTML =
        "<div class='weatherIcon'>" +
        `<img src=${event.extendedProps['iconUrl']} />` +
        "</div><div class='weatherText'><span class='minTempText'>" +
        min_temp +
        "</span><span class='maxTempText'>" +
        max_temp +
        '</span></div>';
      arrayOfDomNodes = [newNode];

      return { domNodes: arrayOfDomNodes };
    };
  }
}
