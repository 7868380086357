export function getTimeBlocks() {
  const minutesInDay = 1440;
  const timeBlocksArr = [];

  for (let i = 0; i <= minutesInDay - 1; i++) {
    const timeLoop = i / (60 * 24);

    const formattedBlock = String(timeLoop);
    const hour = formattedBlock.split('.')[0];
    const minute = i;

    const today = new Date();
    const timeString = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      Number(hour),
      Number(minute)
    );

    timeBlocksArr.push(
      timeString.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })
    );
  }

  return timeBlocksArr;
}


export function getTimeBlocksLimit(limit: number) {
  const minutesInDay = limit;
  const timeBlocksArr = [];

  for (let i = 0; i <= minutesInDay - 1; i++) {
    const timeLoop = i / (limit);

    const formattedBlock = String(timeLoop);
    const hour = formattedBlock.split('.')[0];
    const minute = i;

    const today = new Date();
    const timeString = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      Number(hour),
      Number(minute)
    );

    timeBlocksArr.push(
      timeString.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })
    );
  }

  return timeBlocksArr;
}