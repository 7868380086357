import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardDataService } from '../../services/dashboard-data/dashboard-data.service';
import { Building } from '../../models/building';
import { MatDialog } from '@angular/material/dialog';
import { Panel } from '../../models/panel';
import { Circuit } from '../../models/circuit';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke,
  ChartComponent
} from 'ng-apexcharts';
import { AuthService } from '../../services/auth.service';
import { DialogComponent } from 'src/app/dialog/dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ConnectivityHistoryComponent } from './connectivity-history/connectivity-history.component';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};

const navItems = (sanitizer: DomSanitizer, panel: Panel) => [
  {
    id: 'active1',
    src: sanitizer.bypassSecurityTrustHtml(`
      <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.16577 14.2222C9.16577 13.2444 9.99117 12.4444 11 12.4444C12.0088 12.4444 12.8342 13.2444 12.8342 14.2222C12.8342 15.2 12.0088 16 11 16C9.99117 16 9.16577 15.2 9.16577 14.2222ZM16.8328 10.4533C17.3739 9.92889 17.3739 9.08445 16.8328 8.56889C13.6138 5.44889 8.37705 5.44889 5.15798 8.56889C4.61688 9.09333 4.61688 9.93778 5.15798 10.4533C5.69908 10.9689 6.57034 10.9778 7.10226 10.4533C9.24831 8.37333 12.7425 8.37333 14.8886 10.4533C15.1545 10.7111 15.5122 10.8444 15.8607 10.8444C16.2092 10.8444 16.5669 10.7111 16.8328 10.4533ZM21.5743 6.30222C22.1246 5.79556 22.1429 4.95111 21.6202 4.41778C21.5376 4.33778 21.4551 4.24889 21.3726 4.16889C18.6029 1.48444 14.9161 0 11 0C7.08392 0 3.39712 1.47556 0.627437 4.16C0.544897 4.24 0.462356 4.32889 0.379816 4.40889C-0.142939 4.94222 -0.124597 5.78667 0.425672 6.29333C0.97594 6.8 1.8472 6.78222 2.36995 6.24889L2.57172 6.04444C4.82782 3.85778 7.81761 2.65778 11 2.65778C14.1824 2.65778 17.1814 3.85778 19.4375 6.04444L19.6392 6.24C19.9052 6.51556 20.272 6.64889 20.6297 6.64889C20.969 6.64889 21.3175 6.52444 21.5835 6.28444L21.5743 6.30222Z" fill="#6285FB"/>
      </svg>
    `),
    content: ConnectivityHistoryComponent,
    data: { panel }
  }
];

@Component({
  selector: 'app-connectivity',
  templateUrl: './connectivity.component.html',
  styleUrls: ['./connectivity.component.scss']
})
export class ConnectivityComponent implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  public chartOptionsPanel: Partial<ChartOptions> | any;
  buildings: Building[];
  currentBuilding: Building;

  constructor(
    private sanitizer: DomSanitizer,
    private dashboardDataService: DashboardDataService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this.chartOptions = {
      initialize: false
    };
    this.chartOptionsPanel = {
      initialize: false
    };
    this.dashboardDataService.watchBuildings().forEach(buildings => {
      if (buildings != null) {
        this.buildings = buildings;
      }
    });

    this.dashboardDataService
      .watchCurrentBuilding()
      .forEach((building: Building) => {
        if (building != null && this.currentBuilding == null) {
          this.currentBuilding = building;
          this.getPanels(building.idbuildings);
        }
      });
  }

  panels: (Panel & { connectivity: any })[];
  circuitCategoryMain: any[];
  circuitCategoryPane: any[];
  circuits: Circuit[];
  modal: string | null = null;
  stack: any[] = [];
  powerPercetangeObject: any[];

  public active = 2;
  public isCostSelected = false;
  public isPanelCollapsed: boolean[] = [];
  public isInventryCollapsed: boolean[] = [];
  public panelsIsConnected = true;
  public totalPanelPower = 0;
  public totalCurrent: number = 0;
  public totalPower: number = 0;
  public totalCost: number = 0;
  public fetchsetInterval: boolean = false;
  public panelnonConnected: boolean = false;
  public panelnonActive: boolean = false;
  public panelactive: boolean = false;
  public panelall: boolean = true;

  public panelMapping: { [key: string]: Panel } = {};
  public circuitMapping: { [key: string]: Panel } = {};

  public circuitCurrentMapping: { [key: number]: Circuit } = {};
  public circuitPowerMapping: { [key: string]: number } = {};
  public circuitCostMapping: { [key: string]: number } = {};

  public circuitCategoryCurrentMapping: { [key: number]: number } = {};
  public circuitCategoryPowerMapping: { [key: number]: number } = {};
  public circuitCategoryCostMapping: { [key: number]: number } = {};

  public sidebarCategoryActive: boolean = true;
  public sidebarPanelActive: boolean = false;

  public setTimeoutRef: NodeJS.Timeout | null = null;

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.setTimeoutRef) {
      clearTimeout(this.setTimeoutRef);
      this.setTimeoutRef = null;
    }
  }

  getUsageForAll() {
    for (const element of this.panels) {
      this.getUsage(element);
    }
  }

  getUsage(element: any) {
    if (!element.meter_name) {
      element.panelIsconnected = null;
      return;
    }

    this.dashboardDataService
      .fetchLatestMeterData(element.meter_name)
      .subscribe(result => {
        if (Object.keys(result).length === 0) {
          element.panelIsconnected = false;
          return;
        }

        element.panelIsconnected = true;
      });
  }

  getPanels(buildingId: any): void {
    this.dashboardDataService.getPanels(buildingId).forEach((panels: any) => {
      this.panels = panels.results;

      // TODO: Remove this code once api starts sending panel color
      this.panels.forEach((panel: any) => {
        panel.panelIsconnected = false;
        panel.panel_circuits = [];
        this.panelMapping[panel.panel_id] = panel;

        const subs = this.dashboardDataService
          .getPanelExpansion(panel.panel_id)
          .subscribe(response => {
            panel.connectivity = response.connectivity;
            subs.unsubscribe();
          });
      });

      this.dashboardDataService.getCurrentBuilding();

      this.getUsageForAll();
      this.setTimeoutRef = setTimeout(() => this.getUsageForAll(), 60000);
    });
  }

  getRssiMeta(rssi: number) {
    if (rssi >= -30) {
      return {
        fill: '#22B14C',
        text: 'Excellent',
        wifiStrength: 3,
        value: `${rssi} dBm`
      };
    } else if (rssi >= -60) {
      return {
        fill: '#5EC776',
        text: 'Good',
        wifiStrength: 3,
        value: `${rssi} dBm`
      };
    } else if (rssi >= -70) {
      return {
        fill: '#4DAAE8',
        text: 'Okay',
        wifiStrength: 2,
        value: `${rssi} dBm`
      };
    } else if (rssi >= -80) {
      return {
        fill: '#FF7F27',
        text: 'Not Good',
        wifiStrength: 1,
        value: `${rssi} dBm`
      };
    } else if (rssi >= -90) {
      return {
        fill: '#FEC754',
        text: 'Unusable',
        wifiStrength: 0,
        value: `${rssi} dBm`
      };
    } else {
      return {
        fill: '#FEC754',
        text: 'Unusable',
        wifiStrength: 0,
        value: `${rssi} dBm`
      };
    }
  }

  openConnectivityDialog(panel: Panel) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: panel.meter_name,
        navItems: navItems(this.sanitizer, panel)
      }
    });
  }
}
