import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChartComponent } from 'ng-apexcharts';
import { ChartOptions } from 'src/app/live-usage/live-usage.component';
import { Building } from 'src/app/models/building';
import { EcmScan } from 'src/app/models/ecm_scan';
import { DashboardDataService } from 'src/app/services/dashboard-data/dashboard-data.service';

@Component({
  selector: 'app-explore-savings-potential',
  templateUrl: './explore-savings-potential.component.html',
  styleUrls: ['./explore-savings-potential.component.scss']
})
export class ExploreSavingsPotentialComponent {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any = { initialize: false };

  public building: Building;
  public isLoading = true;
  public ecmScans: EcmScan[] = [];
  public showCreateModal = false;
  public showAboutModal = false;
  public scanForm: FormGroup;
  public isSubmitting = false;

  constructor(
    private dashboardService: DashboardDataService,
    private fb: FormBuilder
  ) {
    this.initForm();
    const subs = this.dashboardService.currentBuilding.subscribe(result => {
      if (!result) return;
      this.building = result;
      subs.unsubscribe();
    });
    this.getEcmScans();
  }

  private initForm() {
    this.scanForm = this.fb.group({
      startDate: ['', [Validators.required]],
      tillDate: ['', Validators.required]
    });
  }

  //openScanModal
  openScanModal($event: Event) {
    const { classList } = $event.target as HTMLSelectElement;
    if(classList?.contains('create-scan-cta')){
      this.showCreateModal = true;
    }else if(classList?.contains('about-scan-cta')){
      this.showAboutModal = true;
    }
  }

  closeCreateScanModal() {
    this.showAboutModal = false;
    this.showCreateModal = false;
    this.scanForm.reset();
  }

  createScan() {
    if (this.scanForm.invalid || this.isSubmitting) return;

    this.isSubmitting = true;
    const scanData = {
      start_date: this.scanForm.value.startDate,
      end_date: this.scanForm.value.tillDate,
      building: this.building.idbuildings
    };

    this.dashboardService.createEcmScan(scanData).subscribe({
      next: (response) => {
        this.closeCreateScanModal();
        this.getEcmScans(); // Refresh the list
      },
      error: (error) => {
        console.error('Error creating ECM scan:', error);
        // Handle error - maybe show a message to user
      },
      complete: () => {
        this.isSubmitting = false;
      }
    });
  }

  getEcmScans() {
    this.dashboardService.getEcmScans(this.building.idbuildings).subscribe(result => {
      for(let scan of result){
        // Extract First Date
        let startDate = new Date(scan.start_date);
        let startMonth = startDate.toLocaleString("default", { month: "long" });
        startMonth = startMonth.slice(0, 3);
        let firstDate = `${startMonth} ${startDate.getFullYear()}`;
        let firstFullDate = `${startMonth} ${startDate.getDate()}, ${startDate.getFullYear()}`;
        scan.firstFullDate = firstFullDate;

        // Extract Second Date
        let endDate = new Date(scan.end_date);
        let endMonth = endDate.toLocaleString("default", { month: "long" });
        endMonth = endMonth.slice(0, 3);
        let secondDate = `${endMonth} ${endDate.getFullYear()}`;
        let endFullDate = `${endMonth} ${endDate.getDate()}, ${endDate.getFullYear()}`;
        scan.finalDate = `${firstDate} - ${secondDate}`;
        scan.endFullDate = endFullDate;

        //Calculate number no of days
        const timeDifference = endDate.getTime() - startDate.getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);

        scan.timeperiod = Math.abs(daysDifference);
      }
      this.ecmScans = result;
      this.isLoading = false;
    });
  }
}
