<div class="dt-main">
  <app-sidebar></app-sidebar>

  <div class="dt-content-wrapper">
    <div class="dt-content dt-content-overwrite">
      <div class="col-md-12 spc-padding">
        <div class="headerContainer">
          <img
            src="assets/images/back-button.png"
            routerLink="/insights"
          />
          <h2 class="pageHeading heading">Asset Intelligence</h2>
        </div>

        <div class="bg-lightgrey card-container">
          <div
            class="card-content secondaryCard"
            style="background-color: #fff"
          >
            <div class="dateSectionContainer">
              <div class="cycleSelected">
                {{ selectedCircuit?.circuit_name }}
              </div>
            </div>

            <ng-container *ngIf="!isLoading">
              
              <div class="line-usage-chart">
                <img src="/assets/images/HeatPump.png" alt="icon" class="circuit-image"/>
              </div>

              <div class="infoContainer">
                <div>
                  <div class="infoContentHeader">General Info</div>
                </div>
              </div>

              <div class="infoContainer">
                <div>
                  <div class="infoContentHeader">Panel:</div>
                  <div class="infoContentHeaderValue">{{ selectedCircuit?.panel__panel_name }}</div>
                  <div class="infoContentHeader">Circuit Number:</div>
                  <div class="infoContentHeaderValue">#{{selectedCircuit?.circuit_number}}</div>
                  <div class="infoContentHeader">Circuit Name:</div>
                  <div class="infoContentHeaderValue">{{selectedCircuit?.circuit_name}}</div>
                  <div class="infoContentHeader">Category:</div>
                  <div class="infoContentHeaderValue">{{selectedCircuit?.circuit_category__name}}</div>
                  <div class="infoContentHeader">Sub Category:</div>
                  <div class="infoContentHeaderValue">{{selectedCircuit?.circuit_sub_category__name}}</div>
                </div>
                <div>
                  <div class="infoContentHeader">Load Type:</div>
                  <div class="infoContentHeaderValue">-</div>
                  <div class="infoContentHeader">Number of Cycles:</div>
                  <div class="infoContentHeaderValue">-</div>
                  <div class="infoContentHeader">Total Hours/ Run Time:</div>
                  <div class="infoContentHeaderValue">-</div>
                </div>
              </div>
              <div [queryParams]="{circuit_id: selectedCircuit?.id, circuit_name:selectedCircuit?.circuit_name, meter_name:selectedCircuit?.panel__panel_meter__name}" [routerLink]="['/insights/circuit-cycles']">
                <button class="btn btn-primary view-cycle">Explore this Asset</button>
              </div>
            </ng-container>

            <ng-container *ngIf="isLoading">
              <div class="loaderContainer">
                <div class="loader"></div>
              </div>
            </ng-container>
          </div>
          <div class="card-content overflow-y-scroll circuitUsageContainer">

            <div class="circuitUsageListContainer">
              <ng-container *ngIf="!isLoading">
                <div style="color: black;">
                  <div>Circuit Name</div>
                  <div>Panel</div>
                  <div>Cateogry</div>
                </div>
                <div *ngFor="let circuit of circuit_list">
                  <div (click)="circuitCycleOnClick(circuit)">
                    
                    <div>
                      <span class="dot"  [ngStyle]="{'background-color': circuit.circuit_category__colour}"></span>
                      #{{ circuit.circuit_name }}
                  </div>
                  </div>
                  <div id="{{ circuit.id }}">
                    <div>{{ circuit.panel__panel_name }}</div>
                  </div >
                  <div id="{{ circuit.circuit_number }}">
                    <div>{{ circuit.circuit_category__name }}</div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="isLoading">
                <div class="loaderContainer">
                  <div class="loader"></div>
                </div>
              </ng-container>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
