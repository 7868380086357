import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subscription, catchError, map } from 'rxjs';

import { Building } from '../models/building';

import { NgToastService } from 'ng-angular-popup';
import { DashboardDataService } from '../services/dashboard-data/dashboard-data.service';
import { SettingService } from 'src/app/services/settings-service';
import { Facility } from '../models/facility';
import { Router } from '@angular/router';
import { CompanyInformation } from '../models/account-settings/companyinfo';

interface GeneralOverviewFormFields {
  id: keyof Building | undefined;
  name: string;
  title: string;
  type: 'text' | 'select';
  selector: (d: Building) => string | number | null;
}
interface OrganizationOverviewFormFields {
  id: keyof CompanyInformation | undefined;
  name: string;
  title: string;
  type: 'text' | 'select';
  selector: (d: CompanyInformation) => string | number | null;
}

type Options = {
  id: string;
  label: string;
  condition: boolean;
  onClick: Function;
  alt?: () => string;
  src?: () => string;
  innerHTML?: string;
};

@Component({
  selector: 'app-about-facility',
  templateUrl: './about-facility.component.html',
  styleUrls: ['./about-facility.component.scss']
})
export class AboutFacilityComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  public currentBuilding: Building;
  public updateInfo: Building;

  public company: CompanyInformation;
  public updateCompanyInfo: CompanyInformation;

  private currentBuildingSubscription: Subscription;
  temp_c: any;
  iconUrl: any;

  public modal: 'general-overview' | 'organization-overview' | null = null;
  public isEditing: string | null = null;

  allFacilities: (Facility & { id: number })[];
  facilityOptions: { type: string[]; sub_type: string[] } = {
    type: [],
    sub_type: []
  };

  facilityImageUrl = (d: Building) => d.image || d.facility.image;

  public options: () => Options[] = () => [
    {
      id: 'organization_overview',
      label: 'Organization Overview',
      src: () => (this.company?.image as string) ?? '',
      alt: () => this.company?.name ?? '',
      condition: !!this.company,
      onClick: () => this.openModal('organization-overview')
    },
    {
      id: 'facility_overview',
      label: 'Facility Overview',
      src: () => this.facilityImageUrl(this.currentBuilding),
      alt: () => this.currentBuilding.facility.type,
      condition: !!this.currentBuilding,
      onClick: () => this.openModal('general-overview')
    },
    {
      id: 'location_info',
      label: 'Location Info',
      src: () => 'assets/images/map.png',
      alt: () => 'Location Info',
      condition: !!this.currentBuilding,
      onClick: () => this.openModal('general-overview')
    },
    {
      id: 'operating_hours',
      label: 'Operating Hours',
      src: () => '',
      alt: () => '',
      condition: true,
      onClick: () => {
        this.router.navigateByUrl('/operatinghours');
      },
      innerHTML: `<div class="HourChart">
                    <div class="column">
                      <p class="title">S</p>
                    </div>

                    <div class="column">
                      <p class="title">M</p>
                    </div>

                    <div class="column">
                      <p class="title">T</p>
                    </div>

                    <div class="column">
                      <p class="title">W</p>
                    </div>

                    <div class="column">
                      <p class="title">T</p>
                    </div>

                    <div class="column">
                      <p class="title">F</p>
                    </div>

                    <div class="column">
                      <p class="title">S</p>
                    </div>
                  </div>`
    },
    {
      id: 'weather',
      label: 'Weather',
      condition: !!this.iconUrl,
      onClick: () => {
        this.router.navigateByUrl('/weather');
      },
      innerHTML: `<div class="weather-div">
                    <div class='weather-div-label-container'>
                      <div class="label">
                        <span></span>
                        <p>Live</p>
                      </div>
                    </div>

                    <div class="weather-spc">
                      <div class="weather_col">
                        <img
                          *ngIf=${this.iconUrl}
                          src=${this.iconUrl}
                          alt="Weather"
                        />
                      </div>

                      <div class="weatherTxt">
                        ${this.temp_c}<sup>&deg;C</sup>
                      </div>
                    </div>`
    }
  ];

  public generalOverviewFields: GeneralOverviewFormFields[] = [
    {
      title: 'Facility',
      id: 'description',
      name: 'description',
      type: 'text',
      selector: d => d.description
    },
    {
      title: 'Building Type',
      id: 'facility',
      name: 'type',
      type: 'select',
      selector: d => d.facility.type
    },
    {
      title: 'Building Sub Type',
      id: 'facility',
      name: 'sub_type',
      type: 'select',
      selector: d => d.facility.sub_type
    },
    {
      title: 'Year Built',
      id: 'year_built',
      name: 'year_built',
      type: 'text',
      selector: d => d.year_built
    },
    {
      title: 'Age',
      id: 'age',
      name: 'age',
      type: 'text',
      selector: d => d.age
    },
    {
      title: 'Size / Square Footage',
      id: 'squarefootage',
      name: 'squarefootage',
      type: 'text',
      selector: d => d.squarefootage
    },
    {
      title: 'Address',
      id: 'address_line_1',
      name: 'address_line_1',
      type: 'text',
      selector: d => d.address_line_1 ?? d.address_line_2
    },
    {
      title: 'Postal Code',
      id: 'postal_code',
      name: 'postal_code',
      type: 'text',
      selector: d => d.postal_code
    },
    {
      title: 'City',
      id: 'city',
      name: 'city',
      type: 'text',
      selector: d => d.city
    },
    {
      title: 'State / Province',
      id: 'province',
      name: 'province',
      type: 'text',
      selector: d => d.province
    },
    {
      title: 'Weather Station',
      id: 'weather_station',
      name: 'weather_station',
      type: 'text',
      selector: d => d.weather_station
    },
    {
      title: 'Time Zone',
      id: 'timezone',
      name: 'timezone',
      type: 'text',
      selector: d => d.timezone
    }
  ];

  public organizationOverviewFields: OrganizationOverviewFormFields[] = [
    {
      title: 'Organization Name',
      id: 'name',
      name: 'name',
      type: 'text',
      selector: d => d.name ?? ''
    },
    {
      title: 'Organization ID',
      id: 'company_id',
      name: 'company_id',
      type: 'text',
      selector: d => d.company_id ?? ''
    }
  ];

  public organizationHeadofficeOverviewFields: OrganizationOverviewFormFields[] =
    [
      {
        title: 'Address',
        id: 'address',
        name: 'address',
        type: 'text',
        selector: d => d.address ?? ''
      },
      {
        title: 'City',
        id: 'city',
        name: 'city',
        type: 'text',
        selector: d => d.city ?? ''
      },
      {
        title: 'State / Province',
        id: 'state',
        name: 'state',
        type: 'text',
        selector: d => d.state ?? ''
      },
      {
        title: 'Postal Code',
        id: 'postal_code',
        name: 'postal_code',
        type: 'text',
        selector: d => d.postal_code ?? ''
      }
    ];

  constructor(
    private dashboardDataService: DashboardDataService,
    private settingService: SettingService,
    private toast: NgToastService,
    private router: Router
  ) {
    this.currentBuildingSubscription = this.dashboardDataService
      .watchCurrentBuilding()
      .subscribe((building: Building) => {
        if (building != null) {
          this.currentBuilding = building;
          this.updateInfo = structuredClone(this.currentBuilding);
          this.getCurrentWeather();

          this.settingService.getFacilities().subscribe((data: any) => {
            const { results } = data;

            this.allFacilities = results;

            this.facilityOptions.type = [
              ...new Set(this.allFacilities.map((d: any) => d.type))
            ] as string[];

            this.updateFacilitySubTypeOptions();
          });
        }
      });

    const subs = this.settingService
      .getCompanyById(this.currentBuilding.company_id)
      .subscribe(data => {
        this.company = data;
        this.updateCompanyInfo = structuredClone(this.company);
        subs.unsubscribe();
      });
  }

  updateFacilitySubTypeOptions() {
    this.facilityOptions.sub_type = [
      ...new Set(
        this.allFacilities
          .filter((d: any) => d.type === this.updateInfo['facility'].type)
          .map((facility: any) => facility.sub_type)
      )
    ];
  }

  getCurrentWeather() {
    const location =
      (this.currentBuilding.weather_station &&
      this.currentBuilding.weather_station.length > 0
        ? this.currentBuilding.weather_station
        : this.currentBuilding.city) +
      ', ' +
      this.currentBuilding.province +
      ', Canada';

    this.dashboardDataService
      .getWeatherCurrent(location)
      .subscribe((event: any) => {
        this.temp_c = String(event.current.temp_c);
        this.iconUrl = event.current.condition.icon;
      });
  }

  openModal(modal: typeof this.modal) {
    this.modal = modal;
  }

  closeGeneralOverviewModal() {
    this.modal = null;
    this.isEditing = null;
  }

  toggleEdit(e: string | null) {
    this.isEditing = e;

    if (e === null) {
      if (this.modal === 'general-overview') {
        this.updateInfo = structuredClone(this.currentBuilding);
      }

      if (this.modal === 'organization-overview') {
        this.updateCompanyInfo = structuredClone(this.company);
      }
    }
  }

  updateFacility(facilityId: string, field: any): void {
    if (
      // @ts-ignore
      typeof this.updateFacility[field] !== 'string' ||
      // @ts-ignore
      this.updateInfo[field] !== this.currentBuilding[field]
    ) {
      const reqBody = {
        [field]:
          field === 'facility'
            ? this.allFacilities.find(
                d =>
                  d.type === this.updateInfo.facility.type &&
                  d.sub_type === this.updateInfo.facility.sub_type
              )?.id
            : // @ts-ignore
              this.updateInfo[field]
      };

      this.settingService.updateFacility(reqBody, facilityId).subscribe(
        success => {
          if (success) {
            this.currentBuilding = structuredClone(this.updateInfo);
            this.dashboardDataService.setCurrentBuilding(this.currentBuilding);
            this.toggleEdit(null);

            this.toast.success({
              detail: 'Success',
              summary: 'Field updated successfully!',
              sticky: true,
              position: 'tr'
            });
          } else {
            this.toast.error({
              detail: 'Error',
              summary: 'Field update was unsuccessfull!',
              sticky: true,
              position: 'tr'
            });
          }
        },
        error => {
          this.toast.error({
            detail: 'Error',
            summary: 'Field update was unsuccessfull!',
            sticky: true,
            position: 'tr'
          });

          console.error('Error updating user:', error);
        }
      );
    } else {
      this.toggleEdit(null);
    }
  }

  updateCompany(field: any): void {
    if (
      // @ts-ignore
      typeof this.updateCompanyInfo[field] !== 'string' ||
      // @ts-ignore
      this.updateCompanyInfo[field] !== this.company[field]
    ) {
      const reqBody = {
        [field]:
          // @ts-ignore
          this.updateCompanyInfo[field]
      };

      this.settingService
        .updatecompany(reqBody, this.company.company_id)
        .subscribe(
          success => {
            if (success) {
              this.company = structuredClone(this.updateCompanyInfo);
              // this.currentBuilding = structuredClone(this.updateCompanyInfo);
              // this.dashboardDataService.setCurrentBuilding(this.currentBuilding);
              this.toggleEdit(null);

              this.toast.success({
                detail: 'Success',
                summary: 'Field updated successfully!',
                sticky: true,
                position: 'tr'
              });
            } else {
              this.toast.error({
                detail: 'Error',
                summary: 'Field update was unsuccessfull!',
                sticky: true,
                position: 'tr'
              });
            }
          },
          error => {
            this.toast.error({
              detail: 'Error',
              summary: 'Field update was unsuccessfull!',
              sticky: true,
              position: 'tr'
            });

            console.error('Error updating user:', error);
          }
        );
    } else {
      this.toggleEdit(null);
    }
  }

  handleValueChange(event: Event, id: any) {
    if (event.target) {
      if (id === 'facility') {
        const { name, value } = event.target as HTMLSelectElement;

        // @ts-ignore
        if (this.updateInfo['facility'][name] !== value) {
          // @ts-ignore
          this.updateInfo['facility'][name] = value;

          if (name === 'type') {
            this.updateFacilitySubTypeOptions();
            this.updateInfo['facility'].sub_type =
              this.facilityOptions.sub_type[0];
          }
        }
      } else {
        const selector =
          this.modal === 'general-overview'
            ? this.updateInfo
            : this.updateCompanyInfo;

        // @ts-ignore
        selector[id] = (event.target as HTMLInputElement).value.trim();
      }
    }
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      if (this.modal === 'general-overview') {
        this.settingService
          .updateFacility(formData, this.updateInfo['idbuildings'])
          .subscribe(
            response => {
              this.currentBuilding = response;
              this.dashboardDataService.setCurrentBuilding(response);
              this.updateInfo = structuredClone(this.currentBuilding);

              this.toast.success({
                detail: 'Success',
                summary: 'Field updated successfully!',
                sticky: true,
                position: 'tr'
              });
            },
            error => {
              this.toast.error({
                detail: 'Error',
                summary: 'Field update was unsuccessfull!',
                sticky: true,
                position: 'tr'
              });

              console.error('Error updating user:', error);
            }
          );
      }

      if (this.modal === 'organization-overview') {
        this.settingService
          .updateCompanyImage(formData, this.company.company_id)
          .subscribe(
            response => {
              this.company = response;
              this.updateCompanyInfo = structuredClone(this.company);

              this.toast.success({
                detail: 'Success',
                summary: 'Field updated successfully!',
                sticky: true,
                position: 'tr'
              });
            },
            error => {
              this.toast.error({
                detail: 'Error',
                summary: 'Field update was unsuccessfull!',
                sticky: true,
                position: 'tr'
              });

              console.error('Error updating user:', error);
            }
          );
      }
    }
  }

  ngOnInit(): void {}
}
