import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardDataService } from '../../services/dashboard-data/dashboard-data.service';
import { NgToastService } from 'ng-angular-popup';
import { ActivatedRoute, Params } from '@angular/router';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke,
  ChartComponent
} from 'ng-apexcharts';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Circuit } from 'src/app/models/circuit';
import { Panel } from 'src/app/models/panel';
import { Subject, debounceTime, type Subscription } from 'rxjs';
import type { ECharts, EChartsOption } from 'echarts';
import { getTimeBlocks } from 'src/utils';

import mqtt from 'mqtt';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};

type LiveLineChartOptions = {
  id: string;
  label: string;
  selector: (d: Circuit, p: Panel) => number;
  symbol: string;
};

@Component({
  selector: 'app-circuit-info-component',
  templateUrl: './circuit-info-component.component.html',
  styleUrls: ['./circuit-info-component.component.scss']
})
export class CircuitInfoComponent implements OnInit {
  @ViewChild('chart', { static: true }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  public chartOptionsMeterAnalyticsData: Partial<ChartOptions> | any;
  public activeId = 1;
  public circuit: Circuit = this.data.circuit;
  public meter_name: string = this.data.meter_name;
  public panel_name: string = this.data.panel_name;
  public circuit_category = this.data.circuit;
  public groupedCircuits: any = this.data.groupedCircuits;
  public buildingId: any = this.data.buildingId;
  public circuitCategories: any = this.data.circuitCategories;
  public panel: Panel = this.data.panel;
  public render_dialog: boolean = false;
  public circuitsStats: any = [];
  public circuits: any = [];
  math = Math;
  public totalCost: number = 0;
  public totalEnergy: number = 0;
  public totalCostLineChart: number = 0;
  public totalEnergyLineChart: number = 0;
  public model: NgbDateStruct = this.calendar.getToday();
  public date: { year: number; month: number };
  public minDate: NgbDateStruct = { year: 2021, month: 1, day: 1 };
  public maxDate: NgbDateStruct = this.calendar.getToday();
  public now: any = new Date();
  public oldestRecord: any;
  public slides: any = [];
  public meter_image: any = '';
  public iseditCircuitName: boolean = false;
  public iseditCircuitCategory: boolean = false;

  public liveLineChartData: Circuit[] = [];
  private panelsSubscription: Subscription | null = null;
  public currentLiveDataValue = '';

  public liveLineChartOptions: LiveLineChartOptions[] = [
    // {
    //   id: 'circuit_power',
    //   label: 'Power Factor',
    //   selector: (d: Circuit, _: Panel) => d.circuit_power,
    //   symbol: 'pf'
    // },
    {
      id: 'circuit_current',
      label: 'Amps',
      selector: (d: Circuit, _: Panel) => d.circuit_current,
      symbol: 'a'
    },
    {
      id: 'circuit_volts',
      label: 'Voltage',
      selector: (_: Circuit, p: Panel) => p.panel_volts,
      symbol: 'v'
    },
    {
      id: 'watts',
      label: 'Wattage',
      selector: (d: Circuit, p: Panel) => {
        return d.circuit_current * p.panel_volts;
      },
      symbol: 'w'
    }
  ];

  public liveConnectivityOptions = [
    { id: 'connectivity', label: 'Connectivity' }
  ];

  public isLiveConnectivitySelected = false;

  public selectedLiveLineChartOption: LiveLineChartOptions =
    this.liveLineChartOptions[this.liveLineChartOptions.length - 1];

  liveChartOptions: EChartsOption;
  lineChartOptions: EChartsOption;

  private lineChartMeterMetrics: any[] = [];

  onDataZoomSubject = new Subject();

  private mqttClient: mqtt.MqttClient | null = null;
  public connectivity: null | ReturnType<typeof this.getNetworkMeta> = null;

  constructor(
    private toast: NgToastService,
    public dialogRef: MatDialogRef<CircuitInfoComponent>,
    private dashboardDataService: DashboardDataService,
    private activatedRoute: ActivatedRoute,
    private calendar: NgbCalendar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.render_dialog = true;

      const today = new Date(
        this.model.year,
        this.model.month - 1,
        this.model.day
      );

      // this.fetchCircuitChartAnalyticsData(this.meter_name, today);
      this.fetchLineChartMeterAnalytics(this.meter_name, today);

      const { activation_date } = data.circuit;

      if (activation_date?.length > 0) {
        const oldestRecord = new Date(data.circuit.activation_date);

        this.oldestRecord = oldestRecord;

        this.minDate = {
          year: oldestRecord.getFullYear(),
          month: oldestRecord.getMonth() + 1,
          day: oldestRecord.getDate()
        };
      }

      const imgs: any = [];

      if (this.panel.panel_images.length > 0) {
        this.panel.panel_images.forEach((element: any) => {
          imgs.push({
            src: element?.image,
            name: element?.image?.split('/').pop()
          });
        });
        this.meter_image = imgs[0]?.src;
        this.slides = imgs;
      }

      this.circuitCategories.forEach((category: any) => {
        if (category.id == this.circuit.circuit_category) {
          this.circuit.circuit_category_name = category.name;
        }
      });

      this.circuit.panel_meter_channels.forEach((channel: any) => {
        let split = channel.expansion_number.split('-');
        this.circuit.extension_module = split[0];
        this.circuit.extension_number = split[1];
      });
    });

    this.connectMqtt();
  }

  connectMqtt() {
    try {
      const { meter_name, panel_meter } = this.data.panel;

      if (!meter_name || panel_meter?.use_api) {
        return;
      }

      const options: mqtt.IClientOptions = {
        protocol: 'ws',
        username: 'sustain_dev',
        password: 'sustain_dev',
        clean: true,
        port: 15675,
        keepalive: 60,
        hostname: 'dev.opti-mized.com',
        path: '/ws',
        clientId: this.circuit.panel_id
      };

      if (window.location.protocol.includes('https')) {
        options.protocol = 'wss';
        options.port = 15676;
      }

      if (
        window.location.hostname.includes('facility.opti-mized.com') &&
        !window.location.hostname.includes('dev-')
      ) {
        options.hostname = 'live.opti-mized.com';
        options.username = 'iotdevice1';
        options.password = 'iotdevice1';
        delete options.path;
      }

      const client = mqtt.connect(options);

      this.mqttClient = client;

      const topic = `attr/${meter_name}`;

      client.on('connect', function () {
        client.publish(
          `cmd/${meter_name}`,
          JSON.stringify({ name: 'get_nw_data' })
        );

        client.subscribe(topic, function (err: any) {
          if (err) {
            console.error(err);
          }
        });
      });

      client.on(
        'message',
        (currentTopic: any, message: { toString: () => any }) => {
          if (currentTopic === topic) {
            const result = JSON.parse(message.toString());

            if (result.network_type) {
              this.connectivity = this.getNetworkMeta(result);
            }
          }
        }
      );

      client.on('error', function (error) {
        console.error('MQTT Connection error: ', error);
      });
    } catch (err) {
      console.error(err);
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.panelsSubscription = this.data.panels$.subscribe((panels: Panel[]) => {
      const panel = panels.find(d => d.panel_id === this.panel.panel_id);

      if (panel) {
        const circuit = (
          this.data.combinePhases
            ? panel.panel_circuitsGrouped
            : panel.panel_circuits
        ).find(d => d.id === this.circuit.id);

        if (circuit) {
          this.panel = { ...panel };

          this.liveLineChartData.push({ ...circuit });
          this.updateCurrentLiveDataValue();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.panelsSubscription?.unsubscribe();
    this.panelsSubscription = null;

    if (this.mqttClient) {
      this.mqttClient.end();
      this.mqttClient = null;
    }
  }

  updateCurrentLiveDataValue() {
    let max: number = -Infinity;

    const data: number[] = [];

    for (const x of this.liveLineChartData) {
      const value = this.selectedLiveLineChartOption.selector(x, this.panel);
      if (value > max) max = value;
      data.push(value);
    }

    this.liveChartOptions = {
      xAxis: {
        type: 'category',
        data: Array.from(Array(data.length).keys()),
        boundaryGap: false,
        axisLine: { lineStyle: { color: '#ECE9F1', width: 1 } },
        axisLabel: { show: false },
        axisPointer: { show: false }
      },
      yAxis: {
        type: 'value',
        alignTicks: false,
        boundaryGap: [0, 0],
        minorTick: { show: false },
        animationEasing: 'linear',
        max: max ?? 0,
        min: 0,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 1,
            type: 'dotted'
          }
        },
        axisLabel: {
          formatter: (value: number) => {
            return `${this.customToFixed(value)}${
              this.selectedLiveLineChartOption.symbol
            }`;
          }
        }
      },
      series: [
        {
          data,
          type: 'line',
          showSymbol: false,
          smooth: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 3,
            type: 'solid'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(31, 171, 61, 1)' },
                { offset: 0.3, color: 'rgba(138, 223, 156, 1)' },
                // { offset: 0.5, color: 'rgba(138, 223, 156, 1)' },
                // { offset: 0.7, color: 'rgba(165, 235, 180, 1)' },
                { offset: 1, color: 'rgba(255, 255, 255, 1)' }
              ]
            }
          },
          universalTransition: { divideShape: 'clone' }
        }
      ],
      stateAnimation: { duration: 300, easing: 'cubicOut' },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          label: { backgroundColor: '#505765' },
          axis: 'y',
          animationEasingUpdate: 'exponentialOut',
          animationDurationUpdate: 200,
          crossStyle: { color: '#999', width: 1, type: 'dashed' },
          lineStyle: { color: '#1FAB3D' },
          shadowStyle: { color: '#1FAB3D' }
        },
        borderColor: '#1FAB3D',
        show: true,
        showContent: true,
        z: 60,
        triggerOn: 'mousemove|click',
        displayMode: 'single',
        renderMode: 'auto',
        backgroundColor: '#fff',
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        shadowOffsetX: 1,
        shadowOffsetY: 2,
        borderRadius: 4,
        borderWidth: 1,
        textStyle: { color: '#666', fontSize: 14 },
        formatter: (params: any) => {
          return `${this.customToFixed(
            params[0].value,
            this.selectedLiveLineChartOption.id === 'watts' ? 1 : 3
          )}${this.selectedLiveLineChartOption.symbol}`;
        }
      }
    };

    this.currentLiveDataValue =
      this.customToFixed(
        this.selectedLiveLineChartOption.selector(
          this.liveLineChartData[this.liveLineChartData.length - 1],
          this.panel
        ),
        this.selectedLiveLineChartOption.id === 'watts' ? 1 : 3
      ) + this.selectedLiveLineChartOption.symbol;
  }

  toggleLiveChartOption(): void {
    this.updateCurrentLiveDataValue();
  }

  customToFixed(value: string | number, precision = 1) {
    return this.data.customToFixed(value, precision);
  }

  handleLiveLineChartOptionChange($event: Event, option: LiveLineChartOptions) {
    if (this.isLiveConnectivitySelected) {
      this.isLiveConnectivitySelected = false;
    }

    if (option.id !== this.selectedLiveLineChartOption.id) {
      this.selectedLiveLineChartOption = option;
    }
  }

  toggleEdit(field: string): void {
    if (field == 'circuit_number')
      this.iseditCircuitName = !this.iseditCircuitName;
    if (field == 'circuit_category')
      this.iseditCircuitCategory = !this.iseditCircuitCategory;
    // if (field=='postal_code')  this.iseditpostal_code = !this.iseditpostal_code;
    // if (field=='state')  this.iseditstate = !this.iseditstate;
  }
  isDisabled(field: string): boolean {
    if (
      field === 'circuit_number' &&
      (this.iseditCircuitName || this.iseditCircuitName)
    )
      return true;
    if (
      field === 'circuit_category' &&
      (this.iseditCircuitCategory || this.iseditCircuitCategory)
    )
      return true;
    // if (field === 'postal_code' &&  (this.iseditaddress || this.iseditstate )) return true;
    // if (field === 'state' &&  (this.iseditpostal_code || this.iseditaddress )) return true;

    return false;
  }
  updateCircuitDetails(field: string, value: string): void {
    this.dashboardDataService
      .updateCircuitDetails(this.circuit.id, field, value)
      .subscribe(
        success => {
          if (success) {
            this.circuit = this.circuit;
            this.toggleEdit(field);
            this.toast.success({
              detail: 'Success',
              summary: 'Field updated successfully!',
              sticky: true,
              position: 'tr'
            });
          } else {
            this.toast.error({
              detail: 'Error',
              summary: 'Field update was unsuccessfull!',
              sticky: true,
              position: 'tr'
            });
          }
        },
        error => {
          this.toast.error({
            detail: 'Error',
            summary: 'Field update was unsuccessfull!',
            sticky: true,
            position: 'tr'
          });
          console.error('Error updating :', error);
        }
      );
  }

  updateCircuitCategory(event: any, field: string, value: string): void {
    this.dashboardDataService
      .updateCircuitDetails(this.circuit.id, field, event)
      .subscribe(
        success => {
          if (success) {
            this.circuitCategories.forEach((category: any) => {
              if (category.id == event) {
                this.circuit.circuit_category_name = category.name;
              }
            });
            this.circuit = success;
            this.toggleEdit(field);
            this.toast.success({
              detail: 'Success',
              summary: 'Field updated successfully!',
              sticky: true,
              position: 'tr'
            });
          } else {
            this.toast.error({
              detail: 'Error',
              summary: 'Field update was unsuccessfull!',
              sticky: true,
              position: 'tr'
            });
          }
        },
        error => {
          this.toast.error({
            detail: 'Error',
            summary: 'Field update was unsuccessfull!',
            sticky: true,
            position: 'tr'
          });
          console.error('Error updating :', error);
        }
      );
  }

  fetchCircuitChartAnalyticsData(meter_name: string, startDate: Date) {
    this.chartOptionsMeterAnalyticsData = {
      noData: {
        text: 'Loading...',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#000000',
          fontSize: '14px',
          fontFamily: 'Helvetica'
        }
      }
    };

    const end_date = new Date(startDate);
    end_date.setDate(end_date.getDate() + 1);
    const start_date_str = new Intl.DateTimeFormat('en-CA').format(startDate);  // en-CA gives YYYY-MM-DD
    const end_date_str = new Intl.DateTimeFormat('en-CA').format(end_date);  // en-CA gives YYYY-MM-DD

    this.totalCost = 0;
    this.totalEnergy = 0;

    const subs = this.dashboardDataService
      .fetchMeterAnalyticsData(
        meter_name,
        start_date_str,
        'hour',
        end_date_str
      )
      .subscribe((response: any) => {
        const categories = Array.from({ length: 24 }, (_, i) => '');

        categories[0] = '12 AM';
        categories[3] = '3 AM';
        categories[6] = '6 AM';
        categories[9] = '9 AM';
        categories[12] = 'Noon';
        categories[15] = '3 PM';
        categories[18] = '6 PM';
        categories[21] = '9 PM';
        categories[23] = '12 AM';

        const data = Array.from({ length: 24 }, (_, i) => '0');
        response.forEach((element: any) => {
          const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const timestampWithTimezone = new Date(
            element.timestamp
          ).toLocaleTimeString('en-US', {
            timeZone: userTimezone,
            hour12: false
          });

          const hour = parseInt(timestampWithTimezone.split(':')[0]);

          // loop on grouped circuits
          let total_power = 0;
          for (let i = 0; i < this.groupedCircuits.length; i++) {
            let circuit = this.groupedCircuits[i];
            total_power += element.circuit_powers[circuit.id] ?? 0;
          }
          total_power = total_power / (1000 * 3600);
          element.circuit_power = total_power;
          data[hour] = total_power.toFixed(2);
          this.totalCost += total_power * 0.12;
          this.totalEnergy += total_power;
        });

        this.chartOptionsMeterAnalyticsData = {
          series: [{ name: 'hourly', data: data }],
          dataLabels: { enabled: false },
          legend: { show: false },
          chart: { type: 'bar' },
          responsive: [
            {
              breakpoint: 480,
              options: { chart: { width: 10 }, legend: { position: 'bottom' } }
            }
          ],
          xaxis: {
            categories: categories,
            labels: {
              rotate: 0
            }
          },
          plotOptions: {
            bar: {
              borderRadius: 0
              // columnWidth: 10
            }
          },
          fill: {
            colors: ['#1FAB3D']
          }
        };

        subs.unsubscribe();
      });
  }

  plotLineChart() {
    let totalCostLineChart = 0;
    let totalEnergyLineChart = 0;

    if (!this.lineChartMeterMetrics.length) return;

    const label: string[] = getTimeBlocks();
    const value: number[] = Array.from({ length: 60 * 24 }).fill(0) as number[];

    let x = 0;
    let y = 0;

    let max = -Infinity;

    for (let i = 0; i < value.length; i++) {
      const curr = this.lineChartMeterMetrics[x];
      const power = (curr.circuit_powers[this.circuit.id] ?? 0) / 3600000;
      const power_w = curr.circuit_powers[this.circuit.id] ?? 0;
      if (power_w > max) max = power_w;

      // power is in watts
      value[i] = power_w;
      totalCostLineChart += power * 0.12;
      totalEnergyLineChart += power;
      x++;

      if (x === this.lineChartMeterMetrics.length) break;

      y++;
    }

    this.totalCostLineChart = totalCostLineChart;
    this.totalEnergyLineChart = totalEnergyLineChart;

    const date = new Date(
      this.lineChartMeterMetrics[0].timestamp
    ).toLocaleDateString();

    this.lineChartOptions = {
      xAxis: {
        type: 'category',
        data: label.map(d => `${date}\n${d}`),
        boundaryGap: false,
        axisLine: { lineStyle: { color: '#6E7079', width: 1 } },
        axisLabel: {
          interval: 'auto',
          show: true,
          margin: 8,
          fontSize: 12
        }
      },
      yAxis: {
        type: 'value',
        alignTicks: false,
        boundaryGap: [0, 0],
        minorTick: { show: false },
        animationEasing: 'linear',
        max: Math.max(max, 0),
        min: 0,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 1,
            type: 'dotted'
          }
        },
        axisLabel: {
          formatter: (value: number) => {
            return `${this.customToFixed(value, 2)}w`;
          }
        }
      },
      series: [
        {
          data: value,
          type: 'line',
          showSymbol: false,
          smooth: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 3,
            type: 'solid'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(31, 171, 61, 1)' },
                { offset: 0.3, color: 'rgba(138, 223, 156, 1)' },
                { offset: 1, color: 'rgba(255, 255, 255, 1)' }
              ]
            }
          },
          universalTransition: { divideShape: 'clone' }
        }
      ],
      stateAnimation: { duration: 300, easing: 'cubicOut' },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          label: { backgroundColor: '#505765' },
          axis: 'y',
          animationEasingUpdate: 'exponentialOut',
          animationDurationUpdate: 200,
          crossStyle: { color: '#999', width: 1, type: 'dashed' },
          lineStyle: { color: '#1FAB3D' },
          shadowStyle: { color: '#1FAB3D' }
        },
        borderColor: '#1FAB3D',
        show: true,
        showContent: true,
        z: 60,
        triggerOn: 'mousemove|click',
        displayMode: 'single',
        renderMode: 'auto',
        backgroundColor: '#fff',
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        shadowOffsetX: 1,
        shadowOffsetY: 2,
        borderRadius: 4,
        borderWidth: 1,
        textStyle: { color: '#666', fontSize: 14 },
        formatter: (params: any) => {
          return `${this.customToFixed(params[0].value, 2)}w`;
        }
      },
      dataZoom: {
        type: 'slider',
        show: true,
        realtime: true,
        start: 0,
        end: 100,
        filterMode: 'filter',
        bottom: 5
      }
    };
  }

  fetchLineChartMeterAnalytics(meter_name: string, startDate: Date) {
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 1);
    const start_date_str = new Intl.DateTimeFormat('en-CA').format(startDate);  // en-CA gives YYYY-MM-DD
    const end_date_str = new Intl.DateTimeFormat('en-CA').format(endDate);  // en-CA gives YYYY-MM-DD

    const subs = this.dashboardDataService
      .fetchMeterAnalyticsData(
        meter_name,
        start_date_str,
        'minute',
        end_date_str
      )
      .subscribe(response => {
        this.lineChartMeterMetrics = response;
        this.plotLineChart();
        subs.unsubscribe();
      });
  }

  onDataZoomUpdate(event: any): void {
    const { start, end } = event;

    const startSlice = Math.floor(
      this.lineChartMeterMetrics.length * (start / 100)
    );
    const endSlice = Math.ceil(this.lineChartMeterMetrics.length * (end / 100));

    const arr = this.lineChartMeterMetrics.slice(startSlice, endSlice);

    let totalCostLineChart = 0;
    let totalEnergyLineChart = 0;

    for (const x of arr) {
      const power = (x.circuit_powers[this.circuit.id] ?? 0) / 3600000;
      totalCostLineChart += power * 0.12;
      totalEnergyLineChart += power;
    }

    this.totalCostLineChart = totalCostLineChart;
    this.totalEnergyLineChart = totalEnergyLineChart;
  }

  onChartReady(myCharts: ECharts) {
    myCharts.on('dataZoom', e => this.onDataZoomSubject.next(e));

    this.onDataZoomSubject
      .pipe(debounceTime(80))
      .subscribe(event => this.onDataZoomUpdate(event));
  }

  onBarChartDateSelect(ngbDate: any) {
    const jsDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    this.fetchCircuitChartAnalyticsData(this.meter_name, jsDate);
  }

  onLineChartDateSelect(ngbDate: any) {
    const jsDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    this.fetchLineChartMeterAnalytics(this.meter_name, jsDate);
  }

  closePopup() {
    // this.fetchLive = false;
    this.dialogRef.close();
  }

  floor(num: number): number {
    return Math.floor(num);
  }

  getNetworkMeta({
    network_params,
    network_type
  }: {
    network_params: { rssi?: number; ssid?: string };
    network_type: string;
  }) {
    const { rssi, ssid } = network_params;

    if (!rssi) {
      return {
        fill: '#ED1C24',
        text: 'Unusable',
        wifiStrength: 0,
        value: rssi ?? 0,
        ssid: ssid ?? '-',
        network_type
      };
    }

    if (rssi >= -30) {
      return {
        fill: '#1FAB3D',
        text: 'Excellent',
        wifiStrength: 3,
        value: rssi,
        ssid,
        network_type
      };
    } else if (rssi >= -60) {
      return {
        fill: '#1FAB3D',
        text: 'Very Good',
        wifiStrength: 3,
        value: rssi,
        ssid,
        network_type
      };
    } else if (rssi >= -70) {
      return {
        fill: '#3649A8',
        text: 'Okay',
        wifiStrength: 2,
        value: rssi,
        ssid,
        network_type
      };
    } else if (rssi >= -90) {
      return {
        fill: '#FF7F27',
        text: 'Not Good',
        wifiStrength: 1,
        value: rssi,
        ssid,
        network_type
      };
    } else {
      return {
        fill: '#ED1C24',
        text: 'Unusable',
        wifiStrength: 0,
        value: rssi,
        ssid,
        network_type
      };
    }
  }
}
