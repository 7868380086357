<div class="dt-main">
  <app-sidebar></app-sidebar>
  <div class="dt-content-wrapper overflow-y-scroll">
    <div class="dt-content">
      <div class="col-md-12 spc-padding">
        <div class="headerContainer">
          <img
            src="assets/images/back-button.png"
            routerLink="/insights"
          />
          <h2 class="pageHeading heading">Potential Energy Savings</h2>
        </div>
        <div class="bg-lightgrey overflow-y-scroll">
          <div class="row">
            <div class="col-md-6 mb-4">
              <div class="card border-radius5 p-3">
                <div class="energy-savings-card">
                  <img
                    src="assets/images/explore-savings-potential.png"
                    alt="connectivity"
                  />
                </div>
                <div class="cardfoot border-top">
                  <div *ngIf="isInstalledUser">
                    <a routerLink="/insights/energy-efficiency/explore-energy-potential" >
                      Explore Savings Potential
                      <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                  </div>
                  <div *ngIf="!isInstalledUser">
                    <a >
                      Explore Savings Potential
                      <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="card border-radius5 p-3">
                <div class="energy-savings-card">
                  <img
                    src="assets/images/performance-analytics.png"
                    alt="connectivity"
                  />
                </div>
                <div class="cardfoot border-top">
                  <a >
                    Performance Analytics
                    <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="card border-radius5 p-3">
                <div class="energy-savings-card">
                  <img
                    src="assets/images/peak-demand.png"
                    alt="connectivity"
                  />
                </div>
                <div class="cardfoot border-top">
                  <a routerLink="/insights/energy-efficiency/peak-demand">
                    Peak Demand
                    <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="card border-radius5 p-3">
                <div class="energy-savings-card">
                  <img
                    src="assets/images/top-energy-consumers.png"
                    alt="connectivity"
                  />
                </div>
                <div class="cardfoot border-top">
                  <a routerLink="/insights/energy-efficiency/total-energy-consumers">
                    Top Energy Consumers
                    <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
