import { HttpClient } from '@angular/common/http';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  constructor(
    private http: HttpClient,
    public dialogRef: MatDialogRef<DialogComponent>,
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      navItems: {
        data: any;
        injector: Injector;
        id: string;
        content: typeof Component;
        src: string;
      }[];
    }
  ) {
    this.data.navItems.forEach(item => {
      item.injector = Injector.create({
        providers: [{ provide: 'data', useValue: item.data }],
        parent: this.injector
      });
    });
  }

  activeId = 0;

  ngOnInit(): void {}

  closePopup() {
    this.dialogRef.close();
  }

  getSvgContent(src: string): string {
    let svgContent = '';

    this.http.get(src, { responseType: 'text' }).subscribe(data => {
      svgContent = data;
    });

    return svgContent;
  }
}
