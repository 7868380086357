<div class="dt-main">
  <app-sidebar></app-sidebar>

  <div class="dt-content-wrapper">
    <div class="col-md-12 spc-padding">
        <div class="headerContainer">
            <img
              src="assets/images/back-button.png"
              [queryParamsHandling]="'preserve'"
              routerLink="/insights/energy-efficiency/explore-energy-potential"
            />
            <h2 class="pageHeading heading" *ngIf="!rejected_scan">Energy Savings Scan {{ scan_id_formatted }}</h2>
            <h2 class="heading mb-0" *ngIf="rejected_scan">Rejected Detections</h2>
            
            <!-- rejected_scan -->
        </div>
  </div>
  <div class="bg-lightgrey three-card-layout">
    <div class="container-layout">
        <div class="container-card overview-container"> 
            <h3 class="overview-title mb-0" *ngIf="!rejected_scan">
                Energy Savings Snapshot
            </h3>
            <h3 class="overview-title mb-0" *ngIf="rejected_scan">
              Overview
            </h3>

            <p class="overview-heading mb-3 mt-4" *ngIf="rejected_scan">What are Rejected Detections?</p>

            <p *ngIf="rejected_scan">Rejected detections are instances where the system identified potential areas of energy loss or inefficiencies within your 
              facility, such as excessive energy consumption, off-hour usage, or peak demand contributions. These detections have been 
              reviewed and marked as inapplicable, inaccurate, or unnecessary for your operations.</p>
            
            <p class="mb-0 mt-4" *ngIf="!rejected_scan">
                This analysis highlights inefficiencies and offers actionable recommendations to optimize energy usage. Using 
                real-time data from individual electrical circuits, it uncovers patterns and behaviors affecting overall 
                consumption, enabling cost savings and sustainable operations.
            </p>

            <div class="scan-details-container" *ngIf="!rejected_scan">
                <h4 class="scan-details-title mb-3">Scan Details</h4>
                <div class="scan-details">
                    <div class="details"><span>Start Date:</span><span class="scan-result">{{ startFullDate }}</span></div>
                    <div class="details"><span>End Date:</span><span class="scan-result">{{ endFullDate }}</span></div>
                    <div class="details"><span>Duration:</span><span class="scan-result">{{ timeperiod }} days</span></div>
                </div>
                <p>The scan identifies opportunities to enhance efficiency by analyzing energy use and operational patterns,
                    providing a clear path to reduce costs and improve sustainability.</p>
            </div>
        </div>
        <div class="column">
            <div class="half-height energy-loss-analysis-container">
              <div class="energy-loss-analysis-header d-flex">
                <h5 class="mr-2" *ngIf="!rejected_scan">Energy Loss Analysis</h5>
                <h5 class="mr-2" *ngIf="rejected_scan">Rejected Energy Loss</h5>
                <img
                  src="assets/images/alert-circle.png"
                  alt="alert"
                />
              </div>
              <div class="energy-loss-analysis-chart-conatiner d-flex align-items-center justify-content-center mt-4" >
                <div class="energy-loss-analysis-chart" id="chart">
                  <!-- <apx-chart *ngIf="!rejected_scan"
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [labels]="chartOptions.labels"
                    [responsive]="chartOptions.responsive"
                    [legend]="chartOptions.legend"
                    [dataLabels]="chartOptions.dataLabels"
                    [colors]="chartOptions.colors"
                    [stroke]="chartOptions.stroke"
                  ></apx-chart> -->

                  <apx-chart *ngIf="rejected_scan"
                  [series]="chartOptionsRejectedEnergy.series"
                  [chart]="chartOptionsRejectedEnergy.chart"
                  [labels]="chartOptionsRejectedEnergy.labels"
                  [responsive]="chartOptionsRejectedEnergy.responsive"
                  [legend]="chartOptionsRejectedEnergy.legend"
                  [dataLabels]="chartOptionsRejectedEnergy.dataLabels"
                  [colors]="chartOptionsRejectedEnergy.colors"
                  [stroke]="chartOptionsRejectedEnergy.stroke"
                ></apx-chart>
                </div>
                <h2 [ngClass]="rejected_scan ? 'result rejected-result' : 'result' ">
                  <span class="number-display">{{ energyLossCount }}</span>
                  <p>Energy Loss</p>
                </h2>
                
              </div> 
              
              <div [ngClass]="rejected_scan ? 'rejected-scan-btn energy-loss-analysis-explore justify-content-center' : 'energy-loss-analysis-explore justify-content-center' ">
                <button *ngIf="!rejected_scan">
                  <a class="scan-anchor" *ngIf="!rejected_scan"
                    routerLink="/insights/energy-efficiency/explore-energy-potential/savings-scan"
                    [queryParams]="{ scan_id: scan_id, timeperiod: timeperiod, startFullDate: startFullDate, endFullDate:endFullDate, isExploreEnergyLoss : true, energyLossCount: energyLossCount, ecmDetectedCount: ecmDetectedCount }">
                    Explore Energy Loss
                  </a>
                </button>
                <button *ngIf="rejected_scan">
                  <a class="scan-anchor" *ngIf="rejected_scan"
                    routerLink="/insights/energy-efficiency/explore-energy-potential/savings-scan"
                    [queryParams]="{ scan_id: scan_id, timeperiod: timeperiod, startFullDate: startFullDate, endFullDate:endFullDate, isExploreEnergyLoss : true, energyLossCount: energyLossCount, ecmDetectedCount: ecmDetectedCount }">
                    View
                  </a>
                </button>
              </div>
             </div>
            <div class="half-height energy-conservation-or-rejected-container"> 
              <div class="energy-conservation-analysis-header d-flex">
                <h5 class="mr-2" *ngIf="!rejected_scan">Energy Conservation Measures</h5>
                <h5 class="mr-2" *ngIf="rejected_scan">Rejected ECM's</h5>
                
                <img
                  src="assets/images/alert-circle.png"
                  alt="alert"
                />
              </div>
              <div class="energy-conservation-analysis-chart-conatiner d-flex align-items-center justify-content-center mt-4">
                <div class="energy-conservation-analysis-chart" id="chart2">
                  <!-- <apx-chart  *ngIf="!rejected_scan"
                    [series]="chartOptions2.series"
                    [chart]="chartOptions2.chart"
                    [labels]="chartOptions2.labels"
                    [responsive]="chartOptions2.responsive"
                    [legend]="chartOptions2.legend"
                    [dataLabels]="chartOptions2.dataLabels"
                    [colors]="chartOptions2.colors"
                    [stroke]="chartOptions2.stroke"
                  ></apx-chart> -->

                  <apx-chart  *ngIf="rejected_scan"
                    [series]="chartOptionsRejectedECM.series"
                    [chart]="chartOptionsRejectedECM.chart"
                    [labels]="chartOptionsRejectedECM.labels"
                    [responsive]="chartOptionsRejectedECM.responsive"
                    [legend]="chartOptionsRejectedECM.legend"
                    [dataLabels]="chartOptionsRejectedECM.dataLabels"
                    [colors]="chartOptionsRejectedECM.colors"
                    [stroke]="chartOptionsRejectedECM.stroke"
                  ></apx-chart>
                </div>
                <h2 *ngIf="!rejected_scan" class="result">{{ ecmDetectedCount }} <br> <p>ECM's Detected</p></h2>
                <h2 *ngIf="rejected_scan" class="result rejected-result">{{ ecmDetectedCount }} <br> <p>Rejected Detections</p></h2>
                
              </div> 
              
              <div class="energy-conservation-analysis-explore justify-content-center mt-3">
                <button [ngClass]="rejected_scan ? 'rejected-scan-btn ' : '' ">
                  <a class="scan-anchor" *ngIf="!rejected_scan" 
                    routerLink="/insights/energy-efficiency/explore-energy-potential/savings-scan"
                    [queryParams]="{ scan_id: scan_id, timeperiod: timeperiod, startFullDate: startFullDate, endFullDate:endFullDate, isExploreEnergyLoss : true, energyLossCount: energyLossCount, ecmDetectedCount: ecmDetectedCount }">
                    Explore ECM's
                </a>
                <a class="scan-anchor" *ngIf="rejected_scan" 
                    routerLink="/insights/energy-efficiency/explore-energy-potential/savings-scan" 
                    [queryParams]="{ scan_id: scan_id, timeperiod: timeperiod, startFullDate: startFullDate, endFullDate:endFullDate, isExploreEnergyLoss : true, energyLossCount: energyLossCount, ecmDetectedCount: ecmDetectedCount }">
                    View
                </a>

                </button>
              </div>
            </div>
        </div>

    </div>

  </div>
</div>
