<div class="bg-lightgrey connectivity-history-container">
  <div class="connectivity-history-header">
    <div
      style="
        color: #0d1758;
        font-size: 25px;
        font-weight: 700;
        line-height: 29px;
        word-wrap: break-word;
      "
    >
      Connectivity
    </div>

    <div class="date-selector-container">
      <div>
        <div class="date-type-selector">Week</div>
      </div>

      <div class="date-range-selector">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          width="20"
          style="fill: white; transform: scale(0.55)"
          (click)="getPreviousWeek()"
          [ngStyle]="{
            'pointer-events': !allowPreviousWeek ? 'none' : 'unset',
            opacity: !allowPreviousWeek ? '0.5' : '1'
          }"
        >
          <path d="m8 18-8-8 8-8 1.417 1.417L2.833 10l6.584 6.583Z" />
        </svg>

        <div>
          {{ energyReportSelectedDay | date : 'dd MMM yyyy' }}
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="20"
          width="20"
          style="fill: white; transform: scale(0.55) rotate(180deg)"
          (click)="getNextWeek()"
          [ngStyle]="{
            'pointer-events': !allowNextWeek ? 'none' : 'unset',
            opacity: !allowNextWeek ? '0.5' : '1'
          }"
        >
          <path d="m8 18-8-8 8-8 1.417 1.417L2.833 10l6.584 6.583Z" />
        </svg>
      </div>
    </div>
  </div>

  <div class="connectivity-history-content">
    <div>
      <div class="connectivity-map">
        <div>
          <div *ngFor="let i of hoursRange">{{ i }}</div>
        </div>

        <ng-container *ngIf="!isLoading">
          <div *ngFor="let day of matrix">
            <div
              *ngFor="let value of day"
              class="connectivity-map-value"
              [ngStyle]="{ 'background-color': getRssiMeta(value).fill }"
            ></div>
          </div>
        </ng-container>

        <ng-container *ngIf="isLoading">
          <div class="loaderContainer">
            <div class="loader"></div>
          </div>
        </ng-container>
      </div>

      <div
        class="current-week-by-day"
        style="margin-top: 8px"
      >
        <div></div>
        <div *ngFor="let day of currentWeekByDay">{{ day.title }}</div>
      </div>

      <div class="current-week-by-day">
        <div></div>
        <div *ngFor="let day of currentWeekByDay">{{ day.value }}</div>
      </div>
    </div>

    <div class="legend-container">
      <div class="legend-header">Legend</div>

      <div class="legend-content">
        <div class="legend-map">
          <div *ngFor="let i of legend">
            <div>
              <div
                [ngStyle]="{ 'background-color': i.fill }"
                class="legend-box"
              ></div>

              <div [ngStyle]="{ color: i.fill }">{{ i.title }}</div>
            </div>

            <div class="legend-map-value">{{ i.value }}</div>
          </div>
        </div>

        <div class="legend-separator"></div>

        <div *ngIf="data.panel.connectivity">
          <div>
            <div style="color: #666f82; font-size: 13px">Network Name:</div>
            <div style="color: #0d1758; font-size: 15px; margin-top: 4px">
              {{ data.panel.connectivity.network_params.ssid }}
            </div>
          </div>

          <div>
            <div style="color: #666f82; font-size: 13px; margin-top: 8px">
              Connection Type:
            </div>

            <div
              style="
                display: grid;
                grid-auto-flow: column;
                align-items: center;
                justify-content: start;
                gap: 6px;
                margin-top: 4px;
              "
            >
              <svg
                *ngIf="
                  data.panel.connectivity.network_type.toLowerCase() === 'wifi'
                "
                width="20"
                height="16"
                viewBox="0 0 20 16"
                [attr.fill]="
                  data.panel.panelIsconnected ? '#6285FB' : '#666F82'
                "
                [style.color]="
                  data.panel.panelIsconnected ? '#6285FB' : '#666F82'
                "
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0475 15.5256C11.3379 15.5256 12.384 14.4795 12.384 13.1891C12.384 11.8986 11.3379 10.8525 10.0475 10.8525C8.75703 10.8525 7.71094 11.8986 7.71094 13.1891C7.71094 14.4795 8.75703 15.5256 10.0475 15.5256Z"
                  fill="currentcolor"
                />
                <path
                  d="M15.6314 7.55996C15.5967 7.52432 15.5601 7.49254 15.5245 7.45979L15.4195 7.36059C15.4021 7.34229 15.3829 7.32881 15.3607 7.31147C13.9111 5.98999 12.0078 5.27827 10.0467 5.32437C8.0857 5.37048 6.21793 6.17085 4.83198 7.55899L4.49393 7.89801L4.02586 8.36608C3.75218 8.6414 3.59889 9.01403 3.59961 9.40222C3.60033 9.79042 3.75501 10.1625 4.02971 10.4368C4.1654 10.573 4.32661 10.6812 4.50412 10.7551C4.68163 10.8291 4.87197 10.8673 5.06426 10.8677C5.25655 10.868 5.44703 10.8305 5.62481 10.7572C5.8026 10.6839 5.9642 10.5764 6.1004 10.4406L6.90749 9.63354C7.79013 8.75357 8.9856 8.25935 10.232 8.25917C11.4783 8.25899 12.6739 8.75286 13.5568 9.63257L14.1395 10.197C14.4124 10.4682 14.7819 10.6201 15.1667 10.6192C15.5515 10.6183 15.9202 10.4647 16.1919 10.1921C16.3273 10.0577 16.4347 9.89771 16.5079 9.72149C16.5812 9.54527 16.6188 9.35629 16.6186 9.16546C16.6198 8.97526 16.583 8.78672 16.5104 8.61091C16.4378 8.43511 16.3308 8.27557 16.1957 8.14168L15.6314 7.55996Z"
                  fill="currentcolor"
                />
                <path
                  d="M19.6121 4.67155L19.1267 4.18614C19.1248 4.18421 19.1238 4.18132 19.1199 4.18036L18.7366 3.79511C18.6701 3.72982 18.5972 3.67141 18.519 3.62079C13.5946 -0.702623 6.10444 -0.569714 1.33703 4.01663C1.31392 4.03782 1.28791 4.05226 1.2648 4.07538L0.388364 4.95278C0.264717 5.07696 0.166772 5.22431 0.100133 5.38639C0.0334941 5.54847 -0.000530481 5.7221 6.25248e-06 5.89734C0.000542986 6.07259 0.0356305 6.246 0.103261 6.40767C0.170892 6.56934 0.269738 6.71609 0.394143 6.83951C0.517632 6.96386 0.664428 7.06263 0.826131 7.13017C0.987834 7.19772 1.16127 7.23272 1.33651 7.23317C1.51176 7.23361 1.68537 7.1995 1.84741 7.13278C2.00946 7.06606 2.15676 6.96804 2.28088 6.84433L3.15731 5.96886C3.16309 5.96115 3.16791 5.95441 3.17369 5.9496C7.02903 2.22524 13.1448 2.20213 17.031 5.87351L17.7205 6.56406C17.9716 6.8135 18.3115 6.95309 18.6654 6.95219C19.0193 6.95128 19.3584 6.80996 19.6082 6.55925C19.8586 6.30917 19.9996 5.97004 20.0003 5.61619C20.001 5.26234 19.8614 4.92264 19.6121 4.67155Z"
                  fill="currentcolor"
                />
              </svg>

              <svg
                *ngIf="
                  data.panel.connectivity.network_type.toLowerCase() ===
                  'ethernet'
                "
                width="20"
                height="20"
                viewBox="0 0 20 20"
                [attr.fill]="
                  data.panel.panelIsconnected ? '#6285FB' : '#666666'
                "
                [style.color]="
                  data.panel.panelIsconnected ? '#6285FB' : '#666F82'
                "
                xmlns="http://www.w3.org/2000/svg"
                ]
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.0248 2.15109H3.97516C2.53441 2.15109 1.36646 3.27769 1.36646 4.66743V15.3319C1.36646 16.7217 2.53441 17.8483 3.97516 17.8483H16.0248C17.4656 17.8483 18.6335 16.7217 18.6335 15.3319V4.66743C18.6335 3.27769 17.4656 2.15109 16.0248 2.15109ZM3.97516 0.833008C1.77974 0.833008 0 2.54974 0 4.66743V15.3319C0 17.4496 1.77974 19.1663 3.97516 19.1663H16.0248C18.2203 19.1663 20 17.4496 20 15.3319V4.66743C20 2.54974 18.2203 0.833008 16.0248 0.833008H3.97516Z"
                  fill="currentcolor"
                />
                <path
                  d="M3.22981 11.7971H4.96894V13.4746C4.96894 13.7393 5.19141 13.9539 5.46584 13.9539H7.01863V15.272C7.01863 15.5367 7.2411 15.7513 7.51553 15.7513H12.4845C12.7589 15.7513 12.9814 15.5367 12.9814 15.272V13.9539H14.5342C14.8086 13.9539 15.0311 13.7393 15.0311 13.4746V11.7971H16.7702C17.0446 11.7971 17.2671 11.5825 17.2671 11.3178V5.20665C17.2671 4.28016 16.4884 3.52909 15.528 3.52909H4.34783C3.45594 3.52909 2.73292 4.22651 2.73292 5.08682V11.3178C2.73292 11.5825 2.95539 11.7971 3.22981 11.7971Z"
                  fill="currentcolor"
                />
              </svg>

              <span
                style="
                  text-transform: capitalize;
                  color: #0d1758;
                  font-size: 15px;
                "
                >{{ data.panel.connectivity?.network_type }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
