import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay, map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment.dev';

export type StatusType = 'success' | 'failure' | 'pending';

export interface MeterStatus {
  status: StatusType;
  message: string;
  value?: number;
  readings?: {
    I1: number;
    I2: number;
    I3: number;
    U1: number;
    U2: number;
    U3: number;
  };
}

export interface MeterData {
  connection: {
    status: StatusType;
    message: string;
  };
  calibration: {
    status: StatusType;
    message: string;
  };
  signalStrength: {
    status: StatusType;
    message: string;
    value?: number;
  };
  liveReadings: {
    status: StatusType;
    message: string;
    readings?: {
      I1: number;
      I2: number;
      I3: number;
      U1: number;
      U2: number;
      U3: number;
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class MeterInstallationService {
  private readonly apiUrl = `${environment.backendBaseAddress}/meter-installations`;
  private mockEnabled = false; // Toggle this to switch between mock and real API calls

  constructor(private http: HttpClient) {}

  // Helper function to simulate random responses
  private getMockResponse(step: string, meterSerialId: string): MeterStatus {
    // Simulate different scenarios based on meter ID for testing
    if (meterSerialId === 'fail') {
      return {
        status: 'failure',
        message: `Failed to ${step}: Connection error`
      };
    }

    if (meterSerialId === 'pending') {
      return {
        status: 'pending',
        message: `${step} in progress...`
      };
    }

    return {
      status: 'success',
      message: `Successfully completed ${step}`
    };
  }

  // Helper function to get random delay
  private getRandomDelay(): number {
    return Math.floor(Math.random() * 2000) + 1000; // Random delay between 1-3 seconds
  }

  // Helper function to handle API errors
  private handleError(error: any): Observable<MeterStatus> {
    console.error('API Error:', error);
    const errorResponse: MeterStatus = {
      status: 'failure',
      message: error.message || 'An error occurred'
    };
    return of(errorResponse);
  }

  private handleDataError(error: any): Observable<MeterData> {
    console.error('API Error:', error);
    const errorResponse: MeterData = {
      connection: { status: 'failure', message: error.message || 'An error occurred' },
      calibration: { status: 'failure', message: error.message || 'An error occurred' },
      signalStrength: { status: 'failure', message: error.message || 'An error occurred' },
      liveReadings: { status: 'failure', message: error.message || 'An error occurred' }
    };
    return of(errorResponse);
  }

  private transformResponse(response: any): MeterData {
    // Transform snake_case to camelCase
    return {
      connection: response.connection,
      calibration: response.calibration,
      signalStrength: response.signal_strength,
      liveReadings: response.live_readings
    };
  }

  checkConnection(meterSerialId: string): Observable<MeterStatus> {
    if (!meterSerialId) {
      return of({ status: 'failure', message: 'Meter serial ID is required' });
    }

    if (this.mockEnabled) {
      return of(this.getMockResponse('connection check', meterSerialId))
        .pipe(delay(this.getRandomDelay()));
    }

    return this.http.post<MeterStatus>(`${this.apiUrl}/check_connection/`, { meter_serial_id: meterSerialId })
      .pipe(
        map(response => ({
          status: response?.status || 'failure' as StatusType,
          message: response?.message || 'No response from server'
        })),
        catchError(error => this.handleError(error))
      );
  }

  calibrateMeter(meterSerialId: string): Observable<MeterStatus> {
    if (!meterSerialId) {
      return of({ status: 'failure', message: 'Meter serial ID is required' });
    }

    if (this.mockEnabled) {
      if (meterSerialId === 'pending') {
        const responses: MeterStatus[] = [
          { status: 'pending', message: 'Calibration starting...' },
          { status: 'pending', message: 'Calibration in progress...' },
          { status: 'success', message: 'Calibration completed successfully' }
        ];
        const randomIndex = Math.floor(Math.random() * responses.length);
        return of(responses[randomIndex]).pipe(delay(this.getRandomDelay()));
      }
      return of(this.getMockResponse('calibration', meterSerialId))
        .pipe(delay(this.getRandomDelay()));
    }

    return this.http.post<MeterStatus>(`${this.apiUrl}/calibrate/`, { meter_serial_id: meterSerialId })
      .pipe(
        map(response => ({
          status: response?.status || 'failure' as StatusType,
          message: response?.message || 'No response from server'
        })),
        catchError(error => this.handleError(error))
      );
  }

  checkSignalStrength(meterSerialId: string): Observable<MeterStatus> {
    if (!meterSerialId) {
      return of({ status: 'failure', message: 'Meter serial ID is required' });
    }

    if (this.mockEnabled) {
      const responses: MeterStatus[] = [
        { status: 'success', message: 'Signal strength excellent (90%)', value: 90 },
        { status: 'success', message: 'Signal strength good (75%)', value: 75 },
        { status: 'failure', message: 'Signal strength too weak (20%)', value: 20 }
      ];
      
      if (meterSerialId === 'fail') {
        return of(responses[2]).pipe(delay(this.getRandomDelay()));
      }
      
      const randomIndex = Math.floor(Math.random() * 2); // Only success cases
      return of(responses[randomIndex]).pipe(delay(this.getRandomDelay()));
    }

    return this.http.post<MeterStatus>(`${this.apiUrl}/signal_strength/`, { meter_serial_id: meterSerialId })
      .pipe(
        map(response => ({
          status: response?.status || 'failure' as StatusType,
          message: response?.message || 'No response from server',
          value: response?.value
        })),
        catchError(error => this.handleError(error))
      );
  }

  getLiveReadings(meterSerialId: string): Observable<MeterStatus> {
    if (!meterSerialId) {
      return of({ status: 'failure', message: 'Meter serial ID is required' });
    }

    if (this.mockEnabled) {
      if (meterSerialId === 'fail') {
        const failureResponse: MeterStatus = {
          status: 'failure',
          message: 'Failed to get readings: Meter not responding'
        };
        return of(failureResponse).pipe(delay(this.getRandomDelay()));
      }

      const readings = {
        I1: Math.floor(Math.random() * 10) + 5,
        I2: Math.floor(Math.random() * 10) + 5,
        I3: Math.floor(Math.random() * 10) + 5,
        U1: Math.floor(Math.random() * 20) + 220,
        U2: Math.floor(Math.random() * 20) + 220,
        U3: Math.floor(Math.random() * 20) + 220
      };

      const successResponse: MeterStatus = {
        status: 'success',
        message: 'Readings retrieved successfully',
        readings
      };
      return of(successResponse).pipe(delay(this.getRandomDelay()));
    }

    return this.http.post<MeterStatus>(`${this.apiUrl}/live_readings/`, {
      meter_serial_id: meterSerialId
    })
      .pipe(
        map(response => ({
          status: response?.status || 'failure' as StatusType,
          message: response?.message || 'No response from server',
          readings: response?.readings
        })),
        catchError(error => this.handleError(error))
      );
  }

  disconnectMeter(meterSerialId: string): Observable<MeterStatus> {
    if (!meterSerialId) {
      return of({ status: 'failure', message: 'Meter serial ID is required' });
    }

    if (this.mockEnabled) {
      if (meterSerialId === 'fail') {
        return of({
          status: 'failure' as StatusType,
          message: 'Failed to disconnect: Meter is busy'
        }).pipe(delay(this.getRandomDelay()));
      }
      return of({
        status: 'success' as StatusType,
        message: 'Meter disconnected successfully'
      }).pipe(delay(this.getRandomDelay()));
    }

    return this.http.post<MeterStatus>(`${this.apiUrl}/disconnect/`, { meter_serial_id: meterSerialId })
      .pipe(
        map(response => ({
          status: response?.status || 'failure' as StatusType,
          message: response?.message || 'No response from server'
        })),
        catchError(error => this.handleError(error))
      );
  }

  getMeterData(meterSerialId: string): Observable<MeterData> {
    if (!meterSerialId) {
      const errorResponse: MeterData = {
        connection: { status: 'failure', message: 'Meter serial ID is required' },
        calibration: { status: 'failure', message: 'Meter serial ID is required' },
        signalStrength: { status: 'failure', message: 'Meter serial ID is required' },
        liveReadings: { status: 'failure', message: 'Meter serial ID is required' }
      };
      return of(errorResponse);
    }

    if (this.mockEnabled) {
      if (meterSerialId === 'fail') {
        const failureData: MeterData = {
          connection: { status: 'failure', message: 'Connection failed' },
          calibration: { status: 'failure', message: 'Calibration not attempted' },
          signalStrength: { status: 'failure', message: 'Signal strength check failed' },
          liveReadings: { status: 'failure', message: 'Live readings not available' }
        };
        return of(failureData).pipe(delay(this.getRandomDelay()));
      }

      if (meterSerialId === 'existing') {
        const existingData: MeterData = {
          connection: { status: 'success', message: 'Connected successfully' },
          calibration: { status: 'success', message: 'Calibrated successfully' },
          signalStrength: { 
            status: 'success', 
            message: 'Signal strength excellent (90%)',
            value: 90
          },
          liveReadings: {
            status: 'success',
            message: 'Readings retrieved successfully',
            readings: {
              I1: Math.floor(Math.random() * 10) + 5,
              I2: Math.floor(Math.random() * 10) + 5,
              I3: Math.floor(Math.random() * 10) + 5,
              U1: Math.floor(Math.random() * 20) + 220,
              U2: Math.floor(Math.random() * 20) + 220,
              U3: Math.floor(Math.random() * 20) + 220
            }
          }
        };
        return of(existingData).pipe(delay(this.getRandomDelay()));
      }

      const pendingData: MeterData = {
        connection: { status: 'pending', message: 'No existing connection' },
        calibration: { status: 'pending', message: 'Not calibrated' },
        signalStrength: { status: 'pending', message: 'Signal strength not checked' },
        liveReadings: { status: 'pending', message: 'No readings available' }
      };
      return of(pendingData).pipe(delay(this.getRandomDelay()));
    }

    return this.http.get<any>(`${this.apiUrl}/get_meter_data/`, {
      params: { meter_serial_id: meterSerialId }
    }).pipe(
      map(response => {
        if (!response) {
          return {
            connection: { status: 'failure', message: 'No response from server' },
            calibration: { status: 'failure', message: 'No response from server' },
            signalStrength: { status: 'failure', message: 'No response from server' },
            liveReadings: { status: 'failure', message: 'No response from server' }
          } as MeterData;
        }
        return this.transformResponse(response);
      }),
      catchError(error => this.handleDataError(error))
    );
  }
} 