import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-password-change',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class PasswordChangeComponent {
  oldPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  error: string = '';

  constructor(
    private AuthService: AuthService,
    private toast: NgToastService,
    public dialogRef: MatDialogRef<PasswordChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  changePassword() {
    if (this.newPassword === this.confirmPassword) {
      this.AuthService.changePassword(this.oldPassword, this.newPassword).subscribe(
        (response) => {
          this.toast.success({ detail: 'Success', summary: 'Password changed successfully!', sticky: true, position: 'tr' })
          this.dialogRef.close();
        },
        (error) => {
          this.error = error?.error?.detail || 'An error occurred while changing the password. Please provide the correct password and try again.';
        }
      );
    } else {
      this.error = 'Passwords do not match.';
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }
}
