import { Component, Inject, OnInit } from '@angular/core';
import { NgToastService } from 'ng-angular-popup';
import { Panel } from 'src/app/models/panel';
import { DashboardDataService } from 'src/app/services/dashboard-data/dashboard-data.service';

@Component({
  selector: 'app-connectivity-history',
  templateUrl: './connectivity-history.component.html',
  styleUrls: ['./connectivity-history.component.scss']
})
export class ConnectivityHistoryComponent implements OnInit {
  constructor(
    private toast: NgToastService,
    private dashboardDataService: DashboardDataService,
    @Inject('data') data: { panel: Panel & { connectivity: any } }
  ) {
    this.data = data;
    this.currentWeek = this.getStartOfWeek(new Date());
    this.energyReportSelectedDay = this.currentWeek;
    this.getCurrentWeekByDay();
    this.getPanelMeterConnectivityData();
  }

  public isLoading = true;
  public data: { panel: Panel & { connectivity: any } };
  public currentWeek: Date;
  public energyReportSelectedDay: Date;
  public allowNextWeek = false;
  public allowPreviousWeek = true;
  public matrix: any = [];
  public hoursRange = Array.from({ length: 24 }).map((_, i) => {
    if (i % 3 === 0) {
      if (i === 0) {
        return `12am`;
      } else if (i < 12) {
        return `${i}am`;
      } else if (i === 12) {
        return `12pm`;
      } else {
        return `${i - 12}pm`;
      }
    }

    return;
  });
  public currentWeekByDay: any = [
    { title: 'S', value: null },
    { title: 'M', value: null },
    { title: 'T', value: null },
    { title: 'W', value: null },
    { title: 'T', value: null },
    { title: 'F', value: null },
    { title: 'S', value: null }
  ];
  public legend = [
    { title: 'Offline', fill: '#EF2448' },
    { title: 'Unusable', fill: '#FEC754', value: '-90 dBm' },
    { title: 'Not Good', fill: '#FF7F27', value: '-80 dBm' },
    { title: 'Okay', fill: '#4DAAE8', value: '-70 dBm' },
    { title: 'Good', fill: '#5EC776', value: '-60 dBm' },
    { title: 'Excellent', fill: '#22B14C', value: '-30 dBm' }
  ];

  ngOnInit(): void {}

  getUTCDate(date: Date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  }

  getPanelMeterConnectivityData() {
    this.isLoading = true;

    const end_date = new Date(this.energyReportSelectedDay);
    end_date.setDate(end_date.getDate() + 7);

    const subs = this.dashboardDataService
      .getPanelMeterConnectivity(
        this.data.panel.meter_id,
        this.getUTCDate(this.energyReportSelectedDay),
        this.getUTCDate(end_date)
      )
      .subscribe(
        response => {
          this.getMatrix(false);

          response.results.forEach((d: any) => {
            const date = new Date(d.timestamp);
            this.matrix[date.getDay()][date.getHours()] = -1 * d.value;
          });

          this.isLoading = false;
          subs.unsubscribe();
        },
        err => {
          this.toast.error({
            detail: 'Error',
            summary: 'Failed to fetch connectivity history',
            sticky: true,
            position: 'tr'
          });

          this.getMatrix(true);

          console.error(err);
          this.isLoading = false;
          subs.unsubscribe();
        }
      );
  }

  getCurrentWeekByDay() {
    this.currentWeekByDay.forEach((d: any, i: any) => {
      const result = new Date(this.energyReportSelectedDay);
      result.setDate(result.getDate() + i);
      d.value = result.getDate();
    });
  }

  getStartOfWeek(date: Date) {
    const startOfWeek = new Date(date);
    const day = startOfWeek.getDay();

    startOfWeek.setDate(startOfWeek.getDate() - day);
    startOfWeek.setHours(0, 0, 0, 0);

    return startOfWeek;
  }

  getPreviousWeek() {
    const startOfWeek = this.getStartOfWeek(this.energyReportSelectedDay);
    const previousWeek = new Date(startOfWeek);

    previousWeek.setDate(startOfWeek.getDate() - 7);

    this.energyReportSelectedDay = previousWeek;
    this.getCurrentWeekByDay();
    this.allowNextWeek = true;

    const { panel_meter } = this.data.panel;

    if (
      panel_meter &&
      previousWeek.getTime() ===
        this.getStartOfWeek(panel_meter.activation_date).getTime()
    ) {
      this.allowPreviousWeek = false;
    }

    this.getPanelMeterConnectivityData();
  }

  getNextWeek() {
    const startOfWeek = this.getStartOfWeek(this.energyReportSelectedDay);
    const nextWeek = new Date(startOfWeek);

    nextWeek.setDate(startOfWeek.getDate() + 7);
    this.energyReportSelectedDay = nextWeek;
    this.getCurrentWeekByDay();
    this.allowPreviousWeek = true;

    if (nextWeek.getTime() === this.currentWeek.getTime()) {
      this.allowNextWeek = false;
    }

    this.getPanelMeterConnectivityData();
  }

  getRssiMeta(rssi: number) {
    if (rssi === undefined) {
      return {
        fill: '#EF2448',
        text: 'Offline'
      };
    } else if (rssi === 1000) {
      return {
        fill: '#7f7f9533',
        text: 'Not defined'
      };
    }

    if (rssi <= 30) {
      return {
        fill: '#22B14C',
        text: 'Excellent',
        wifiStrength: 3,
        value: `${rssi} dBm`
      };
    } else if (rssi <= 60) {
      return {
        fill: '#5EC776',
        text: 'Good',
        wifiStrength: 3,
        value: `${rssi} dBm`
      };
    } else if (rssi <= 70) {
      return {
        fill: '#4DAAE8',
        text: 'Okay',
        wifiStrength: 2,
        value: `${rssi} dBm`
      };
    } else if (rssi <= 80) {
      return {
        fill: '#FF7F27',
        text: 'Not Good',
        wifiStrength: 1,
        value: `${rssi} dBm`
      };
    } else if (rssi <= 90) {
      return {
        fill: '#FEC754',
        text: 'Unusable',
        wifiStrength: 0,
        value: `${rssi} dBm`
      };
    } else {
      return {
        fill: '#FEC754',
        text: 'Unusable',
        wifiStrength: 0,
        value: `${rssi} dBm`
      };
    }
  }

  getMatrix(isFailed: boolean) {
    this.matrix = Array.from({ length: 7 });

    const now = new Date();
    const x = now.getDay();
    const y = now.getHours();

    const dateDiff =
      this.currentWeek.getDate() - this.energyReportSelectedDay.getDate();

    for (let i = 0; i < 7; i++) {
      for (let j = 0; j < 24; j++) {
        if (!this.matrix[i]) {
          this.matrix[i] = [];
        }

        if (isFailed || (dateDiff === 0 && (i >= x || (i === x && j > y)))) {
          this.matrix[i][j] = 1000;
        } else {
          this.matrix[i][j] = undefined;
        }
      }
    }
  }
}
