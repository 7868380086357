import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { MonthSchedule, OperatingSchedule } from '../models/operating_schedule';

@Injectable({
  providedIn: 'root'
})
export class OperatingHoursService {
  private readonly currentYear = new Date().getFullYear();
  private readonly apiUrl = environment.backendBaseAddress;
  
  constructor(private readonly http: HttpClient) { }

  private generateMonths(selected: boolean): MonthSchedule[] {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return monthNames.map(name => ({ name, selected }));
  }

  // Update operating schedule in building
  updateOperatingSchedule(buildingId: string, data: OperatingSchedule): Observable<OperatingSchedule> {
    return this.http.patch<OperatingSchedule>(
      `${this.apiUrl}/buildings/${buildingId}/save_operating_schedule/`,
      { operating_schedule: data }
    );
  }

  // Helper method to generate initial schedule if none exists
  generateInitialSchedule(): OperatingSchedule {
    return {
      weekly: {
        weekDays: [
          { name: 'Sunday', openTime: '09:00:00', closeTime: '17:00:00', closed: true },
          { name: 'Monday', openTime: '08:00:00', closeTime: '18:00:00', closed: false },
          { name: 'Tuesday', openTime: '08:00:00', closeTime: '18:00:00', closed: false },
          { name: 'Wednesday', openTime: '08:00:00', closeTime: '18:00:00', closed: false },
          { name: 'Thursday', openTime: '08:00:00', closeTime: '18:00:00', closed: false },
          { name: 'Friday', openTime: '08:00:00', closeTime: '16:00:00', closed: false },
          { name: 'Saturday', openTime: '10:00:00', closeTime: '15:00:00', closed: false }
        ]
      },
      monthly: [
        {
          year: this.currentYear - 1,
          months: this.generateMonths(false)
        },
        {
          year: this.currentYear,
          months: this.generateMonths(true)
        },
        {
          year: this.currentYear + 1,
          months: this.generateMonths(false)
        }
      ]
    };
  }
} 