import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardDataService } from '../../services/dashboard-data/dashboard-data.service';
import { NgToastService } from 'ng-angular-popup';
import { ActivatedRoute, Params } from '@angular/router';

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke,
  ChartComponent
} from 'ng-apexcharts';
import { type Circuit } from 'src/app/models/circuit';
import { type Subscription } from 'rxjs';
import { type EChartsOption } from 'echarts';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};

interface LiveLineChartOptions {
  id: string;
  label: string;
  selector: (d: any) => number;
  symbol: string;
}

@Component({
  selector: 'app-circuit-category-info-component',
  templateUrl: './circuit-category-info-component.component.html',
  styleUrls: ['./circuit-category-info-component.component.scss']
})
export class CircuitCategoryInfoComponent implements OnInit {
  @ViewChild('chart', { static: true }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  activeId = 1;
  public circuit_category_name = this.data.circuit.name;
  public circuit_category = this.data.circuit;
  public render_dialog: boolean = false;
  public circuitsStats: any = [];
  public circuits: any = [];
  public description = this.data.description;
  math = Math;
  public connected = this.data.circuit.circuitIsconnected;

  liveChartOptions: EChartsOption;

  public liveLineChartData: Circuit[] = [];
  private circuitsCatergoryMainSubs: Subscription | null = null;
  public currentLiveDataValue = '';

  public liveLineChartOptions: LiveLineChartOptions[] = [
    // {
    //   id: 'circuit_power',
    //   label: 'Power Factor',
    //   selector: (d: Circuit, _: Panel) => d.circuit_power,
    //   symbol: 'pf'
    // },
    {
      id: 'circuit_current',
      label: 'Amps',
      selector: d => {
        return d.totalCurrent;
      },
      symbol: 'a'
    },
    // {
    //   id: 'circuit_volts',
    //   label: 'Voltage',
    //   selector: d => d.totalVolts,
    //   symbol: 'v'
    // },
    {
      id: 'watts',
      label: 'Wattage',
      selector: d => {
        return d.totalPower;
      },
      symbol: 'w'
    }
  ];

  public selectedLiveLineChartOption: LiveLineChartOptions =
    this.liveLineChartOptions[this.liveLineChartOptions.length - 1];

  constructor(
    private toast: NgToastService,
    public dialogRef: MatDialogRef<CircuitCategoryInfoComponent>,
    private dashboardDataService: DashboardDataService,
    private activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.dashboardDataService
        .getCircuitsCategoryOverview(
          params['building_id'],
          this.circuit_category?.id
        )
        .forEach((circuits: any) => {
          this.render_dialog = true;
          this.circuitsStats = circuits?.data?.panel_stats;
          this.circuits = circuits.data?.circuits;

          let circuit_series: any = [];
          let labels: any = [];
          let colors: any = [];

          this.circuitsStats?.forEach((element: any) => {
            circuit_series.push(element.percentage);
            labels.push(element.panel_name);
            colors.push(element.panel_colour);
          });
          this.chartOptions = {
            series: circuit_series,
            dataLabels: { enabled: false },
            legend: { show: false },
            chart: { type: 'donut', width: '200px' },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      show: true,
                      label: '',
                      formatter: () => 'Text you want'
                    }
                  }
                }
              }
            },
            colors: colors,
            labels: labels,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: { width: 10 },
                  legend: { position: 'bottom' }
                }
              }
            ]
          };
        });
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.circuitsCatergoryMainSubs = this.data.circuitCategoryMain$.subscribe(
      (circuitCategories: Circuit[]) => {
        const circuit = circuitCategories.find(
          d => d.id === this.data.circuit.id
        );

        if (circuit) {
          this.liveLineChartData.push({ ...circuit });
          this.updateCurrentLiveDataValue();
        }
      }
    );
  }

  updateCurrentLiveDataValue() {
    let max: number = -Infinity;

    const data: number[] = [];

    for (const x of this.liveLineChartData) {
      const value = this.selectedLiveLineChartOption.selector(x);
      if (value > max) max = value;
      data.push(value);
    }

    this.liveChartOptions = {
      xAxis: {
        type: 'category',
        data: Array.from(Array(data.length).keys()),
        boundaryGap: false,
        axisLine: { lineStyle: { color: '#ECE9F1', width: 1 } },
        axisLabel: { show: false },
        axisPointer: { show: false }
      },
      yAxis: {
        type: 'value',
        alignTicks: false,
        boundaryGap: [0, 0],
        minorTick: { show: false },
        animationEasing: 'linear',
        max: max ?? 0,
        min: 0,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 1,
            type: 'dotted'
          }
        },
        axisLabel: {
          formatter: (value: number) => {
            return `${this.customToFixed(value)}${
              this.selectedLiveLineChartOption.symbol
            }`;
          }
        }
      },
      series: [
        {
          data,
          type: 'line',
          showSymbol: false,
          smooth: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 3,
            type: 'solid'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(31, 171, 61, 1)' },
                { offset: 0.3, color: 'rgba(138, 223, 156, 1)' },
                // { offset: 0.5, color: 'rgba(138, 223, 156, 1)' },
                // { offset: 0.7, color: 'rgba(165, 235, 180, 1)' },
                { offset: 1, color: 'rgba(255, 255, 255, 1)' }
              ]
            }
          },
          universalTransition: { divideShape: 'clone' }
        }
      ],
      stateAnimation: { duration: 300, easing: 'cubicOut' },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          label: { backgroundColor: '#505765' },
          axis: 'y',
          animationEasingUpdate: 'exponentialOut',
          animationDurationUpdate: 200,
          crossStyle: { color: '#999', width: 1, type: 'dashed' },
          lineStyle: { color: '#1FAB3D' },
          shadowStyle: { color: '#1FAB3D' }
        },
        borderColor: '#1FAB3D',
        show: true,
        showContent: true,
        z: 60,
        triggerOn: 'mousemove|click',
        displayMode: 'single',
        renderMode: 'auto',
        backgroundColor: '#fff',
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        shadowOffsetX: 1,
        shadowOffsetY: 2,
        borderRadius: 4,
        borderWidth: 1,
        textStyle: { color: '#666', fontSize: 14 },
        formatter: (params: any) => {
          return `${this.customToFixed(
            params[0].value,
            this.selectedLiveLineChartOption.id === 'watts' ? 1 : 3
          )}${this.selectedLiveLineChartOption.symbol}`;
        }
      }
    };

    this.currentLiveDataValue =
      this.customToFixed(
        this.selectedLiveLineChartOption.selector(
          this.liveLineChartData[this.liveLineChartData.length - 1]
        ),
        this.selectedLiveLineChartOption.id === 'watts' ? 1 : 3
      ) + this.selectedLiveLineChartOption.symbol;
  }

  ngOnDestroy(): void {
    this.circuitsCatergoryMainSubs?.unsubscribe();
    this.circuitsCatergoryMainSubs = null;
  }

  toggleLiveChartOption(): void {
    this.updateCurrentLiveDataValue();
  }

  customToFixed(value: string | number, precision = 1) {
    return this.data.customToFixed(value, precision);
  }

  handleLiveLineChartOptionChange($event: Event, option: LiveLineChartOptions) {
    if (option.id !== this.selectedLiveLineChartOption.id) {
      this.selectedLiveLineChartOption = option;
    }
  }

  active1 = 4;
  //   public slides = [
  //     { src: '/assets/images/carousel/ignite-ui-angular-indigo-design.png' },
  //     { src: '/assets/images/carousel/slider-image-chart.png' },
  //     { src: '/assets/images/carousel/ignite-ui-angular-charts.png' }
  // ];

  closePopup() {
    // this.fetchLive = false;
    this.dialogRef.close();
  }
}
