<div class="dt-main">
    <app-sidebar></app-sidebar>
    <div class="dt-content-wrapper">
      <div class="dt-content">
        <div class="col-md-12 spc-padding">
          <h2 class="pageHeading">
            <span class="main-chevron">
                <a routerLink="/utilities" >
                    <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.666748 7.5L7.91675 14.75L9.6205 13.0463L4.08633 7.5L9.6205 1.95375L7.91675 0.250003L0.666748 7.5Z" fill="#0D1758"> </path>
                    </svg>
                </a>
            </span>Natural Gas
          </h2>

          <div class="bg-lightgrey p-20">
            <div class="utility-stats-container">
              <!-- Primary Stats -->
              <div class="row">
                <div class="col-6 utiliti-cards">
                  <!-- Big Stats Boxes -->
                  <div class="stats-box">
                      <!-- Stats Heading -->
                    <div class="utitlity-stats-heading">
                    <h3>Plan Details</h3>
                  </div>
                  <!-- Stats Card -->
                  <div class="card stats-card p-3">
                    <div class="stats-content">
                      <div class="d-flex align-items-center">
                        <div class="col-8">
                          <img src="assets/images/ATCO-Logo.png" width="100%" height="100%" />
                        </div>
                        <div class="col-4">
                          <!-- Stats box inner item -->
                          <div class="stats-box-inner mb-2">
                            <div class="stats-text-item">
                              <label>your rate</label>
                              <p><small>$</small>3.39<small>/GJ</small></p>
                            </div>
                          </div>
                          <!-- Stats box inner item -->
                          <div class="stats-box-inner mb-2">
                            <div class="stats-text-item">
                              <label>contract type</label>
                              <p>Fixed</p>
                            </div>
                          </div>
                          <!-- Stats box inner item -->
                          <div class="stats-box-inner mb-2">
                            <div class="stats-text-item">
                              <label>contract end</label>
                              <p>Jan-2023</p>
                            </div>
                          </div>


                        </div>
                      </div>

                    </div>
                    <div class="cardfoot bg-transparent border-top">
                      <a href="javascript: void(0);" class="link-gray" >Explore Plan <img src="assets/images/expand_right.svg"
                          alt="arrow" />
                      </a>
                    </div>
                </div>
                 <!-- Stats Card End -->
                </div>
                <!-- Big Stats Boxes End -->
              </div>
                <div class="col-6 utiliti-cards">
                        <!-- Big Stats Boxes -->
                        <div class="stats-box">
                          <!-- Stats Heading -->
                        <div class="utitlity-stats-heading">
                        <h3>Past-Bills</h3>
                      </div>
                      <!-- Stats Card -->
                      <div class="card stats-card p-3">
                        <div class="stats-content">
                        <div class="p-4">
                          <svg width="100%" height="27.5vh" viewBox="0 0 394 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="9" height="111" rx="4.5" fill="#FF7F27"/>
                            <rect x="35" y="18" width="9" height="93" rx="4.5" fill="#FF7F27"/>
                            <rect x="70" y="48" width="9" height="63" rx="4.5" fill="#FF7F27"/>
                            <rect x="105" y="64" width="9" height="47" rx="4.5" fill="#FF7F27"/>
                            <rect x="140" y="83" width="9" height="28" rx="4.5" fill="#FF7F27"/>
                            <rect x="175" y="96" width="9" height="15" rx="4.5" fill="#FF7F27"/>
                            <rect x="210" y="103" width="9" height="8" rx="4" fill="#FF7F27"/>
                            <rect x="245" y="83" width="9" height="28" rx="4.5" fill="#FF7F27"/>
                            <rect x="280" y="64" width="9" height="47" rx="4.5" fill="#FF7F27"/>
                            <rect x="315" y="48" width="9" height="63" rx="4.5" fill="#FF7F27"/>
                            <rect x="350" y="18" width="9" height="93" rx="4.5" fill="#FF7F27"/>
                            <rect x="385" width="9" height="111" rx="4.5" fill="#FF7F27"/>
                            <path d="M4.398 134.077C3.98 134.077 3.58033 133.993 3.199 133.824C2.81767 133.648 2.50233 133.399 2.253 133.076L3.144 131.976C3.48867 132.409 3.87733 132.625 4.31 132.625C4.93333 132.625 5.245 132.181 5.245 131.294V126.19H6.796V131.283C6.796 133.146 5.99667 134.077 4.398 134.077Z" fill="#7F7F7F"/>
                            <path d="M36.847 134V126.19H42.028V127.565H38.376V129.655H41.423V131.041H38.376V134H36.847Z" fill="#7F7F7F"/>
                            <path d="M70.858 134V126.19H72.409L74.807 130.634H74.829L77.227 126.19H78.778V134H77.238V129.171H77.216L75.313 132.526H74.312L72.42 129.16H72.398V134H70.858Z" fill="#7F7F7F"/>
                            <path d="M106.176 134L109.069 126.19H110.565L113.469 134H111.852L111.313 132.438H108.321L107.793 134H106.176ZM108.761 131.118H110.873L109.828 128.06H109.806L108.761 131.118Z" fill="#7F7F7F"/>
                            <path d="M140.858 134V126.19H142.409L144.807 130.634H144.829L147.227 126.19H148.778V134H147.238V129.171H147.216L145.313 132.526H144.312L142.42 129.16H142.398V134H140.858Z" fill="#7F7F7F"/>
                            <path d="M179.398 134.077C178.98 134.077 178.58 133.993 178.199 133.824C177.818 133.648 177.502 133.399 177.253 133.076L178.144 131.976C178.489 132.409 178.877 132.625 179.31 132.625C179.933 132.625 180.245 132.181 180.245 131.294V126.19H181.796V131.283C181.796 133.146 180.997 134.077 179.398 134.077Z" fill="#7F7F7F"/>
                            <path d="M213.398 134.077C212.98 134.077 212.58 133.993 212.199 133.824C211.818 133.648 211.502 133.399 211.253 133.076L212.144 131.976C212.489 132.409 212.877 132.625 213.31 132.625C213.933 132.625 214.245 132.181 214.245 131.294V126.19H215.796V131.283C215.796 133.146 214.997 134.077 213.398 134.077Z" fill="#7F7F7F"/>
                            <path d="M246.176 134L249.069 126.19H250.565L253.469 134H251.852L251.313 132.438H248.321L247.793 134H246.176ZM248.761 131.118H250.873L249.828 128.06H249.806L248.761 131.118Z" fill="#7F7F7F"/>
                            <path d="M284.201 134.099C283.108 134.099 282.159 133.685 281.352 132.856L282.287 131.712C282.566 132.027 282.877 132.269 283.222 132.438C283.574 132.607 283.911 132.691 284.234 132.691C284.593 132.691 284.868 132.618 285.059 132.471C285.25 132.317 285.345 132.115 285.345 131.866C285.345 131.595 285.246 131.386 285.048 131.239C284.857 131.092 284.505 130.931 283.992 130.755C283.713 130.66 283.475 130.572 283.277 130.491C283.086 130.403 282.877 130.289 282.65 130.15C282.43 130.011 282.25 129.864 282.111 129.71C281.972 129.549 281.854 129.351 281.759 129.116C281.671 128.874 281.627 128.61 281.627 128.324C281.627 127.701 281.869 127.176 282.353 126.751C282.837 126.318 283.431 126.098 284.135 126.091C285.088 126.091 285.968 126.421 286.775 127.081L285.873 128.247C285.242 127.734 284.674 127.477 284.168 127.477C283.889 127.477 283.662 127.547 283.486 127.686C283.317 127.825 283.229 128.016 283.222 128.258C283.222 128.485 283.314 128.665 283.497 128.797C283.688 128.929 284.036 129.094 284.542 129.292C285.349 129.607 285.877 129.849 286.126 130.018C286.691 130.399 286.973 130.953 286.973 131.679C286.973 132.222 286.826 132.684 286.533 133.065C286.24 133.439 285.891 133.707 285.488 133.868C285.085 134.022 284.656 134.099 284.201 134.099Z" fill="#7F7F7F"/>
                            <path d="M319.51 134.099C318.337 134.099 317.38 133.725 316.639 132.977C315.898 132.222 315.528 131.261 315.528 130.095C315.528 128.929 315.898 127.972 316.639 127.224C317.38 126.469 318.337 126.091 319.51 126.091C320.661 126.091 321.611 126.465 322.359 127.213C323.107 127.954 323.481 128.914 323.481 130.095C323.481 131.276 323.107 132.24 322.359 132.988C321.611 133.729 320.661 134.099 319.51 134.099ZM319.51 132.647C320.185 132.647 320.746 132.405 321.193 131.921C321.64 131.437 321.864 130.828 321.864 130.095C321.864 129.362 321.64 128.753 321.193 128.269C320.746 127.785 320.185 127.543 319.51 127.543C318.813 127.543 318.241 127.789 317.794 128.28C317.354 128.771 317.134 129.376 317.134 130.095C317.134 130.814 317.354 131.419 317.794 131.91C318.241 132.401 318.813 132.647 319.51 132.647Z" fill="#7F7F7F"/>
                            <path d="M350.847 134V126.19H352.365L355.874 131.459H355.896V126.19H357.425V134H355.874L352.409 128.841H352.387V134H350.847Z" fill="#7F7F7F"/>
                            <path d="M385.847 134V126.19H388.432C389.657 126.19 390.639 126.553 391.38 127.279C392.121 127.998 392.491 128.94 392.491 130.106C392.491 131.272 392.128 132.214 391.402 132.933C390.683 133.644 389.704 134 388.465 134H385.847ZM387.376 132.625H388.366C389.114 132.625 389.719 132.387 390.181 131.91C390.643 131.433 390.874 130.832 390.874 130.106C390.874 129.373 390.647 128.768 390.192 128.291C389.737 127.807 389.158 127.565 388.454 127.565H387.376V132.625Z" fill="#7F7F7F"/>
                            </svg>

                        </div>
                        </div>
                        <div class="cardfoot bg-transparent border-top">
                          <a routerLink="/utilities/naturalgas/bill" class="link-gray" >Explore Past Billing <img src="assets/images/expand_right.svg" />
                            </a>

                        </div>
                    </div>
                     <!-- Stats Card End -->
                    </div>
                    <!-- Big Stats Boxes End -->

                </div>
                <!-- <div class="col-md-3 utiliti-cards">
                  <div class="card border-radius5 p-3">
                      <div class="cardupper comparing">
                        <h4> Compare your<br>facility to<br> others</h4>
                         <div class="comparebox">
                             <div class="comparecol green-bg"></div>
                             <div class="arrow blue-bg "></div>
                             <div class="comparecol yellow-bg"></div>
                             <div class="comparecol orange-bg"></div>
                         </div>
                      </div>
                      <div class="cardfoot border-top">
                          <a >Compare <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                      </div>
                  </div>
                  
              </div>
              
              <div class="col-md-3 utiliti-cards">
                 <div class="card border-radius5 p-3">
                      <div class="cardupper">
                          <div class="learningmode">
                              <div class="learning-btn"><span class="circle yellow-bg"></span> Learning</div>
                              <img src="assets/images/learningcode.svg" alt="learningcode="/>
                              <h3>learning <br>mode</h3>
                          </div>
                      </div>
                      <div class="cardfoot border-top">
                          <a >Demand Peaks <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                          
                         
                          
                      </div>
                  </div>
                  
              </div>
              
              <div class="col-md-3 utiliti-cards">
                  <div class="card border-radius5 p-3 explorePlan">
                      <div class="cardupper">
                        <h3>Re-calculate your<br> bill with other<br> plans</h3>
                      </div>
                      <div class="cardfoot border-top">
                          <a >Explore Plans <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                      </div>
                  </div>
                  
              </div>
              
              <div class="col-md-3 utiliti-cards">
                  <div class="card border-radius5 p-3">
                      <div class="cardupper">
                         
                      </div>
                      <div class="cardfoot border-top">
                          <a > <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                      </div>
                  </div>
                  
              </div> -->
              </div>


               <!-- Secondary Stats -->
               <!-- <div class="row mt-4"> -->

               <!-- stats-product-item -->
                <!-- <div class="col-3">
                  <div class="card stats-product p-3">
                    <div class="stats-pro-content">
                      <div class="stats-pro-label">
                        <h5 class="text-center p-4">Compare your facility to others</h5>
                      </div>
                      <div class="my-5 px-3">
                        <svg width="100%" height="30" viewBox="0 0 179 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M179 23C179 26.866 175.866 30 172 30L121 30L121 16L172 16C175.866 16 179 19.134 179 23Z" fill="#FF7F27"/>
                          <path d="M0 23C0 19.134 3.13401 16 7 16H58V30H7C3.13401 30 0 26.866 0 23Z" fill="#22B14C"/>
                          <path d="M60 16H119V30H60V16Z" fill="#FFDA59"/>
                          <path d="M51.7646 30C51.7646 30 51.7647 17.5985 51.7646 9.65217C51.7646 5.88235 55.2941 -2.06163e-06 57.9411 -2.17733e-06C60.5881 -2.29304e-06 64.1176 5.29412 64.1176 9.65217C64.1176 17.5985 64.1176 30 64.1176 30L51.7646 30Z" fill="#3649A8"/>
                          <rect x="50" y="30" width="16.4706" height="1.76471" transform="rotate(-90 50 30)" fill="white"/>
                          <rect x="64.1177" y="30" width="16.4706" height="1.76471" transform="rotate(-90 64.1177 30)" fill="white"/>
                          </svg>

                      </div>
                    </div>
                    <div class="cardfoot bg-transparent border-top">
                      <a href="javascript: void(0);">Compare <img src="assets/images/expand_right.svg"
                          alt="arrow" />
                      </a>
                    </div>
                  </div>
                </div> -->
                 <!-- stats-product-item -->
                 <!-- <div class="col-3">
                  <div class="card stats-product p-3">
                    <div class="stats-pro-content">

                    </div>
                    <div class="cardfoot bg-transparent border-top">
                      <a href="javascript: void(0);">Demand Peaks <img src="assets/images/expand_right.svg"
                          alt="arrow" />
                      </a>
                    </div>
                  </div>
                </div> -->
                 <!-- stats-product-item -->
                 <!-- <div class="col-3">
                  <div class="card stats-product p-3">
                    <div class="stats-pro-content">

                    </div>
                    <div class="cardfoot bg-transparent border-top">
                      <a href="javascript: void(0);" >Explore Plans <img src="assets/images/expand_right.svg"
                          alt="arrow" />
                      </a>
                    </div>
                  </div>
                </div> -->
                 <!-- stats-product-item -->
                 <!-- <div class="col-3">
                  <div class="card stats-product p-3">
                    <div class="stats-pro-content">

                    </div>
                    <div class="cardfoot bg-transparent border-top">
                      <a href="javascript: void(0);">&nbsp; <img src="assets/images/expand_right.svg"
                          alt="arrow" />
                      </a>
                    </div>
                  </div>
                </div> -->

              <!-- </div> -->

            </div>


            </div>

        </div>
      </div>
    </div>
  </div>