<div class="dt-main">
  <app-sidebar></app-sidebar>
  <div class="dt-content-wrapper overflow-y-scroll">
    <div class="dt-content">
      <div class="col-md-12 spc-padding">
        <h2 class="pageHeading">
          <span class="main-chevron">
            <a routerLink="/insights">
              <svg
                width="10"
                height="15"
                viewBox="0 0 10 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.666748 7.5L7.91675 14.75L9.6205 13.0463L4.08633 7.5L9.6205 1.95375L7.91675 0.250003L0.666748 7.5Z"
                  fill="#0D1758"
                ></path>
              </svg>
            </a>
          </span>
          Insights
        </h2>
        <div class="bg-lightgrey p-20 overflow-y-scroll">
          <div
            style="
              width: 100%;
              padding: 10px;
              background: #f5f5f5;
              border-radius: 16px;
              overflow: hidden;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-end;
              gap: 2px;
              display: inline-flex;
            "
          >
            <div
              style="
                align-self: stretch;
                justify-content: flex-start;
                align-items: center;
                gap: 24px;
                display: inline-flex;
              "
            >
              <div
                style="
                  flex: 1 1 0;
                  padding: 24px;
                  background: white;
                  border-radius: 16px;
                  overflow: hidden;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 24px;
                  display: inline-flex;
                "
              >
                <div
                  style="
                    align-self: stretch;
                    height: 33px;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    gap: 12px;
                    display: flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      padding-left: 2px;
                      padding-right: 2px;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 10px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        color: #0d1758;
                        font-size: 17px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Energy Efficiency
                    </div>
                  </div>
                  <div
                    style="
                      align-self: stretch;
                      height: 0px;
                      border: 1px #e3e7ed solid;
                    "
                  ></div>
                </div>
                <div
                  style="
                    min-height: 98px;
                    align-self: stretch;
                    flex: 1 1 0;
                    justify-content: space-between;
                    align-items: center;
                    display: inline-flex;
                  "
                >
                  <svg
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_7390_19160)">
                      <path
                        d="M7.76562 27.9995C7.76562 19.4815 13.0037 12.2926 20.2649 9.29113H20.266L22.5957 5.10645C12.313 7.51204 4.48438 16.7723 4.48438 27.9995C4.48438 38.5644 11.472 47.5818 21.1879 50.5119L21.4351 47.1454C13.3577 44.3797 7.76562 36.741 7.76562 27.9995ZM34.8115 5.48707L34.5644 8.85363C42.6417 11.6193 48.2339 19.2579 48.2339 27.9995C48.2339 36.5092 43.002 43.7039 35.7347 46.7079H35.7336L33.4039 50.8926C43.6867 48.487 51.5151 39.2267 51.5151 27.9995C51.5151 17.4349 44.5275 8.41712 34.8115 5.48707Z"
                        fill="#0D1758"
                      />
                      <path
                        d="M43.6327 24.118C43.4879 23.8716 43.2812 23.6673 43.0331 23.5253C42.785 23.3834 42.5041 23.3087 42.2183 23.3087H30.2136L31.795 1.76088C31.8224 1.38825 31.7218 1.01745 31.5097 0.709792C31.2977 0.402136 30.9871 0.176062 30.6291 0.0689614C30.2712 -0.0382537 29.8873 -0.0200206 29.5412 0.120642C29.195 0.261305 28.9072 0.515963 28.7255 0.842461L12.3478 30.2521C12.2088 30.5018 12.1374 30.7835 12.1407 31.0693C12.1441 31.3551 12.222 31.6351 12.3668 31.8815C12.5117 32.1279 12.7184 32.3322 12.9664 32.4742C13.2145 32.6161 13.4954 32.6908 13.7812 32.6908H25.786L24.2044 54.2388C24.1771 54.6114 24.2777 54.9822 24.4898 55.2899C24.7018 55.5975 25.0125 55.8235 25.3705 55.9306C26.1139 56.1533 26.9028 55.8239 27.274 55.1571L43.6516 25.7476C43.7907 25.4978 43.8621 25.2161 43.8588 24.9303C43.8554 24.6445 43.7775 24.3645 43.6327 24.118Z"
                        fill="#FFC90E"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_7390_19160">
                        <rect
                          width="56"
                          height="56"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <div
                    style="
                      flex-direction: column;
                      justify-content: center;
                      align-items: flex-end;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        color: #7f7f7f;
                        font-size: 14px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Monitoring:
                    </div>
                    <div
                      style="
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;
                        gap: 4px;
                        display: flex;
                      "
                    >
                      <div
                        *ngIf="buildingInsights.metrics"
                        style="
                          color: #0d1758;
                          font-size: 32px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        {{ buildingInsights.metrics.monitoring }}
                      </div>
                      <div
                        *ngIf="buildingInsights.metrics"
                        style="
                          color: #7f7f7f;
                          font-size: 14px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        / {{ buildingInsights.metrics.total }} Assets
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                routerLink="/insights/equipment-health"
                style="
                  flex: 1 1 0;
                  padding: 24px;
                  background: white;
                  border-radius: 16px;
                  overflow: hidden;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 24px;
                  display: inline-flex;
                  cursor: pointer;
                "
              >
                <div
                  style="
                    align-self: stretch;
                    height: 33px;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    gap: 12px;
                    display: flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      padding-left: 2px;
                      padding-right: 2px;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 10px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        color: #0d1758;
                        font-size: 17px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Asset Health
                    </div>
                  </div>
                  <div
                    style="
                      align-self: stretch;
                      height: 0px;
                      border: 1px #e3e7ed solid;
                    "
                  ></div>
                </div>
                <div
                  style="
                    min-height: 98px;
                    align-self: stretch;
                    justify-content: space-between;
                    align-items: center;
                    display: inline-flex;
                  "
                >
                  <svg
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_8554_29753)">
                      <path
                        d="M28 0C43.4631 0 56 12.5369 56 28C56 43.4613 43.4631 56 28 56C12.5369 56 0 43.4629 0 28C0 12.5371 12.5351 0 28 0Z"
                        fill="#E2574C"
                      />
                      <path
                        d="M15.7479 22.755H22.7742V15.7481C22.7742 14.7822 23.5565 13.998 24.5224 13.998H31.5153C32.4796 13.998 33.2618 14.782 33.2618 15.7481V22.7534H40.2513C40.4811 22.7532 40.7088 22.7983 40.9212 22.8862C41.1336 22.974 41.3266 23.1029 41.4892 23.2655C41.6517 23.428 41.7806 23.621 41.8685 23.8334C41.9564 24.0459 42.0015 24.2735 42.0013 24.5034V31.5051C42.0013 32.4746 41.219 33.2585 40.2513 33.2585H33.2618V40.2444C33.2618 41.2121 32.4794 41.9944 31.5153 41.9944H24.5224C23.5565 41.9944 22.7742 41.2121 22.7742 40.2444V33.2585H15.7479C14.7802 33.2585 13.9961 32.4746 13.9961 31.5051V24.5034C13.9961 23.5373 14.7802 22.755 15.7479 22.755Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8554_29753">
                        <rect
                          width="56"
                          height="56"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <div
                    style="
                      flex-direction: column;
                      justify-content: center;
                      align-items: flex-end;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        color: #7f7f7f;
                        font-size: 14px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Monitoring:
                    </div>
                    <div
                      style="
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;
                        gap: 4px;
                        display: flex;
                      "
                    >
                      <div
                        *ngIf="buildingInsights.metrics"
                        style="
                          color: #0d1758;
                          font-size: 32px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        {{ buildingInsights.metrics.monitoring }}
                      </div>
                      <div
                        *ngIf="buildingInsights.metrics"
                        style="
                          color: #7f7f7f;
                          font-size: 14px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        / {{ buildingInsights.metrics.total }} Assets
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  flex: 1 1 0;
                  align-self: stretch;
                  padding: 24px;
                  background: white;
                  border-radius: 16px;
                  overflow: hidden;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 2px;
                  display: inline-flex;
                "
              >
                <div
                  style="
                    align-self: stretch;
                    height: 33px;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    gap: 12px;
                    display: flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      padding-left: 2px;
                      padding-right: 2px;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 10px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        color: #0d1758;
                        font-size: 17px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Important Alerts
                    </div>
                  </div>
                  <div
                    style="
                      align-self: stretch;
                      height: 0px;
                      border: 1px #e3e7ed solid;
                    "
                  ></div>
                </div>
                <div
                  style="
                    align-self: stretch;
                    flex: 1 1 0;
                    justify-content: space-between;
                    align-items: center;
                    display: inline-flex;
                  "
                >
                  <svg
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M21.8555 6.50123V3.41921C21.8555 2.66701 22.1543 1.94561 22.6862 1.41371C23.2181 0.881822 23.9395 0.583008 24.6917 0.583008C25.4439 0.583008 26.1653 0.881822 26.6972 1.41371C27.2291 1.94561 27.5279 2.66701 27.5279 3.41921V6.50123H21.8555Z"
                      fill="#F29C1F"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M41.9732 33.6628C42.0677 34.5231 42.1906 35.3929 42.3514 36.2532L43.5993 43.1263H5.7832L7.03113 36.2532C7.45751 33.9077 7.67306 31.5281 7.67401 29.1438V23.2728C7.67315 19.9971 8.61802 16.7908 10.3951 14.039C12.1722 11.2872 14.706 9.107 17.692 7.76023C20.6781 6.41345 23.9895 5.9574 27.2284 6.44684C30.4674 6.93629 33.4962 8.35042 35.951 10.5194L41.9732 33.6628Z"
                      fill="#F0C419"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.4562 24.2174C11.2054 24.2174 10.965 24.1178 10.7877 23.9405C10.6104 23.7632 10.5108 23.5228 10.5108 23.272C10.5066 21.125 10.9923 19.0054 11.9311 17.0745C12.8698 15.1435 14.2368 13.4523 15.9279 12.1295C16.0255 12.0527 16.1373 11.9958 16.2568 11.9621C16.3764 11.9285 16.5014 11.9187 16.6248 11.9334C16.7481 11.948 16.8674 11.9868 16.9757 12.0476C17.084 12.1083 17.1794 12.1898 17.2562 12.2874C17.3331 12.385 17.3899 12.4968 17.4236 12.6163C17.4573 12.7359 17.467 12.8609 17.4524 12.9843C17.4377 13.1076 17.3989 13.2268 17.3382 13.3352C17.2774 13.4435 17.1959 13.5388 17.0983 13.6157C15.6324 14.7619 14.4474 16.2274 13.6335 17.9008C12.8196 19.5742 12.3982 21.4112 12.4016 23.272C12.4016 23.5228 12.302 23.7632 12.1247 23.9405C11.9474 24.1178 11.7069 24.2174 11.4562 24.2174Z"
                      fill="#F3D55B"
                    />
                    <path
                      d="M43.5997 43.127H5.78356C3.69504 43.127 2.00195 44.82 2.00195 46.9086C2.00195 48.9971 3.69504 50.6902 5.78356 50.6902H43.5997C45.6882 50.6902 47.3813 48.9971 47.3813 46.9086C47.3813 44.82 45.6882 43.127 43.5997 43.127Z"
                      fill="#F3D55B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.9658 50.6895H29.4198C29.4198 51.9431 28.9218 53.1455 28.0353 54.032C27.1488 54.9184 25.9465 55.4165 24.6928 55.4165C23.4391 55.4165 22.2368 54.9184 21.3503 54.032C20.4638 53.1455 19.9658 51.9431 19.9658 50.6895Z"
                      fill="#F29C1F"
                    />
                    <path
                      d="M41.7092 33.6723C48.4969 33.6723 53.9994 28.1697 53.9994 21.382C53.9994 14.5943 48.4969 9.0918 41.7092 9.0918C34.9215 9.0918 29.4189 14.5943 29.4189 21.382C29.4189 28.1697 34.9215 33.6723 41.7092 33.6723Z"
                      fill="#E64C3C"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M43.5981 27.0538H42.6527V14.7636C42.6527 14.5766 42.5972 14.3939 42.4933 14.2384C42.3894 14.083 42.2418 13.9619 42.0691 13.8903C41.8963 13.8188 41.7063 13.8001 41.5229 13.8365C41.3396 13.873 41.1711 13.963 41.0389 14.0952L39.1481 15.986C38.9759 16.1643 38.8806 16.4031 38.8827 16.651C38.8849 16.8988 38.9843 17.136 39.1596 17.3112C39.3349 17.4865 39.572 17.586 39.8199 17.5881C40.0678 17.5903 40.3066 17.495 40.4849 17.3228L40.7619 17.0458V27.0538H39.8165C39.5658 27.0538 39.3253 27.1534 39.148 27.3307C38.9707 27.508 38.8711 27.7485 38.8711 27.9992C38.8711 28.2499 38.9707 28.4904 39.148 28.6677C39.3253 28.845 39.5658 28.9446 39.8165 28.9446H43.5981C43.8488 28.9446 44.0893 28.845 44.2666 28.6677C44.4439 28.4904 44.5435 28.2499 44.5435 27.9992C44.5435 27.7485 44.4439 27.508 44.2666 27.3307C44.0893 27.1534 43.8488 27.0538 43.5981 27.0538Z"
                      fill="#ECF0F1"
                    />
                  </svg>

                  <div
                    style="
                      align-self: stretch;
                      flex-direction: column;
                      justify-content: flex-end;
                      align-items: flex-end;
                      gap: 4px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;
                        gap: 4px;
                        display: flex;
                      "
                    >
                      <div
                        style="
                          color: #0d1758;
                          font-size: 32px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        {{ buildingInsights.alerts ?? 0 }}
                      </div>
                      <div
                        style="
                          color: #7f7f7f;
                          font-size: 14px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        Important Alerts
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div
              style="
                min-height: 98px;
                align-self: stretch;
                justify-content: flex-start;
                gap: 12px;
                display: inline-flex;
                height: 100%;
              "
            >
              <div
                routerLink="/insights/energy-efficiency"
                *ngIf="isBaselineRunning"
                style="
                  margin-right: 5px;
                  width: 50%;
                  height: 450px;
                  padding: 24px;
                  background: white;
                  border-radius: 16px;
                  overflow: hidden;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 40px;
                  display: inline-flex;
                  cursor: pointer;
                "
              >
                <div
                  style="
                    align-self: stretch;
                    height: 48px;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    gap: 12px;
                    display: flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      padding-left: 2px;
                      padding-right: 2px;
                      justify-content: space-between;
                      align-items: center;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        color: #0d1758;
                        font-size: 22px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Explore Energy Efficiency
                    </div>
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_8086_30498)">
                        <rect
                          width="36"
                          height="36"
                          transform="translate(36) rotate(90)"
                          fill="white"
                        />
                        <path
                          d="M24 18L15 9L12.885 11.115L19.755 18L12.885 24.885L15 27L24 18Z"
                          fill="#0D1758"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8086_30498">
                          <rect
                            width="36"
                            height="36"
                            fill="white"
                            transform="translate(36) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div
                    style="
                      align-self: stretch;
                      height: 0px;
                      border: 1px #e3e7ed solid;
                    "
                  ></div>
                </div>
                <div
                  style="
                    text-align: center;
                    color: #0d1758;
                    font-size: 22px;
                    font-weight: 700;
                    word-wrap: break-word;
                  "
                >
                  Baseline in Progress
                </div>
                <div
                  style="
                    width: 150px;
                    height: 150px;
                    padding: 24px;
                    border-radius: 1000px;
                    border: 6px #3649a8 solid;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    display: flex;
                  "
                >
                  <div
                    style="
                      flex-direction: column;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 2px;
                      display: flex;
                    "
                  >
                    <div
                      style="
                        text-align: center;
                        color: #3649a8;
                        font-size: 19px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      {{ baselineTimeRemaining.days }} :
                      {{ baselineTimeRemaining.hours }} :
                      {{ baselineTimeRemaining.minutes }}
                    </div>
                    <div
                      style="
                        align-self: stretch;
                        justify-content: space-between;
                        align-items: flex-start;
                        display: inline-flex;
                      "
                    >
                      <div
                        style="
                          text-align: center;
                          color: #7f7f7f;
                          font-size: 10px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        Days
                      </div>
                      <div
                        style="
                          text-align: center;
                          color: #7f7f7f;
                          font-size: 10px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        Hrs
                      </div>
                      <div
                        style="
                          text-align: center;
                          color: #7f7f7f;
                          font-size: 10px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        Min
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    text-align: center;
                    color: rgba(13, 23, 88, 0.6);
                    font-size: 17px;
                    font-weight: 700;
                    word-wrap: break-word;
                  "
                >
                  We are currently learning <br />about your facility
                </div>
              </div>
              <div
                routerLink="/insights/energy-efficiency"
                *ngIf="!isBaselineRunning"
                style="
                  margin-right: 5px;
                  width: 50%;
                  height: 450px;
                  align-self: stretch;
                  padding: 20px;
                  background: white;
                  border-radius: 16px;
                  overflow: hidden;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  gap: 40px;
                  display: inline-flex;
                  cursor: pointer;
                "
              >
                <div
                  style="
                    align-self: stretch;
                    height: 48px;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    gap: 2px;
                    display: flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      padding-left: 2px;
                      padding-right: 2px;
                      justify-content: space-between;
                      align-items: center;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        color: #0d1758;
                        font-size: 22px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Explore Energy Efficiency
                    </div>
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_8086_30498)">
                        <rect
                          width="36"
                          height="36"
                          transform="translate(36) rotate(90)"
                          fill="white"
                        />
                        <path
                          d="M24 18L15 9L12.885 11.115L19.755 18L12.885 24.885L15 27L24 18Z"
                          fill="#0D1758"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8086_30498">
                          <rect
                            width="36"
                            height="36"
                            fill="white"
                            transform="translate(36) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div
                    style="
                      align-self: stretch;
                      height: 0px;
                      border: 1px #e3e7ed solid;
                    "
                  ></div>
                </div>
                <div
                  style="
                    align-self: stretch;
                    justify-content: center;
                    align-items: center;
                    gap: 40px;
                    display: inline-flex;
                  "
                >
                  <svg
                    width="121"
                    height="121"
                    viewBox="0 0 121 121"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.516947 62.1759C0.79897 74.0395 4.59261 85.5529 11.4181 95.2604C18.2436 104.968 27.7945 112.433 38.8629 116.713C49.9313 120.992 62.0201 121.893 73.6007 119.302C85.1812 116.711 95.7334 110.744 103.923 102.156C112.112 93.5679 117.571 82.7442 119.609 71.0536C121.647 59.363 120.172 47.3306 115.371 36.4781C110.571 25.6255 102.66 16.4402 92.6392 10.0837C82.6183 3.72718 70.9376 0.484923 59.0741 0.766947L59.4835 17.9899C67.9406 17.7888 76.2675 20.1001 83.4111 24.6315C90.5546 29.1629 96.194 35.7108 99.6162 43.4473C103.038 51.1837 104.09 59.7612 102.637 68.0951C101.184 76.429 97.2927 84.1449 91.4548 90.2672C85.6168 96.3895 78.0945 100.643 69.8391 102.49C61.5836 104.337 52.9659 103.695 45.0755 100.644C37.1852 97.5935 30.3767 92.2716 25.511 85.3514C20.6453 78.4313 17.9409 70.2237 17.7399 61.7665L0.516947 62.1759Z"
                      fill="#7F7F7F"
                    />
                    <path
                      [ngStyle]="{ fill: energyEfficiencyColor }"
                      d="M56.6325 0.874778C41.592 1.84629 27.468 8.44276 17.0691 19.3525C6.67012 30.2622 0.757972 44.6861 0.508237 59.7558L17.7337 60.0413C17.9117 49.2985 22.1263 39.0162 29.5394 31.239C36.9525 23.4617 47.021 18.7593 57.743 18.0667L56.6325 0.874778Z"
                    />
                  </svg>

                  <div
                    style="
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      gap: 2px;
                      display: inline-flex;
                    "
                  >
                    <div
                      [ngStyle]="{ color: energyEfficiencyColor }"
                      style="
                        font-size: 80px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      0
                    </div>
                    <div
                      [ngStyle]="{ color: energyEfficiencyColor }"
                      style="
                        text-align: center;
                        font-size: 17px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Energy savings <br />opportunities
                    </div>
                  </div>
                </div>
                <div
                  style="
                    align-self: stretch;
                    height: 135px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 32px;
                    display: flex;
                  "
                >
                  <div
                    style="
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      gap: 20px;
                      display: flex;
                    "
                  >
                    <div
                      [ngStyle]="{ color: energyEfficiencyColor }"
                      style="
                        font-size: 22px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Save
                    </div>
                    <div
                      style="
                        width: 263px;
                        justify-content: flex-start;
                        align-items: flex-start;
                        gap: 4px;
                        display: inline-flex;
                      "
                    >
                      <svg
                        width="264"
                        height="16"
                        viewBox="0 0 264 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          [ngStyle]="{
                            fill: energyEfficiencyColor,
                            alpha: 0.3
                          }"
                          d="M0.5 8C0.5 3.58172 4.08172 0 8.5 0H85.5V16H8.5C4.08172 16 0.5 12.4183 0.5 8Z"
                        />
                        <rect
                          [ngStyle]="{
                            fill: energyEfficiencyColor,
                            alpha: 0.6
                          }"
                          x="89.5"
                          width="85"
                          height="16"
                        />
                        <path
                          [ngStyle]="{ fill: energyEfficiencyColor }"
                          d="M178.5 0H255.5C259.918 0 263.5 3.58172 263.5 8C263.5 12.4183 259.918 16 255.5 16H178.5V0Z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    style="
                      width: 348px;
                      justify-content: space-between;
                      align-items: flex-start;
                      display: inline-flex;
                    "
                  >
                    <div
                      [ngStyle]="{ color: energyEfficiencyColor }"
                      style="
                        font-size: 32px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      0 kwh
                    </div>
                    <div
                      [ngStyle]="{ color: energyEfficiencyColor }"
                      style="
                        font-size: 32px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      0 kwh
                    </div>
                  </div>
                </div>
              </div>
              <div
              routerLink="/insights/asset-health"
                style="
                  margin-left: 5px;
                  width: 50%;
                  align-self: stretch;
                  height: 450px;
                  padding: 24px;
                  background: white;
                  border-radius: 16px;
                  overflow: hidden;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 40px;
                  display: inline-flex;
                  cursor: pointer;
                "
              >
                <div
                  style="
                    align-self: stretch;
                    height: 48px;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-start;
                    gap: 12px;
                    display: flex;
                  "
                >
                  <div
                    style="
                      align-self: stretch;
                      padding-left: 2px;
                      padding-right: 2px;
                      justify-content: space-between;
                      align-items: center;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        color: #0d1758;
                        font-size: 22px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Explore Asset Health
                    </div>
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_8086_30498)">
                        <rect
                          width="36"
                          height="36"
                          transform="translate(36) rotate(90)"
                          fill="white"
                        />
                        <path
                          d="M24 18L15 9L12.885 11.115L19.755 18L12.885 24.885L15 27L24 18Z"
                          fill="#0D1758"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_8086_30498">
                          <rect
                            width="36"
                            height="36"
                            fill="white"
                            transform="translate(36) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div
                    style="
                      align-self: stretch;
                      height: 0px;
                      border: 1px #e3e7ed solid;
                    "
                  ></div>
                </div>
                <div
                  style="
                    height: 25%;
                    align-self: stretch;
                    justify-content: center;
                    align-items: center;
                    gap: 40px;
                    display: inline-flex;
                  "
                >
                  <svg
                    width="117"
                    height="116"
                    viewBox="0 0 117 116"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M106.64 25.649C102.087 18.8744 96.1554 13.1369 89.2332 8.81186C82.3109 4.4868 74.5538 1.6715 66.4689 0.550045C58.3839 -0.571409 50.1534 0.0262373 42.3153 2.30391C34.4772 4.58158 27.2081 8.48798 20.9833 13.7677L31.7555 26.4681C36.1929 22.7044 41.3749 19.9196 46.9624 18.2959C52.5499 16.6722 58.4173 16.2462 64.1808 17.0457C69.9442 17.8451 75.4741 19.852 80.4088 22.9353C85.3434 26.0185 89.5717 30.1085 92.8172 34.938L106.64 25.649Z"
                      fill="#FF7F27"
                    />
                    <path
                      d="M19.6616 14.9236C15.7888 18.4153 12.4046 22.4134 9.60044 26.8097L23.641 35.7654C25.64 32.6314 28.0525 29.7813 30.8133 27.2921L19.6616 14.9236Z"
                      fill="#EE5937"
                    />
                    <path
                      d="M116.5 58C116.5 90.0325 90.5325 116 58.5 116C26.4675 116 0.5 90.0325 0.5 58C0.5 25.9675 26.4675 0 58.5 0C90.5325 0 116.5 25.9675 116.5 58ZM17.1535 58C17.1535 80.835 35.665 99.3465 58.5 99.3465C81.335 99.3465 99.8465 80.835 99.8465 58C99.8465 35.165 81.335 16.6535 58.5 16.6535C35.665 16.6535 17.1535 35.165 17.1535 58Z"
                      fill="#3649A8"
                    />
                  </svg>

                  <div
                    style="
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      gap: 2px;
                      display: inline-flex;
                    "
                  >
                    <div
                      *ngIf="buildingInsights.metrics"
                      style="
                        color: #0d1758;
                        font-size: 80px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      {{ buildingInsights.metrics.monitoring }}
                    </div>
                    <div
                      style="
                        text-align: center;
                        color: #0d1758;
                        font-size: 17px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Assets monitored
                    </div>
                  </div>
                </div>
                <div
                  style="
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 8px;
                    display: flex;
                  "
                >
                  <div
                    style="
                      justify-content: flex-start;
                      align-items: center;
                      gap: 16px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                        display: flex;
                      "
                    >
                      <div
                        style="
                          width: 8px;
                          height: 8px;
                          background: #1fab3d;
                          border-radius: 9999px;
                        "
                      ></div>
                      <div
                        *ngIf="buildingInsights.metrics"
                        style="
                          color: #1fab3d;
                          font-size: 25px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        {{ buildingInsights.metrics.healthy_operation ?? 0 }}
                      </div>
                    </div>
                    <div
                      style="
                        color: #7f7f7f;
                        font-size: 15px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Healthy operation
                    </div>
                  </div>
                  <div
                    style="
                      justify-content: flex-start;
                      align-items: center;
                      gap: 16px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                        display: flex;
                      "
                    >
                      <div
                        style="
                          width: 8px;
                          height: 8px;
                          background: #ff7f27;
                          border-radius: 9999px;
                        "
                      ></div>
                      <div
                        *ngIf="buildingInsights.metrics"
                        style="
                          text-align: center;
                          color: #ff7f27;
                          font-size: 25px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        {{ buildingInsights.metrics.requires_action }}
                      </div>
                    </div>
                    <div
                      style="
                        color: #7f7f7f;
                        font-size: 15px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Requires Action
                    </div>
                  </div>
                  <div
                    style="
                      justify-content: flex-start;
                      align-items: center;
                      gap: 16px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                        display: flex;
                      "
                    >
                      <div
                        style="
                          width: 8px;
                          height: 8px;
                          background: #ee5937;
                          border-radius: 9999px;
                        "
                      ></div>
                      <div
                        *ngIf="buildingInsights.metrics"
                        style="
                          text-align: center;
                          color: #ee5937;
                          font-size: 25px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        {{ buildingInsights.metrics.requires_immediate_action }}
                      </div>
                    </div>
                    <div
                      style="
                        color: #7f7f7f;
                        font-size: 15px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Requires Immediate Action
                    </div>
                  </div>
                  <div
                    style="
                      justify-content: flex-start;
                      align-items: center;
                      gap: 16px;
                      display: inline-flex;
                    "
                  >
                    <div
                      style="
                        justify-content: flex-start;
                        align-items: center;
                        gap: 8px;
                        display: flex;
                      "
                    >
                      <div
                        style="
                          width: 8px;
                          height: 8px;
                          background: #3649a8;
                          border-radius: 9999px;
                        "
                      ></div>
                      <div
                        *ngIf="buildingInsights.metrics"
                        style="
                          text-align: center;
                          color: #3649a8;
                          font-size: 25px;
                          font-weight: 700;
                          word-wrap: break-word;
                        "
                      >
                        {{ buildingInsights.metrics.baseline_in_progress }}
                      </div>
                    </div>
                    <div
                      style="
                        color: #7f7f7f;
                        font-size: 15px;
                        font-weight: 700;
                        word-wrap: break-word;
                      "
                    >
                      Baseline in Progress
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="isFacilityOffline"
              style="
                position: fixed;
                top: 50%;
                left: 55%;
                transform: translate(-50%, -50%);
                z-index: 1000;
                width: 500px;
                height: 155px;
                padding: 20px;
                background: rgba(245, 247, 251, 0.8);
                box-shadow: 0px 0px 20.399999618530273px rgba(0, 0, 0, 0.1);
                border-radius: 12px;
                overflow: hidden;
                border: 2px #6285fb solid;
                backdrop-filter: blur(42.7px);
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 16px;
                display: inline-flex;
              "
            >
              <div
                style="
                  align-self: stretch;
                  border-top-left-radius: 2px;
                  border-top-right-radius: 8px;
                  border-bottom-right-radius: 8px;
                  border-bottom-left-radius: 2px;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 16px;
                  display: inline-flex;
                "
              >
                <div style="width: 24px; height: 24px; position: relative">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 11C12.5523 11 13 11.4477 13 12V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V12C11 11.4477 11.4477 11 12 11Z"
                      fill="#6285FB"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.679 7.25577C13.0895 7.62525 13.1227 8.25754 12.7533 8.66803L12.7433 8.67914C12.3738 9.08963 11.7415 9.12288 11.331 8.7534C10.9205 8.38393 10.8873 7.75163 11.2567 7.34114L11.2667 7.33003C11.6362 6.91954 12.2685 6.88629 12.679 7.25577Z"
                      fill="#6285FB"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1 12.5C1 6.42487 5.92487 1.5 12 1.5C18.0751 1.5 23 6.42486 23 12.5C23 18.5751 18.0751 23.5 12 23.5C5.92486 23.5 1 18.5751 1 12.5ZM12 3.5C7.02943 3.5 3 7.52943 3 12.5C3 17.4705 7.02944 21.5 12 21.5C16.9705 21.5 21 17.4705 21 12.5C21 7.52944 16.9705 3.5 12 3.5Z"
                      fill="#6285FB"
                    />
                  </svg>
                </div>
                <div
                  style="
                    flex: 1 1 0;
                    color: #0d1758;
                    font-size: 22px;
                    font-weight: 600;
                    word-wrap: break-word;
                  "
                >
                  Unlock full potential
                </div>
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.32843 5.82843C5.76633 5.39052 6.47632 5.39052 6.91422 5.82843L12 10.9142L17.0858 5.82843C17.5237 5.39053 18.2337 5.39052 18.6716 5.82843C19.1095 6.26633 19.1095 6.97631 18.6716 7.41422L13.5858 12.5L18.6716 17.5858C19.1095 18.0237 19.1095 18.7337 18.6716 19.1716C18.2337 19.6095 17.5237 19.6095 17.0858 19.1716L12 14.0858L6.91422 19.1716C6.47631 19.6095 5.76633 19.6095 5.32843 19.1716C4.89052 18.7337 4.89052 18.0237 5.32843 17.5858L10.4142 12.5L5.32843 7.41422C4.89052 6.97631 4.89052 6.26633 5.32843 5.82843Z"
                    fill="#8C8C8C"
                  />
                </svg>
              </div>
              <div
                style="
                  align-self: stretch;
                  color: rgba(13, 23, 88, 0.6);
                  font-size: 17px;
                  font-weight: 600;
                  line-height: 24px;
                  word-wrap: break-word;
                "
              >
                Activate live sensors to actively find and discover energy
                efficiency opportunities as well as monitor and share deep
                insights on your assets.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
