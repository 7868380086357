import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-peak-demand',
  templateUrl: './peak-demand.component.html',
  styleUrls: ['./peak-demand.component.scss']
})
export class PeakDemandComponent implements OnInit {
  
  constructor() { }

  public isLoading = false;

  ngOnInit(): void {
  }

  explorePeakHeatmap() {
    // This will be implemented in the future for the Peak Heatmap feature
    console.log('Navigate to Peak Heatmap');
  }

  explorePeakExplorer() {
    // This will be implemented in the future for the Peak Explorer feature
    console.log('Navigate to Peak Explorer');
  }

  exploreTopPeaks() {
    // This will be implemented in the future for the Top 50 Peaks feature
    console.log('Navigate to Top 50 Peaks');
  }
} 