<div class="dt-main">
  <app-sidebar></app-sidebar>

  <div class="dt-content-wrapper">
    <div class="dt-content dt-content-overwrite">
      <div class="col-md-12 spc-padding">
        <h2 class="pageHeading heading">About Facility</h2>

        <!-- <div class="bg-lightgrey overflow-y-scroll card-container">
          <div class="card border-radius5 p-3 about-card">
            <div class="about-card-body">
              <div class="pd-img">
                <img
                  *ngIf="currentBuilding"
                  src="{{
                    currentBuilding.image || currentBuilding.facility.image
                  }}"
                  alt="{{ currentBuilding.facility.type }}"
                  class="img-fluid"
                />
              </div>

              <div class="CardDesc">
                <ul>
                  <li>
                    <h5>Building Type</h5>
                    <h3 *ngIf="currentBuilding">
                      {{ currentBuilding.facility.type }}
                    </h3>
                  </li>

                  <li>
                    <h5>Year Built <span class="floatRight">Age</span></h5>

                    <h3 *ngIf="currentBuilding">
                      {{ currentBuilding.year_built || 'Unknown'
                      }}<span class="floatRight">{{
                        currentBuilding.age || '???'
                      }}</span>
                    </h3>
                  </li>

                  <li>
                    <h5>Size</h5>

                    <h3 *ngIf="currentBuilding">
                      {{ currentBuilding.squarefootage || 'Unknown' }} ft<sup
                        >2</sup
                      >
                    </h3>
                  </li>
                </ul>
              </div>
            </div>

            <div class="cardfoot border-top about-card-footer">
              <a
                href="javascript: void(0);"
                (click)="openGeneralOverviewModal()"
                >General Overview
                <img
                  src="assets/images/expand_right.svg"
                  alt="arrow"
              /></a>
            </div>
          </div>

          <div class="card border-radius5 p-3 about-card">
            <div class="about-card-body">
              <div class="pd-img text-center">
                <img
                  src="assets/images/map.png"
                  alt="map"
                  class="img-fluid"
                />
                <h3>Zone 7A</h3>
              </div>

              <div class="CardDesc">
                <ul>
                  <li>
                    <h5>Address</h5>
                    <h3 *ngIf="currentBuilding">
                      {{ currentBuilding.address_line_1 || 'Unknown' }}<br />{{
                        currentBuilding.address_line_2
                      }}
                    </h3>
                  </li>
                  <li>
                    <h5>City</h5>
                    <h3 *ngIf="currentBuilding">
                      {{ currentBuilding.city }},
                      {{ currentBuilding.province }}
                    </h3>
                  </li>

                  <li>
                    <h5>Weather Station</h5>
                    <h3 *ngIf="currentBuilding">
                      {{ currentBuilding.weather_station }}
                    </h3>
                  </li>
                </ul>
              </div>
            </div>

            <div class="cardfoot border-top about-card-footer">
              <a
                href="javascript: void(0);"
                (click)="openGeneralOverviewModal()"
                >Location Info
                <img
                  src="assets/images/expand_right.svg"
                  alt="arrow"
              /></a>
            </div>
          </div>

          <div class="card border-radius5 p-3 about-card">
            <div class="d-flex align-items-center">
              <div class="typical-hours">
                <h4 class="typical-hours-header">Typical Hours</h4>

                <div class="HourChart">
                  <div class="column">
                    <p class="title">S</p>
                  </div>

                  <div class="column">
                    <p class="title">M</p>
                  </div>

                  <div class="column">
                    <p class="title">T</p>
                  </div>

                  <div class="column">
                    <p class="title">W</p>
                  </div>

                  <div class="column">
                    <p class="title">T</p>
                  </div>

                  <div class="column">
                    <p class="title">F</p>
                  </div>

                  <div class="column">
                    <p class="title">S</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="cardfoot border-top about-card-footer">
              <a
                routerLink="/operatinghours"
                href="#"
                >Operating Hours
                <img
                  src="assets/images/expand_right.svg"
                  alt="arrow"
              /></a>
            </div>
          </div>

          <div class="card border-radius5 p-3 about-card">
            <div class="d-flex align-items-center">
              <div class="weather-div">
                <div class="label">
                  <span></span>
                  <p>live</p>
                </div>

                <div class="weather-spc">
                  <div class="weather_col">
                    <img
                      *ngIf="iconUrl"
                      src="{{ iconUrl }}"
                      alt="weather"
                    />
                  </div>

                  <div class="weatherTxt">
                    {{ temp_c }}<sup>0</sup><span>c</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="cardfoot border-top about-card-footer">
              <a
                routerLink="/weather"
                href="#"
                >Weather
                <img
                  src="assets/images/expand_right.svg"
                  alt="arrow"
              /></a>
            </div>
          </div>

          <div class="card border-radius5 p-3 about-card">
            <div class="d-flex align-items-center">
              <div class="weather-div">
                <div class="label">
                  <span></span>
                  <p>live</p>
                </div>

                <div class="weather-spc">
                  <div class="weather_col">
                    <img
                      *ngIf="iconUrl"
                      src="{{ iconUrl }}"
                      alt="weather"
                    />
                  </div>

                  <div class="weatherTxt">
                    {{ temp_c }}<sup>0</sup><span>c</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="cardfoot border-top about-card-footer">
              <a
                routerLink="/weather"
                href="#"
                >Weather
                <img
                  src="assets/images/expand_right.svg"
                  alt="arrow"
              /></a>
            </div>
          </div>
        </div> -->

        <div class="bg-lightgrey overflow-y-scroll card-container">
          <div
            *ngFor="let option of options()"
            class="card border-radius5 p-3 about-card"
          >
            <div class="about-card-body">
              <img
                *ngIf="
                  option.condition &&
                  option.src &&
                  !!option.src() &&
                  option.alt &&
                  !!option.alt()
                "
                src="{{ option.src() }}"
                alt="{{ option.alt() }}"
                class="cardImage"
              />

              <div
                *ngIf="option.condition && option.innerHTML"
                [innerHTML]="option.innerHTML"
                class="about-card-innerHTML"
              ></div>
            </div>

            <div
              class="about-card-footer"
              (click)="option.onClick()"
            >
              <div>{{ option.label }}</div>

              <img
                src="assets/images/expand_right.svg"
                alt="arrow"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal"
    id="general-overview"
    tabindex="-1"
    role="dialog"
    style="display: block"
    *ngIf="modal == 'general-overview'"
  >
    <div
      class="modal-dialog about-facility-modal-dialog"
      role="document"
    >
      <div class="modal-content about-facility-modal-content">
        <div class="modal-header about-facility-modal-header">
          <h4 class="modal-title">General Information</h4>

          <button
            type="button"
            class="btn"
            (click)="closeGeneralOverviewModal()"
          >
            <img
              src="assets/images/cancel.svg"
              alt="arrow"
            />
          </button>
        </div>

        <div class="bg-lightgrey overflow-y-scroll about-facility-modal-body">
          <h3>Overview</h3>

          <div class="about-facility-modal-info">
            <h3 class="modal-info-title">General Overview</h3>

            <div class="about-facility-modal-form">
              <div class="form-fields">
                <div *ngFor="let field of generalOverviewFields">
                  <span class="form-label">{{ field.title }}:</span>

                  <ng-container *ngIf="isEditing !== field.id">
                    <span
                      class="form-value"
                      *ngIf="isEditing !== field.id"
                      >{{ field.selector(currentBuilding) }}</span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      (click)="toggleEdit(field.id)"
                      *ngIf="field.id"
                      [ngClass]="{
                        svg_disabled:
                          isEditing !== null && isEditing !== field.id
                      }"
                    >
                      <g clip-path="url(#clip0_9410_16744)">
                        <path
                          d="M13.641 7.91746C13.244 7.91746 12.9231 8.23916 12.9231 8.63537V14.379C12.9231 14.7746 12.6014 15.0969 12.2052 15.0969H2.15385C1.7575 15.0969 1.43594 14.7746 1.43594 14.379V4.32767C1.43594 3.93211 1.7575 3.60977 2.15385 3.60977H7.89748C8.2945 3.60977 8.61539 3.28811 8.61539 2.89187C8.61539 2.49553 8.2945 2.17383 7.89748 2.17383H2.15385C0.96637 2.17383 0 3.1402 0 4.32767V14.379C0 15.5665 0.96637 16.5328 2.15385 16.5328H12.2052C13.3926 16.5328 14.359 15.5665 14.359 14.379V8.63537C14.359 8.23839 14.038 7.91746 13.641 7.91746Z"
                          fill="#0D1758"
                        />
                        <path
                          d="M5.53672 8.46078C5.48682 8.5109 5.45264 8.57451 5.43838 8.64379L4.93082 11.1826C4.90716 11.3002 4.94448 11.4215 5.02915 11.507C5.06257 11.5403 5.10222 11.5667 5.14584 11.5847C5.18946 11.6027 5.23619 11.6119 5.28337 11.6118C5.30626 11.6118 5.33005 11.6097 5.35371 11.6047L7.89168 11.0971C7.96202 11.0826 8.02589 11.049 8.07546 10.9986L13.7559 5.31821L11.2179 2.78038L5.53672 8.46078ZM15.5105 1.02503C14.8106 0.324992 13.6719 0.324992 12.9725 1.02503L11.9789 2.01859L14.5169 4.55656L15.5105 3.56286C15.8494 3.22474 16.036 2.77381 16.036 2.29425C16.036 1.81468 15.8494 1.36378 15.5105 1.02503Z"
                          fill="#0D1758"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_9410_16744">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </ng-container>

                  <ng-container *ngIf="isEditing === field.id">
                    <ng-container *ngIf="field.type === 'text'">
                      <input
                        type="{{ field.type }}"
                        value="{{ field.selector(updateInfo) }}"
                        (input)="handleValueChange($event, field.id)"
                        name="{{ field.name }}"
                      />
                    </ng-container>

                    <ng-container
                      *ngIf="field.type === 'select' && field.name === 'type'"
                    >
                      <select
                        (input)="handleValueChange($event, field.id)"
                        name="{{ field.name }}"
                        class="select"
                      >
                        <option
                          *ngFor="let option of facilityOptions.type"
                          value="{{ option }}"
                          [selected]="option === updateInfo.facility.type"
                        >
                          {{ option }}
                        </option>
                      </select>
                    </ng-container>

                    <ng-container
                      *ngIf="
                        field.type === 'select' && field.name === 'sub_type'
                      "
                    >
                      <select
                        value="{{ updateInfo.facility.sub_type }}"
                        (input)="handleValueChange($event, field.id)"
                        name="{{ field.name }}"
                        class="select"
                      >
                        <option
                          *ngFor="let option of facilityOptions.sub_type"
                          value="{{ option }}"
                          [selected]="option === updateInfo.facility.sub_type"
                        >
                          {{ option }}
                        </option>
                      </select>
                    </ng-container>

                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 24 24"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="
                        field.id &&
                          updateFacility(currentBuilding.idbuildings, field.id)
                      "
                    >
                      <path
                        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                        fill="green"
                      />
                    </svg>

                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 24 24"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="toggleEdit(null)"
                    >
                      <path
                        d="M6.70711 6.70711C6.31658 7.09763 6.31658 7.7308 6.70711 8.12132L10.5858 12L6.70711 15.8787C6.31658 16.2692 6.31658 16.9024 6.70711 17.2929C7.09763 17.6834 7.7308 17.6834 8.12132 17.2929L12 13.4142L15.8787 17.2929C16.2692 17.6834 16.9024 17.6834 17.2929 17.2929C17.6834 16.9024 17.6834 16.2692 17.2929 15.8787L13.4142 12L17.2929 8.12132C17.6834 7.7308 17.6834 7.09763 17.2929 6.70711C16.9024 6.31658 16.2692 6.31658 15.8787 6.70711L12 10.5858L8.12132 6.70711C7.7308 6.31658 7.09763 6.31658 6.70711 6.70711Z"
                        fill="red"
                      />
                    </svg>
                  </ng-container>
                </div>
              </div>

              <div class="facility-icon">
                <div>Facility Icon:</div>

                <img
                  src="{{ facilityImageUrl(currentBuilding) }}"
                  width="100%"
                />

                <input
                  type="file"
                  #fileInput
                  style="display: none"
                  (change)="onFileSelected($event)"
                  accept="image/*"
                />
                <button
                  class="upload-profile-btn"
                  (click)="fileInput.click()"
                >
                  Upload Picture
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal"
    id="organization-overview"
    tabindex="-1"
    role="dialog"
    style="display: block"
    *ngIf="modal == 'organization-overview'"
  >
    <div
      class="modal-dialog about-facility-modal-dialog"
      role="document"
    >
      <div class="modal-content about-facility-modal-content">
        <div class="modal-header about-facility-modal-header">
          <h4 class="modal-title">Organization Information</h4>

          <button
            type="button"
            class="btn"
            (click)="closeGeneralOverviewModal()"
          >
            <img
              src="assets/images/cancel.svg"
              alt="arrow"
            />
          </button>
        </div>

        <div class="bg-lightgrey overflow-y-scroll about-facility-modal-body">
          <h3>Overview</h3>

          <div class="about-facility-modal-info">
            <div
              class="about-facility-modal-form"
              style="align-items: center"
            >
              <div class="form-fields">
                <div *ngFor="let field of organizationOverviewFields">
                  <span class="form-label">{{ field.title }}:</span>

                  <ng-container *ngIf="isEditing !== field.id">
                    <span
                      class="form-value"
                      *ngIf="isEditing !== field.id"
                      >{{ field.selector(company) }}</span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      (click)="toggleEdit(field.id)"
                      *ngIf="field.id && field.id !== 'company_id'"
                      [ngClass]="{
                        svg_disabled:
                          isEditing !== null && isEditing !== field.id
                      }"
                    >
                      <g clip-path="url(#clip0_9410_16744)">
                        <path
                          d="M13.641 7.91746C13.244 7.91746 12.9231 8.23916 12.9231 8.63537V14.379C12.9231 14.7746 12.6014 15.0969 12.2052 15.0969H2.15385C1.7575 15.0969 1.43594 14.7746 1.43594 14.379V4.32767C1.43594 3.93211 1.7575 3.60977 2.15385 3.60977H7.89748C8.2945 3.60977 8.61539 3.28811 8.61539 2.89187C8.61539 2.49553 8.2945 2.17383 7.89748 2.17383H2.15385C0.96637 2.17383 0 3.1402 0 4.32767V14.379C0 15.5665 0.96637 16.5328 2.15385 16.5328H12.2052C13.3926 16.5328 14.359 15.5665 14.359 14.379V8.63537C14.359 8.23839 14.038 7.91746 13.641 7.91746Z"
                          fill="#0D1758"
                        />
                        <path
                          d="M5.53672 8.46078C5.48682 8.5109 5.45264 8.57451 5.43838 8.64379L4.93082 11.1826C4.90716 11.3002 4.94448 11.4215 5.02915 11.507C5.06257 11.5403 5.10222 11.5667 5.14584 11.5847C5.18946 11.6027 5.23619 11.6119 5.28337 11.6118C5.30626 11.6118 5.33005 11.6097 5.35371 11.6047L7.89168 11.0971C7.96202 11.0826 8.02589 11.049 8.07546 10.9986L13.7559 5.31821L11.2179 2.78038L5.53672 8.46078ZM15.5105 1.02503C14.8106 0.324992 13.6719 0.324992 12.9725 1.02503L11.9789 2.01859L14.5169 4.55656L15.5105 3.56286C15.8494 3.22474 16.036 2.77381 16.036 2.29425C16.036 1.81468 15.8494 1.36378 15.5105 1.02503Z"
                          fill="#0D1758"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_9410_16744">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </ng-container>

                  <ng-container *ngIf="isEditing === field.id">
                    <ng-container *ngIf="field.type === 'text'">
                      <input
                        type="{{ field.type }}"
                        value="{{ field.selector(updateCompanyInfo) }}"
                        (input)="handleValueChange($event, field.id)"
                        name="{{ field.name }}"
                      />
                    </ng-container>

                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 24 24"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="updateCompany(field.id)"
                    >
                      <path
                        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                        fill="green"
                      />
                    </svg>

                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 24 24"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="toggleEdit(null)"
                    >
                      <path
                        d="M6.70711 6.70711C6.31658 7.09763 6.31658 7.7308 6.70711 8.12132L10.5858 12L6.70711 15.8787C6.31658 16.2692 6.31658 16.9024 6.70711 17.2929C7.09763 17.6834 7.7308 17.6834 8.12132 17.2929L12 13.4142L15.8787 17.2929C16.2692 17.6834 16.9024 17.6834 17.2929 17.2929C17.6834 16.9024 17.6834 16.2692 17.2929 15.8787L13.4142 12L17.2929 8.12132C17.6834 7.7308 17.6834 7.09763 17.2929 6.70711C16.9024 6.31658 16.2692 6.31658 15.8787 6.70711L12 10.5858L8.12132 6.70711C7.7308 6.31658 7.09763 6.31658 6.70711 6.70711Z"
                        fill="red"
                      />
                    </svg>
                  </ng-container>
                </div>
              </div>

              <div class="facility-icon">
                <div>Organization Logo:</div>

                <img
                  *ngIf="company.image"
                  src="{{ company.image }}"
                  width="100%"
                  [style.height]="company.image ? 'auto' : '200px'"
                />

                <div
                  *ngIf="!company.image"
                  class="emptyImg"
                ></div>

                <input
                  type="file"
                  #fileInput
                  style="display: none"
                  (change)="onFileSelected($event)"
                  accept="image/*"
                />
                <button
                  class="upload-profile-btn"
                  (click)="fileInput.click()"
                >
                  Upload Picture
                </button>
              </div>
            </div>

            <div>
              <div class="head-office-info-header">Head Office Info:</div>

              <div
                class="form-fields organization-overview-headoffice-form-fields"
              >
                <div *ngFor="let field of organizationHeadofficeOverviewFields">
                  <span class="form-label">{{ field.title }}:</span>

                  <ng-container *ngIf="isEditing !== field.id">
                    <span
                      class="form-value"
                      *ngIf="isEditing !== field.id"
                      >{{ field.selector(company) }}</span
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      (click)="toggleEdit(field.id)"
                      *ngIf="field.id"
                      [ngClass]="{
                        svg_disabled:
                          isEditing !== null && isEditing !== field.id
                      }"
                    >
                      <g clip-path="url(#clip0_9410_16744)">
                        <path
                          d="M13.641 7.91746C13.244 7.91746 12.9231 8.23916 12.9231 8.63537V14.379C12.9231 14.7746 12.6014 15.0969 12.2052 15.0969H2.15385C1.7575 15.0969 1.43594 14.7746 1.43594 14.379V4.32767C1.43594 3.93211 1.7575 3.60977 2.15385 3.60977H7.89748C8.2945 3.60977 8.61539 3.28811 8.61539 2.89187C8.61539 2.49553 8.2945 2.17383 7.89748 2.17383H2.15385C0.96637 2.17383 0 3.1402 0 4.32767V14.379C0 15.5665 0.96637 16.5328 2.15385 16.5328H12.2052C13.3926 16.5328 14.359 15.5665 14.359 14.379V8.63537C14.359 8.23839 14.038 7.91746 13.641 7.91746Z"
                          fill="#0D1758"
                        />
                        <path
                          d="M5.53672 8.46078C5.48682 8.5109 5.45264 8.57451 5.43838 8.64379L4.93082 11.1826C4.90716 11.3002 4.94448 11.4215 5.02915 11.507C5.06257 11.5403 5.10222 11.5667 5.14584 11.5847C5.18946 11.6027 5.23619 11.6119 5.28337 11.6118C5.30626 11.6118 5.33005 11.6097 5.35371 11.6047L7.89168 11.0971C7.96202 11.0826 8.02589 11.049 8.07546 10.9986L13.7559 5.31821L11.2179 2.78038L5.53672 8.46078ZM15.5105 1.02503C14.8106 0.324992 13.6719 0.324992 12.9725 1.02503L11.9789 2.01859L14.5169 4.55656L15.5105 3.56286C15.8494 3.22474 16.036 2.77381 16.036 2.29425C16.036 1.81468 15.8494 1.36378 15.5105 1.02503Z"
                          fill="#0D1758"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_9410_16744">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </ng-container>

                  <ng-container *ngIf="isEditing === field.id">
                    <ng-container *ngIf="field.type === 'text'">
                      <input
                        type="{{ field.type }}"
                        value="{{ field.selector(updateCompanyInfo) }}"
                        (input)="handleValueChange($event, field.id)"
                        name="{{ field.name }}"
                      />
                    </ng-container>

                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 24 24"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="updateCompany(field.id)"
                    >
                      <path
                        d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z"
                        fill="green"
                      />
                    </svg>

                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 24 24"
                      fill="white"
                      xmlns="http://www.w3.org/2000/svg"
                      (click)="toggleEdit(null)"
                    >
                      <path
                        d="M6.70711 6.70711C6.31658 7.09763 6.31658 7.7308 6.70711 8.12132L10.5858 12L6.70711 15.8787C6.31658 16.2692 6.31658 16.9024 6.70711 17.2929C7.09763 17.6834 7.7308 17.6834 8.12132 17.2929L12 13.4142L15.8787 17.2929C16.2692 17.6834 16.9024 17.6834 17.2929 17.2929C17.6834 16.9024 17.6834 16.2692 17.2929 15.8787L13.4142 12L17.2929 8.12132C17.6834 7.7308 17.6834 7.09763 17.2929 6.70711C16.9024 6.31658 16.2692 6.31658 15.8787 6.70711L12 10.5858L8.12132 6.70711C7.7308 6.31658 7.09763 6.31658 6.70711 6.70711Z"
                        fill="red"
                      />
                    </svg>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
