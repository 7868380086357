<div class="dt-main">
  <app-sidebar></app-sidebar>
  <div class="dt-content-wrapper overflow-y-scroll">
    <div class="dt-content">
      <div class="col-md-12 spc-padding">
        <div class="headerContainer">
          <div class="left-section">
            <img
              src="assets/images/back-button.png"
              routerLink="/insights/energy-efficiency"
            />
            <h2 class="pageHeading heading">Explore Savings Potential</h2>
          </div>
        </div>
        <div class="bg-lightgrey overflow-y-scroll"  *ngIf="!isLoading">
          <div class="explore-energy-potential-container d-flex mb-4">
            <button class="about-scan-cta insights-explore-potential-about-cta" (click)="openScanModal($event)">
              <img  class="mx-1"
                    src="assets/images/AboutScan.png"
                    alt="about scan"
                  />
              About E.O Scan
            </button>
            <button class="create-scan-cta insights-explore-potential-request-cta" (click)="openScanModal($event)">
              <img  class="mx-1"
                    src="assets/images/CreateScan.png"
                    alt="request scan"
                  />
              Request E.O Scan 
            </button>
            <button class="rejected-scan-cta insights-explore-potential-rejected-cta" 
              routerLink="/insights/energy-efficiency/explore-energy-potential/energy-savings-snapshot" 
              [queryParams]="{rejected_scan: true}">
              <img  class="mx-1"
                    src="assets/images/RejectedScan.png"
                    alt="rejected scan"
                  />
              Rejected ECM's
            </button>
          </div>
          <div class="scan-cards-container d-flex justify-content-between" >
            <div class="explore-energy-potential-card" *ngFor="let scan of ecmScans; let i = index">
              <div class="card border-radius5">
                <div [class]="scan.is_completed ? 'energy-savings-card align-items-baseline' : 'energy-savings-card align-items-baseline justify-content-start'">
                  <div class="dates">{{scan.finalDate}}</div>
                  <h1 class="opportunitues-no" *ngIf="scan.is_completed">{{ scan.ecm_circuit_count}}</h1>
                  <p class="opportunitues-text" *ngIf="scan.is_completed">Opportunities Identified</p>
                </div>
                <div class="cardfoot border-top">
                  <a *ngIf="scan.is_completed" class="scan-anchor" 
                    routerLink="/insights/energy-efficiency/explore-energy-potential/energy-savings-snapshot" 
                    [queryParams]="scan">
                    Scan #{{i+1}}
                    <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                  <a *ngIf="!scan.is_completed" class="scan-anchor disabled">
                    Scanning...
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="isLoading">
          <div class="loaderContainer">
            <div class="loader"></div>
          </div>
        </ng-container>
        <!-- Add Modal -->
        <div class="modal" *ngIf="showCreateModal || showAboutModal">
          <div class="modal-content explore-savings-potential-modal">
            <div class="modal-header">
              <h2 *ngIf="showCreateModal">Request E.O Scan</h2>
              <h2 *ngIf="showAboutModal">About E.O Scan</h2>
                <button class="close-btn" (click)="closeCreateScanModal()">
                  <img class="close-button"
                  src="assets/images/cancel.svg"
                  alt="arrow"
                  />
                </button>
            </div>
            <div class="modal-body">
              <div class="modal-body-header" *ngIf="showCreateModal">
                <h4>
                  ECM Scan Results
                </h4>
              </div>
              <div class="modal-body-title">
                <div class="modal-body-text">
                  <p [ngClass]="showAboutModal === true ? 'about-scan-modal-title' : '' ">
                    The Energy Optimization Scan (E.O Scan) analyzes your facility's energy usage to identify areas of energy loss and potential savings.
                  </p>
                </div>
                <div class="modal-form-container" *ngIf="showCreateModal">
                  <form [formGroup]="scanForm" (ngSubmit)="createScan()">
                    <div class="d-flexx">
                      <div class="d-flex date-picker">
                        <div class="form-group">
                          <label for="startDate">From:</label>
                          <input 
                            type="date" 
                            id="startDate" 
                            formControlName="startDate"
                            class="form-control"
                          >
                        </div>
                        <div class="form-group">
                          <label for="tillDate">Till:</label>
                          <input 
                            type="date" 
                            id="tillDate" 
                            formControlName="tillDate"
                            class="form-control"
                          >
                        </div>
                      </div>
  
                      <div class="modal-footer flex-nowrap">
                        <p>Please, note the scan and analysis will take up to 24 hours to complete</p>
                        <!-- <button type="button" class="btn-cancel" (click)="closeCreateScanModal()">Cancel</button> -->
                        <button type="submit" class="btn-save begin-analyis" [disabled]="!scanForm.valid || isSubmitting" (click)="createScan()">
                          {{ isSubmitting ? 'Creating...' : 'Begin Analysis' }}
                        </button>
                      </div>
                    </div>

                  </form>
                </div>
                <div class="modal-form-container" *ngIf="showAboutModal">
                  <div class="about-scan-contianer pb-4">
                    <div class="energy-loss-container">
                      <img
                      src="assets/images/EnergyLoss.png"
                      alt="energy loss"
                      />
                      <span>Energy Loss Analysis</span>
                    </div>

                    <p class="mb-0 about-scan-modal-text">The Energy Loss Analysis helps identify potential energy losses within your facility by analyzing 
                      various factors such as operational patterns, environmental conditions, and equipment efficiency. While the system detects inefficiencies 
                      automatically, user feedback is essential to refine the algorithm and ensure accurate recommendations for energy optimization.</p>
                  </div>
                  <div>
                    <div class="energy-conservation-container mt-4">
                      <img
                        src="assets/images/EnergyConservation.png"
                        alt="energy conservation"
                      />
                      <span>Energy Conservation Measures (ECMs)</span>
                    </div>
                    <p class="mb-0 about-scan-modal-text">Energy Conservation Measures (ECMs) are actionable recommendations designed to reduce energy loss and
                      improve efficiency within your facility. These measures are based on system-detected inefficiencies and take into account factors like operational 
                      patterns,environmental conditions, and equipment performance. User feedback helps refine ECM suggestions, ensuring they align with your 
                      facility's specific needs and priorities.</p>
                  </div>
                  

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
