import { Component, OnInit } from '@angular/core';
import { Building } from 'src/app/models/building';
import { DashboardDataService } from 'src/app/services/dashboard-data/dashboard-data.service';
import { NgToastService } from 'ng-angular-popup';

interface PeakDemandHeatMap {
  heatmap_data: PeakHeatmapData[];
  color_scale: { value: string; color: string }[];
}
interface PeakHeatmapData {
  index: number;
  peakDemand: number;
  color: string;
  date: string;
  hour: number;
  quarter?: number;
}

@Component({
  selector: 'app-peak-heatmap',
  templateUrl: './peak-heatmap.component.html',
  styleUrls: ['./peak-heatmap.component.scss']
})
export class PeakHeatmapComponent implements OnInit {

  public peakHeatmapData: PeakHeatmapData[] = [];
  public selectedMonth: Date;
  public colorScale: { value: string; color: string }[]  = [];
  public rangeSelectedMonth = true;
  public intervalSelected = '60m';
  currentBuilding: Building;
  public uniqueDates: string[] = [];
  public isLoading: boolean = false;


  constructor(
    private dashboardDataService: DashboardDataService,
    private toast: NgToastService
  ) { 
    // Initialize with current month
    this.selectedMonth = new Date();
    this.dashboardDataService.watchCurrentBuilding().forEach(building => {
      if (building != null) {
        this.currentBuilding = building;
        this.loadData();
      }
    });

   
  }

  ngOnInit(): void {
  }
  
  /**
   * Load data for the selected month
   */
  loadData(): void {
    this.isLoading = true;
    this.peakHeatmapData = [];
    this.colorScale = [];
    this.uniqueDates = [];
    this.dashboardDataService.getPeakDemandHeatmapData(this.currentBuilding.idbuildings, this.selectedMonth).subscribe(
      (data: PeakDemandHeatMap) => {
        this.peakHeatmapData = data.heatmap_data;
        this.colorScale = data.color_scale;
        this.uniqueDates = this.getUniqueDates();
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        console.error('Error fetching peak demand heatmap data:', error);
        this.toast.error({ 
          detail: 'Error', 
          summary: 'Failed to load peak demand data', 
          position: 'tr',
          duration: 3000
        });
      }
    );
  }
  
  /**
   * Select the previous month
   */
  selectPreviousMonth(): void {
    this.isLoading = true;
    const currentMonth = this.selectedMonth.getMonth();
    this.selectedMonth.setMonth(currentMonth - 1);
    this.selectedMonth = new Date(this.selectedMonth);
    this.loadData();
  }
  
  selectPrevious() {
    this.selectPreviousMonth();
  }

  selectNext() {
    this.selectNextMonth();
  }

  rangeSelected(event: any) {
    this.isLoading = true;
    this.rangeSelectedMonth = true;
    document.getElementById('rangeSelectorMonth')?.classList.add('selected');
    this.intervalSelected = '60m';

    this.loadData();
  }
  
  /**
   * Select the next month
   */
  selectNextMonth(): void {
    const currentMonth = this.selectedMonth.getMonth();
    
    // Don't allow selecting future months beyond current month
    const today = new Date();
    if (
      this.selectedMonth.getFullYear() === today.getFullYear() && 
      currentMonth === today.getMonth()
    ) {
      return;
    }
    
    this.isLoading = true;
    this.selectedMonth.setMonth(currentMonth + 1);
    this.selectedMonth = new Date(this.selectedMonth);
    this.loadData();
  }
  
  getUniqueDates(): string[] {
    // Extract unique dates from the data for the selected month
    const uniqueDates = Array.isArray(this.peakHeatmapData) 
      ? [...new Set(this.peakHeatmapData.map(item => item.date))]
      : [];
    // Return dates in chronological order
    return uniqueDates.sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });
  }

  getCellStyle(date: string, hour: number, quarter: number): any {
    // Find the data point for this specific date, hour, and quarter
    const dataPoint = this.peakHeatmapData.find(item => 
      item.date === date && 
      item.hour === hour && 
      item.quarter === quarter
    );
    
    if (!dataPoint) {
      return { backgroundColor: '#f0f0f0' }; // Default color for no data
    }
    
    // Calculate color based on peak demand value
    const color = dataPoint.color;
    console.log(date, hour, quarter, color);
    return {
      backgroundColor: color,
      cursor: 'pointer'
    };
  }
  
  getColorForValue(value: number): string {
    // Normalize value between 0 and 1 for color scale
    // Assuming max peak demand is 1000 (adjust as needed)
    const normalized = Math.min(Math.max(value / 1000, 0), 1);
    
    // Color scale from low (blue) to high (red)
    // Get the appropriate color from the scale
    const colorIndex = Math.floor(normalized * (this.colorScale.length - 1));
    return this.colorScale[colorIndex].color;
  }

} 