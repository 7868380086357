<div class="modal" id="viewModal" tabindex="-1" role="dialog" style="display: block">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
              <div>
                <h2 class="modal-title" *ngIf="!isExploreLossInsights"> Scan #001</h2>
                <h2 class="modal-title explore-loss-insights" *ngIf="isExploreLossInsights">
                  Off-Hours Usage
                </h2>
                <h4 class="modal-title">{{circuit.circuit_name}}</h4>
              </div>
                <button type="button" class="btn" (click)="closePopup()">
                    <img src="assets/images/cancel.svg" alt="arrow" />
                </button>
            </div>
            <div class="modal-body">
              <div class="">
                <div class="dt-content dt-content-overwrite">
                <div class="w-100">
                <div class="sortByContainer">
                    <div class="sortByActions">
                        <div
                          (click)="handleTabChange(0)"
                          [attr.date-selected]="selectedTab == 0"
                        >
                        <img [src]="selectedTab == 0 ? 'assets/images/ECMOverviewActive.png' :
                        'assets/images/ECMOverview.png'" alt="ECMOverview" />
                        </div>
                        <div
                          (click)="handleTabChange(1)"
                          [attr.date-selected]="selectedTab == 1"
                        >
                        <img [src]="selectedTab == 1 ? 'assets/images/CircuitOverviewActive.png' : 
                        'assets/images/CircuitOverview.png'" alt="CircuitOverview" />
                        </div>
                        <div
                          (click)="handleTabChange(2)"
                          [attr.date-selected]="selectedTab == 2"
                          *ngIf="!isExploreLossInsights"
                        >
                        <img [src]="selectedTab == 2 ? 'assets/images/ECMScanResultActive.png' : 
                        'assets/images/ECMScanResult.png'" alt="ECMScanResult" />
                        </div>
                        <div
                          (click)="handleTabChange(3)"
                          [attr.date-selected]="selectedTab == 3"
                          *ngIf="!isExploreLossInsights"
                        >
                        <img [src]="selectedTab == 3 ? 'assets/images/CircuitOverview2Active.png' :
                        'assets/images/CircuitOverview2.png'" alt="CircuitOverview2" />
                        </div>
                        <div
                          (click)="handleTabChange(4)"
                          [attr.date-selected]="selectedTab == 4"
                        >
                        <img [src]="selectedTab == 4 ? 'assets/images/MethodologyActive.png' :
                        'assets/images/Methodology.png'" alt="Methodology" />
                        </div>
                        <div
                          (click)="handleTabChange(5)"
                          [attr.date-selected]="selectedTab == 5"
                        >
                        <img [src]="selectedTab == 5 ? 'assets/images/RejectECMActive.png' :
                        'assets/images/RejectECM.png'" alt="RejectECM" />
                        </div>
                    </div>
                </div>
                <div class="bg-lightgrey overflow-y-scroll card-container">
                  <div class="infoContainer">
                    <div *ngIf="selectedTab==0">
                      <h3 *ngIf="!isExploreLossInsights">ECM Overview</h3>
                      <h3 *ngIf="isExploreLossInsights">Energy Loss Overview</h3>
                    </div>
                    <h3 *ngIf="selectedTab==1">Circuit Overview</h3>
                    <h3 *ngIf="selectedTab==2">ECM Scan Results</h3>
                    <h3 *ngIf="selectedTab==3">Circuit Overview</h3>
                    <h3 *ngIf="selectedTab==4">Methodology</h3>
                    <h3 *ngIf="selectedTab==5">Reject ECM</h3>
                  </div>
                  <div
                    class="card-content secondaryCard"
                    style="background-color: #fff"
                  >

                  <!-- Selected Tab 1 -->
                  <div *ngIf="selectedTab==0" class="infoContainer selected-tab-1">
                    <div class="overview-text-container" *ngIf="!isExploreLossInsights">
                      <div class="infoContentHeader">ECM Description:</div>
                      <div class="infoContentHeaderValue">The Manual Hall Lighting ECM reccoemnds tuning off halway lightings manually
                        after hours or
                        when the facility is closed. The simple measure reduces unnessary energy use, lower operational costs, and
                        supports sustainability
                        by ensuring lights are only on when needed.</div>
                  
                      <div class="infoContentHeader">Technical Description:</div>
                      <div class="infoContentHeaderValue border-0">The Manual Hall Lighting ECM reccoemnds tuning off halway lightings
                        manually after hours or when
                        the facility is closed. The simple measure reduces unnessary energy use, lower operational costs, and supports
                        sustainability by
                        ensuring lights are only on when needed.</div>
                    </div>
                    <div class="overview-text-container" *ngIf="isExploreLossInsights">
                      <div class="chart-overview-container">
                        <div class="chart-energy-loss d-flex flex-column">
                          <apx-chart class="mb-2"
                          [series]="chartOptions.series"
                          [chart]="chartOptions.chart"
                          [labels]="chartOptions.labels"
                          [responsive]="chartOptions.responsive"
                          [legend]="chartOptions.legend"
                          [dataLabels]="chartOptions.dataLabels"
                          [colors]="chartOptions.colors"
                          [stroke]="chartOptions.stroke"
                        ></apx-chart>
                          <p class="align-self-center">
                            Type of Energy Loss:
                          </p>
                          <p class="align-self-center bold-value-ecm">
                            <span class="panel-color"
                            [ngStyle]="{ 'background-color': categoryColor }"></span>{{ circuitEcmSelection.ecm.name }}
                          </p>
                        </div>

                        <div class="total-energy-metric">
                          <div>
                            <p class="heading-text">Total Energy Consumed:</p>
                            <p class="bold-value">{{ total_power.toFixed(2) }} kWh</p>
                          </div>
                          <div>
                            <p class="heading-text">Energy Loss Detected</p>
                            <p class="bold-value-loss">{{ total_savings.toFixed(2) }} kWh</p>
                          </div>
                          <div>
                            <p class="heading-text">Reduction</p>
                            <p class="bold-value">{{ percentage_loss }}%</p>
                          </div>
                        </div>

                        <div class="energy-loss-description">
                          <p class="heading">
                            Energy Loss Description
                          </p>
                          <p>
                            Circuits consuming energy outside scheduled operating hours
                          </p>
                        </div>
                      </div>
                      <div class="section-text">
                        <p>
                          During the scan a total of <span class="color-green">{{ total_power.toFixed(2) }} kWh</span> was recorded. If this Energy Conservaion Measure (ECM) would have been implemented buring that time 
                          period, the calculated savings would of been <span class="color-green">{{ percentage_loss }}%</span> of approximatly <span class="color-green">{{ total_savings.toFixed(2) }} kWh</span>, this would of been the savings during the period of the ECM scan.
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <!-- Selected Tab 2 -->
                  <div *ngIf="selectedTab==1" class="selected-tab-2">
                    <div class="infoContentHeader">Circuit Name:</div>
                    <div class="infoContentHeaderValue circuit-title">{{circuit.circuit_name}}</div>
                  
                    <div class="circuit-overview-items-container pb-3 mb-3">
                      <div class="item-container">
                        <div class="infoContentHeader">Panel:</div>
                        <div class="infoContentHeaderValue"><span class="panel-color"
                            [ngStyle]="{ 'background-color': panelColor }"></span>{{circuit?.panel_name}}</div>
                      </div>
                      <div class="item-container">
                        <div class="infoContentHeader">Circuit:</div>
                        <div class="infoContentHeaderValue">#{{circuit.circuit_number}}</div>
                      </div>
                  
                      <div class="item-container">
                        <div class="infoContentHeader">Category:</div>
                        <div class="infoContentHeaderValue"><span class="panel-color"
                            [ngStyle]="{ 'background-color': categoryColor }"></span>{{circuit.circuit_category_data?.name}}</div>
                      </div>
                      <div class="item-container">
                        <div class="infoContentHeader">Sub Category:</div>
                        <div class="infoContentHeaderValue">
                          {{ circuit?.circuit_sub_category_data?.name }}
                        </div>
                      </div>
                    </div>
                  
                    <div class="infoContentHeaderValue mb-3">Purpose of the Circuit:</div>
                    <div class="infoContentHeader">The {{circuit.circuit_name}} circuit controls the lightning in the hallways, ensuring
                      safe ana visible
                      passage for occupants and visitors.</div>
                  </div>
                  
                  <!-- Selected Tab 3 -->
                  <div *ngIf="selectedTab==2" class="selected-tab-3">
                    <div class="section-text">
                      During the scan a total of <span class="highlight-text">{{ total_usage }} kWh</span> was recorded. If this Energy Conservaion
                      Measure (ECM) would
                      have been implemented buring that time period, the calculated savings would of been <span
                        class="highlight-text">18.5%</span> of
                      approximatly <span class="highlight-text">{{ total_savings}} kWh</span>, this would of been the savings during the period of the
                      ECM scan.
                    </div>
                    <div class="scan-results-container">
                      <div class="result-conatiner">
                        <p class="result-title">
                          Total Energy Consumed:
                        </p>
                        <h3 class="result-value">
                          {{ total_usage }}
                        </h3>
                      </div>
                      <div class="result-conatiner">
                        <p class="result-title">
                          Savings Calculated:
                        </p>
                        <h3 class="result-value">
                          {{ total_savings }}
                        </h3>
                      </div>
                      <div class="result-conatiner">
                        <p class="result-title">
                          Reduced By:
                        </p>
                        <h3 class="result-value">
                          123
                        </h3>
                      </div>
                    </div>
                  </div>
                  
                  <!-- Selected Tab 4 -->
                  <div *ngIf="selectedTab==3" class="selected-tab-4">
                    <p class="section-text">
                      Implementing this Energy Conservation Measure (ECM) is projected to yield substantial energy and cost savings based
                      on the
                      analysis conducted during the Energy Savings Scan. This ECM addresses a specific inefficiency and could potentially
                      reduce
                      energy consumption by [Projected Savings Percentage]% or approximately [Projected Energy Savings in kWh] over the
                      scanned period.
                    </p>
                    <p class="section-text">
                      The estimated cost for implementing this ECM is <span class="highlight-text">[Estimated Cost]</span>, with an
                      anticipated return on investment (ROI) of
                      <span class="highlight-text">[Estimated ROI]%</span>. The break-even point is projected to be <span
                        class="highlight-text">[Break-even Time Period],</span> after which the savings continue to
                      accumulate. Over the estimated lifetime of the ECM, it is expected to generate total savings of [Estimated Lifetime
                      Savings].
                    </p>
                    <div class="scan-results-container">
                      <div class="result-conatiner">
                        <p class="result-title">
                          Estimated Cost:
                        </p>
                        <h3 class="result-value">
                          123
                        </h3>
                      </div>
                      <div class="result-conatiner">
                        <p class="result-title">
                          Estimated Annual Savings:
                        </p>
                        <h3 class="result-value">
                          {{ (total_savings * 365).toFixed(2) }}
                        </h3>
                      </div>
                    </div>
                    <div class="scan-results-container">
                      <div class="result-conatiner">
                        <p class="result-title">
                          Estimdated Brekaeven / ROI:
                        </p>
                        <h3 class="result-value">
                          123
                        </h3>
                      </div>
                      <div class="result-conatiner">
                        <p class="result-title">
                          Estimated Lifetime Savings:
                        </p>
                        <h3 class="result-value">
                          123
                        </h3>
                      </div>
                  
                    </div>
                  </div>
                  
                  <!-- Selected Tab 5 -->
                  <div *ngIf="selectedTab==4" class="selected-tab-5">
                    <div class="section-container">
                      <div class="d-flex">
                        <img class="align-self-baseline" src="assets/images/alert-circle.png" alt="alert" />
                        <p class="section-header ml-2">
                          Important Notice
                  
                        </p>
                      </div>
                  
                      <p class="section-text">
                        We continuously monitor the "hallway light" circuit 24/7, collecting comprehensive data on its usage patterns. By
                        analyzing this
                        information in conjunction with the facility's operating hours, we can accurately calculate potential energy
                        savings. This method
                        allows us to precisely determine how much energy and cost could be saved by implementing the suggested ECMs, such
                        as manually
                        turning off lights after hours. Our data-driven approach ensures that our savings estimates are reliable and
                        tailored to the
                        specific needs of the facility.
                      </p>
                    </div>
                    <button>
                      Download Simulations
                      <svg width="24" height="45" viewBox="0 0 24 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 0H12C18.6274 0 24 5.37258 24 12V33C24 39.6274 18.6274 45 12 45H1V0Z" fill="#3649A8" />
                        <g clip-path="url(#clip0_0_1)">
                          <path d="M16 22L10 16L8.59 17.41L13.17 22L8.59 26.59L10 28L16 22Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_0_1">
                            <rect width="24" height="24" fill="white" transform="translate(24 10) rotate(90)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                  
                  <!-- Selected Tab 6 -->
                  <div *ngIf="selectedTab==5" class="selected-tab-6">
                    <div class="section-container" *ngIf="!deleteECMRequest">
                      <p class="header-text">
                        Are you sure you want to reject this Energy Conservation Measure (ECM)?
                      </p>
                      <p class="section-text">
                        Please select the reason for rejection:
                      </p>
                  
                      <div class="form-container">
                        <div class="radio-group">
                          <label class="radio-option">
                            <input type="radio" name="recommendation" checked>
                            Recommendation not applicable to this facility
                          </label>
                  
                          <label class="radio-option">
                            <input type="radio" name="recommendation">
                            Implementation is not feasible
                          </label>
                  
                          <label class="radio-option">
                            <input type="radio" name="recommendation">
                            Other
                          </label>
                        </div>
                        <button class="reject-ecm" (click)="handleECMDeletion(0)">Reject ECM</button>
                      </div>
                    </div>
                    <div class="section-container" *ngIf="deleteECMRequest">
                      <p class="header-text">
                        Thank you for your feedback
                      </p>
                      <p class="section-text">
                        If you selected 'Other' or want to provide additional details, please add your notes below:
                      </p>
                  
                      <div class="form-container">
                        <div class="form-group-ele">
                          <input type="textarea" class="textarea" placeholder="Please provide details on any special requests..." />
                          <div>
                            <img class="align-self-baseline" src="assets/images/alert-circle.png" alt="alert" />
                            <p class="helper-text">
                              Your input plays a vital role in training the algorithm to better align with your facility's needs
                            </p>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between">
                          <button class="cancel-reject" (click)="handleECMDeletion(1)">Cancel</button>
                          <button class="reject-ecm">Reject ECM</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
            </div>
    </div>
</div>
</div>