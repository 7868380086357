<div class="dt-main">
    <app-sidebar></app-sidebar>
    <div class="dt-content-wrapper">
      <div class="dt-content">
        <div class="col-md-12 spc-padding">
          <h2 class="pageHeading">
            <span class="main-chevron">
              <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.666748 7.5L7.91675 14.75L9.6205 13.0463L4.08633 7.5L9.6205 1.95375L7.91675 0.250003L0.666748 7.5Z"
                  fill="#0D1758"></path>
              </svg>
            </span>
            Weather
          </h2>
          <div class="bg-lightgrey ">
            <div class="col-md-12  calendarOperatingHours">
  
              <full-calendar *ngIf='currentBuilding'[options]='calendarOptions' class="show-content">
              </full-calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  