<div class="dt-main">
  <app-sidebar></app-sidebar>
  <div class="dt-content-wrapper overflow-y-scroll">
     <div class="dt-content">
           <div class="col-md-12 spc-padding">
               <h2 class="pageHeading">
                  <span class="main-chevron">
                     <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.666748 7.5L7.91675 14.75L9.6205 13.0463L4.08633 7.5L9.6205 1.95375L7.91675 0.250003L0.666748 7.5Z" fill="#0D1758"></path>
                     </svg>
                  </span>
                  Utilities
               </h2>
               <div class="bg-lightgrey p-20 utiltiesAlernatives overflow-y-scroll">

                   <div class="row">
                       <!-- <div class="col-md-3 mb-4">
                          <div class="subheadingBx"><h3 class="subheading">EUI</h3></div>
                           <div class="card border-radius5 p-3">
                               <div class="cardupper eui">
                                   <p>energy usage <br>
                                      intensity</p>
                                  <svg width="170" height="71" viewBox="0 0 170 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <rect x="105" y="32" width="5" height="28" rx="2.5" fill="#FF7F27"/>
                                      <rect width="5" height="60" rx="2.5" fill="#FF7F27"/>
                                      <path d="M0 44H5V57.5C5 58.8807 3.88071 60 2.5 60C1.11929 60 0 58.8807 0 57.5V44Z" fill="#FFC90E"/>
                                      <path d="M105 44H110V57.5C110 58.8807 108.881 60 107.5 60C106.119 60 105 58.8807 105 57.5V44Z" fill="#FFC90E"/>
                                      <rect x="30" y="7" width="5" height="53" rx="2.5" fill="#FF7F27"/>
                                      <rect x="60" y="27" width="5" height="33" rx="2.5" fill="#FF7F27"/>
                                      <rect x="90" y="42" width="5" height="18" rx="2.5" fill="#FF7F27"/>
                                      <rect x="120" y="23" width="5" height="37" rx="2.5" fill="#FF7F27"/>
                                      <rect x="150" y="11" width="5" height="49" rx="2.5" fill="#FF7F27"/>
                                      <rect x="15" y="3" width="5" height="57" rx="2.5" fill="#FF7F27"/>
                                      <rect x="45" y="16" width="5" height="44" rx="2.5" fill="#FF7F27"/>
                                      <rect x="75" y="36" width="5" height="24" rx="2.5" fill="#FF7F27"/>
                                      <rect x="135" y="18" width="5" height="42" rx="2.5" fill="#FF7F27"/>
                                      <rect x="165" y="3" width="5" height="57" rx="2.5" fill="#FF7F27"/>
                                      <path d="M15 44H20V57.5C20 58.8807 18.8807 60 17.5 60C16.1193 60 15 58.8807 15 57.5V44Z" fill="#FFC90E"/>
                                      <path d="M120 44H125V57.5C125 58.8807 123.881 60 122.5 60C121.119 60 120 58.8807 120 57.5V44Z" fill="#FFC90E"/>
                                      <path d="M30 44H35V57.5C35 58.8807 33.8807 60 32.5 60C31.1193 60 30 58.8807 30 57.5V44Z" fill="#FFC90E"/>
                                      <path d="M135 44H140V57.5C140 58.8807 138.881 60 137.5 60C136.119 60 135 58.8807 135 57.5V44Z" fill="#FFC90E"/>
                                      <path d="M45 44H50V57.5C50 58.8807 48.8807 60 47.5 60C46.1193 60 45 58.8807 45 57.5V44Z" fill="#FFC90E"/>
                                      <path d="M150 44H155V57.5C155 58.8807 153.881 60 152.5 60C151.119 60 150 58.8807 150 57.5V44Z" fill="#FFC90E"/>
                                      <path d="M60 44H65V57.5C65 58.8807 63.8807 60 62.5 60C61.1193 60 60 58.8807 60 57.5V44Z" fill="#FFC90E"/>
                                      <path d="M165 44H170V57.5C170 58.8807 168.881 60 167.5 60C166.119 60 165 58.8807 165 57.5V44Z" fill="#FFC90E"/>
                                      <path d="M75 49H80V57.5C80 58.8807 78.8807 60 77.5 60C76.1193 60 75 58.8807 75 57.5V49Z" fill="#FFC90E"/>
                                      <path d="M90 51H95V57.5C95 58.8807 93.8807 60 92.5 60C91.1193 60 90 58.8807 90 57.5V51Z" fill="#FFC90E"/>
                                      <path d="M2.09 70.035C1.9 70.035 1.71833 69.9967 1.545 69.92C1.37167 69.84 1.22833 69.7267 1.115 69.58L1.52 69.08C1.67667 69.2767 1.85333 69.375 2.05 69.375C2.33333 69.375 2.475 69.1733 2.475 68.77V66.45H3.18V68.765C3.18 69.6117 2.81667 70.035 2.09 70.035Z" fill="#7F7F7F"/>
                                      <path d="M15.385 70V66.45H16.7C17.1133 66.45 17.4217 66.5583 17.625 66.775C17.8317 66.9883 17.935 67.2733 17.935 67.63C17.935 68.01 17.8183 68.2983 17.585 68.495C17.3517 68.6883 17.0333 68.785 16.63 68.785H16.08V70H15.385ZM16.08 68.165H16.555C16.7683 68.165 16.93 68.1167 17.04 68.02C17.15 67.92 17.205 67.7867 17.205 67.62C17.205 67.45 17.1517 67.3167 17.045 67.22C16.9417 67.1233 16.79 67.075 16.59 67.075H16.08V68.165Z" fill="#7F7F7F"/>
                                      <path d="M30.39 70V66.45H31.095L32.185 68.47H32.195L33.285 66.45H33.99V70H33.29V67.805H33.28L32.415 69.33H31.96L31.1 67.8H31.09V70H30.39Z" fill="#7F7F7F"/>
                                      <path d="M46.08 70L47.395 66.45H48.075L49.395 70H48.66L48.415 69.29H47.055L46.815 70H46.08ZM47.255 68.69H48.215L47.74 67.3H47.73L47.255 68.69Z" fill="#7F7F7F"/>
                                      <path d="M61.39 70V66.45H62.095L63.185 68.47H63.195L64.285 66.45H64.99V70H64.29V67.805H64.28L63.415 69.33H62.96L62.1 67.8H62.09V70H61.39Z" fill="#7F7F7F"/>
                                      <path d="M77.09 70.035C76.9 70.035 76.7183 69.9967 76.545 69.92C76.3717 69.84 76.2283 69.7267 76.115 69.58L76.52 69.08C76.6767 69.2767 76.8533 69.375 77.05 69.375C77.3333 69.375 77.475 69.1733 77.475 68.77V66.45H78.18V68.765C78.18 69.6117 77.8167 70.035 77.09 70.035Z" fill="#7F7F7F"/>
                                      <path d="M92.09 70.035C91.9 70.035 91.7183 69.9967 91.545 69.92C91.3717 69.84 91.2283 69.7267 91.115 69.58L91.52 69.08C91.6767 69.2767 91.8533 69.375 92.05 69.375C92.3333 69.375 92.475 69.1733 92.475 68.77V66.45H93.18V68.765C93.18 69.6117 92.8167 70.035 92.09 70.035Z" fill="#7F7F7F"/>
                                      <path d="M105.08 70L106.395 66.45H107.075L108.395 70H107.66L107.415 69.29H106.055L105.815 70H105.08ZM106.255 68.69H107.215L106.74 67.3H106.73L106.255 68.69Z" fill="#7F7F7F"/>
                                      <path d="M120.385 70V66.45H121.665C122.042 66.45 122.33 66.5233 122.53 66.67C122.73 66.8167 122.83 67.0317 122.83 67.315C122.83 67.4883 122.787 67.6467 122.7 67.79C122.613 67.9333 122.478 68.04 122.295 68.11V68.12C122.525 68.1767 122.702 68.2833 122.825 68.44C122.948 68.5933 123.01 68.7867 123.01 69.02C123.01 69.3133 122.91 69.55 122.71 69.73C122.51 69.91 122.22 70 121.84 70H120.385ZM121.08 67.9H121.61C121.95 67.9 122.12 67.7617 122.12 67.485C122.12 67.2083 121.94 67.07 121.58 67.07H121.08V67.9ZM121.08 69.38H121.74C121.91 69.38 122.042 69.3417 122.135 69.265C122.232 69.1883 122.28 69.0767 122.28 68.93C122.28 68.7867 122.228 68.675 122.125 68.595C122.022 68.5117 121.87 68.47 121.67 68.47H121.08V69.38Z" fill="#7F7F7F"/>
                                      <path d="M137.05 70.045C136.517 70.045 136.082 69.875 135.745 69.535C135.408 69.1917 135.24 68.755 135.24 68.225C135.24 67.695 135.408 67.26 135.745 66.92C136.082 66.5767 136.517 66.405 137.05 66.405C137.573 66.405 138.005 66.575 138.345 66.915C138.685 67.2517 138.855 67.6883 138.855 68.225C138.855 68.7617 138.685 69.2 138.345 69.54C138.005 69.8767 137.573 70.045 137.05 70.045ZM137.05 69.385C137.357 69.385 137.612 69.275 137.815 69.055C138.018 68.835 138.12 68.5583 138.12 68.225C138.12 67.8917 138.018 67.615 137.815 67.395C137.612 67.175 137.357 67.065 137.05 67.065C136.733 67.065 136.473 67.1767 136.27 67.4C136.07 67.6233 135.97 67.8983 135.97 68.225C135.97 68.5517 136.07 68.8267 136.27 69.05C136.473 69.2733 136.733 69.385 137.05 69.385Z" fill="#7F7F7F"/>
                                      <path d="M150.385 70V66.45H151.095V67.985H152.545V66.45H153.25V70H152.545V68.61H151.095V70H150.385Z" fill="#7F7F7F"/>
                                      <path d="M167.05 70.045C166.517 70.045 166.082 69.875 165.745 69.535C165.408 69.1917 165.24 68.755 165.24 68.225C165.24 67.695 165.408 67.26 165.745 66.92C166.082 66.5767 166.517 66.405 167.05 66.405C167.573 66.405 168.005 66.575 168.345 66.915C168.685 67.2517 168.855 67.6883 168.855 68.225C168.855 68.7617 168.685 69.2 168.345 69.54C168.005 69.8767 167.573 70.045 167.05 70.045ZM167.05 69.385C167.357 69.385 167.612 69.275 167.815 69.055C168.018 68.835 168.12 68.5583 168.12 68.225C168.12 67.8917 168.018 67.615 167.815 67.395C167.612 67.175 167.357 67.065 167.05 67.065C166.733 67.065 166.473 67.1767 166.27 67.4C166.07 67.6233 165.97 67.8983 165.97 68.225C165.97 68.5517 166.07 68.8267 166.27 69.05C166.473 69.2733 166.733 69.385 167.05 69.385Z" fill="#7F7F7F"/>
                                      </svg>

                               </div>
                               <div class="cardfoot eui">
                                   <a >explore your facilities <br>
                                      combined energy sources <img src="assets/images/lightarrow.svg" alt="arrow"/></a>
                               </div>
                           </div>

                       </div> -->

                       <div class="col-md-3 mb-4">
                           <div class="card border-radius5 p-3">
                               <div class="cardupper">
                                   <img src="assets/images/ElectricMeter.png" alt="ElectricMeter"/>
                               </div>
                               <div class="cardfoot border-top">
                                   <a routerLink="/utilities/electricity" >Electricity <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                               </div>
                           </div>

                       </div>

                       <div class="col-md-3 mb-4">
                           <div class="card border-radius5 p-3">
                               <div class="cardupper">
                                   <img src="assets/images/gasmeter.png" alt="gasmeter"/>
                               </div>
                               <div class="cardfoot border-top">
                                   <a  routerLink="/utilities/naturalgas" >Natural Gas <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                               </div>
                           </div>

                       </div>
                       <!-- <div class="col-md-3 mb-4">
                          <div class="subheadingBx"></div>
                           <div class="card border-radius5 p-3">
                               <div class="cardupper">
                                   <img src="assets/images/watermeter.png" alt="watermeter"/>
                               </div>
                               <div class="cardfoot border-top">
                                   <a >Water <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                               </div>
                           </div>

                       </div> -->



                       <!-- <div class="col-md-3">
                          <div class="subheadingBx"><h3 class="subheading">Energy Star</h3></div>
                           <div class="card border-radius5 p-3">
                               <div class="cardupper">
                                <img src="assets/images/espm_logo.png" alt="Portfolio"/>

                               </div>
                               <div class="cardfoot border-top">
                                   <a >Portfolio Manager <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                               </div>
                           </div>

                       </div> -->

                       <!-- <div class="col-md-3">
                          <div class="subheadingBx"><h3 class="subheading">Alternatives</h3></div>
                           <div class="card border-radius5 p-3">
                               <div class="cardupper">
                                   <img src="assets/images/PolySolarPanel.png" alt="PolySolarPanel"/>
                               </div>
                               <div class="cardfoot border-top">
                                   <a >Solar <img src="assets/images/expand_right.svg" alt="arrow"/></a>



                               </div>
                           </div>

                       </div> -->

                       <!-- <div class="col-md-3">
                          <div class="subheadingBx"></div>
                           <div class="card border-radius5 p-3">
                               <div class="cardupper">
                                   <img src="assets/images/HeatPump.png" alt="HeatPump"/>
                               </div>
                               <div class="cardfoot border-top">
                                   <a >CHP <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                               </div>
                           </div>

                       </div> -->

                       <!-- <div class="col-md-3">
                          <div class="subheadingBx"></div>
                           <div class="card border-radius5 p-3">
                               <div class="cardupper">
                                   <img src="assets/images/CHP-Unit.png" alt="CHP-Unit"/>
                               </div>
                               <div class="cardfoot border-top">
                                   <a >Heat Pumps <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                               </div>
                           </div>

                       </div> -->
                   </div>
               </div>
           </div>
       </div>
   </div>
</div>