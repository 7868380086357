import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-natural-gas',
  templateUrl: './natural-gas.component.html',
  styleUrls: ['./natural-gas.component.scss']
})
export class NaturalGasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
