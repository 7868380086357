import { Component, OnInit } from '@angular/core';

import { DashboardDataService } from '../services/dashboard-data/dashboard-data.service';

import type { EChartsOption } from 'echarts';

const Options = {
  main: 'main',
  ext1: 'ext1',
  ext2: 'ext2',
  ext3: 'ext3',
  ext4: 'ext4',
  overview: 'overview',
  I1: 'I1',
  I2: 'I2',
  I3: 'I3'
} as const;

type KeyOptions = keyof typeof Options;

function getLineChartConfig(
  data: number[],
  max?: number,
  formatter?: (value: number) => string
): EChartsOption {
  return {
    xAxis: {
      type: 'category',
      data,
      boundaryGap: false,
      axisLine: { lineStyle: { color: '#ECE9F1', width: 1 } },
      axisLabel: { show: false },
      axisPointer: { show: false }
    },
    yAxis: {
      type: 'value',
      alignTicks: false,
      boundaryGap: [0, 0],
      minorTick: { show: false },
      animationEasing: 'linear',
      max,
      min: 0,
      splitLine: {
        show: true,
        lineStyle: {
          color: '#1FAB3D',
          width: 1,
          type: 'dotted'
        }
      },
      axisLabel: {
        formatter: (value: number) => {
          return formatter?.(value) ?? `${value}`;
        }
      }
    },
    series: [
      {
        data,
        type: 'line',
        showSymbol: false,
        smooth: true,
        lineStyle: {
          color: '#1FAB3D',
          width: 3,
          type: 'solid'
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              { offset: 0, color: 'rgba(31, 171, 61, 1)' },
              { offset: 0.3, color: 'rgba(138, 223, 156, 1)' },
              // { offset: 0.5, color: 'rgba(138, 223, 156, 1)' },
              // { offset: 0.7, color: 'rgba(165, 235, 180, 1)' },
              { offset: 1, color: 'rgba(255, 255, 255, 1)' }
            ]
          }
        },
        universalTransition: { divideShape: 'clone' }
      }
    ],
    stateAnimation: { duration: 300, easing: 'cubicOut' },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        label: { backgroundColor: '#505765' },
        axis: 'y',
        animationEasingUpdate: 'exponentialOut',
        animationDurationUpdate: 200,
        crossStyle: { color: '#999', width: 1, type: 'dashed' },
        lineStyle: { color: '#1FAB3D' },
        shadowStyle: { color: '#1FAB3D' }
      },
      borderColor: '#1FAB3D',
      show: true,
      showContent: true,
      z: 60,
      triggerOn: 'mousemove|click',
      displayMode: 'single',
      renderMode: 'auto',
      backgroundColor: '#fff',
      shadowBlur: 10,
      shadowColor: 'rgba(0, 0, 0, 0.2)',
      shadowOffsetX: 1,
      shadowOffsetY: 2,
      borderRadius: 4,
      borderWidth: 1,
      textStyle: { color: '#666', fontSize: 14 },
      formatter: (params: any) => {
        const { value } = params[0];
        return formatter?.(value) ?? value;
      }
    }
  };
}

@Component({
  selector: 'app-raw-data',
  templateUrl: './raw-data.component.html',
  styleUrls: ['./raw-data.component.scss']
})
export class RawDataComponent implements OnInit {
  public toggleOptions: {
    label: string;
    options: { label: string | null; id: KeyOptions }[];
  }[] = [
    { label: 'Overview', options: [{ label: null, id: Options.overview }] },
    {
      label: 'Gateway Ports',
      options: [{ label: null, id: Options.main }]
    },
    {
      label: 'Expansions',
      options: [
        { label: 'A', id: Options.ext1 },
        { label: 'B', id: Options.ext2 },
        { label: 'C', id: Options.ext3 },
        { label: 'D', id: Options.ext4 }
      ]
    }
  ];

  public data: { [K in KeyOptions]: number[] } = {
    overview: [],
    main: [],
    ext1: [],
    ext2: [],
    ext3: [],
    ext4: [],
    I1: [],
    I2: [],
    I3: []
  };
  public expansionsData: Record<string, Record<string, number[]>> = {};

  public selectedOption: KeyOptions = this.toggleOptions[0].options[0].id;

  public aggregatedChartConfig: EChartsOption;
  public aggregatedChartLabel = '';
  public aggregatedDataValue = '';

  public meters: string[] = [];
  public meterId: null | string = null;

  public extraCards: { label: string; config: EChartsOption; value: string }[] =
    [];

  public isMeterIdEditing = false;

  private liveMeterSubs: null | NodeJS.Timeout = null;

  constructor(private dashboardDataService: DashboardDataService) {
    const panelsSubs = this.dashboardDataService
      .getPanels('')
      .subscribe((panels: any) => {
        panels.results.forEach((d: { meter_name: string | null }) => {
          if (d.meter_name) this.meters.push(d.meter_name);
        });

        if (!this.meterId) this.meterId = this.meters[0];

        this.getLastMeterData();

        panelsSubs.unsubscribe();
      });
  }

  getLastMeterData() {
    if (this.meterId) {
      if (this.liveMeterSubs) {
        clearTimeout(this.liveMeterSubs);
        this.liveMeterSubs = null;
      }

      this.data = {
        overview: [],
        main: [],
        ext1: [],
        ext2: [],
        ext3: [],
        ext4: [],
        I1: [],
        I2: [],
        I3: []
      };

      this.expansionsData = {};

      this.aggregatedChartLabel = '';
      this.aggregatedDataValue = '';

      this.aggregatedChartConfig = getLineChartConfig([]);
      this.extraCards = [];

      const lastMeterDataSubs = this.dashboardDataService
        .fetchLastMeterData(this.meterId)
        .subscribe(
          (
            results: Array<
              Record<
                KeyOptions,
                {
                  id: number;
                  meter_name: string;
                  expansion_id: string;
                  data: Record<string, number>;
                  rawData?: Record<string, number>;
                }
              >
            >
          ) => {
            results.forEach(result => this.getValues(result));
            this.getAggregatedChartConfig();
            lastMeterDataSubs.unsubscribe();

            this.getLiveMeterData();
          }
        );
    }
  }

  getLiveMeterData() {
    if (this.meterId) {
      const subs = this.dashboardDataService
        .fetchLatestMeterData(this.meterId)
        .subscribe(
          (
            result: Record<
              KeyOptions,
              {
                id: number;
                meter_name: string;
                expansion_id: string;
                data: Record<string, number>;
                rawData?: Record<string, number>;
              }
            >
          ) => {
            this.getValues(result);
            this.getAggregatedChartConfig();

            subs.unsubscribe();

            this.liveMeterSubs = setTimeout(
              () => this.getLiveMeterData(),
              2000
            );
          }
        );
    }
  }

  getValues(
    result: Record<
      KeyOptions,
      {
        id: number;
        meter_name: string;
        expansion_id: string;
        data: Record<string, number>;
        rawData?: Record<string, number>;
      }
    >
  ) {
    const output: Record<KeyOptions, number> = {
      overview: 0,
      main: 0,
      ext1: 0,
      ext2: 0,
      ext3: 0,
      ext4: 0,
      I1: 0,
      I2: 0,
      I3: 0
    };

    const expansions: Record<string, Record<string, number>> = {};

    Object.entries(result).forEach(([key, value]) => {
      Object.entries(value.rawData ?? value.data).forEach(([newKey, d]) => {
        if (key !== Options.main) {
          output.overview += d;
          output[key as KeyOptions] += d;

          if (!expansions[key]) expansions[key] = {};
          if (!expansions[key][newKey]) expansions[key][newKey] = 0;

          expansions[key][newKey] += d;
        }

        if (key === Options.main) {
          if (newKey === 'I1' || newKey === 'I2' || newKey === 'I3') {
            output['main'] += d;
            output[newKey] += d;
          }
        }
      });
    });

    Object.entries(output).forEach(([key, value]) => {
      this.data[key as KeyOptions].push(value);
    });

    Object.entries(expansions).forEach(([key, value]) => {
      Object.entries(value).forEach(([newKey, d]) => {
        if (!this.expansionsData[key]) this.expansionsData[key] = {};

        if (!this.expansionsData[key][newKey]) {
          this.expansionsData[key][newKey] = [];
        }

        this.expansionsData[key][newKey].push(d);
      });
    });
  }

  ngOnInit(): void {}

  customToFixed(value: any, precision: number = 3, isPercent: boolean = false) {
    if (value === null || value === undefined) return value;

    let decimalVal = value.toLocaleString(undefined, {
      maximumFractionDigits: precision
    });

    if (isPercent == true && Number(decimalVal) > 100) {
      decimalVal = 100;
    }

    return decimalVal;
  }

  getAggregatedChartConfig() {
    const data = this.data[this.selectedOption];

    this.aggregatedChartConfig = getLineChartConfig(data, undefined, value =>
      this.customToFixed(value)
    );
    this.getAggregatedChartLabel();
    this.aggregatedDataValue = this.customToFixed(data[data.length - 1]);

    if (this.selectedOption === 'overview') {
      this.extraCards = [
        {
          label: 'Exp A',
          config: getLineChartConfig(this.data['ext1'], undefined, value =>
            this.customToFixed(value)
          ),
          value: this.customToFixed(
            this.data['ext1'][this.data['ext1'].length - 1]
          )
        },
        {
          label: 'Exp B',
          config: getLineChartConfig(this.data['ext2'], undefined, value =>
            this.customToFixed(value)
          ),
          value: this.customToFixed(
            this.data['ext2'][this.data['ext2'].length - 1]
          )
        },
        {
          label: 'Exp C',
          config: getLineChartConfig(this.data['ext3'], undefined, value =>
            this.customToFixed(value)
          ),
          value: this.customToFixed(
            this.data['ext3'][this.data['ext3'].length - 1]
          )
        },
        {
          label: 'Exp D',
          config: getLineChartConfig(this.data['ext4'], undefined, value =>
            this.customToFixed(value)
          ),
          value: this.customToFixed(
            this.data['ext4'][this.data['ext4'].length - 1]
          )
        }
      ];
    } else if (this.selectedOption === 'main') {
      this.extraCards = [
        {
          label: 'Port I1',

          config: getLineChartConfig(this.data['I1'], undefined, value =>
            this.customToFixed(value)
          ),
          value: this.customToFixed(this.data['I1'][this.data['I1'].length - 1])
        },
        {
          label: 'Port I2',

          config: getLineChartConfig(this.data['I2'], undefined, value =>
            this.customToFixed(value)
          ),
          value: this.customToFixed(this.data['I2'][this.data['I2'].length - 1])
        },
        {
          label: 'Port I3',

          config: getLineChartConfig(this.data['I3'], undefined, value =>
            this.customToFixed(value)
          ),
          value: this.customToFixed(this.data['I3'][this.data['I3'].length - 1])
        }
      ];
    } else if (this.selectedOption.includes('ext')) {
      this.extraCards = [];

      for (let i = 0; i < 16; i++) {
        const data = !!this.expansionsData[this.selectedOption]?.[`I${i + 1}`]
          ? this.expansionsData[this.selectedOption][`I${i + 1}`]
          : [0];

        this.extraCards.push({
          label: `Port ${i + 1}`,
          config: getLineChartConfig(data, undefined, value =>
            this.customToFixed(value)
          ),
          value: this.customToFixed(data[data.length - 1])
        });
      }
    } else {
      this.extraCards = [];
    }
  }

  ngOnDestroy(): void {
    if (this.liveMeterSubs) {
      clearTimeout(this.liveMeterSubs);
      this.liveMeterSubs = null;
    }
  }

  handleOptionClick(_: Event, id: KeyOptions) {
    this.selectedOption = id;
    this.getAggregatedChartConfig();
  }

  getAggregatedChartLabel() {
    this.aggregatedChartLabel =
      this.selectedOption === Options.main ||
      this.selectedOption === Options.overview
        ? 'Gateway - Aggregated'
        : `Expansions ${String.fromCharCode(
            64 + parseInt(this.selectedOption.replace('ext', ''))
          )} - Aggregated`;
  }

  handleValueChange($event: Event): void {
    this.meterId = ($event.target as HTMLSelectElement).value;
    this.isMeterIdEditing = false;
    this.getLastMeterData();
  }

  trackByFn(_: number, e: any) {
    return e.label;
  }
}
