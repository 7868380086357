import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, Observable, of, BehaviorSubject } from 'rxjs';
import { Building } from '../../models/building';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DashboardDataService {
  private currentBuildingSubscription: Subscription;
  private _currentBuilding: Building;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParamMap.subscribe(params => {
      this.currentBuildingSubscription = this.watchCurrentBuilding().subscribe(
        (building: Building) => {
          if (building != null) {
            this._currentBuilding = building;
            const queryParams: Params = {
              building_id: this._currentBuilding.idbuildings
            };
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: queryParams,
              queryParamsHandling: 'merge' // remove to replace all query params by provided
            });
          }
        }
      );
      if (this._currentBuilding == null) {
        this.requestBuildings().subscribe(
          (data: any) => {
            this.buildings.next(data);
            if (!this._currentBuilding && params.get('building_id')) {
              data.forEach((building: Building) => {
                if (
                  building != null &&
                  building.idbuildings == params.get('building_id')
                ) {
                  this.setCurrentBuilding(building);
                }
              });
            }
            if (!this._currentBuilding) {
              this.currentBuilding.next(data[0]);
            }
            const queryParams: Params = {
              building_id: this._currentBuilding.idbuildings
            };
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: queryParams,
              queryParamsHandling: 'merge' // remove to replace all query params by provided
            });

            //this.setCurrentBuilding(data[0]);
          },
          (err: any) => {
            localStorage.removeItem('auth_token');
            window.location.reload();
            console.log(err);
          }
        );
      }
    });
  }

  ngOnDestroy(): void {
    // this.currentBuildingSubscription.unsubscribe();
  }

  baseURL = environment.backendBaseAddress + '/';
  buildingsUrl = 'buildings/';
  panelsUrl = 'panels/';
  panelsOverviewUrl = 'panel_overview/';
  circuitsOverviewUrl = 'circuits_overview/';
  circuitsCategoryOverview = 'circuits_category_overview/';
  circuitsUrl = 'circuits/';
  circuitCyclesUrl = 'circuit_cycles/';
  panelScheduleReport = 'panel_report/building/';
  operatinghourUrl = 'operatingHours/';
  circuitCategoryUrl = 'circuit_categories/';
  buildings = new BehaviorSubject<any>(null);
  currentBuilding = new BehaviorSubject<any>(null);
  panelExpansion = 'panel_expansions/';
  panelMeterCreate = 'panel_meters/';
  fetchLiveMeter =
    'https://qm5jcxb08b.execute-api.us-east-1.amazonaws.com/default/TelemetryAPI';
  energy_data = 'energy_data/';
  latest = 'latest/';
  oldest = 'oldest/';
  analytics = 'analytics/';
  energyDataRaw = this.panelsUrl + 'download/';
  voltageType = 'voltage_type/';
  current_ct_clamp = 'current_ct_clamp/';
  weatherApiUrl = 'https://api.weatherapi.com/v1/history.json';
  currentWeatherApiUrl = 'https://api.weatherapi.com/v1/current.json';
  weatherApiKey = environment.weatherApiKey;

  fetchLiveMeterAPI(expansionID: any, meterName: any): Observable<any> {
    let deviceId = meterName;
    const httpOptions = {
      params: { meter_name: deviceId, expansion_id: expansionID }
    };
    return this.http.get<any>(
      this.baseURL + this.energy_data + this.latest,
      httpOptions
    );
  }

  fetchOldestMeterRecord(meterName: any): Observable<any> {
    let deviceId = meterName;
    const httpOptions = {
      params: { meter_name: deviceId }
    };
    return this.http.get<any>(
      this.baseURL + this.energy_data + this.oldest,
      httpOptions
    );
  }

  fetchLatestMeterData(meterName: any): Observable<any> {
    let deviceId = meterName;
    const httpOptions = {
      params: { meter_name: deviceId }
    };
    return this.http.get<any>(
      this.baseURL + this.energy_data + this.latest,
      httpOptions
    );
  }

  fetchLastMeterData(meter_name: string, limit: number = 30): Observable<any> {
    const httpOptions = { params: { meter_name, limit } };

    return this.http.get<any>(
      this.baseURL + this.energy_data + this.latest,
      httpOptions
    );
  }

  getPanelExpansion(panelId: any): Observable<any> {
    return this.http.get<any>(this.baseURL + this.panelExpansion, {
      params: { panel_id: panelId }
    });
  }

  createMeter(panelId: any, meter_name: any, meterPhase: any): Observable<any> {
    const body: any = {
      panel: panelId,
      name: meter_name,
      number_of_phases: meterPhase
    };

    return this.http.post<any>(this.baseURL + this.panelMeterCreate, body);
  }

  updateMeterName(
    panelId: any,
    meter_name: any,
    meterId: any,
    meterPhase: any
  ): Observable<any> {
    const body: any = {
      panel: panelId,
      name: meter_name,
      number_of_phases: meterPhase
    };

    return this.http.patch<any>(
      this.baseURL + this.panelMeterCreate + meterId + '/',
      body
    );
  }

  updatePanelMeterPortVoltageCoefficient(
    meterId: number,
    voltage_coefficients: Record<string, number>
  ): Observable<any> {
    const body = { voltage_coefficients };

    return this.http.patch<any>(
      this.baseURL + this.panelMeterCreate + meterId + '/',
      body
    );
  }

  updatePanelMeterPortVoltageTransformers(
    meterId: number,
    voltage_transformers: Record<string, string>
  ): Observable<any> {
    const body = { voltage_transformers };

    return this.http.patch<any>(
      this.baseURL + this.panelMeterCreate + meterId + '/',
      body
    );
  }

  updatePanelMeterType(panelId: any, meter_type: any): Observable<any> {
    const body = { meter_type };

    return this.http.patch<any>(
      this.baseURL + this.panelsUrl + panelId + '/',
      body
    );
  }

  updatePanelLoadBalancing(
    panelId: string,
    load_balancing: string | null
  ): Observable<any> {
    const body = { load_balancing };

    return this.http.patch<any>(
      this.baseURL + this.panelsUrl + panelId + '/',
      body
    );
  }

  updatePanelInfo(panelId: any, body: object): Observable<any> {
    return this.http.patch<any>(
      this.baseURL + this.panelsUrl + panelId + '/',
      body
    );
  }

  updateMeterVoltageReference(
    meterId: any,
    voltage_port_number: string,
    voltage_refrence_type: string
  ): Observable<any> {
    const body = {
      voltage_port_number: voltage_port_number,
      voltage_refrence_type: voltage_refrence_type
    };
    return this.http.patch<any>(
      this.baseURL + this.panelMeterCreate + meterId + '/',
      body
    );
  }

  updateMeterVoltageReferencePanel(
    meterId: any,
    reference_meter: any
  ): Observable<any> {
    const body = { reference_meter: reference_meter, artificial_voltage: {} };
    return this.http.patch<any>(
      this.baseURL + this.panelMeterCreate + meterId + '/',
      body
    );
  }
  updateMeterVoltageType(meterId: any, voltage_type: any): Observable<any> {
    const body = { voltage_type: voltage_type, reference_meter: null };
    return this.http.patch<any>(
      this.baseURL + this.panelMeterCreate + meterId + '/',
      body
    );
  }

  updateMeterCtClamp(
    meterId: any,
    ct_clamp: any,
    ct_clamp2: any,
    ct_clamp3: any
  ): Observable<any> {
    const body = {
      current_ct_clamp: ct_clamp,
      current_ct_clamp2: ct_clamp2,
      current_ct_clamp3: ct_clamp3
    };
    return this.http.patch<any>(
      this.baseURL + this.panelMeterCreate + meterId + '/',
      body
    );
  }

  updateMeterCircuit(
    expressionId: any,
    panelId: any,
    bodyValue: any
  ): Observable<any> {
    // console.log(expressionId)
    const body = { panel_id: panelId, expansions: bodyValue };
    // const body = { panel_id: panelId, circuit_no: circuitNumber , voltage_refrence_type:voltage_refrence , voltage_refrence_value:voltage_refrence_value};
    return this.http.patch<any>(
      this.baseURL + this.panelExpansion + panelId + '/',
      body
    );
  }

  updateMeterCurrentCtClamp(panelId: any, bodyValue: any): Observable<any> {
    const body = { panel_id: panelId, expansions: bodyValue };
    return this.http.patch<any>(
      this.baseURL + this.panelExpansion + panelId + '/',
      body
    );
  }

  updateCircuitPower(panelId: any, expansionList: any): Observable<any> {
    const body = { panel_id: panelId, expansions: expansionList };
    return this.http.patch<any>(
      this.baseURL + this.panelExpansion + panelId + '/',
      body
    );
  }

  updatePanelMeterArtificialVoltage(
    meterId: number,
    artificial_voltage: Record<string, string>
  ): Observable<any> {
    const body = { artificial_voltage };

    return this.http.patch<any>(
      this.baseURL + this.panelMeterCreate + meterId + '/',
      body
    );
  }

  getBuildings(): Observable<any> {
    return this.http.get<any>(this.baseURL + this.buildingsUrl);
  }

  getBuildingInsights(building_id: string): Observable<any> {
    return this.http.get<any>(
      this.baseURL + this.buildingsUrl + building_id + '/insights/'
    );
  }

  watchBuildings(): Observable<any> {
    return this.buildings.asObservable();
  }

  requestBuildings(): any {
    return this.http
      .get<any>(this.baseURL + this.buildingsUrl)
      .pipe(map(response => (response.results as Building[]) || []));
  }

  watchCurrentBuilding(): Observable<any> {
    return this.currentBuilding.asObservable();
  }

  /* TODO: This code reeks, and is going to be a problem in the future */
  setCurrentBuilding(building: Building): void {
    this.currentBuilding.next(building);
  }

  getCurrentBuilding(): Building | null {
    if (this._currentBuilding) return this._currentBuilding;
    else return null;
  }

  getPanels(buildingId: any): Observable<any> {
    return this.http.get<any>(this.baseURL + this.panelsUrl, {
      params: { building_id: buildingId }
    });
  }
  getCircuits(panelId: any): Observable<any> {
    return this.http.get<any>(this.baseURL + this.circuitsUrl, {
      params: { panel_id: panelId }
    });
  }

  getCircuit(id: any): Observable<any> {
    return this.http.get<any>(this.baseURL + this.circuitsUrl + id + '/');
  }

  updateCircuit(id: any, is_monitored: boolean): Observable<any> {
    return this.http.patch<any>(this.baseURL + this.circuitsUrl + id + '/', {
      is_monitored: is_monitored
    });
  }
  updateCircuitDetails(id: any, field: string, value: string): Observable<any> {
    return this.http.patch<any>(this.baseURL + this.circuitsUrl + id + '/', {
      [field]: value
    });
  }
  getCircuitCategory(buildingId: any): Observable<any> {
    return this.http.get<any>(
      this.baseURL + this.circuitCategoryUrl + 'circuits_grouped/',
      { params: { building_id: buildingId } }
    );
  }

  getBuildingSchedule(buildingId: any): Observable<any> {
    return this.http.get<any>(
      this.baseURL + 'building/' + buildingId + '/schedule/'
    );
  }

  getOperatingHours(
    buildingId: string,
    start_date: string,
    end_date: string
  ): Observable<any> {
    const params = { start_date, end_date };

    return this.http.get<any>(
      this.baseURL + 'buildings/' + buildingId + '/operating_hours',
      { params }
    );
  }

  getWeatherForecast(
    location: String,
    start_date: String,
    end_date: String
  ): Observable<any> {
    return this.http.get<any>(
      `${this.weatherApiUrl}?key=${this.weatherApiKey}&q=${location}&dt=${start_date}&end_dt=${end_date}`
    );
  }
  getWeatherCurrent(location: String): Observable<any> {
    return this.http.get<any>(
      `${this.currentWeatherApiUrl}?key=${this.weatherApiKey}&q=${location}`
    );
  }

  addEvents(buildingId: any, params: any): Observable<any> {
    return this.http.post<any>(this.baseURL + this.operatinghourUrl, params, {
      params: { building_id: buildingId }
    });
  }

  updateEvents(id: any, buildingId: any, params: any): Observable<any> {
    // const body = { start_time: start_time || '',end_time:end_time || '',event_date:date|| '' };
    return this.http.put<any>(
      this.baseURL + this.operatinghourUrl + id + '/',
      params,
      { params: { building_id: buildingId } }
    );
  }

  removeEvent(id: any, building_id: any): Observable<any> {
    return this.http.delete<any>(
      this.baseURL + this.operatinghourUrl + id + '/',
      { params: { building_id: building_id } }
    );
  }

  deleteEvent(id: any, ischeck: any): Observable<any> {
    return this.http.delete<any>(
      this.baseURL + this.operatinghourUrl + id + '/',
      { params: { is_repeat: ischeck } }
    );
  }

  downloadPanelScheduleReport(buildingId: any) {
    let url =
      this.baseURL + this.panelScheduleReport + buildingId + '/' + 'download/';
    window.open(url, '_blank');
    //return this.http.get(url, { responseType: 'blob' });
  }

  emailEnergyReport(building: Building, date: any, is_circuit:boolean, generate_daily: boolean): Observable<any> {
    let building_id = building.idbuildings;
    let url = '';
    if (date.day == null) {
      url =
        this.baseURL +
        this.panelScheduleReport +
        building_id +
        '/email/' +
        '?year=' +
        date.year +
        '&month=' +
        date.month +
        '&is_circuit=' +
        is_circuit +
        '&generate_daily=' +
        generate_daily;
    } else {
      url =
        this.baseURL +
        this.panelScheduleReport +
        building_id +
        '/email/' +
        '?year=' +
        date.year +
        '&month=' +
        date.month +
        '&day=' +
        date.day+
        '&is_circuit=' +
        is_circuit +
        '&generate_daily=' +
        generate_daily;
    }
    return this.http.get(url, { responseType: 'blob' });
  }

  getPanelsOverview(panelId: any): Observable<any> {
    return this.http.get<any>(this.baseURL + this.panelsOverviewUrl, {
      params: { panel_id: panelId }
    });
  }
  getCircuitsOverview(panelId: any): Observable<any> {
    return this.http.get<any>(this.baseURL + this.circuitsOverviewUrl, {
      params: { panel_id: panelId }
    });
  }
  getCircuitsCategoryOverview(
    building_id: any,
    circuit_category_id: any
  ): Observable<any> {
    return this.http.get<any>(this.baseURL + this.circuitsCategoryOverview, {
      params: {
        building_id: building_id,
        circuit_category_id: circuit_category_id
      }
    });
  }

  fetchMeterAnalyticsData(
    meterName: any,
    timestamp__gte: any,
    grouping: any,
    timestamp__lt: any
  ): Observable<any> {
    const httpOptions = {
      params: {
        meter_name: meterName,
        timestamp__gte: timestamp__gte,
        grouping: grouping,
        timestamp__lt: timestamp__lt
      }
    };
    return this.http.get<any>(
      this.baseURL + this.energy_data + this.analytics,
      httpOptions
    );
  }

  downloadRawEnergyData(
    start_date: Date,
    end_date: Date,
    building: Building,
    unit: string,
    circuitNeeded: boolean,
    panel_id: string,
    interval: string
  ): Observable<any> {
    let params = {
      start_date: start_date.toISOString(),
      end_date: end_date.toISOString(),
      building_id: building.idbuildings,
      unit: unit,
      circuit_needed: circuitNeeded,
      panel_id: panel_id,
      interval: interval
    };
    return this.http.get(this.baseURL + this.energyDataRaw, {
      params: params,
      responseType: 'blob'
    });
  }

  getBuildingsByUsage(
    buildingId: string,
    start_date: Date,
    end_date: Date
  ): Observable<any> {
    const params = {
      start_date: start_date.toISOString(),
      end_date: end_date.toISOString()
    };

    return this.http.get(this.baseURL + 'buildings/' + buildingId + '/usage/', {
      params
    });
  }

  getEcms(buildingId: string): Observable<any> {
    return this.http.get(this.baseURL + 'buildings/' + buildingId + '/ecms/');
  }

  getEcmsAcceptedOrUnReviewed(scan_id: number): Observable<any> {
    return this.http.get(this.baseURL + 'ecm_scans/' + scan_id + '/ecm_selections/?status__in=unreviewed,accepted');
  }

  getEcmsRejected(scan_id: number): Observable<any> {
    return this.http.get(this.baseURL + 'ecm_scans/' + scan_id + '/ecm_selections/?status__in=rejected');
  }

  getVoltageType(): Observable<any> {
    return this.http.get<any>(this.baseURL + this.voltageType);
  }
  getCurrentCTClamp(): Observable<any> {
    return this.http.get<any>(this.baseURL + this.current_ct_clamp);
  }

  updateCircuitGroup(circuit_id: any, circuit_group: string): Observable<any> {
    return this.http.patch<any>(
      this.baseURL + this.circuitsUrl + circuit_id + '/',
      { circuit_group: circuit_group }
    );
  }

  getDeviceCommands(): Observable<any> {
    return this.http.get(this.baseURL + 'device_commands');
  }

  updatePanelDeviceCommand(command: number, meter: number): Observable<any> {
    return this.http.post(this.baseURL + 'panel_device_commands/', {
      command,
      meter
    });
  }

  getPanelDeviceCommandByMeter(meter: number): Observable<any> {
    return this.http.get(
      this.baseURL + 'panel_device_commands/?meter=' + meter
    );
  }

  getPanelMeterConnectivity(
    meter_id: number,
    start_date: Date,
    end_date: Date
  ): Observable<any> {
    const params = {
      start_date: start_date.toISOString(),
      end_date: end_date.toISOString()
    };

    return this.http.get(
      this.baseURL + this.panelMeterCreate + meter_id + '/connectivity/',
      { params }
    );
  }

  getCircuitCycles(circuitId: any, month:any): Observable<any> {
    const params = {
      circuit_id: circuitId,
      month: month
    };
    return this.http.get<any>(this.baseURL + this.circuitCyclesUrl, { params });
  }

  getCircuitsByBuilding(buildingId: any): Observable<any> {
    return this.http.get<any>(this.baseURL + this.buildingsUrl + buildingId + '/circuits/');
  }

  getCircuitInfo(circuitId: any): Observable<any> {
    return this.http.get<any>(this.baseURL + this.circuitsUrl + circuitId + '/');
  }

  updateCircuitEcmStatus(circuitId: any, ecmId: any, status: any): Observable<any> {
    return this.http.patch<any>(this.baseURL + this.circuitsUrl + circuitId + '/update_ecm_status/', {
      ecm_id: ecmId,
      status: status
    });
  }

  getEcmScans(buildingId: any): Observable<any> {
    return this.http.get<any>(this.baseURL + this.buildingsUrl + buildingId + '/ecm_scans/');
  }

  getEcmScan(scanId: any): Observable<any> {
    return this.http.get<any>(this.baseURL + 'ecm_scans/' + scanId + '/ecm_selections/');
  }

  createEcmScan(scanData: any): Observable<any> {
    return this.http.post(`${this.baseURL}ecm_scans/`, scanData);
  }

  getPeakDemandHeatmapData(buildingId: any, month: Date): Observable<any> {
    const params = {
      month: month.toISOString()
    };
    return this.http.get<any>(this.baseURL + this.buildingsUrl + buildingId + '/heatmap/', { params });
  }
}
