import { Component, OnInit } from '@angular/core';
import { DashboardDataService } from '../services/dashboard-data/dashboard-data.service';
import { Building } from '../models/building';
import { Subscription } from 'rxjs';
import { saveAs } from 'file-saver';
import { Panel } from '../models/panel';
import { SettingService } from '../services/settings-service';
import { CompanyInformation } from '../models/account-settings/companyinfo';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-alert-reports',
  templateUrl: './alert-reports.component.html',
  styleUrls: ['./alert-reports.component.scss']
})
export class AlertReportsComponent implements OnInit {
  currentBuilding: Building;
  private currentBuildingSubscription: Subscription;
  panels: Panel[];
  selectedPanel: string | null = null;

  displayStyle = 'none';
  isShow = true;
  isShowDivIf = false;
  modal: string | null = null;

  energyReportSelection: string = 'month';
  energyReportSelectedMonth: Date = new Date();
  energyReportSelectedDay: Date = new Date();

  public wattSelection: boolean = true;
  public voltSelection: boolean = false;
  public allPanelsSelection = true;
  public allPanelsWithCircuitsSelection = false;
  public singlePanelsWithCircuitsSelection = false;
  public rangeSelectedDay = false;
  public rangeSelectedWeek = false;
  public rangeSelectedMonth = true;
  public intervalSelected = '60m';

  public isGenerating = false;
  public isError = false;

  public company: CompanyInformation | null = null;

  public energyReportTab = 'energyReport'; // circuitEnergyReport

  public energyCheckboxModel = false;

  constructor(
    private dashboardDataService: DashboardDataService,
    private settingService: SettingService,
    private toast: NgToastService
  ) {
    this.selectPreviousMonth();
    this.currentBuildingSubscription = this.dashboardDataService
      .watchCurrentBuilding()
      .subscribe((building: Building) => {
        if (building != null) {
          this.currentBuilding = building;
          this.dashboardDataService
            .getPanels(this.currentBuilding.idbuildings)
            .forEach((panels: any) => {
              this.panels = panels.results;
            });

          const subs = this.settingService
            .getCompanyById(this.currentBuilding.company_id)
            .subscribe(data => {
              this.company = data;
              subs.unsubscribe();
            });
        }
      });
  }

  ngOnInit(): void {}

  selectPreviousMonth() {
    let currentDate = this.energyReportSelectedMonth;
    const newYear = currentDate.getMonth() === 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    const newMonth = currentDate.getMonth() === 0 ? 11 : currentDate.getMonth() - 1;
    this.energyReportSelectedMonth = new Date(newYear, newMonth, 1);
  }

  selectNextMonth() {
    let currentDate = this.energyReportSelectedMonth;
    const newYear = currentDate.getMonth() === 11 ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
    const newMonth = currentDate.getMonth() === 11 ? 0 : currentDate.getMonth() + 1;
    const today = new Date();
    if (newYear === today.getFullYear() && newMonth === today.getMonth()) {
      return;
    }
    this.energyReportSelectedMonth = new Date(newYear, newMonth, 1);
  }

  selectPreviousDay() {
    let currentDate = this.energyReportSelectedDay;

    if (
      currentDate.getUTCFullYear() == 2022 &&
      currentDate.getMonth() == 7 &&
      currentDate.getDate() == 1
    )
      return;

    this.energyReportSelectedDay = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 1
    );
  }

  selectNextDay() {
    let currentDate = this.energyReportSelectedDay;
    let now = new Date();

    // current date should not be greater than today
    if (
      currentDate.getUTCFullYear() == now.getFullYear() &&
      currentDate.getMonth() == now.getMonth() &&
      currentDate.getDate() == now.getDate()
    )
      return;

    this.energyReportSelectedDay = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    );
  }

  rangeSelected(event: any) {
    if (event.target.id == 'rangeSelectorDay') {
      this.rangeSelectedDay = true;
      this.rangeSelectedWeek = false;
      this.rangeSelectedMonth = false;
      document.getElementById('rangeSelectorDay')?.classList.add('selected');
      document
        .getElementById('rangeSelectorWeek')
        ?.classList.remove('selected');
      document
        .getElementById('rangeSelectorMonth')
        ?.classList.remove('selected');
      this.intervalSelected = '60m';
    }
    // else if (event.target.id == 'rangeSelectorWeek'){
    //   this.rangeSelectedDay = false;
    //   this.rangeSelectedWeek = true;
    //   this.rangeSelectedMonth = false;
    //   document.getElementById('rangeSelectorWeek')?.classList.add('selected');
    //   document.getElementById('rangeSelectorDay')?.classList.remove('selected');
    //   document.getElementById('rangeSelectorMonth')?.classList.remove('selected');
    // }
    else if (event.target.id == 'rangeSelectorMonth') {
      this.rangeSelectedDay = false;
      this.rangeSelectedWeek = false;
      this.rangeSelectedMonth = true;
      document.getElementById('rangeSelectorMonth')?.classList.add('selected');
      document
        .getElementById('rangeSelectorWeek')
        ?.classList.remove('selected');
      document.getElementById('rangeSelectorDay')?.classList.remove('selected');
      this.intervalSelected = '60m';
    }
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  openScheduleModal() {
    this.modal = 'schedule';
  }

  openEnergyReportModal() {
    this.modal = 'energyReport';
  }
  openExportDataModal() {
    this.modal = 'exportData';
  }

  energyReportTabClicked(tab: string) {
    this.energyReportTab = tab;
    console.log(this.energyReportTab);
  }

  closePopup() {
    this.modal = null;
  }

  toggleDisplayDivIf() {
    this.isShowDivIf = !this.isShowDivIf;
  }

  toggleDisplay2() {
    this.isShow = !this.isShow;
  }

  downloadPanelScheduleReport() {
    this.dashboardDataService.downloadPanelScheduleReport('2cbf7a65');
  }

  emailEnergyReport(): void {
    this.isGenerating = true;
    this.isError = false;

    let date = {};
    if (this.rangeSelectedDay) {
      date = {
        year: this.energyReportSelectedDay.getFullYear(),
        month: this.energyReportSelectedDay.getMonth() + 1,
        day: this.energyReportSelectedDay.getDate()
      };
    } else if (this.rangeSelectedMonth) {
      date = {
        year: this.energyReportSelectedMonth.getFullYear(),
        month: this.energyReportSelectedMonth.getMonth() + 1,
        day: null
      };
    }
    let is_circuit = this.energyReportTab != 'energyReport'
    let generate_daily = this.energyCheckboxModel;
    this.dashboardDataService
      .emailEnergyReport(this.currentBuilding, date, is_circuit, generate_daily)
      .subscribe(
        data => {
          this.toast.success({
            detail: 'Success',
            summary: 'Energy report will be sent to your email.',
            sticky: true,
            position: 'tr'
          });
          this.isGenerating = false;
        },
        () => {
          this.isGenerating = false;
          this.isError = true;
        }
      );
  }

  unitEdit(event: any) {
    if (event.target.id == 'watts') {
      (document.getElementById('watts') as HTMLInputElement).checked = true;
      (document.getElementById('volts') as HTMLInputElement).checked = false;
      this.wattSelection = true;
      this.voltSelection = false;
    } else if (event.target.id == 'volts') {
      (document.getElementById('watts') as HTMLInputElement).checked = false;
      (document.getElementById('volts') as HTMLInputElement).checked = true;
      this.wattSelection = false;
      this.voltSelection = true;
    }
  }

  intervalEdit(event: any) {
    this.intervalSelected = event.target.value;
  }

  generatorEdit(event: any) {
    if (event.target.id == 'allPanels') {
      (document.getElementById('allPanels') as HTMLInputElement).checked = true;
      (
        document.getElementById('allPanelsWithCircuits') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('singlePanelsWithCircuits') as HTMLInputElement
      ).checked = false;
      this.allPanelsSelection = true;
      this.allPanelsWithCircuitsSelection = false;
      this.singlePanelsWithCircuitsSelection = false;
    } else if (event.target.id == 'allPanelsWithCircuits') {
      (document.getElementById('allPanels') as HTMLInputElement).checked =
        false;
      (
        document.getElementById('allPanelsWithCircuits') as HTMLInputElement
      ).checked = true;
      (
        document.getElementById('singlePanelsWithCircuits') as HTMLInputElement
      ).checked = false;
      this.allPanelsSelection = false;
      this.allPanelsWithCircuitsSelection = true;
      this.singlePanelsWithCircuitsSelection = false;
    } else if (event.target.id == 'singlePanelsWithCircuits') {
      (document.getElementById('allPanels') as HTMLInputElement).checked =
        false;
      (
        document.getElementById('allPanelsWithCircuits') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('singlePanelsWithCircuits') as HTMLInputElement
      ).checked = true;
      this.allPanelsSelection = false;
      this.allPanelsWithCircuitsSelection = false;
      this.singlePanelsWithCircuitsSelection = true;
    }
  }

  downloadRawData(): void {
    let unit = this.wattSelection ? 'watts' : 'volts';
    let panel_id = '';
    let circuitNeeded = false;
    let start_date = new Date();
    let end_date = new Date();
    if (this.rangeSelectedDay == true) {
      start_date = this.energyReportSelectedDay;
      end_date = new Date(
        this.energyReportSelectedDay.getUTCFullYear(),
        this.energyReportSelectedDay.getMonth(),
        this.energyReportSelectedDay.getDate() + 1
      );
    } else {
      start_date = this.energyReportSelectedMonth;
      end_date = new Date(
        this.energyReportSelectedMonth.getUTCFullYear(),
        this.energyReportSelectedMonth.getMonth() + 1,
        0
      );
    }
    if (this.singlePanelsWithCircuitsSelection == true) {
      if (this.selectedPanel == null) {
        alert('Please select a panel');
        return;
      }
      panel_id = this.selectedPanel;
      circuitNeeded = true;
    } else if (this.allPanelsWithCircuitsSelection == true) {
      circuitNeeded = true;
    }
    let interval = this.intervalSelected;
    // removing the time from the date so that we send UTC and backend decides the timezone
    start_date = new Date(
      Date.UTC(
        start_date.getFullYear(),
        start_date.getMonth(),
        start_date.getDate()
      )
    );
    end_date = new Date(
      Date.UTC(end_date.getFullYear(), end_date.getMonth(), end_date.getDate())
    );
    this.dashboardDataService
      .downloadRawEnergyData(
        start_date,
        end_date,
        this.currentBuilding,
        unit,
        circuitNeeded,
        panel_id,
        interval
      )
      .subscribe(data => {
        saveAs(data, 'data.xlsx');
      });
  }
}
