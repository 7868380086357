import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardDataService } from '../../services/dashboard-data/dashboard-data.service';
import { Building } from '../../models/building';
import { MatDialog } from '@angular/material/dialog';
import { Panel } from '../../models/panel';
import { Circuit } from '../../models/circuit';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke,
  ChartComponent
} from 'ng-apexcharts';
import { AuthService } from '../../services/auth.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};

@Component({
  selector: 'app-equipment-health',
  templateUrl: './equipment-health.component.html',
  styleUrls: ['./equipment-health.component.scss']
})
export class EquipmentHealthComponent implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  public chartOptionsPanel: Partial<ChartOptions> | any;
  buildings: Building[];
  currentBuilding: Building;

  constructor(
    private dashboardDataService: DashboardDataService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this.chartOptions = {
      initialize: false
    };
    this.chartOptionsPanel = {
      initialize: false
    };
    this.dashboardDataService.watchBuildings().forEach(buildings => {
      if (buildings != null) {
        this.buildings = buildings;
      }
    });
    this.dashboardDataService
      .watchCurrentBuilding()
      .forEach((building: Building) => {
        if (building != null && this.currentBuilding == null) {
          this.currentBuilding = building;
          this.getPanels(building.idbuildings);
          this.getCircuitCategory(building.idbuildings);
        }
      });
  }

  panels: Panel[];
  circuitCategoryMain: any[];
  circuitCategoryPane: any[];
  circuits: Circuit[];
  modal: string | null = null;
  stack: any[] = [];
  powerPercetangeObject: any[];

  public active = 2;
  public isCostSelected = false;
  public isPanelCollapsed: boolean[] = [];
  public isInventryCollapsed: boolean[] = [];
  public panelsIsConnected = true;
  public totalPanelPower = 0;
  public totalCurrent: number = 0;
  public totalPower: number = 0;
  public totalCost: number = 0;
  public fetchsetInterval: boolean = false;
  public panelnonConnected: boolean = false;
  public panelnonActive: boolean = false;
  public panelactive: boolean = false;
  public panelall: boolean = true;

  public panelMapping: { [key: string]: Panel } = {};
  public circuitMapping: { [key: string]: Panel } = {};

  public circuitCurrentMapping: { [key: number]: Circuit } = {};
  public circuitPowerMapping: { [key: string]: number } = {};
  public circuitCostMapping: { [key: string]: number } = {};

  public circuitCategoryCurrentMapping: { [key: number]: number } = {};
  public circuitCategoryPowerMapping: { [key: number]: number } = {};
  public circuitCategoryCostMapping: { [key: number]: number } = {};

  public sidebarCategoryActive: boolean = true;
  public sidebarPanelActive: boolean = false;

  ngOnInit(): void {}

  getPanels(buildingId: any): void {
    this.dashboardDataService.getPanels(buildingId).forEach((panels: any) => {
      this.panels = panels.results;
      // TODO: Remove this code once api starts sending panel color
      this.panels.forEach((panel: Panel) => {
        panel.colour = '#' + Math.floor(Math.random() * 16777215).toString(16);
        panel.panelIsconnected = false;
        panel.panel_circuits = [];
        this.panelMapping[panel.panel_id] = panel;
      });
      this.dashboardDataService.getCurrentBuilding();
      this.getCircuits();
    });
  }

  customToFixed(value: any, precision: number = 3, isPercent: boolean = false) {
    let decimalVal = 0;

    if (isPercent == true) {
      decimalVal = value.toFixed(precision);
      if (decimalVal >= 100) {
        decimalVal = 100;
      } else if (decimalVal == 0.0) {
        decimalVal = 0;
      }
    } else {
      decimalVal = value.toFixed(precision);
    }

    return decimalVal;
  }

  getCircuitCategory(buildingId: any): void {
    this.dashboardDataService
      .getCircuitCategory(buildingId)
      .forEach((circuitCategory: any) => {
        // this.circuitCategoryPane = circuitCategory
        this.circuitCategoryMain = JSON.parse(
          JSON.stringify(circuitCategory.results)
        );
        let totalCurrentCategory = 0;
        let totalPowerCategory = 0;
        let totalCostCategory = 0;
        for (const circuitCategory of this.circuitCategoryMain) {
          this.isInventryCollapsed.push(true);
          circuitCategory.Isconnected = false;
          circuitCategory.active_circuits = 0;
          circuitCategory.in_active_circuit = 0;
          for (const element of circuitCategory.circuit_set) {
            if (element.is_monitored == true) {
              circuitCategory.active_circuits =
                circuitCategory.active_circuits + 1;
              circuitCategory.Isconnected = true;
            } else {
              circuitCategory.in_active_circuits =
                circuitCategory.in_active_circuits + 1;
            }

            if (this.circuitCurrentMapping[element.id]) {
              element['connected'] =
                this.circuitCurrentMapping[element.id].connected;
              element['circuit_disabled'] =
                this.circuitCurrentMapping[element.id].circuit_disabled;
              element['circuit_current'] =
                this.circuitCurrentMapping[element.id].circuit_current;
              element['circuit_power'] =
                this.circuitCurrentMapping[element.id].circuit_power;
              element['circuit_cost'] =
                this.circuitCurrentMapping[element.id].circuit_cost;
              totalCurrentCategory =
                totalCurrentCategory +
                this.circuitCurrentMapping[element.id].circuit_current;
              totalPowerCategory =
                totalPowerCategory +
                this.circuitCurrentMapping[element.id].circuit_power;
              totalCostCategory =
                totalCostCategory +
                this.circuitCurrentMapping[element.id].circuit_cost;
            }
          }
          this.circuitCategoryCurrentMapping[circuitCategory.id] =
            totalCurrentCategory;
          this.circuitCategoryPowerMapping[circuitCategory.id] =
            totalPowerCategory;
          this.circuitCategoryCostMapping[circuitCategory.id] =
            totalCostCategory;
        }
      });
  }

  getCircuits(): void {
    for (const element of this.panels) {
      this.isPanelCollapsed.push(true);
      element.panel_expansion = {};
      this.dashboardDataService
        .getCircuits(element.panel_id)
        .forEach((circuits: any) => {
          element.panel_circuits = circuits.results;
          element.panel_circuitsIndexed = {};
          element.active_circuits = 0;
          element.in_active_circuits = 0;
          circuits.results.forEach((circuit: Circuit) => {
            circuit.panel = element;
            this.circuitMapping[circuit.id] = element;
            element.panel_circuitsIndexed[parseInt(circuit.circuit_number)] =
              circuit;
            if (circuit.panel_meter_channels?.length > 0) {
              element.panel_expansion[
                circuit.panel_meter_channels[0].expansion_number
              ] = parseInt(circuit.circuit_number);
            }
            if (circuit.is_monitored == true) {
              element.active_circuits = element.active_circuits + 1;
              element.panelIsconnected = true;
            } else {
              element.in_active_circuits = element.in_active_circuits + 1;
            }
          });
        });
    }
  }

  circuit_monitor_toggle(event: any, circuit: Circuit) {
    this.dashboardDataService
      .updateCircuit(circuit.id, !circuit.is_monitored)
      .subscribe((data: any) => {
        circuit.is_monitored = !circuit.is_monitored;
        let panel = circuit.panel;
        if (!panel) {
          panel = this.circuitMapping[circuit.id];
        }
        console.log(panel);
        if (circuit.is_monitored == true) {
          panel.active_circuits = panel.active_circuits + 1;
          panel.in_active_circuits = panel.in_active_circuits - 1;
        } else {
          panel.active_circuits = panel.active_circuits - 1;
          panel.in_active_circuits = panel.in_active_circuits + 1;
        }
      });
  }

  radioMainEdit(event: any) {
    if (event.target.id == 'radioPanelNonConnected') {
      (
        document.getElementById('radioPanelNonConnected') as HTMLInputElement
      ).checked = true;
      (
        document.getElementById('radioPanelNonActive') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('radioPanelActive') as HTMLInputElement
      ).checked = false;
      (document.getElementById('radioPanelAll') as HTMLInputElement).checked =
        false;
      this.panelnonConnected = true;
      this.panelnonActive = false;
      this.panelactive = false;
      this.panelall = false;
    } else if (event.target.id == 'radioPanelNonActive') {
      (
        document.getElementById('radioPanelNonConnected') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('radioPanelNonActive') as HTMLInputElement
      ).checked = true;
      (
        document.getElementById('radioPanelActive') as HTMLInputElement
      ).checked = false;
      (document.getElementById('radioPanelAll') as HTMLInputElement).checked =
        false;
      this.panelnonConnected = false;
      this.panelnonActive = true;
      this.panelactive = false;
      this.panelall = false;
    } else if (event.target.id == 'radioPanelActive') {
      (
        document.getElementById('radioPanelNonConnected') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('radioPanelNonActive') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('radioPanelActive') as HTMLInputElement
      ).checked = true;
      (document.getElementById('radioPanelAll') as HTMLInputElement).checked =
        false;
      this.panelnonConnected = false;
      this.panelnonActive = false;
      this.panelactive = true;
      this.panelall = false;
    } else {
      (
        document.getElementById('radioPanelNonConnected') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('radioPanelNonActive') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('radioPanelActive') as HTMLInputElement
      ).checked = false;
      (document.getElementById('radioPanelAll') as HTMLInputElement).checked =
        true;
      this.panelnonConnected = false;
      this.panelnonActive = false;
      this.panelactive = false;
      this.panelall = true;
    }
  }
}
