<div class="modal" id="viewModal" tabindex="-1" role="dialog" style="display: block">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Bulk Update</h4>
        <button type="button" class="btn" (click)="dialogRef.close()">
          <img src="assets/images/cancel.svg" alt="close" />
        </button>
      </div>
      <div class="modal-body">
        <div class="schedule-container">
          <div class="schedule-title">
            Choose the Operating Months
          </div>
          <div class="schedule-content">
            <div class="schedule-box">
              <div *ngIf="loading" class="loading-spinner">
                Loading...
              </div>
              <div *ngIf="!loading">
                <div class="year-selector">
                  <button class="year-nav" (click)="previousYear()">
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
                      <path d="M7.41 1.41L6 0L0 6L6 12L7.41 10.59L2.83 6L7.41 1.41Z" fill="currentColor"/>
                    </svg>
                  </button>
                  <span class="year">{{currentYear}}</span>
                  <button class="year-nav" (click)="nextYear()">
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
                      <path d="M0.59 10.59L2 12L8 6L2 0L0.59 1.41L5.17 6L0.59 10.59Z" fill="currentColor"/>
                    </svg>
                  </button>
                </div>
                <div class="months-grid">
                  <div class="month-item" *ngFor="let month of months; let i = index">
                    <label class="month-checkbox" [class.disabled]="isMonthDisabled(i)">
                      <input 
                        type="checkbox" 
                        [(ngModel)]="month.selected"
                        [disabled]="isMonthDisabled(i)"
                      >
                      <span class="checkbox-custom"></span>
                      <span class="month-name">{{month.name}}</span>
                    </label>
                  </div>
                </div>
                <div class="button-group">
                  <button class="btn btn-outline" (click)="onBack()">Back</button>
                  <button class="btn btn-apply" (click)="onApply()">Apply</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 