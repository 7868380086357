<div
  class="modal"
  id="viewModal"
  tabindex="-1"
  role="dialog"
  style="display: block"
>
  <div
    class="modal-dialog"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{ data.title }}</h4>
        <button
          type="button"
          class="btn"
          (click)="closePopup()"
        >
          <img
            src="assets/images/cancel.svg"
            alt="arrow"
          />
        </button>
      </div>

      <div class="modal-body">
        <ul
          ngbNav
          #nav="ngbNav"
          [(activeId)]="activeId"
          class="nav-tabs"
        >
          <li
            *ngFor="let item of data.navItems; let i = index"
            [ngbNavItem]="i"
          >
            <a ngbNavLink>
              <div [innerHTML]="item.src"></div>
            </a>

            <ng-template ngbNavContent>
              <ng-container
                *ngComponentOutlet="item.content; injector: item.injector"
              ></ng-container>
            </ng-template>
          </li>
        </ul>

        <div class="energy-report-modal spceq">
          <div
            [ngbNavOutlet]="nav"
            class="mt-2"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
