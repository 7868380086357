<div class="dt-main">
  <app-sidebar></app-sidebar>

  <div class="dt-content-wrapper spc-padding">
    <h2 class="pageHeading pageHeader">Raw Data Explorer</h2>

    <div class="card bggrey tabs-container allCardsContainer">
      <div class="card rawDataCard meterInfoContainer">
        <div>
          <div class="meterIdHeader">Meter ID:</div>

          <div
            class="meterId"
            *ngIf="meterId"
          >
            <ng-container *ngIf="!isMeterIdEditing">
              <span>{{ meterId }}</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                class="svg"
                (click)="isMeterIdEditing = true"
              >
                <g clip-path="url(#clip0_9410_16744)">
                  <path
                    d="M13.641 7.91746C13.244 7.91746 12.9231 8.23916 12.9231 8.63537V14.379C12.9231 14.7746 12.6014 15.0969 12.2052 15.0969H2.15385C1.7575 15.0969 1.43594 14.7746 1.43594 14.379V4.32767C1.43594 3.93211 1.7575 3.60977 2.15385 3.60977H7.89748C8.2945 3.60977 8.61539 3.28811 8.61539 2.89187C8.61539 2.49553 8.2945 2.17383 7.89748 2.17383H2.15385C0.96637 2.17383 0 3.1402 0 4.32767V14.379C0 15.5665 0.96637 16.5328 2.15385 16.5328H12.2052C13.3926 16.5328 14.359 15.5665 14.359 14.379V8.63537C14.359 8.23839 14.038 7.91746 13.641 7.91746Z"
                    fill="#0D1758"
                  />
                  <path
                    d="M5.53672 8.46078C5.48682 8.5109 5.45264 8.57451 5.43838 8.64379L4.93082 11.1826C4.90716 11.3002 4.94448 11.4215 5.02915 11.507C5.06257 11.5403 5.10222 11.5667 5.14584 11.5847C5.18946 11.6027 5.23619 11.6119 5.28337 11.6118C5.30626 11.6118 5.33005 11.6097 5.35371 11.6047L7.89168 11.0971C7.96202 11.0826 8.02589 11.049 8.07546 10.9986L13.7559 5.31821L11.2179 2.78038L5.53672 8.46078ZM15.5105 1.02503C14.8106 0.324992 13.6719 0.324992 12.9725 1.02503L11.9789 2.01859L14.5169 4.55656L15.5105 3.56286C15.8494 3.22474 16.036 2.77381 16.036 2.29425C16.036 1.81468 15.8494 1.36378 15.5105 1.02503Z"
                    fill="#0D1758"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_9410_16744">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </ng-container>

            <ng-container *ngIf="isMeterIdEditing">
              <select
                (input)="handleValueChange($event)"
                class="select"
              >
                <option
                  *ngFor="let id of meters"
                  value="{{ id }}"
                  [selected]="meterId === id"
                >
                  {{ id }}
                </option>
              </select>
            </ng-container>
          </div>
        </div>

        <div class="toggleOptionContainer">
          <div *ngFor="let toggleOption of toggleOptions">
            <div class="toggleLabel">{{ toggleOption.label }}</div>

            <div class="optionsContainer">
              <div
                *ngFor="let option of toggleOption.options"
                (click)="handleOptionClick($event, option.id)"
              >
                <input
                  type="radio"
                  [checked]="selectedOption === option.id"
                  value="{{ option.id }}"
                  id="{{ option.id }}"
                />

                <label
                  *ngIf="option.label"
                  [for]="option.id"
                  class="inputLabel"
                  >{{ option.label }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card rawDataCard">
        <div class="chartLabel">{{ aggregatedChartLabel }}</div>

        <div
          echarts
          [options]="aggregatedChartConfig"
        ></div>

        <div class="chartValue">
          {{ aggregatedDataValue }}
        </div>
      </div>

      <div class="extraCardsContainer">
        <div
          *ngFor="let card of extraCards; trackBy: trackByFn"
          class="card rawDataCard"
        >
          <div class="chartLabel extraCardLabel">{{ card.label }}</div>

          <div
            echarts
            [options]="card.config"
          ></div>

          <div class="chartValue">
            {{ card.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
