import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SettingService } from 'src/app/services/settings-service';
import { Facilities } from 'src/app/models/account-settings/facility';
import { FacilitiesInnerInfo } from 'src/app/models/account-settings/facility-inner';
import { DataService } from 'src/app/services/data-sharing-service';
import { Router } from '@angular/router';
import { NgToastService } from 'ng-angular-popup';
import { MatDialog } from '@angular/material/dialog';
import { FacilityDetaillComponent } from '../facility-detaill/facility-detaill.component';
import { query } from 'express';
import { CompanyInformation } from 'src/app/models/account-settings/companyinfo';

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.scss']
})
export class FacilitiesComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(private settingService: SettingService, private dataService: DataService, private router: Router,public dialog: MatDialog, private toast: NgToastService) { }
  facilities: Facilities[];
  facilitiesInnerInfo: FacilitiesInnerInfo[];
  facilitiesTypes: string[];
  facilitiesSubTypes: string[];
  dataLoading: boolean = true;
  newFacility = { 'description': '', 'company':'', 'facility': 0, 'squarefootage': '', 'address_line_1': '', 'postal_code': '', 'city': '', 'province': '', 'weather_station': '', 'time_zone': '', 'image': '' };
  isOverlay: boolean = false;
  provinceOptions: any[] = [];
  file: File;
  company : CompanyInformation;
  addFacilityClicked() {
    this.isOverlay = true;
  }
  selectedFacilityType: string = '';
  selectedFacilitySubType: string = '';

  facilityDetailClicked(facility: Facilities) {
    this.dataService.setClickedFacilityId(facility.idbuildings);
    this.router.navigate(['/account-settings/facility-detail'], {queryParams:{facility: JSON.stringify(facility), facilitiesInnerInfo: JSON.stringify(this.facilitiesInnerInfo)}});
    this.dataLoading = false;
  //   let dialogRef = this.dialog.open(FacilityDetaillComponent, {
  //     data: {
  //       facility: facility,
  //     }
  // });
  // dialogRef.afterClosed().subscribe(result => {
  //   console.log(`Dialog result: ${result}`);
  //   this.dataLoading = true;
  // });
  }

  closeOverlay() {
    this.isOverlay = false;
    this.newFacility = { 'description': '', 'company':'', 'facility': 0, 'squarefootage': '', 'address_line_1': '', 'postal_code': '', 'city': '', 'province': '', 'weather_station': '', 'time_zone': '', 'image': '' };
    this.settingService.getBuildings().subscribe((data: any) => {
      this.facilities = data.results;
    });
  }

  handleNewFacility() {
    if (!this.newFacility.description || !this.newFacility.facility || !this.newFacility.image || !this.newFacility.address_line_1 || !this.newFacility.city || !this.newFacility.postal_code) {
      alert('description, type, subtype, image, address, city and postal code are required');
      return;
    }
    this.newFacility['company'] = this.company.company_id
    this.settingService.addNewFacility(this.newFacility, this.file).subscribe(
      (response) => {
        this.toast.success({ detail: 'Success', summary: 'Facility added successfully!', sticky: true, position: 'tr' })
        this.closeOverlay();
      },
      (error) => {
        console.error('Error adding facility', error);
        this.handleError(error);
      }
    );
    
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.newFacility.image = e.target?.result as string;
      };
      reader.readAsDataURL(file);
      this.newFacility.image = file
      this.file = file
      // this.uploadImage(file);
    }
  }


  ngOnInit(): void {
    this.dataService.setClickedFacilityId('');
    this.settingService.getBuildings().subscribe((data: any) => {
      this.facilities = data.results;
    });
    this.settingService.getCompanyInformation().subscribe((data: any) => {
      this.company = data;
    });

    this.settingService.getProvinceOptions().subscribe((options: any) => {
      this.provinceOptions = Object.entries(options).map(([key, value]) => ({ key, value }));
    });
    this.settingService.getFacilities().subscribe((data: any) => {
      this.facilitiesInnerInfo = data.results;
      this.facilitiesTypes = [...new Set( this.facilitiesInnerInfo.map((facility) => facility.type)) ];
      if (this.facilitiesTypes.length >= 1) {
        this.selectedFacilityType = this.facilitiesTypes[0];
        this.facilityTypeSelected();
      }
    });

  }

  private handleError(error: any): void {
    console.error('Error in component:', error);
    this.toast.error({ detail: 'Error', summary: 'An error occurred!', sticky: true, position: 'tr' });
  }
  facilityTypeSelected(): void {
    this.facilitiesSubTypes = [...new Set( this.facilitiesInnerInfo.filter((facility) => facility.type === this.selectedFacilityType).map((facility) => facility.sub_type)) ];
    if (this.facilitiesSubTypes.length == 0) {
      this.selectedFacilitySubType = '';
      let facilitySelected = this.facilitiesInnerInfo.find((facility) => facility.type === this.selectedFacilityType)?.id;
      if (!facilitySelected) {
        return;
      }
      this.newFacility.facility = facilitySelected
    }
  }

  facilitySubTypeSelected(): void {
    let facilitySelected = this.facilitiesInnerInfo.find((facility) => facility.type === this.selectedFacilityType && facility.sub_type === this.selectedFacilitySubType)?.id;
    if (!facilitySelected) {
      return;
    }
    this.newFacility.facility = facilitySelected;
  }

}
