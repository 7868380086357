import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PickerInteractionMode } from 'igniteui-angular';
import { OperatingMonthsDialogComponent } from '../operating-months-dialog/operating-months-dialog.component';
import { OperatingHoursService } from '../../services/operating-hours.service';
import { Building } from '../../models/building';
import { WeeklySchedule } from '../../models/operating_schedule';

interface DialogData {
  building: Building;
  weekDays?: any[];
}

@Component({
  selector: 'app-bulk-update-dialog',
  templateUrl: './bulk-update-dialog.component.html',
  styleUrls: ['./bulk-update-dialog.component.scss']
})
export class BulkUpdateDialogComponent implements OnInit {
  public mode: PickerInteractionMode = PickerInteractionMode.DropDown;
  public format = 'hh:mm tt';
  public loading = true;
  
  weekDays = [
    { name: 'Sunday', openTime: '09:00:00', closeTime: '18:00:00', closed: false },
    { name: 'Monday', openTime: '09:00:00', closeTime: '18:00:00', closed: false },
    { name: 'Tuesday', openTime: '09:00:00', closeTime: '18:00:00', closed: false },
    { name: 'Wednesday', openTime: '09:00:00', closeTime: '18:00:00', closed: false },
    { name: 'Thursday', openTime: '09:00:00', closeTime: '18:00:00', closed: false },
    { name: 'Friday', openTime: '09:00:00', closeTime: '18:00:00', closed: false },
    { name: 'Saturday', openTime: '09:00:00', closeTime: '18:00:00', closed: false }
  ];

  constructor(
    public dialogRef: MatDialogRef<BulkUpdateDialogComponent>,
    private dialog: MatDialog,
    private operatingHoursService: OperatingHoursService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.loading = true;
    if (this.data.weekDays) {
      this.weekDays = this.data.weekDays;
    }
    else if (this.data.building.operating_schedule?.weekly?.weekDays) {
      this.weekDays = this.data.building.operating_schedule.weekly.weekDays;
    }
    this.loading = false;
  }

  onNext(): void {
    const weeklySchedule: WeeklySchedule = {
      weekDays: this.weekDays
    };
    
    // Open months dialog with current data
    const monthsDialogRef = this.dialog.open(OperatingMonthsDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        building: this.data.building,
        weeklySchedule
      }
    });

    monthsDialogRef.afterClosed().subscribe(result => {
      if (result === 'back') {
        // Re-open the weekly schedule dialog with saved data
        this.dialogRef = this.dialog.open(BulkUpdateDialogComponent, {
          width: '790px',
          disableClose: true,
          data: { 
            building: this.data.building,
            weekDays: this.weekDays // Pass the saved weekDays data
          }
        });
      } else if (result) {
        // Pass the final result up to the parent component
        this.dialogRef.close(result);
      }
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onTimeChange(day: any, type: 'open' | 'close', event: any): void {
    if (type === 'open') {
      day.openTime = event;
    } else {
      day.closeTime = event;
    }
  }
} 