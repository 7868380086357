<div class="dt-main">
  <app-sidebar></app-sidebar>

  <div class="dt-content-wrapper">
    <div class="col-md-12 spc-padding">
      <div class="headerContainer">
        <img
          src="assets/images/back-button.png"
          routerLink="/insights/energy-efficiency/peak-demand"
          alt="Back"
        />
        <h2 class="pageHeading heading">Peak Demand Heatmap</h2>
      </div>

      <div class="bg-lightgrey">
        <div class="row mb-12" style="height: 100%;">
          <div class="col-12" style=" background: white; border-radius: 10px; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); margin-bottom: 20px; padding: 20px;">
            <div class="legend-container">
              <h4>Legend</h4>
              <div class="legend-scale row ">
                <div class="legend-scale col-9">
                  <ng-container *ngFor="let item of colorScale">
                    <div class="legend-item">
                      <div class="legend-color" [ngStyle]="{'background-color': item.color}"></div>
                      <span>{{item.value}}</span>
                    </div>
                  </ng-container>
                </div>
                <div class="dateContainer col-3 text-end">
                  <div class="dateActionContainer">
                    <img src="assets/images/back.svg" alt="Back" (click)="selectPreviousMonth()">
                    <div class="formattedDate">{{ selectedMonth | date : 'MMMM yyyy' }}</div>
                    <img src="assets/images/forward.svg "alt="Forward" (click)="selectNextMonth()">
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Loading state -->
          <div *ngIf="isLoading" class="col-12 d-flex justify-content-center align-items-center" style="height: 400px; background: white; border-radius: 10px; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); padding: 20px; margin-bottom: 20px;">
            <div class="loader"></div>
          </div>
          
          <!-- Content when not loading -->
          <div *ngIf="!isLoading" class="col-12" style="height: 100%; background: white; border-radius: 10px; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); padding: 20px; overflow: auto; margin-bottom: 20px;">
            <div class="heatmap-container">
              <!-- Date labels on top (X-axis) -->
              <div class="heatmap-date-labels">
                <div class="time-label-spacer"></div>
                <div class="date-labels">
                  <div class="date-label" *ngFor="let date of uniqueDates">
                    {{date}}
                  </div>
                </div>
              </div>
              
              <div class="heatmap-content">
                <!-- Hours on the left (Y-axis) with 15-min intervals -->
                <ng-container *ngFor="let hour of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]">
                  <div class="hour-row">
                    <div class="hour-label">{{hour}}:00</div>
                    <div class="quarter-rows">
                      <ng-container *ngFor="let quarter of [1,2,3,4]">
                        <div class="quarter-row">
                          <div class="cell-container">
                            <div class="heat-cell" *ngFor="let date of uniqueDates"
                              [ngStyle]="getCellStyle(date, hour, quarter)">
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <!-- Clearer hour separator with horizontal line -->
                  <div class="hour-separator" *ngIf="hour < 23">
                    <div class="hour-label-spacer"></div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 