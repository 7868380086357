<div class="dt-main">
  <app-sidebar></app-sidebar>

  <div class="dt-content-wrapper">
      <div class="col-md-12 spc-padding">
        <div class="headerContainer">
          <img
            src="assets/images/back-button.png"
            routerLink="/insights/energy-efficiency"
            alt="Back"
          />
          <h2 class="pageHeading heading">Peak Demand</h2>
        </div>

        <div class="bg-lightgrey overflow-y-scroll card-container row">
            <!-- Left section -->
            <div class="col-md-6 h-100">
              <div class="section-card h-100">
                  <h3 class=" card-header mb-0">What is Peak Demand</h3>
                <div class="card-body row d-flex flex-column h-100">
                  <div class="col-12 flex-grow-1">
                    <div class="card-body-paragraph">
                      <p>Peak demand refers to the highest amount of electrical power a facility or system draws at any given moment within a specific timeframe. It represents the maximum load required from the electrical grid or a facility's power system at one time.</p>
                    </div>
                  </div>
                  <div class="col-12 mt-auto">
                    <button class="btn btn-secondary" routerLink="/insights/energy-efficiency/peak-demand/peak-heatmap">Explore Peak Heatmap</button>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Right section with 2 vertical sections -->
            <div class="col-md-6 h-100">
              <div class="row h-100">
                <div class="col-12 mb-4">
                  <div class="section-card h-100">
                    <h4 class="card-header mb-0">Peak Explorer</h4>
                    <div class="card-body d-flex flex-column h-100 p-3">
                      <div class="row flex-grow-1">
                        <div class="col-md-4 p-2 text-center">
                          <img src="assets/images/peak-demand/peak-explorer.svg" alt="Peak Explorer"> 
                        </div>
                        <div class="col-md-8 border-start h-100 d-flex flex-column p-2">
                          <p class="card-body-paragraph">Shows your facility's highest energy demand each month, helping you track trends, identify causes, and reduce costs.</p>
                          <div class="mt-auto">
                            <button class="btn btn-secondary disabled">Explore</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12 mb-4">
                  <div class="section-card h-100">
                    <h4 class="card-header mb-0">Top 50 Peaks</h4>
                    <div class="card-body d-flex flex-column h-100 p-3">
                      <div class="row flex-grow-1">
                        <div class="col-md-4 p-2 text-center">
                          <img src="assets/images/peak-demand/top-50.svg" alt="Top 50 Peaks"> 
                        </div>
                        <div class="col-md-8 border-start h-100 d-flex flex-column">
                          <p class="card-body-paragraph">Highlights your facility's highest energy demand events, helping you identify patterns, key contributors, and cost-saving opportunities.</p>
                          <div class="mt-auto">
                            <button class="btn btn-secondary disabled">Explore</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </div>
    </div>
</div>
