import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SettingService } from 'src/app/services/settings-service';
import { User } from 'src/app/models/account-settings/user';
import { GeneralInformationUser } from 'src/app/models/account-settings/generalInfo';
import { Facilities } from 'src/app/models/account-settings/facility';
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;
  constructor(private settingService: SettingService, private toast: NgToastService,
  ) { }

  image: any;
  file: File | null;
  users: User[];
  dataLoading: boolean = true;
  IsNewUserAdding: boolean = false;
  imageLoadedSuccessfully: boolean = true;
  IsViewUserDetails: boolean = false;
  isViewFacilities: boolean = false;
  isOverlay: boolean = false;
  newUser: GeneralInformationUser = { 'account_type': 0, 'email': '', 'first_name': '', 'last_name': '', 'password': '', 'mobile_number': '', 'position': '', 'image': null, 'company_id': '', 'is_superuser': false };
  userDetails: User ;
  facilities: Facilities[];

  closOverlay() {
    this.IsNewUserAdding = false;
    this.IsViewUserDetails = false;
    this.userDetails ;
    this.isViewFacilities = false;
    this.facilities = [];
    this.isOverlay = false;
  }

  showNewUserWindow() {
    this.IsNewUserAdding = true;
  }
  handleAddNewUser() {
    if (!this.newUser.first_name || !this.newUser.last_name || !this.newUser.email) {
      alert('first name, last name and email are required');
      return;
    }
    this.settingService.addNewUserInfo(this.newUser, this.file).subscribe(
      (data: any) => {
        this.users = [...this.users, data]
        this.toast.success({ detail: 'Success', summary: 'User  Added successfully!', sticky: true, position: 'tr' })
      },
      (error) => {
        // Handle the error here
        this.handleError(error);
      }
    );
    this.file = null;
    this.newUser = { 'account_type': 0, 'email': '', 'first_name': '', 'last_name': '', 'password': '', 'mobile_number': '', 'position': '', 'image': null, 'company_id': '', 'is_superuser': false };
    this.IsNewUserAdding = false;
  }

  handleImageError() {
    this.imageLoadedSuccessfully = false;
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target?.result as string;
        this.imageLoadedSuccessfully = true;
      };
      reader.readAsDataURL(file);
      this.file = file
      // this.uploadImage(file);
    }
  }

  // uploadImage(file: File): void {
  //   this.settingService.uploadUserImage(file).subscribe(
  //     response => {
  //       this.toast.success({ detail: 'Success', summary: 'Image updated successfully!', sticky: true, position: 'tr' })
  //       // Optionally, update the userInfo.image with the new image URL
  //     },
  //     error => {
  //       this.toast.error({ detail: 'Error', summary: 'Image update was unsuccessfull!', sticky: true, position: 'tr' })
  //       console.error('Error uploading image:', error);
  //     }
  //   );
  // }

  getUserDetailsById(userId: number) {
    this.settingService.getUserDetailsById(userId).subscribe((data: any) => {
      this.userDetails = data;
    });
  }

  getBuildingsByUserId(userId: number) {
    this.settingService.getBuildingsByUserId(userId).subscribe((data: any) => {
      this.facilities = data.results;
    });
  }


  viewDetailsUser(user: User) {
    this.userDetails = user;
    // this.getUserDetailsById(userId);
    this.getBuildingsByUserId(user.id);
    this.IsViewUserDetails = true;
    this.isOverlay = true;
  }

  getUsers(): void {
    this.settingService.getAllUsers().subscribe((data: any) => {
      this.users = data.results;
      this.dataLoading = false;
    });
  }


  ngOnInit(): void {
    this.getUsers()
  }


  viewFacilityPermissions() {
    this.IsViewUserDetails = false;
    this.isViewFacilities = true;
  }

  viewUserDetails() {
    this.isViewFacilities = false;
    this.IsViewUserDetails = true;
  }

  removeFacility(facility: Facilities) {
    const facilityId = facility.idbuildings;
    this.settingService.removeFacilityUserAccess(facilityId, this.userDetails.id).subscribe(
      (data: any) => {
        this.toast.success({ detail: 'Success', summary: 'Facility removed successfully!', sticky: true, position: 'tr' })
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  private handleError(error: any): void {
    console.error('Error in component:', error);
    this.toast.error({ detail: 'Error', summary: 'An error occurred!', sticky: true, position: 'tr' });
  }

}
