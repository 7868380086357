import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardDataService } from 'src/app/services/dashboard-data/dashboard-data.service';
import {
    ApexNonAxisChartSeries,
    ApexResponsive,
    ApexChart
  } from "ng-apexcharts";

  export type ChartOptions = {
    series: ApexNonAxisChartSeries;
    chart: ApexChart;
    responsive: ApexResponsive[];
  };

@Component({
  selector: 'app-energy-savings-snapshot',
  templateUrl: './energy-savings-snapshot.component.html',
  styleUrls: ['./energy-savings-snapshot.component.scss']
})
export class EnergySavingsSnapshotComponent implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any = { initialize: false };
  public chartOptions2: Partial<ChartOptions> | any = { initialize: false };
  public chartOptionsRejectedEnergy: Partial<ChartOptions> | any = { initialize: false };
  public chartOptionsRejectedECM: Partial<ChartOptions> | any = { initialize: false };
  queryParams: any = {};
  scan_id: Number
  scan_id_formatted: String
  timeperiod: Number
  startFullDate: String
  endFullDate: String
  rejected_scan: Boolean
  isLoading = true;
  energyLossCount: Number;
  ecmDetectedCount: Number;


  constructor(private route: ActivatedRoute, private router: Router,private dashboardService: DashboardDataService) {
    
    this.route.queryParams.subscribe(params => {
      const rejected_scan = params['rejected_scan']
      if(rejected_scan){
        this.rejected_scan = true;
      }else {
        if(!this.scan_id){
          let scanID = +params['id'] || +params['scan_id'];
          this.scan_id = scanID;
          if(scanID < 10){
            this.scan_id_formatted = `#00${scanID}`;
          }else{
            this.scan_id_formatted = `#0${scanID}`;
          }
          this.startFullDate = params['firstFullDate'] || params['startFullDate'];
          this.endFullDate = params['endFullDate'];
          this.energyLossCount = params['ecm_circuit_count'] || params['energyLossCount'];
          this.ecmDetectedCount = params['ecm_count'] || params['ecm_circuit_count'];
          this.timeperiod = +params['timeperiod']
        }
      }
    });

    const options = [
        {
          name: 'Energy Loss',
          value: 45,
          color: '#FF7F27'
        },
        {
          name: 'Conservative',
          value: 100,
          color: '#7F7F7F'
        }
      ];

      const options2 = [
        {
          name: "ECM's Detected",
          value: 45,
          color: '#1FAB3D'
        },
        {
          name: 'Conservative',
          value: 100,
          color: '#7F7F7F'
        }
      ];

      const chartOptionsRejectedEnergyOptions = [
        {
          name: "Energy Loss",
          value: 45,
          color: '#E04A41'
        },
        {
          name: 'Conservative',
          value: 100,
          color: '#7F7F7F'
        }
      ];

      // this.chartOptions = {
      //   series: options.map(d => d.value),
      //   dataLabels: { enabled: false },
      //   legend: { show: false },
      //   chart: { type: 'donut', width: '150px' },
      //   plotOptions: {
      //     pie: {
      //       donut: {
      //         labels: {
      //           show: true,
      //           total: {
      //             show: true,
      //             label: '',
      //             formatter: () => 'Text you want'
      //           }
      //         }
      //       }
      //     }
      //   },
      //   colors: options.map(d => d.color),
      //   labels: options.map(d => d.name),
      //   responsive: [
      //     {
      //       breakpoint: 1000,
      //       options: {
      //         chart: { width: 10 },
      //         legend: { position: 'bottom' }
      //       }
      //     }
      //   ],
      //   initialize: true,
      //   stroke: { show: false }
      // };
      
      // const chartOptions2 = Object.assign({},this.chartOptions);
      // chartOptions2.series = options2.map(d => d.value);
      // chartOptions2.colors = options2.map(d => d.color);
      // chartOptions2.labels = options2.map(d => d.name);

      // this.chartOptions2 = chartOptions2;

      if(this.rejected_scan){
        const chartOptionsRejectedEnergy = Object.assign({},this.chartOptions);
        chartOptionsRejectedEnergy.series = chartOptionsRejectedEnergyOptions.map(d => d.value);
        chartOptionsRejectedEnergy.colors = chartOptionsRejectedEnergyOptions.map(d => d.color);
        chartOptionsRejectedEnergy.labels = chartOptionsRejectedEnergyOptions.map(d => d.name);
        this.chartOptionsRejectedEnergy = chartOptionsRejectedEnergy

        const chartOptionsRejectedECM = Object.assign({},this.chartOptionsRejectedEnergy);
        this.chartOptionsRejectedECM = chartOptionsRejectedECM;

      }
  }

  ngOnInit(): void {
  }
}


