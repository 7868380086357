<div class="modal" id="viewModal" tabindex="-1" role="dialog" style="display: block">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Bulk Update</h4>
        <button type="button" class="btn" (click)="onClose()">
          <img src="assets/images/cancel.svg" alt="arrow" />
        </button>
      </div>
      <div class="modal-body">
        <div class="schedule-container">
          <div class="schedule-title">
            Set a Weekly Schedule
          </div>
          <div class="schedule-content">
            <div class="schedule-box">
              <div *ngIf="loading" class="loading-spinner">
                Loading...
              </div>
              <div *ngIf="!loading">
                <div class="table-header">
                  <div class="day-column" style="text-align: center"></div>
                  <div class="time-column open" style="text-align: center">Opens at</div>
                  <div class="time-column close" style="text-align: center">Closed at</div>
                  <div class="closed-column" style="text-align: center">
                    <span>Closed</span>
                    <br>
                    <span>All Day</span>
                  </div>
                </div>
                <div class="table-body">
                  <div class="table-row" *ngFor="let day of weekDays">
                    <div class="day-column">{{day.name}}</div>
                    <div class="time-column">
                      <igx-time-picker
                        [mode]="mode"
                        [(ngModel)]="day.openTime"
                        [inputFormat]="format"
                        [disabled]="day.closed"
                        (ngModelChange)="onTimeChange(day, 'open', $event)">
                      </igx-time-picker>
                    </div>
                    <div class="time-column">
                      <igx-time-picker
                        [mode]="mode"
                        [(ngModel)]="day.closeTime"
                        [inputFormat]="format"
                        [disabled]="day.closed"
                        (ngModelChange)="onTimeChange(day, 'close', $event)">
                      </igx-time-picker>
                    </div>
                    <div class="closed-column">
                      <div class="form-group">
                        <input 
                          type="checkbox" 
                          [checked]="day.closed"
                          (change)="day.closed = !day.closed"
                          id="closed-{{day.name}}"
                          name="closeCheck"
                          ngDefaultControl
                        >
                        <label [for]="'closed-' + day.name" class="check-red"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-button-apply">
                  <button class="btn btn-primary" (click)="onNext()">Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>