import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardDataService } from '../services/dashboard-data/dashboard-data.service';
import { Building } from '../models/building';
import { MatDialog } from '@angular/material/dialog';
import { Panel } from '../models/panel';
import { Circuit } from '../models/circuit';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke,
  ChartComponent
} from 'ng-apexcharts';
import { AuthService } from '../services/auth.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};

@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  styleUrls: ['./insights.component.scss']
})
export class InsightsComponent implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  public chartOptionsPanel: Partial<ChartOptions> | any;
  buildings: Building[];
  currentBuilding: Building;
  public fetchLiveAPI: boolean = true;
  public isBaselineRunning: boolean = true;
  public buildingInsights: any = {};
  public baselineTimeRemaining: any = {
    days: 0,
    hours: 0,
    minutes: 0
  };
  public isFacilityOffline: boolean = false;
  public energyEfficiencyColor: string = '#E04A41';

  constructor(
    private dashboardDataService: DashboardDataService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {
    this.chartOptions = {
      initialize: false
    };
    this.chartOptionsPanel = {
      initialize: false
    };
    this.dashboardDataService.watchBuildings().forEach(buildings => {
      if (buildings != null) {
        this.buildings = buildings;
      }
    });
    this.dashboardDataService
      .watchCurrentBuilding()
      .forEach((building: Building) => {
        if (building != null && this.currentBuilding == null) {
          this.currentBuilding = building;
          this.dashboardDataService
            .getBuildingInsights(building.idbuildings)
            .subscribe((data: any) => {
              this.buildingInsights = data;
              let timeRemaining =
                new Date(this.buildingInsights['oldest_circuit']).getTime() +
                this.buildingInsights['base_timeline_period_in_days'] *
                  24 *
                  60 *
                  60 *
                  1000 -
                new Date().getTime();
              if (timeRemaining > 0) {
                this.baselineTimeRemaining = {
                  days: Math.floor(timeRemaining / (24 * 60 * 60 * 1000)),
                  hours: Math.floor(
                    (timeRemaining % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)
                  ),
                  minutes: Math.floor(
                    ((timeRemaining % (24 * 60 * 60 * 1000)) %
                      (60 * 60 * 1000)) /
                      (60 * 1000)
                  )
                };
              }
              this.isBaselineRunning =
                this.buildingInsights.metrics.monitoring > 0 &&
                timeRemaining > 0;
              this.isFacilityOffline =
                this.buildingInsights.metrics.monitoring == 0;
              this.energyEfficiencyColor = this.isBaselineRunning
                ? '#1FAB3D'
                : '#E04A41';
            });
          // this.getPanels(building.idbuildings);
          // this.getCircuitCategory(building.idbuildings);
        }
      });
  }

  panels: Panel[];
  circuitCategoryMain: any[];
  circuitCategoryPane: any[];
  circuits: Circuit[];
  modal: string | null = null;
  stack: any[] = [];
  powerPercetangeObject: any[];

  active = 2;
  public isCostSelected = false;
  public isPanelCollapsed: boolean[] = [];
  public isInventryCollapsed: boolean[] = [];
  public panelsIsConnected = true;
  public totalPanelPower = 0;
  public totalCurrent: number = 0;
  public totalPower: number = 0;
  public totalCost: number = 0;
  public fetchsetInterval: boolean = false;
  public currentSelection: boolean = true;
  public energySelection: boolean = false;
  public costSelection: boolean = false;

  public circuitCurrentMapping: { [key: number]: Circuit } = {};
  public circuitPowerMapping: { [key: string]: number } = {};
  public circuitCostMapping: { [key: string]: number } = {};

  public circuitCategoryCurrentMapping: { [key: number]: number } = {};
  public circuitCategoryPowerMapping: { [key: number]: number } = {};
  public circuitCategoryCostMapping: { [key: number]: number } = {};

  public sidebarCategoryActive: boolean = true;
  public sidebarPanelActive: boolean = false;

  public costPerKwh: number = 0.12;

  ngOnInit(): void {
    // setInterval(() => {
    //     if (this.fetchsetInterval) {
    //         this.totalPanelPower = 0
    //         this.getUsageForAll()
    //     }
    // }, 2000);
  }

  ngOnDestroy(): void {
    this.fetchLiveAPI = false;
  }

  getPanels(buildingId: any): void {
    this.dashboardDataService.getPanels(buildingId).forEach((panels: any) => {
      this.panels = panels.results;
      // TODO: Remove this code once api starts sending panel color
      this.panels.forEach((panel: Panel) => {
        panel.colour = '#' + Math.floor(Math.random() * 16777215).toString(16);
      });
      this.dashboardDataService.getCurrentBuilding();
      this.getCircuits();
    });
  }

  customToFixed(value: any, precision: number = 3, isPercent: boolean = false) {
    let decimalVal = 0;

    if (isPercent == true) {
      decimalVal = value.toFixed(precision);
      if (decimalVal >= 100) {
        decimalVal = 100;
      } else if (decimalVal == 0.0) {
        decimalVal = 0;
      }
    } else {
      decimalVal = value.toFixed(precision);
    }

    return decimalVal;
  }

  getCircuitCategory(buildingId: any): void {
    this.dashboardDataService
      .getCircuitCategory(buildingId)
      .forEach((circuitCategory: any) => {
        // this.circuitCategoryPane = circuitCategory
        this.circuitCategoryMain = JSON.parse(
          JSON.stringify(circuitCategory.results)
        );
        let totalCurrentCategory = 0;
        let totalPowerCategory = 0;
        let totalCostCategory = 0;
        for (const circuitCategory of this.circuitCategoryMain) {
          this.isInventryCollapsed.push(true);
          for (const element of circuitCategory.circuit_set) {
            if (this.circuitCurrentMapping[element.id]) {
              element['connected'] =
                this.circuitCurrentMapping[element.id].connected;
              element['circuit_disabled'] =
                this.circuitCurrentMapping[element.id].circuit_disabled;
              element['circuit_current'] =
                this.circuitCurrentMapping[element.id].circuit_current;
              element['circuit_power'] =
                this.circuitCurrentMapping[element.id].circuit_power;
              element['circuit_cost'] =
                this.circuitCurrentMapping[element.id].circuit_cost;
              totalCurrentCategory =
                totalCurrentCategory +
                this.circuitCurrentMapping[element.id].circuit_current;
              totalPowerCategory =
                totalPowerCategory +
                this.circuitCurrentMapping[element.id].circuit_power;
              totalCostCategory =
                totalCostCategory +
                this.circuitCurrentMapping[element.id].circuit_cost;
            }
          }
          this.circuitCategoryCurrentMapping[circuitCategory.id] =
            totalCurrentCategory;
          this.circuitCategoryPowerMapping[circuitCategory.id] =
            totalPowerCategory;
          this.circuitCategoryCostMapping[circuitCategory.id] =
            totalCostCategory;
        }
      });
  }

  getCircuits(): void {
    for (const element of this.panels) {
      this.isPanelCollapsed.push(true);
      element.panel_expansion = {};
      this.dashboardDataService
        .getCircuits(element.panel_id)
        .forEach((circuits: any) => {
          element.panel_circuits = circuits.results;
          element.panel_circuitsIndexed = {};
          circuits.results.forEach((circuit: Circuit) => {
            element.panel_circuitsIndexed[parseInt(circuit.circuit_number)] =
              circuit;
            if (circuit.panel_meter_channels?.length > 0) {
              element.panel_expansion[
                circuit.panel_meter_channels[0].expansion_number
              ] = parseInt(circuit.circuit_number);
            }
          });
        });
    }
    this.getUsageForAll();
  }
  getUsageForAll() {
    this.fetchsetInterval = false;
    this.totalCurrent = 0;
    this.totalPower = 0;
    this.totalCost = 0;
    for (const element of this.panels) {
      // through this we can stop the interval for the panel which is disconnected
      if (element.fetchsetInterval == false) {
        continue;
      }
      this.getUsage(element);
      if (element.panel_current != null) {
        element.panel_power = element.panel_current * element.panel_volts;
        element.panel_cost = element.panel_power * this.costPerKwh;

        this.totalCurrent = this.totalCurrent + element.panel_current;
        this.totalPower = this.totalPower + element.panel_power;
        this.totalCost = this.totalCost + element.panel_cost;
      }
    }
    for (const panel of this.panels) {
      panel.panelCurrentPercentage =
        (100 * panel.panel_current) / this.totalCurrent;
      panel.panelPowerPercentage = (100 * panel.panel_power) / this.totalPower;
      panel.panelCostPercentage = (100 * panel.panel_cost) / this.totalCost;
    }
    if (this.fetchLiveAPI) {
      if (this.sidebarCategoryActive == true) {
        let series = this.circuitCategoryMain.map(circuitCategory =>
          circuitCategory.totalCurrent != null && this.totalCurrent != null
            ? this.customToFixed(
                (100 * circuitCategory.totalCurrent) / this.totalCurrent,
                1,
                true
              )
            : null
        );
        series = series
          .filter(ele => !isNaN(ele!) && ele != null)
          .map(ele => parseFloat(ele!.toString()));
        if (series.length > 0) {
          if (!this.chartOptions.initialize) {
            this.chartOptions = {
              series: series,
              dataLabels: { enabled: false },
              legend: { show: false },
              chart: { type: 'donut', width: '320px' },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        label: '',
                        formatter: () => 'Text you want'
                      }
                    }
                  }
                }
              },
              colors: this.circuitCategoryMain.map(
                circuitCategory => circuitCategory.colour
              ),
              labels: this.circuitCategoryMain.map(
                circuitCategory => circuitCategory.name
              ),
              responsive: [
                {
                  breakpoint: 1000,
                  options: {
                    chart: { width: 10 },
                    legend: { position: 'bottom' }
                  }
                }
              ],
              initialize: true,
              stroke: {
                show: false
              }
            };
          }
          this.chartOptions.series = series;
        }
      }
      if (this.sidebarPanelActive == true) {
        let series = this.panels.map(panel =>
          panel.panelCurrentPercentage != null &&
          !Number.isNaN(panel.panelCurrentPercentage)
            ? this.customToFixed(panel.panelCurrentPercentage, 1, true)
            : null
        );
        series = series
          .filter(ele => !isNaN(ele!) && ele != null)
          .map(ele => parseFloat(ele!.toString()));
        if (series.length > 0) {
          if (!this.chartOptionsPanel.initialize) {
            this.chartOptionsPanel = {
              series: series,
              dataLabels: { enabled: false },
              legend: { show: false },
              chart: { type: 'donut', width: '320px' },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        label: '',
                        formatter: () => 'Text you want'
                      }
                    }
                  }
                }
              },
              colors: this.panels.map(panel => panel.colour),
              labels: this.panels.map(panel => panel.panel_name),
              responsive: [
                {
                  breakpoint: 1000,
                  options: {
                    chart: { width: 10 },
                    legend: { position: 'bottom' }
                  }
                }
              ],
              initialize: true,
              stroke: {
                show: false
              }
            };
          }
          this.chartOptionsPanel.series = series;
        }
      }
    }
  }

  setExpansion(expansion: any, element: Panel) {
    let tic;
    let total_current = 0;
    if (expansion['expansion_id'] == 'ext1') {
      tic = 'A-';
    } else if (expansion['expansion_id'] == 'ext2') {
      tic = 'B-';
    } else if (expansion['expansion_id'] == 'ext3') {
      tic = 'C-';
    } else if (expansion['expansion_id'] == 'ext4') {
      tic = 'D-';
    } else {
      tic = '';
    }
    for (let k = 0; k < 16; k++) {
      let currentValue = expansion['data'][`I${k + 1}`];
      let keyPair = {
        expansion_number: tic + (k + 1),
        current: currentValue
      };
      if (element.panel_expansion[tic + (k + 1)]) {
        let circuit: Circuit =
          element.panel_circuitsIndexed[element.panel_expansion[tic + (k + 1)]];
        if (circuit != null) {
          circuit.circuit_current = parseFloat(currentValue);
          circuit.connected = true;
          circuit.circuit_disabled = false;
          circuit.circuit_percentage = '0%';
          circuit.circuit_power = circuit.circuit_current * element.panel_volts;
          circuit.circuit_cost = circuit.circuit_power * this.costPerKwh;
          this.circuitCurrentMapping[circuit.id] = circuit;
        }
        total_current = total_current + parseFloat(currentValue);
      }

      this.powerPercetangeObject.push(keyPair);
    }
    element.panelIsconnected = true;
    return total_current;
  }

  getUsage(element: any) {
    if (this.fetchLiveAPI) {
      let total_current = 0;
      this.powerPercetangeObject = [];
      if (!element.meter_name) {
        return;
      }
      this.dashboardDataService
        .fetchLatestMeterData(element.meter_name)
        .subscribe(result => {
          if (Object.keys(result).length === 0) {
            element.fetchsetInterval = false;
            return;
          }
          if (result['main'] != null) {
            element.panel_volts =
              parseFloat(result['main']['data']['U1']) |
              parseFloat(result['main']['data']['U2']) |
              parseFloat(result['main']['data']['U3']);
          }
          for (const [key, value] of Object.entries(result)) {
            if (value == null || key == 'main') {
              continue;
            }
            total_current =
              total_current + this.setExpansion(value, element) || 0;
          }
          element.panel_current = Number(total_current.toFixed(3));
          this.fetchsetInterval = true;
          // for circuit categories
          for (const circuitCategory of this.circuitCategoryMain) {
            this.isInventryCollapsed.push(true);
            let totalCurrentCategory: number = 0;
            let totalPowerCategory: number = 0;
            let totalCostCategory: number = 0;
            for (const element of circuitCategory.circuit_set) {
              if (this.circuitCurrentMapping[element.id]) {
                element['connected'] =
                  this.circuitCurrentMapping[element.id].connected;
                element['circuit_disabled'] =
                  this.circuitCurrentMapping[element.id].circuit_disabled;
                element['circuit_current'] =
                  this.circuitCurrentMapping[element.id].circuit_current;
                element['circuit_power'] =
                  this.circuitCurrentMapping[element.id].circuit_power;
                element['circuit_cost'] =
                  this.circuitCurrentMapping[element.id].circuit_cost;
                totalCurrentCategory =
                  totalCurrentCategory +
                  this.circuitCurrentMapping[element.id].circuit_current;
                totalPowerCategory =
                  totalPowerCategory +
                  this.circuitCurrentMapping[element.id].circuit_power;
                totalCostCategory =
                  totalCostCategory +
                  this.circuitCurrentMapping[element.id].circuit_cost;
              }
            }
            circuitCategory['totalCurrent'] = totalCurrentCategory;
            circuitCategory['totalPower'] = totalPowerCategory;
            circuitCategory['totalCost'] = totalCostCategory;
            circuitCategory['circuitIsconnected'] = true;
          }
        });
    }
  }

  radioMainEdit(event: any) {
    if (event.target.id == 'radioPanelCurrent') {
      (
        document.getElementById('radioPanelCurrent') as HTMLInputElement
      ).checked = true;
      (
        document.getElementById('radioPanelEnergy') as HTMLInputElement
      ).checked = false;
      (document.getElementById('radioPanelCost') as HTMLInputElement).checked =
        false;
      this.currentSelection = true;
      this.energySelection = false;
      this.costSelection = false;
      // document.getElementById('panel_current')?.innerHTML = "";
    } else if (event.target.id == 'radioPanelEnergy') {
      (
        document.getElementById('radioPanelCurrent') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('radioPanelEnergy') as HTMLInputElement
      ).checked = true;
      (document.getElementById('radioPanelCost') as HTMLInputElement).checked =
        false;
      this.currentSelection = false;
      this.energySelection = true;
      this.costSelection = false;
    } else if (event.target.id == 'radioPanelCost') {
      (
        document.getElementById('radioPanelCurrent') as HTMLInputElement
      ).checked = false;
      (
        document.getElementById('radioPanelEnergy') as HTMLInputElement
      ).checked = false;
      (document.getElementById('radioPanelCost') as HTMLInputElement).checked =
        true;
      this.currentSelection = false;
      this.energySelection = false;
      this.costSelection = true;
    }
  }

  sidetab(type: string) {
    if (type == 'category') {
      this.sidebarCategoryActive = true;
      this.sidebarPanelActive = false;
    } else {
      this.sidebarPanelActive = true;
      this.sidebarCategoryActive = false;
    }
  }

  checkValue(event: any) {
    var targetedValue = event.target.value;

    // When unchecked
    if (!event.target.checked) {
      var index = 0;
      this.circuitCategoryMain.forEach(element => {
        if (element.name == targetedValue) {
          index = this.circuitCategoryMain.indexOf(element);
        }
      });
      if (index !== -1) {
        let obj = this.circuitCategoryMain.splice(index, 1);
        this.stack.push(obj[0]);
      }
    }

    // When checked
    if (event.target.checked) {
      var index = 0;
      this.stack.forEach(element => {
        if (element.name == targetedValue) {
          index = this.stack.indexOf(element);
        }
      });
      if (index !== -1) {
        let obj = this.stack.splice(index, 1);
        this.circuitCategoryMain.push(obj[0]);
      }
    }
  }

  // toggleDropdown(index: number): void{
  //     this.isCollapsed[index] = !this.isCollapsed[index]
  // }
}
