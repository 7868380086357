import { Component, OnInit, ViewChild } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { customToFixed,groupCircuitsByCircuitGroup } from "src/app/helpers/circuit";
import { ChartOptions } from "src/app/live-usage/live-usage.component";
import { Building } from "src/app/models/building";
import { DashboardDataService } from "src/app/services/dashboard-data/dashboard-data.service";
import { MatDialog } from "@angular/material/dialog";
import { CircuitEcmInfoComponent } from "../circuit-ecm-info/circuit-ecm-info.component";
import { ActivatedRoute } from "@angular/router";
import { Circuit } from "../../../../models/circuit";

interface CircuitECMSelection {
  total_energy: number;
  id: number;
  ecm: {
    id: number;
    name: string;
    description: string;
  };
  circuit: {
    circuit_category: any;
    circuit_name: string;
    id: number;
    is_monitored: boolean;
  };
  status: string;
  savings: {
    [key: number]: {
      total_usage: number;
      total_saving_formatted: string;
      total_saving: string;
      total_power_formatted: string;
      total_power: string;
      total_energy: any;
      mean: number;
      end_date: string;
      max_saving: number;
      min_saving: number;
      start_date: string;
      ecm_scan_id: number;
      min_saving_formatted: string;
      max_saving_formatted: string;
      total_energy_formatted: string
    };
  };
  created_at: string;
  updated_at: string;
}

interface RecordKeyVal {
  value: number;
  color: string;
}

interface CircuitECMType {
  name: string;
  res: number;
  color: string;
}

@Component({
  selector: "app-savings-scan",
  templateUrl: "./savings-scan.component.html",
  styleUrls: ["./savings-scan.component.scss"],
})
export class SavingsScanComponent implements OnInit {
  @ViewChild("chart", { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any = { initialize: false };
  public chartOptionsExploreEnergy: Partial<ChartOptions> | any = {
    initialize: false,
  };
  public chartOptionsECMBreakdown: Partial<ChartOptions> | any;
  public chartOptionsPanel: Partial<ChartOptions> | any;

  public scan_id: number;
  public timeperiod: number;
  public isExploreEnergyLoss: boolean;
  public startFullDate: String;
  public endFullDate: String;
  rejected_scan: Boolean;

  public modes = [
    {
      id: "energy",
      label: "Energy",
      getValue: (d: number) => `${this.toKwH(d, 3)} kWh`,
    },
    {
      id: "cost",
      label: "Cost",
      getValue: (d: number) => `$${this.toCost(d, 2)}`,
    },
  ];

  public DEMONINATOR = 3600000;

  public sortOptions = [
    {
      id: "savings",
      label: "Savings",
      selector: (d: CircuitECMSelection) =>
        Number(d.savings[this.scan_id].total_power) ?? 0,
      getTotal: () => this.total_accepted_max_savings,
      min_savings: (d: CircuitECMSelection) =>
        d.savings[this.scan_id].min_saving ?? 0,
    },
  ];

  public selectedTab = 0;

  public selectedMode = 0;
  public selectedSort = 0;
  circuitCategoryMain: any[];
  public circuitCategoryMainLength = 0;
  public isInventryCollapsed: boolean[] = [];
  public circuitCurrentMapping: { [key: number]: Circuit } = {};
  public circuitCategoryCurrentMapping: { [key: number]: number } = {};
  public circuitCategoryPowerMapping: { [key: number]: number } = {};
  public circuitCategoryCostMapping: { [key: number]: number } = {};
  public circuitECMTypeMapping = new Map();
  public circuitECMTypeMappingArr: CircuitECMType[] = [];
  public buildingId = "";
  sidebarCategoryActive = true;
  sidebarPanelActive = false;
  public totalAssetCurrent: number = 0;
  public isCircuitsLoaded = false;
  public isRejectedECMLoaded = false;

  public total_usage = 0;
  public maxUsage = 0;
  public minUsage = 0;

  public total_circuit_total_on_hours_usage = 0;
  public total_on_hours_usage = 0;

  public suggestedECMsList: CircuitECMSelection[] = [];
  public rejectedECMsList: CircuitECMSelection[] = [];
  public total_percentage = 0;

  public rangeSelectedDay = true;
  public rangeSelectedWeek = false;
  public rangeSelectedMonth = false;
  public intervalSelected = "60m";

  public selectedMonth: Date = new Date();
  public selectedDay: Date = new Date();

  public customToFixed = customToFixed;

  private building: Building;
  public operatingHours: any = [];

  public assets: [string, RecordKeyVal][] = [];
  public panels: [string, RecordKeyVal][] = [];

  public sidetabOptions = [
    { id: "assets", label: "Assets", getOptions: () => this.assets },
    { id: "panel", label: "Panel", getOptions: () => this.panels },
  ];

  // public selectedSidetabOptions = 0;
  public isLoading = true;
  public total_accepted_min_savings = 0;
  public total_accepted_max_savings = 0;
  public total_accepted_min_savings_formatted = "0";
  public total_accepted_max_savings_formatted = "0";

  public total_rejected_min_savings = 0;
  public total_rejected_max_savings = 0;
  public total_rejected_min_savings_formatted = "0";
  public total_rejected_max_savings_formatted = "0";

  public energyLossCount: number;
  public ecmCircuitCount: number;

  constructor(
    private dashboardService: DashboardDataService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    this.chartOptions = {
      initialize: false,
    };
    this.chartOptionsPanel = {
      initialize: false,
    };

    this.chartOptionsExploreEnergy = {
      initialize: false,
    };

    this.route.queryParams.subscribe((params) => {
      this.buildingId = params["building_id"];
      this.scan_id = params["id"] || params["scan_id"];
      this.timeperiod = params["timeperiod"];
      this.isExploreEnergyLoss = params["isExploreEnergyLoss"];
      this.startFullDate = params["startFullDate"];
      this.endFullDate = params["endFullDate"];
      this.energyLossCount = params["energyLossCount"];
      this.ecmCircuitCount = params["ecm_circuit_count"];
    });

    const subs = this.dashboardService.currentBuilding.subscribe((result) => {
      if (!result) return;

      this.building = result;

      this.getAll();

      subs.unsubscribe();
    });
  }

  getCircuitUsageList() {
    this.isLoading = true;

    const subs = this.dashboardService
      .getEcmsAcceptedOrUnReviewed(this.scan_id)
      .subscribe((result: CircuitECMSelection[]) => {
        this.total_accepted_min_savings = 0;
        this.total_accepted_max_savings = 0;
        this.total_rejected_min_savings = 0;
        this.total_rejected_max_savings = 0;

        this.suggestedECMsList = [];
        this.rejectedECMsList = [];

        result.forEach((row) => {
          if (row.status === "unreviewed" || row.status === "accepted") {
            this.suggestedECMsList.push(row);
            // save ECM's type
            const typeName = row.ecm?.name;
            const color = row.circuit?.circuit_category?.colour;
            if (this.circuitECMTypeMapping.has(typeName)) {
              this.circuitECMTypeMapping.set(typeName, {
                res: +this.circuitECMTypeMapping.get(typeName).res + 1,
                color: color,
              });
            } else {
              this.circuitECMTypeMapping.set(typeName, {
                res: 1,
                color: color,
              });
            }

            const d = row.savings[this.scan_id];
            if (d.min_saving) {
              const minSavings =
                typeof d.min_saving === "string"
                  ? parseFloat(d.min_saving)
                  : d.min_saving;
              this.total_accepted_min_savings += minSavings;
              d.min_saving_formatted = this.toKwH(minSavings, 3);
            }
            if (d.max_saving) {
              const maxSavings =
                typeof d.max_saving === "string"
                  ? parseFloat(d.max_saving)
                  : d.max_saving;
              this.total_accepted_max_savings += maxSavings;
              d.max_saving_formatted = this.toKwH(maxSavings, 3);
            }
            //TODO
            if (d.total_power) {
              const total_power =
                typeof d.total_power === "string"
                  ? parseFloat(d.total_power)
                  : d.total_power;
              d.total_power_formatted = this.toKwH(total_power, 3);
              this.total_usage += total_power;
              this.maxUsage = Math.max(this.maxUsage, total_power);
            }
            if (d.total_saving) {
              const total_saving =
                typeof d.total_saving === "string"
                  ? parseFloat(d.total_saving)
                  : d.total_saving;
              d.total_saving_formatted = this.toKwH(total_saving, 3);
              this.total_accepted_max_savings += total_saving;
            }
          } else if (row.status === "rejected") {
            this.rejectedECMsList.push(row);
            const d = row.savings[this.scan_id];
            if (d.min_saving) {
              const minSavings =
                typeof d.min_saving === "string"
                  ? parseFloat(d.min_saving)
                  : d.min_saving;
              this.total_rejected_min_savings += minSavings;
              d.min_saving_formatted = this.toKwH(minSavings, 3);
            }
            if (d.max_saving) {
              const maxSavings =
                typeof d.max_saving === "string"
                  ? parseFloat(d.max_saving)
                  : d.max_saving;
              this.total_rejected_max_savings += maxSavings;
              d.max_saving_formatted = this.toKwH(maxSavings, 3);
            }
          }
        });
        this.total_accepted_min_savings_formatted = this.toKwH(
          this.total_accepted_min_savings,
          3
        );
        this.total_accepted_max_savings_formatted = this.toKwH(
          this.total_accepted_max_savings,
          3
        );

        this.total_rejected_min_savings_formatted = this.toKwH(
          this.total_rejected_min_savings,
          3
        );
        this.total_rejected_max_savings_formatted = this.toKwH(
          this.total_rejected_max_savings,
          3
        );

        this.total_usage = +this.toKwH(this.total_usage, 3);

        this.getSortedCircuitList();
        this.isLoading = false;
        subs.unsubscribe();
      });
  }

  getCircuitUsageListRejected() {
    this.isLoading = true;

    const subs = this.dashboardService
      .getEcmsRejected(this.scan_id)
      .subscribe((result: CircuitECMSelection[]) => {
        this.total_rejected_min_savings = 0;
        this.total_rejected_max_savings = 0;

        this.rejectedECMsList = [];

        result.forEach((row) => {
          if (row.status === "rejected") {
            this.rejectedECMsList.push(row);
            const d = row.savings[this.scan_id];
            if (d.min_saving) {
              const minSavings =
                typeof d.min_saving === "string"
                  ? parseFloat(d.min_saving)
                  : d.min_saving;
              this.total_rejected_min_savings += minSavings;
              d.min_saving_formatted = this.toKwH(minSavings, 3);
            }
            if (d.max_saving) {
              const maxSavings =
                typeof d.max_saving === "string"
                  ? parseFloat(d.max_saving)
                  : d.max_saving;
              this.total_rejected_max_savings += maxSavings;
              d.max_saving_formatted = this.toKwH(maxSavings, 3);
            }
          }
        });

        this.total_rejected_min_savings_formatted = this.toKwH(
          this.total_rejected_min_savings,
          3
        );
        this.total_rejected_max_savings_formatted = this.toKwH(
          this.total_rejected_max_savings,
          3
        );

        this.total_usage = +this.toKwH(this.total_usage, 3);

        this.getSortedCircuitList();
        this.isLoading = false;
        this.isRejectedECMLoaded = true;
        subs.unsubscribe();
      });
  }

  getFormattedDate(date: Date) {
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
      "0" + date.getDate()
    ).slice(-2)}`;
  }

  getSortedCircuitList() {
    const { selector } = this.sortOptions[this.selectedSort];
    const list = structuredClone(this.suggestedECMsList);
    list.sort((a, b) => selector(b) - selector(a));
    this.suggestedECMsList = list;

    const list2 = structuredClone(this.rejectedECMsList);
    list2.sort((a, b) => selector(b) - selector(a));
    this.rejectedECMsList = list2;

    this.getChartsOptions();
  }

  toKwH(value: number, fixed = 1) {
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: fixed,
    }).format(value / this.DEMONINATOR);
  }

  toCost(value: number, fixed = 1, cost = 0.192) {
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: fixed,
    }).format((value * cost) / this.DEMONINATOR);
  }

  toPercentage(value: number) {
    return (
      (value / this.sortOptions[this.selectedSort].getTotal()) *
      100
    ).toFixed(1);
  }
  toCircuitPercentage(circuitUsage: CircuitECMSelection) {
    let value = circuitUsage.savings[this.scan_id].max_saving;
    let x = (
      (value / this.sortOptions[this.selectedSort].getTotal()) *
      100
    ).toFixed(1);
    // console.log(x);
    return x;
  }

  get circuitEntries() {
    return Array.from(this.circuitECMTypeMapping.entries());
  }

  toEnergyInsightsPower(circuitUsage: CircuitECMSelection, type: Number) {
    const total_power: number = +this.toKwH(
      +circuitUsage.savings[this.scan_id].total_power
    );
    const max_power: number = +this.toKwH(
      +this.maxUsage
    );
    const total_saving: number = +this.toKwH(
      +circuitUsage.savings[this.scan_id].total_saving
    );
    const relative_power: number = (total_power / max_power) * 100;
    const savingsPercent: number = (total_saving / total_power) * relative_power; // Orange part
    console.log(circuitUsage.circuit.circuit_name, circuitUsage.ecm.name, savingsPercent, relative_power);
    if (type == 0) {
      return relative_power - savingsPercent; // Green part
    } else {
      return savingsPercent; // Orange part
    }
  }

  toEnergyInsightsPowerPercentage(circuitUsage: CircuitECMSelection) {
    const total_power: number = +this.toKwH(
      +circuitUsage.savings[this.scan_id].total_power,
      3
    );
    const total_saving: number = +this.toKwH(
      +circuitUsage.savings[this.scan_id].total_saving,
      3
    );
    const percent: number = (total_saving / total_power) * 100; // Orange part
    return percent == 100 ? percent.toFixed(0) : percent.toFixed(2);
  }
  toTotalSavingsPercentage(circuitUsage: CircuitECMSelection) {
    let value = circuitUsage.total_energy;
    let x = (
      (value / this.sortOptions[this.selectedSort].getTotal()) *
      100
    ).toFixed(1);
    return x + 2;
  }

  toEnergyInsightsTotalPower(circuitUsage: CircuitECMSelection) {
    const total_power: number = +this.toKwH(
      +circuitUsage.savings[this.scan_id].total_power,
      3
    );
    return `${total_power} kWh`;
  }

  totalEnergySavedPotential(circuitUsage: CircuitECMSelection, type: Number) {
    const total_saving: number = +this.toKwH(
      +circuitUsage.savings[this.scan_id].total_saving,
      3
    );
    if (type == 0) return total_saving;
    else return `${total_saving} kWh`;
  }
  totalEnergySavedRejected(circuitUsage: CircuitECMSelection){
    const total_saving: number = +this.toKwH(
      +circuitUsage.savings[this.scan_id].total_saving
    );
    
    return `0 - ${total_saving} kWh`;
  }

  calculatePercentageECMs(circuitCategory: any) {
    const percent =
      (circuitCategory?.circuit_set?.length / this.circuitCategoryMainLength) *
      100;
    return Math.round(percent);
  }

  calculatecircuitECMTypeMappingArrPercent(res: number) {
    return Math.round((res / this.suggestedECMsList.length) * 100);
  }

  toUtc(date: Date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  }

  get formattedDate(): Date {
    return this.rangeSelectedDay ? this.selectedDay : this.selectedMonth;
  }

  get dateFormat(): string {
    return this.rangeSelectedDay ? "dd MMM yyyy" : "LLLL YYYY";
  }

  handleTabChange(number: Number) {
    this.selectedTab = +number;
    if (+number == 1) {
      if (!this.isCircuitsLoaded) {
        this.isLoading = true;
        this.getCircuitCategory(this.buildingId);
      }
    }else if(+number == 3 && !this.isRejectedECMLoaded) {
      this.getCircuitUsageListRejected()
    }
  }

  getDateSlice(date: string | undefined, index: number) {
    return Number(date?.split(":")[index] ?? "");
  }

  getAmOrPm(date: string) {
    return this.getDateSlice(date, 0) < 12 ? "AM" : "PM";
  }

  getChartsOptions() {
    const options = [
      {
        name: "Suggested ECMs",
        value: this.suggestedECMsList.length,
        color: "#1FAB3D",
      },
      {
        name: "Rejected ECMs",
        value: this.rejectedECMsList.length,
        color: "#7F7F7F",
      },
    ];

    this.chartOptions = {
      series: options.map((d) => d.value),
      dataLabels: { enabled: false },
      legend: { show: false },
      chart: { type: "donut", width: "100%" },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: "",
                formatter: () => "Text you want",
              },
            },
          },
        },
      },
      colors: options.map((d) => d.color),
      labels: options.map((d) => d.name),
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: { width: 10 },
            legend: { position: "bottom" },
          },
        },
      ],
      initialize: true,
      stroke: { show: false },
    };

    let savings = +this.total_accepted_max_savings_formatted
    const savingsCorrected = savings > this.total_usage ? this.total_usage : savings
    const totalEnergy = +this.total_usage
    const savingsPercentage = Math.round((savingsCorrected / totalEnergy) * 100)
    const totalEnergyPercentage = Math.round(100 - savingsPercentage) 
    const optionschartOptionsExploreEnergy = [
      {
        name: "Energy Loss %",
        value: savingsPercentage,
        color: "#FF7F27",
      },
      {
        name: "Energy Used Efficiently %",
        value: totalEnergyPercentage,
        color: "#1FAB3D",
      },
    ];

    this.chartOptionsExploreEnergy = {
      series: optionschartOptionsExploreEnergy.map((d) => d.value),
      dataLabels: { enabled: false },
      legend: { show: false },
      chart: { type: "donut", width: "100%" },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: "",
                formatter: () => "Text you want",
              },
            },
          },
        },
      },
      colors: optionschartOptionsExploreEnergy.map((d) => d.color),
      labels: optionschartOptionsExploreEnergy.map((d) => d.name),
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: { width: 10 },
            legend: { position: "bottom" },
          },
        },
      ],
      initialize: true,
      stroke: { show: false },
    };
  }

  getAll() {
    this.getCircuitUsageList();
  }

  openCircuitModal(circuit: CircuitECMSelection) {
    console.log(circuit);
    const dialogRef = this.dialog.open(CircuitEcmInfoComponent, {
      data: {
        circuitEcmSelection: circuit,
        timeperiod: this.timeperiod,
        isExploreLossInsights: this.isExploreEnergyLoss,
        scan_id: this.scan_id,
        total_savings: circuit.savings[this.scan_id].total_saving_formatted,
        total_power: circuit.savings[this.scan_id].total_power_formatted,
        percentage_loss: Number(
          this.toEnergyInsightsPowerPercentage(circuit)
        ).toFixed(2),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  ecmAction(circuit: CircuitECMSelection, action: number) {
    const actionMap = {
      0: "rejected",
      1: "accepted",
      2: "unreviewed",
    } as const;
    this.dashboardService
      .updateCircuitEcmStatus(
        circuit.circuit.id,
        circuit.ecm.id,
        actionMap[action as keyof typeof actionMap]
      )
      .subscribe((result) => {
        if (result == null) {
          if (action === 0) {
            this.rejectedECMsList.push(circuit);
            this.suggestedECMsList = this.suggestedECMsList.filter(
              (c) => c.circuit.id !== circuit.circuit.id
            );
          } else if (action === 1) {
            this.suggestedECMsList.push(circuit);
            this.rejectedECMsList = this.rejectedECMsList.filter(
              (c) => c.circuit.id !== circuit.circuit.id
            );
          }

          this.getSortedCircuitList();
        }
      });
  }

  getCircuitCategory(buildingId: any): void {
    this.dashboardService
      .getCircuitCategory(buildingId)
      .forEach((circuitCategory: any) => {
        this.circuitCategoryMain = JSON.parse(
          JSON.stringify(circuitCategory.results)
        );

        const filteredData = [...this.circuitCategoryMain].filter(
          (item) => item.circuit_set.length > 0
        );
        const sortedData = filteredData.sort(
          (a, b) => b.circuit_set.length - a.circuit_set.length
        );
        this.circuitCategoryMain = sortedData;

        //Calculate total length of the circuit
        this.circuitCategoryMain.forEach((item) => {
          const length = item.circuit_set?.length;
          this.circuitCategoryMainLength += +length;
        });

        let totalCurrentCategory = 0;
        let totalPowerCategory = 0;
        let totalCostCategory = 0;
        for (const circuitCategory of this.circuitCategoryMain) {
          this.isInventryCollapsed.push(true);
          circuitCategory.circuit_setGroup = groupCircuitsByCircuitGroup(
            circuitCategory.circuit_set
          );
          for (const element of circuitCategory.circuit_set) {
            element.circuit_group_temp =
              element.circuit_group == null || element.circuit_group == ""
                ? element.circuit_name
                : element.circuit_group;
            if (this.circuitCurrentMapping[element.id]) {
              element["connected"] =
                this.circuitCurrentMapping[element.id].connected;
              element["circuit_disabled"] =
                this.circuitCurrentMapping[element.id].circuit_disabled;
              element["circuit_current"] =
                this.circuitCurrentMapping[element.id].circuit_current;
              element["circuit_power"] =
                this.circuitCurrentMapping[element.id].circuit_power;
              element["circuit_cost"] =
                this.circuitCurrentMapping[element.id].circuit_cost;
              totalCurrentCategory =
                totalCurrentCategory +
                this.circuitCurrentMapping[element.id].circuit_current;
              totalPowerCategory =
                totalPowerCategory +
                this.circuitCurrentMapping[element.id].circuit_power;
              totalCostCategory =
                totalCostCategory +
                this.circuitCurrentMapping[element.id].circuit_cost;
            }
          }
          this.circuitCategoryCurrentMapping[circuitCategory.id] =
            totalCurrentCategory;
          this.circuitCategoryPowerMapping[circuitCategory.id] =
            totalPowerCategory;
          this.circuitCategoryCostMapping[circuitCategory.id] =
            totalCostCategory;
        }

        for (let [key, value] of this.circuitECMTypeMapping) {
          const val = value["res"];
          const obj: CircuitECMType = {
            name: key,
            res: value["res"],
            color: value["color"],
          };
          if (val > 0) {
            this.circuitECMTypeMappingArr.push(obj);
          }
        }
        this.circuitECMTypeMappingArr.sort((a, b) => b.res - a.res);

        this.chartOptionsECMBreakdown = {
          series: this.circuitCategoryMain.map(
            (ele) => ele.circuit_set?.length
          ),
          dataLabels: { enabled: false },
          legend: { show: false },
          chart: { type: "donut", width: "260px" },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    label: "",
                    formatter: () => "Text you want",
                  },
                },
              },
            },
          },
          colors: this.circuitCategoryMain.map((d) => d.colour),
          labels: this.circuitCategoryMain.map((d) => d.name),
          responsive: [
            {
              breakpoint: 1000,
              options: {
                chart: { width: 10 },
                legend: { position: "bottom" },
              },
            },
          ],
          initialize: true,
          stroke: { show: false },
        };
        this.isLoading = false;
        this.isCircuitsLoaded = true;
      });
  }

  sidetab(type: string) {
    if (type == "category") {
      this.sidebarCategoryActive = true;
      this.sidebarPanelActive = false;
    } else {
      this.sidebarPanelActive = true;
      this.sidebarCategoryActive = false;
    }
  }

  ngOnInit(): void {}
}
