import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private clickedFacilityId: any;
  private clickedFacilityName: any;

  setClickedFacilityId(data: any) {
    this.clickedFacilityId = data;
  }

  getClickedFacilityId() {
    return this.clickedFacilityId;
  }

  setClickedFacilityName(data: any) {
    this.clickedFacilityName = data;
  }

  getClickedFacilityName() {
    return this.clickedFacilityName;
  }
}
