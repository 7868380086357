import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Circuit } from 'src/app/models/circuit';
import { DashboardDataService } from 'src/app/services/dashboard-data/dashboard-data.service';
import { ChartComponent } from "ng-apexcharts";
import { ChartOptions } from "src/app/live-usage/live-usage.component";

@Component({
  selector: 'app-circuit-ecm-info-component',
  templateUrl: './circuit-ecm-info.component.html',
  styleUrls: ['./circuit-ecm-info.component.scss']
})
export class CircuitEcmInfoComponent implements OnInit {
    @ViewChild("chart", { static: false }) chart: ChartComponent;
    public chartOptions: Partial<ChartOptions> | any = { initialize: false };
    public DEMONINATOR = 3600000;
    public circuitEcmSelection = this.data.circuitEcmSelection;
    public circuit = this.circuitEcmSelection.circuit;
    public timeperiod = this.data.timeperiod;
    public isExploreLossInsights = this.data.isExploreLossInsights;
    public activeId = 0;
    public total_power = this.data.total_power;
    public total_savings = this.data.total_savings;
    public percentage_loss = 0;

    panelColor = '';
    categoryColor  = '';
    deleteECMRequest = false;
    public circuitCommentary = {
        ecm : 'Turn off lights - After hours',
        purpose: 'To reduce energy consumption',
        description: 'Turning off lights after hours can save energy and reduce energy consumption. This is a simple and effective way to reduce energy consumption and save money.',
        notice : `We continuously monitor the "{circuit_name}" circuit 24/7, collecting comprehensive data on its usage patterns. By analyzing this information in conjunction with the facility's operating hours, we can accurately calculate potential energy savings. This method allows us to precisely determine how much energy and cost could be saved by implementing the suggested ECMs, such as manually turning off lights after hours. Our data-driven approach ensures that our savings estimates are reliable and tailored to the specific needs of the facility.`,
    }

    public tabOptions = [
        {
          id: 'suggested-ecm',
          label: 'Suggested ECMs',
          color: '#1FAB3D',
          header: 'ECM Overview',
          svg:`
`
        },
        {
          id: 'rejected-ecm',
          label: 'Rejected ECMs',
          color: '#E04A41',
          header: 'Circuit Overview',
        },
        {
            id: 'all-ecm',
            label: 'All ECMs',
            color: '#1FAB3D',
            header: 'Methodology',
        }
      ];
    
    public selectedTab = 0;
    public options = [];
    public total_usage = 0;

  constructor(
    private dashboardDataService: DashboardDataService,
    public dialogRef: MatDialogRef<CircuitEcmInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.circuitEcmSelection = this.data.circuitEcmSelection;
    this.total_power = +this.data.total_power,
    this.total_savings = +this.data.total_savings,
    this.percentage_loss = this.data.percentage_loss;
    this.dashboardDataService.getCircuit(this.circuitEcmSelection.circuit.id).subscribe((circuit_db: Circuit) => {
        this.circuit = circuit_db;
        this.panelColor = this.circuit?.panel?.colour;
        this.categoryColor = this.circuit?.category_color;
        const options = [
          {
            name: "Total Energy Consumed kWh",
            value: this.total_savings - this.total_power,
            color: "#1FAB3D",
          },
          {
            name: "Energy Loss Detected kWh",
            value: this.total_savings,
            color: "#FF7F27",
          },
        ];
        this.chartOptions = {
          series: options.map((d) => d.value),
          dataLabels: { enabled: false },
          legend: { show: false },
          chart: { type: "donut", width: "100%" },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    label: "",
                    formatter: () => "Text you want",
                  },
                },
              },
            },
          },
          colors: options.map((d) => d.color),
          labels: options.map((d) => d.name),
          responsive: [
            {
              breakpoint: 1000,
              options: {
                chart: { width: 10 },
                legend: { position: "bottom" },
              },
            },
          ],
          initialize: true,
          stroke: { show: false },
        };
    }) ;
  }

  closePopup() {
    this.dialogRef.close();
  }
  handleTabChange(i: number) {
    if (this.selectedTab !== i) {
      this.selectedTab = i;
    }
  }
  handleECMDeletion(option: number){
    if(option == 0)
      this.deleteECMRequest = true;

    if(option == 1)
      this.deleteECMRequest = false;
  }
  ngOnInit(): void {}

  }
