import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { customToFixed } from 'src/app/helpers/circuit';
import { ChartOptions } from 'src/app/live-usage/live-usage.component';
import { Building } from 'src/app/models/building';
import { DashboardDataService } from 'src/app/services/dashboard-data/dashboard-data.service';
import type { ECharts, EChartsOption } from 'echarts';
import { getTimeBlocksLimit } from 'src/utils';

import { Subject, debounceTime, type Subscription } from 'rxjs';

interface BuildingCircuit {
  circuit_category__colour: string;
  circuit_category__name: string;
  circuit_name: string ;
  circuit_number: string;
  circuit_sub_category__name: string;
  id: number;
  panel__building__description: string;
  panel__panel_image: string;
  panel__panel_name: string;
  panel__panel_meter__name: string;
}

interface RecordKeyVal { 
  value: number;
  color: string;
}

@Component({
  selector: 'app-asset-intelligence',
  templateUrl: './asset-intelligence.component.html',
  styleUrls: ['./asset-intelligence.component.scss']
})
export class AssetIntelligenceComponent implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any = { initialize: false };

  public modes = [
    {
      id: 'energy',
      label: 'Energy',
      getValue: (d: number) => `${this.toKwH(d, 3)} kWh`
    },
    {
      id: 'percentage',
      label: 'Percentage %',
      getValue: (d: number) => `${this.toPercentage(d)}%`
    }
  ];

  public DEMONINATOR = 3600000;

  public sortOptions = [
    {
      id: 'cycle_number',
      label: 'Total',
      selector: (d: BuildingCircuit) => d.circuit_name,
      getTotal: () => this.total_circuit_total_usage
    },
    {
      id: 'total_power',
      label: 'Total Power',
      selector: (d: BuildingCircuit) => d.circuit_name,
      getTotal: () => this.total_circuit_total_on_hours_usage
    },
    {
      id: 'max_current',
      label: 'Off-hours',
      selector: (d: BuildingCircuit) => d.circuit_name,
      getTotal: () => this.total_circuit_total_off_hours_usage
    } 
  ]; 

  public selectedMode = 0;
  public selectedSort = 0;

  public total_circuit_total_usage = 0;
  public total_circuit_total_on_hours_usage = 0;
  public total_circuit_total_off_hours_usage = 0;

  public circuit_list: BuildingCircuit[] = [];
  public total_percentage = 0;

  public rangeSelectedDay = false;
  public rangeSelectedWeek = false;
  public rangeSelectedMonth = true;
  public intervalSelected = '60m';

  onDataZoomSubject = new Subject();

  public selectedMonth: Date = new Date();
  public selectedDay: Date = new Date();

  public customToFixed = customToFixed;

  private building: Building;
  public operatingHours: any = [];

  public assets: [string, RecordKeyVal][] = [];
  public panels: [string, RecordKeyVal][] = [];

  public sidetabOptions = [
    { id: 'assets', label: 'Assets', getOptions: () => this.assets },
    { id: 'panel', label: 'Panel', getOptions: () => this.panels }
  ];

  public selectedSidetabOptions = 0;
  public isLoading = true;

  public circuitId: number = 7446;

  public selectedCircuit: BuildingCircuit | undefined;

  lineChartOptions: EChartsOption;
  private lineChartMeterMetrics: any[] = [];

  constructor(private dashboardService: DashboardDataService) {
    this.selectPreviousDay();

    const subs = this.dashboardService.currentBuilding.subscribe(result => {
      if (!result) return;

      this.building = result;

      this.getAll();

      subs.unsubscribe();
    });
  }

  getCircuitCycleList() {
    this.isLoading = true;

    const { start_date, end_date } = this.getDate();
    console.log(this.selectedMonth);
    const subs = this.dashboardService
      .getCircuitsByBuilding(this.building.idbuildings)
      .subscribe((results ) => {
        this.total_circuit_total_usage = 0;
        this.total_circuit_total_on_hours_usage = 0;
        this.total_circuit_total_off_hours_usage = 0;

        this.circuit_list = results;
        if (this.circuit_list.length > 0) {
          this.circuitCycleOnClick(this.circuit_list[this.circuit_list.length -1]);
        }

        this.isLoading = false;
        subs.unsubscribe();
      });
  }

  circuitCycleOnClick(circuit: BuildingCircuit) {
    this.selectedCircuit = circuit;
    // this.selectedCycle = this.circuit_list.find(
    //   (d) => d.cycle_number === cycle_number
    // );
    // this.fetchLineChartMeterAnalytics('em-84fce677c0bc', this.selectedCycle?.start_time ?? new Date(), this.selectedCycle?.end_time ?? new Date());
  }

  fetchLineChartMeterAnalytics(meter_name: string, startDate: Date, endDate: Date) {

    const subs = this.dashboardService
      .fetchMeterAnalyticsData(
        meter_name,
        typeof startDate == 'string' ? startDate : startDate.toISOString(),
        'minute',
        typeof endDate == 'string' ? endDate : endDate.toISOString()
      )
      .subscribe(response => {
        this.lineChartMeterMetrics = response;
        this.plotLineChart();
        subs.unsubscribe();
      });
  }

  plotLineChart() {
    if (!this.lineChartMeterMetrics.length) return;
    let timeBlocksLimit = 24*60;
    const label: string[] = getTimeBlocksLimit(timeBlocksLimit);
    let value: number[] = Array.from({ length: timeBlocksLimit }).fill(0) as number[];

    let x = 0;
    let y = 0;

    let max = -Infinity;

    for (let i = 0; i < value.length; i++) {
      const curr = this.lineChartMeterMetrics[x];
      const currTime = new Date(curr.timestamp).toLocaleTimeString('en-GB');

      if (currTime === label[y]) {
        const power = (curr.circuit_powers[this.circuitId] ?? 0) / 3600000;
        if (power > max) max = power;
        value[i] = power;
        x++;
      }

      if (x === this.lineChartMeterMetrics.length) break;

      y++;
    }

    const date = new Date(
      this.lineChartMeterMetrics[0].timestamp
    ).toLocaleDateString();
    
    this.lineChartOptions = {
      xAxis: {
        type: 'category',
        data: label.map(d => `${date}\n${d}`),
        boundaryGap: false,
        axisLine: { lineStyle: { color: '#6E7079', width: 1 } },
        axisLabel: {
          interval: 'auto',
          show: true,
          margin: 8,
          fontSize: 12
        }
      },
      yAxis: {
        type: 'value',
        alignTicks: false,
        boundaryGap: [0, 0],
        minorTick: { show: false },
        animationEasing: 'linear',
        max: Math.max(max, 0),
        min: 0,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 1,
            type: 'dotted'
          }
        },
        axisLabel: {
          formatter: (value: number) => {
            return `${this.customToFixed(value, 4)}kWh`;
          }
        }
      },
      series: [
        {
          data: value,
          type: 'line',
          showSymbol: false,
          smooth: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 3,
            type: 'solid'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(31, 171, 61, 1)' },
                { offset: 0.3, color: 'rgba(138, 223, 156, 1)' },
                { offset: 1, color: 'rgba(255, 255, 255, 1)' }
              ]
            }
          },
          universalTransition: { divideShape: 'clone' }
        }
      ],
      // stateAnimation: { duration: 300, easing: 'cubicOut' },
      // tooltip: {
      //   trigger: 'axis',
      //   axisPointer: {
      //     type: 'line',
      //     label: { backgroundColor: '#505765' },
      //     axis: 'y',
      //     animationEasingUpdate: 'exponentialOut',
      //     animationDurationUpdate: 200,
      //     crossStyle: { color: '#999', width: 1, type: 'dashed' },
      //     lineStyle: { color: '#1FAB3D' },
      //     shadowStyle: { color: '#1FAB3D' }
      //   },
      //   borderColor: '#1FAB3D',
      //   show: true,
      //   showContent: true,
      //   z: 60,
      //   triggerOn: 'mousemove|click',
      //   displayMode: 'single',
      //   renderMode: 'auto',
      //   backgroundColor: '#fff',
      //   shadowBlur: 10,
      //   shadowColor: 'rgba(0, 0, 0, 0.2)',
      //   shadowOffsetX: 1,
      //   shadowOffsetY: 2,
      //   borderRadius: 4,
      //   borderWidth: 1,
      //   textStyle: { color: '#666', fontSize: 14 },
      //   formatter: (params: any) => {
      //     return `${this.customToFixed(params[0].value, 4)}kWh`;
      //   }
      // },
      // dataZoom: {
      //   type: 'slider',
      //   show: true,
      //   realtime: true,
      //   start: 0,
      //   end: 100,
      //   filterMode: 'filter',
      //   bottom: 5
      // }
    };
  }
  onDataZoomUpdate(event: any): void {
    const { start, end } = event;

    const startSlice = Math.floor(
      this.lineChartMeterMetrics.length * (start / 100)
    );
    const endSlice = Math.ceil(this.lineChartMeterMetrics.length * (end / 100));

    const arr = this.lineChartMeterMetrics.slice(startSlice, endSlice);

    let totalCostLineChart = 0;
    let totalEnergyLineChart = 0;

    for (const x of arr) {
      const power = (x.circuit_powers[this.circuitId] ?? 0) / 3600000;
      totalCostLineChart += power * 0.12;
      totalEnergyLineChart += power;
    }

  }

  onChartReady(myCharts: ECharts) {
    console.log('Chart ready');
    myCharts.on('dataZoom', e => this.onDataZoomSubject.next(e));

    this.onDataZoomSubject
      .pipe(debounceTime(80))
      .subscribe(event => this.onDataZoomUpdate(event));
  }

  getFormattedDate(date: Date) {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}`;
  }

  getDate() {
    let start_date = new Date();
    let end_date = new Date();

    if (this.rangeSelectedDay == true) {
      start_date = this.selectedDay;
      end_date = new Date(
        this.selectedDay.getUTCFullYear(),
        this.selectedDay.getMonth(),
        this.selectedDay.getDate() + 1
      );
    } else {
      start_date = this.selectedMonth;
      end_date = new Date(
        this.selectedMonth.getUTCFullYear(),
        this.selectedMonth.getMonth() + 1,
        0
      );
    }

    return {
      start_date: this.toUtc(start_date),
      end_date: this.toUtc(end_date)
    };
  }

  getSortedCircuitList() {
    const { selector } = this.sortOptions[this.selectedSort];
    const list = structuredClone(this.circuit_list);
    // list.sort((a, b) => selector(b) - selector(a));
    this.circuit_list = list;

    // this.getSortedAssets();
    // this.getSortedPanels();
    this.getChartsOptions();
  }


  toKwH(value: number, fixed = 1) {
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: fixed
    }).format(value / this.DEMONINATOR);
  }

  toPercentage(value: number) {
    return (
      (value / this.sortOptions[this.selectedSort].getTotal()) *
      100
    ).toFixed(1);
  }

  getUTCDate(date: Date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  }

  rangeSelected(event: any) {
    if (event.target.id == 'rangeSelectorDay' && !this.rangeSelectedDay) {
      this.rangeSelectedDay = true;
      this.rangeSelectedWeek = false;
      this.rangeSelectedMonth = false;
      document.getElementById('rangeSelectorDay')?.classList.add('selected');
      document
        .getElementById('rangeSelectorWeek')
        ?.classList.remove('selected');
      document
        .getElementById('rangeSelectorMonth')
        ?.classList.remove('selected');
      this.intervalSelected = '60m';

      this.getAll();
    }
    // else if (event.target.id == 'rangeSelectorWeek'){
    //   this.rangeSelectedDay = false;
    //   this.rangeSelectedWeek = true;
    //   this.rangeSelectedMonth = false;
    //   document.getElementById('rangeSelectorWeek')?.classList.add('selected');
    //   document.getElementById('rangeSelectorDay')?.classList.remove('selected');
    //   document.getElementById('rangeSelectorMonth')?.classList.remove('selected');
    // }
    else if (
      event.target.id == 'rangeSelectorMonth' &&
      !this.rangeSelectedMonth
    ) {
      this.rangeSelectedDay = false;
      this.rangeSelectedWeek = false;
      this.rangeSelectedMonth = true;
      document.getElementById('rangeSelectorMonth')?.classList.add('selected');
      document
        .getElementById('rangeSelectorWeek')
        ?.classList.remove('selected');
      document.getElementById('rangeSelectorDay')?.classList.remove('selected');
      this.intervalSelected = '60m';

      this.getAll();
    }
  }

  selectPreviousMonth() {
    const currentDate = this.selectedMonth;

    if (currentDate.getUTCFullYear() == 2022 && currentDate.getMonth() == 7)
      return;

    this.selectedMonth = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getMonth() - 1
    );
  }

  selectNextMonth() {
    const currentDate = this.selectedMonth;
    const now = new Date();

    if (currentDate.getMonth() == now.getMonth() - 1) return;

    this.selectedMonth = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getMonth() + 1
    );
  }

  selectPreviousDay() {
    const currentDate = this.selectedDay;

    if (
      currentDate.getUTCFullYear() == 2022 &&
      currentDate.getMonth() == 7 &&
      currentDate.getDate() == 1
    )
      return;

    this.selectedDay = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 1
    );
  }

  selectNextDay() {
    const currentDate = this.selectedDay;
    const now = new Date();

    // current date should not be greater than today
    if (
      currentDate.getUTCFullYear() == now.getFullYear() &&
      currentDate.getMonth() == now.getMonth() &&
      currentDate.getDate() == now.getDate()
    )
      return;

    this.selectedDay = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    );
  }

  selectPrevious() {
    if (this.rangeSelectedDay === true) {
      this.selectPreviousDay();
    } else {
      this.selectPreviousMonth();
    }

    this.getAll();
  }

  selectNext() {
    if (this.rangeSelectedDay === true) {
      this.selectNextDay();
    } else {
      this.selectNextMonth();
    }

    this.getAll();
  }

  toUtc(date: Date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  }

  get formattedDate(): Date {
    return this.rangeSelectedDay ? this.selectedDay : this.selectedMonth;
  }

  get dateFormat(): string {
    return this.rangeSelectedDay ? 'dd MMM yyyy' : 'MMM yyyy';
  }

  get cycleDateFormat(): string {
    return 'dd MMM yyyy HH:mm';
  }

  handleSortChange(i: number) {
    if (this.selectedSort !== i) {
      this.selectedSort = i;
      // this.getSortedCircuitList();
    }
  }

  getDateSlice(date: string | undefined, index: number) {
    return Number(date?.split(':')[index] ?? '');
  }

  getAmOrPm(date: string) {
    return this.getDateSlice(date, 0) < 12 ? 'AM' : 'PM';
  }

  getChartsOptions() {
    const options =
      this.sidetabOptions[this.selectedSidetabOptions].getOptions();

    const series = options
      .map(d => this.toPercentage(d[1].value))
      .map(ele => parseFloat(ele.toString()));

    this.chartOptions = {
      series: series,
      legend: { show: false },
      chart: { type: 'line', width: '100%' },
      plotOptions: {
        line: { dataLabels: { position: 'top' } },
      },
      initialize: true,
      stroke: { show: false }
    };
  }

  getAll() {
    this.getCircuitCycleList();
    // this.getOperatingHours();
  }

  handleSidetabOptionsChange(i: number) {
    if (i !== this.selectedSidetabOptions) {
      this.selectedSidetabOptions = i;
      this.getChartsOptions();
    }
  }

  ngOnInit(): void {}
}
