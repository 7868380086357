<div class="login-container">
  <div class="login-logo">
    <svg width="252" height="112" viewBox="0 0 129 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.3165 38.6662C40.3165 42.1636 37.8977 44.3597 34.9088 44.3597C33.6272 44.3597 32.5908 43.9732 31.8387 43.2015V48.1203H27.9758V33.1562H31.6552V34.2543C32.3877 33.3804 33.4648 32.9727 34.9088 32.9727C37.8977 32.9727 40.3165 35.1689 40.3165 38.6662ZM36.4131 38.6662C36.4131 36.9995 35.3962 36.0429 34.095 36.0429C32.7939 36.0429 31.777 36.998 31.777 38.6662C31.777 40.3344 32.7939 41.2896 34.095 41.2896C35.3962 41.2896 36.4131 40.3329 36.4131 38.6662Z" fill="black"/>
      <path d="M49.7092 43.728C49.0383 44.1552 48.0425 44.3582 47.0257 44.3582C44.1992 44.3582 42.5521 42.9759 42.5521 40.1494V36.449H40.9456V33.5624H42.5521V30.6953H46.4149V33.5624H48.936V36.449H46.4149V40.1088C46.4149 40.9226 46.8828 41.3693 47.5732 41.3693C48.0004 41.3693 48.4276 41.2475 48.7525 41.0234L49.7092 43.728Z" fill="black"/>
      <path d="M50.7666 29.8831C50.7666 28.7248 51.7022 27.8298 53.1252 27.8298C54.5482 27.8298 55.4839 28.6631 55.4839 29.8229C55.4839 31.0428 54.5482 31.9378 53.1252 31.9378C51.7022 31.9363 50.7666 31.0413 50.7666 29.8831ZM51.1938 33.1563H55.0567V44.1763H51.1938V33.1563Z" fill="black"/>
      <path d="M57.2915 36.8973H63.2287V39.8651H57.2915V36.8973Z" fill="black"/>
      <path d="M84.2517 37.8735V44.1762H80.3888V38.5038C80.3888 36.9183 79.7375 36.2474 78.7221 36.2474C77.6045 36.2474 76.7907 36.9995 76.7907 38.7068V44.1762H72.9279V38.5038C72.9279 36.9183 72.2976 36.2474 71.2612 36.2474C70.1225 36.2474 69.3087 36.9995 69.3087 38.7068V44.1762H65.4458V33.1562H69.1252V34.3145C69.9585 33.4195 71.1183 32.9727 72.4194 32.9727C73.9237 32.9727 75.2053 33.5218 75.9769 34.7011C76.8509 33.603 78.2137 32.9727 79.7992 32.9727C82.3609 32.9727 84.2517 34.4574 84.2517 37.8735Z" fill="black"/>
      <path d="M86.2649 29.8831C86.2649 28.7248 87.2005 27.8298 88.6235 27.8298C90.0465 27.8298 90.9822 28.6631 90.9822 29.8229C90.9822 31.0428 90.0465 31.9378 88.6235 31.9378C87.199 31.9363 86.2649 31.0413 86.2649 29.8831ZM86.6906 33.1563H90.5535V44.1763H86.6906V33.1563Z" fill="black"/>
      <path d="M102.65 41.2882V44.1748H92.4849V41.8974L97.5887 36.0218H92.6278V33.1548H102.447V35.4111L97.3435 41.2867H102.65V41.2882Z" fill="black"/>
      <path d="M115.44 39.6425H107.43C107.755 40.7601 108.71 41.4115 110.134 41.4115C111.211 41.4115 111.923 41.1061 112.675 40.4563L114.709 42.5712C113.651 43.7506 112.106 44.3598 110.013 44.3598C106.088 44.3598 103.547 41.941 103.547 38.6467C103.547 35.3329 106.13 32.9742 109.606 32.9742C112.86 32.9742 115.503 35.0486 115.503 38.6873C115.501 38.9701 115.459 39.3371 115.44 39.6425ZM107.368 37.6088H111.882C111.679 36.4505 110.825 35.718 109.626 35.718C108.427 35.718 107.592 36.4505 107.368 37.6088Z" fill="black"/>
      <path d="M129 29.0903V44.1763H125.321V43.0782C124.588 43.9521 123.511 44.3598 122.088 44.3598C119.08 44.3598 116.659 42.1636 116.659 38.6467C116.659 35.1494 119.078 32.9743 122.088 32.9743C123.368 32.9743 124.406 33.3398 125.137 34.113V29.0903H129ZM125.199 38.6452C125.199 36.9785 124.203 36.0429 122.902 36.0429C121.58 36.0429 120.584 36.9785 120.584 38.6452C120.584 40.333 121.58 41.2881 122.902 41.2881C124.202 41.2881 125.199 40.333 125.199 38.6452Z" fill="black"/>
      <path d="M2.31665 36.6039C2.31665 29.8725 7.36485 25.1191 14.2648 25.1191C21.2489 25.1191 26.255 29.8725 26.255 36.6039C26.255 43.3354 21.2489 48.1309 14.2648 48.1309C7.36635 48.1309 2.31665 43.3339 2.31665 36.6039ZM20.9541 36.6039C20.9541 32.3124 18.093 29.5777 14.2648 29.5777C10.4786 29.5777 7.61756 32.3124 7.61756 36.6039C7.61756 40.8955 10.4786 43.6302 14.2648 43.6302C18.093 43.6287 20.9541 40.894 20.9541 36.6039Z" fill="#4DAAE8"/>
      <path d="M18.3551 21.7392C22.5696 21.7392 25.986 18.3227 25.986 14.1082C25.986 9.89373 22.5696 6.47723 18.3551 6.47723C14.1406 6.47723 10.7241 9.89373 10.7241 14.1082C10.7241 18.3227 14.1406 21.7392 18.3551 21.7392Z" fill="#98C355"/>
      <path d="M3.80871 57.4301C5.91221 57.4301 7.61743 55.7248 7.61743 53.6213C7.61743 51.5178 5.91221 49.8126 3.80871 49.8126C1.70522 49.8126 0 51.5178 0 53.6213C0 55.7248 1.70522 57.4301 3.80871 57.4301Z" fill="#4DAAE8"/>
      <path d="M46.0467 9.99559C47.9899 9.99559 49.5651 8.42036 49.5651 6.4772C49.5651 4.53404 47.9899 2.9588 46.0467 2.9588C44.1036 2.9588 42.5283 4.53404 42.5283 6.4772C42.5283 8.42036 44.1036 9.99559 46.0467 9.99559Z" fill="#DB4B46"/>
      <path d="M33.9446 8.85992C36.3912 8.85992 38.3746 6.87656 38.3746 4.42996C38.3746 1.98336 36.3912 0 33.9446 0C31.498 0 29.5146 1.98336 29.5146 4.42996C29.5146 6.87656 31.498 8.85992 33.9446 8.85992Z" fill="#F7CA50"/>
    </svg>
  </div>

  <div class="recovery-message">
    <div class="recover-password">
      <span class="heading">Recover Password</span>
    </div>
    <p>Don't worry, happens to the best of us</p>
  </div>

  <form [formGroup]="resetForm" (ngSubmit)= "onSubmit()">
    <label for="email">Your email</label>
    <input id="email" type="email" name="email" formControlName="email" placeholder="e.g. yourname@email.com">
    <div class="error-message" >
      <div  id="email-error-message" *ngIf="errors">{{ errorMessage }}</div>
    </div>
    <button type="submit">Email me a recovery link</button>
  </form>

  <div class="forgotten-password-container">
    <a routerLink="/login">Sign In</a>
  </div>
</div>

