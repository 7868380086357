import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA
} from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LiveUsageComponent } from './live-usage/live-usage.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InventoryDataStreamComponent } from './inventory-data-stream/inventory-data-stream.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AboutFacilityComponent } from './about-facility/about-facility.component';
import { OperatingHoursComponent } from './operating-hours/operating-hours.component';
import { WeatherComponent } from './weather/weather.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FullCalendarModule } from '@fullcalendar/angular';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import {
  IgxTimePickerModule,
  IgxInputGroupModule,
  IgxIconModule,
  IgxCarouselModule
} from 'igniteui-angular';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import interactionPlugin from '@fullcalendar/interaction';
import { AlertReportsComponent } from './alert-reports/alert-reports.component';
import { LoginComponent } from './auth/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth/auth-interceptor';
import { AuthGuard } from './auth/auth.guard';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ResetPasswordSentComponent } from './auth/reset-password-sent/reset-password-sent.component';
import { ResetPasswordConfirmComponent } from './auth/reset-password-confirm/reset-password-confirm.component';
import { ResetPasswordSuccessComponent } from './auth/reset-password-success/reset-password-success.component';
import { SwitchFacilitiesComponent } from './switch-facilities/switch-facilities.component';
import { DashboardDataService } from './services/dashboard-data/dashboard-data.service';
import { UtilitiesComponent } from './utilities/utilities.component';
import { ElectricityComponent } from './utilities/electricity/electricity.component';
import { NaturalGasComponent } from './utilities/natural-gas/natural-gas.component';
import { ElectricityBillComponent } from './utilities/electricity/electricity-bill/electricity-bill.component';
import { NaturalGasBillComponent } from './utilities/natural-gas/natural-gas-bill/natural-gas-bill.component';
import { PanelMeterComponentComponent } from './live-usage/panel-meter-component/panel-meter-component.component';
import { PanelInfoComponentComponent } from './live-usage/panel-info-component/panel-info-component.component';
import { CircuitCategoryInfoComponent } from './live-usage/circuit-category-info-component/circuit-category-info-component.component';
import { InsightsComponent } from './insights/insights.component';
import { EquipmentHealthComponent } from './insights/equipment-health/equipment-health.component';
import { CircuitInfoComponent } from './live-usage/circuit-info-component/circuit-info-component.component';
import { ConnectivityComponent } from './alert-reports/connectivity/connectivity.component';
import { GeneralInformationComponent } from './account-settings/general-information/general-information.component';
import { PanelMeterCreateComponent } from './live-usage/panel-meter-create-component/panel-meter-create-component.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgToastModule } from 'ng-angular-popup';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CompanyInformationComponent } from './account-settings/company-information/company-information.component';
import { FacilitiesComponent } from './account-settings/facilities/facilities.component';
import { UsersComponent } from './account-settings/users/users.component';
import { NavbarComponent } from './account-settings/navbar/navbar.component';
import { PasswordChangeComponent } from './auth/change-password/change-password.component';
import { FacilityDetaillComponent } from './account-settings/facility-detaill/facility-detaill.component';
import { FaciliyUsersComponent } from './account-settings/faciliy-users/faciliy-users.component';
import { RawDataComponent } from './raw-data/raw-data.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxEchartsModule } from 'ngx-echarts';
import { EnergyEfficiencyComponent } from './insights/energy-efficiency/energy-efficiency.component';
import { DialogComponent } from './dialog/dialog.component';
import { ConnectivityHistoryComponent } from './alert-reports/connectivity/connectivity-history/connectivity-history.component';
import { CircuitCyclesComponent } from './insights/circuit-cycles/circuit-cycles.component';
import { AssetIntelligenceComponent } from './insights/asset-intelligence/asset-intelligence.component';
import {AssetHealthComponent} from './insights/asset-health/asset-health.component';
import { TotalEnergyConsumersComponent } from './insights/energy-efficiency/total-energy-consumers/total-energy-consumers.component';
import {ExploreSavingsPotentialComponent} from './insights/energy-efficiency/explore-savings-potential/explore-savings-potential.component';
import {SavingsScanComponent} from './insights/energy-efficiency/explore-savings-potential/savings-scan/savings-scan.component';
import {CircuitEcmInfoComponent} from './insights/energy-efficiency/explore-savings-potential/circuit-ecm-info/circuit-ecm-info.component';
import {EnergySavingsSnapshotComponent} from './insights/energy-efficiency/explore-savings-potential/energy-savings-snapshot/energy-savings-snapshot.component';
import { BulkUpdateDialogComponent } from './operating-hours/bulk-update-dialog/bulk-update-dialog.component';
import { OperatingMonthsDialogComponent } from './operating-hours/operating-months-dialog/operating-months-dialog.component';
import { MeterInstallationModule } from './meter-installation/meter-installation.module';
import { MeterInstallationComponent } from './meter-installation/meter-installation.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
FullCalendarModule.registerPlugins([
  dayGridPlugin,
  rrulePlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    LiveUsageComponent,
    InventoryDataStreamComponent,
    SidebarComponent,
    AboutFacilityComponent,
    OperatingHoursComponent,
    AlertReportsComponent,
    LoginComponent,
    ResetPasswordComponent,
    ResetPasswordSentComponent,
    ResetPasswordConfirmComponent,
    ResetPasswordSuccessComponent,
    SwitchFacilitiesComponent,
    UtilitiesComponent,
    ElectricityComponent,
    NaturalGasComponent,
    ElectricityBillComponent,
    NaturalGasBillComponent,
    PanelMeterComponentComponent,
    PanelInfoComponentComponent,
    CircuitCategoryInfoComponent,
    InsightsComponent,
    EquipmentHealthComponent,
    CircuitInfoComponent,
    ConnectivityComponent,
    GeneralInformationComponent,
    CompanyInformationComponent,
    FacilitiesComponent,
    UsersComponent,
    NavbarComponent,
    PasswordChangeComponent,
    FacilityDetaillComponent,
    FaciliyUsersComponent,
    WeatherComponent,
    PanelMeterCreateComponent,
    RawDataComponent,
    EnergyEfficiencyComponent,
    DialogComponent,
    ConnectivityHistoryComponent,
    CircuitCyclesComponent,
    AssetIntelligenceComponent,
    AssetHealthComponent,
    TotalEnergyConsumersComponent,
    ExploreSavingsPotentialComponent,
    SavingsScanComponent,
    CircuitEcmInfoComponent,
    EnergySavingsSnapshotComponent,
    BulkUpdateDialogComponent,
    OperatingMonthsDialogComponent
  ],
  imports: [
    NgToastModule,
    BrowserModule,
    FullCalendarModule,
    NgbModule,
    IgxCarouselModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HammerModule,
    HttpClientModule,
    MatDialogModule,
    DragDropModule,
    RouterModule.forRoot([
      { path: 'home', component: LiveUsageComponent, canActivate: [AuthGuard] },
      {
        path: 'utilities',
        component: UtilitiesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'utilities/electricity',
        component: ElectricityComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'utilities/electricity/bill',
        component: ElectricityBillComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'utilities/naturalgas',
        component: NaturalGasComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'utilities/naturalgas/bill',
        component: NaturalGasBillComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'inventory',
        component: InventoryDataStreamComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'alertreports',
        component: AlertReportsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'alertreports/connectivity',
        component: ConnectivityComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'about',
        component: AboutFacilityComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'operatinghours',
        component: OperatingHoursComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'weather',
        component: WeatherComponent,
        canActivate: [AuthGuard]
      },
      { path: 'login', component: LoginComponent },
      { path: 'password_reset', component: ResetPasswordComponent },
      { path: 'password_reset/sent', component: ResetPasswordSentComponent },
      {
        path: 'password_reset/confirm',
        component: ResetPasswordConfirmComponent
      },
      {
        path: 'password_reset/success',
        component: ResetPasswordSuccessComponent
      },
      { path: 'switchfacilities', component: SwitchFacilitiesComponent },
      {
        path: 'insights',
        component: InsightsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'insights/equipment-health',
        component: EquipmentHealthComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'insights/energy-efficiency',
        component: EnergyEfficiencyComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'insights/energy-efficiency/total-energy-consumers',
        component: TotalEnergyConsumersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'insights/energy-efficiency/explore-energy-potential',
        component: ExploreSavingsPotentialComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'insights/energy-efficiency/explore-energy-potential/savings-scan',
        component: SavingsScanComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'insights/energy-efficiency/explore-energy-potential/energy-savings-snapshot',
        component: EnergySavingsSnapshotComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'account-settings',
        component: GeneralInformationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'account-settings/company-information',
        component: CompanyInformationComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'account-settings/facilities',
        component: FacilitiesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'account-settings/users',
        component: UsersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'account-settings/facility-detail',
        component: FacilityDetaillComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'account-settings/facility-users',
        component: FaciliyUsersComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'raw-data',
        component: RawDataComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'insights/circuit-cycles',
        component: CircuitCyclesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'insights/circuit-cycles/:id',
        component: CircuitCyclesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'insights/asset-intelligence',
        component: AssetIntelligenceComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'insights/asset-health',
        component: AssetHealthComponent,
        canActivate: [AuthGuard]
      },
      { path: 'meter-installation', component: MeterInstallationComponent },
      { path: '', pathMatch: 'full', redirectTo: 'home' }
    ]),
    NgApexchartsModule,
    IgxTimePickerModule,
    IgxInputGroupModule,
    IgxIconModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatFormFieldModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    MeterInstallationModule,
    MatSnackBarModule
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    DashboardDataService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule {}
