import { Circuit } from '../models/circuit';

export function groupCircuitsByCircuitGroup(circuits: any) {
  return structuredClone(circuits).reduce((acc: any, val: Circuit) => {
    val.circuit_group_temp =
      val.circuit_group == null || val.circuit_group == ''
        ? val.circuit_name
        : val.circuit_group;

    //search in acc array if there're an element with the same "date"
    const element: Circuit = acc.find(
      (x: any) => x.circuit_group_temp == val.circuit_group_temp
    );

    if (!element)
      //if not exist
      acc.push(val); //add the val to acc
    //if exist
    else if (val.circuit_current != null) {
      element.circuit_current += val.circuit_current; //simple increment the xUsed property
      element.circuit_amps += val.circuit_amps;
      element.circuit_power += val.circuit_power;
      element.circuit_cost += val.circuit_cost;
    }

    return acc;
  }, []);
}

export function customToFixed(
  value: any,
  precision: number = 3,
  isPercent: boolean = false
) {
  if (!value) return value;

  let decimalVal = value.toFixed(precision);

  if (isPercent == true && Number(decimalVal) > 100) {
    decimalVal = 100;
  }

  return decimalVal;
}
