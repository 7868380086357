import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OperatingHoursService } from '../../services/operating-hours.service';
import { Building } from '../../models/building';
import { WeeklySchedule, YearSchedule, MonthSchedule } from '../../models/operating_schedule';

interface DialogData {
  building: Building;
  weeklySchedule: WeeklySchedule;
}

@Component({
  selector: 'app-operating-months-dialog',
  templateUrl: './operating-months-dialog.component.html',
  styleUrls: ['./operating-months-dialog.component.scss']
})
export class OperatingMonthsDialogComponent implements OnInit {
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth(); // 0-11 for Jan-Dec
  loading = true;
  yearSchedules: YearSchedule[] = [];
  currentYearSchedule: YearSchedule | undefined;

  constructor(
    public dialogRef: MatDialogRef<OperatingMonthsDialogComponent>,
    private operatingHoursService: OperatingHoursService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.loading = true;
    if (this.data.building.operating_schedule?.monthly) {
      this.yearSchedules = this.data.building.operating_schedule.monthly;
    } else {
      // Use initial schedule if none exists
      const initialSchedule = this.operatingHoursService.generateInitialSchedule();
      this.yearSchedules = initialSchedule.monthly;
    }
    this.updateCurrentYearSchedule();
    this.loading = false;
  }

  private generateMonthsForYear(year: number): MonthSchedule[] {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    // If it's current year, past months should be unselected
    // const isCurrentYear = year === new Date().getFullYear();
    // return monthNames.map((name, index) => ({
    //   name,
    //   selected: isCurrentYear ? index >= this.currentMonth : true
    // }));
    return monthNames.map((name, index) => ({
      name,
      selected: true
    }));
  }

  private updateCurrentYearSchedule(): void {
    this.currentYearSchedule = this.yearSchedules.find(y => y.year === this.currentYear);
    
    // If year schedule doesn't exist, create it
    if (!this.currentYearSchedule) {
      this.currentYearSchedule = {
        year: this.currentYear,
        months: this.generateMonthsForYear(this.currentYear)
      };
      this.yearSchedules.push(this.currentYearSchedule);
      // Sort years in ascending order
      this.yearSchedules.sort((a, b) => a.year - b.year);
    } else {
      // Update existing year schedule
      // if (this.currentYear === new Date().getFullYear()) {
      //   this.currentYearSchedule.months.forEach((month, index) => {
      //     if (index < this.currentMonth) {
      //       month.selected = false;
      //     }
      //   });
      // }
    }
  }

  isMonthDisabled(monthIndex: number): boolean {
    // Disable if it's current year and month is in the past
    // return this.currentYear === new Date().getFullYear() && monthIndex < this.currentMonth;
    return false;
  }

  onBack(): void {
    this.dialogRef.close('back');
  }

  onApply(): void {
    const schedule = {
      weekly: this.data.weeklySchedule,
      monthly: this.yearSchedules
    };
    this.operatingHoursService.updateOperatingSchedule(this.data.building.idbuildings, schedule)
      .subscribe(response => {
        this.dialogRef.close(response);
      });
  }

  nextYear(): void {
    const nextYear = this.currentYear + 1;
    const today_year = new Date().getFullYear();
    if (nextYear <= today_year + 1) {
      this.currentYear = nextYear;
      this.updateCurrentYearSchedule();
    }
  }

  previousYear(): void {
    const today_year = new Date().getFullYear();
    const prevYear = this.currentYear - 1;
    if (prevYear >= today_year - 1) {
      this.currentYear = prevYear;
      this.updateCurrentYearSchedule();
    }
  }

  get months() {
    return this.currentYearSchedule?.months || [];
  }
} 