import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';
import { customToFixed } from 'src/app/helpers/circuit';
import { ChartOptions } from 'src/app/live-usage/live-usage.component';
import { Building } from 'src/app/models/building';
import { DashboardDataService } from 'src/app/services/dashboard-data/dashboard-data.service';
import { MatDialog } from '@angular/material/dialog';
import { CircuitInfoComponent } from 'src/app/live-usage/circuit-info-component/circuit-info-component.component';
import { of } from 'rxjs';

interface CircuitUsage {
  id: number;
  total_usage: number;
  total_off_hours_usage: number;
  total_on_hours_usage: number;
  time: Date;
  circuit_category_name: string;
  circuit_name: string;
  panel_name: string;
  circuit_category_colour: string;
}

interface RecordKeyVal {
  value: number;
  color: string;
}

@Component({
  selector: 'app-total-energy-consumers',
  templateUrl: './total-energy-consumers.component.html',
  styleUrls: ['./total-energy-consumers.component.scss']
})
export class TotalEnergyConsumersComponent implements OnInit {
  @ViewChild('chart', { static: false }) chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any = { initialize: false };

  public modes = [
    {
      id: 'energy',
      label: 'Energy',
      getValue: (d: number) => `${this.toKwH(d, 3)} kWh`
    },
    {
      id: 'percentage',
      label: 'Percentage %',
      getValue: (d: number) => `${this.toPercentage(d)}%`
    }
  ];

  public DEMONINATOR = 3600000;

  public sortOptions = [
    {
      id: 'total',
      label: 'Total',
      selector: (d: CircuitUsage) => d.total_usage,
      getTotal: () => this.total_usage
    },
    {
      id: 'on-hours',
      label: 'On-hours',
      selector: (d: CircuitUsage) => d.total_on_hours_usage,
      getTotal: () => this.total_circuit_total_on_hours_usage
    },
    {
      id: 'off-hours',
      label: 'Off-hours',
      selector: (d: CircuitUsage) => d.total_off_hours_usage,
      getTotal: () => this.total_on_hours_usage
    }
  ];

  public selectedMode = 0;
  public selectedSort = 0;

  public total_usage = 0;
  public total_circuit_total_on_hours_usage = 0;
  public total_on_hours_usage = 0;

  public circuit_list: CircuitUsage[] = [];
  public total_percentage = 0;

  public rangeSelectedDay = true;
  public rangeSelectedWeek = false;
  public rangeSelectedMonth = false;
  public intervalSelected = '60m';

  public selectedMonth: Date = new Date();
  public selectedDay: Date = new Date();

  public customToFixed = customToFixed;

  private building: Building;
  public operatingHours: any = [];

  public assets: [string, RecordKeyVal][] = [];
  public panels: [string, RecordKeyVal][] = [];

  public sidetabOptions = [
    { id: 'assets', label: 'Assets', getOptions: () => this.assets },
    { id: 'panel', label: 'Panel', getOptions: () => this.panels }
  ];

  public selectedSidetabOptions = 0;
  public isLoading = true;

  constructor(private dashboardService: DashboardDataService,public dialog: MatDialog) {
    this.selectPreviousDay();

    const subs = this.dashboardService.currentBuilding.subscribe(result => {
      if (!result) return;

      this.building = result;

      this.getAll();

      subs.unsubscribe();
    });
  }

  getCircuitUsageList() {
    this.isLoading = true;

    const { start_date, end_date } = this.getDate();

    const subs = this.dashboardService
      .getBuildingsByUsage(this.building.idbuildings, start_date, end_date)
      .subscribe((result: CircuitUsage[]) => {
        this.total_usage = 0;
        this.total_circuit_total_on_hours_usage = 0;
        this.total_on_hours_usage = 0;

        result.forEach(d => {
          this.total_usage += d.total_usage;
          this.total_circuit_total_on_hours_usage +=
            d.total_on_hours_usage;
          this.total_on_hours_usage +=
            d.total_off_hours_usage;
        });

        this.circuit_list = result;
        this.getSortedCircuitList();

        this.isLoading = false;
        subs.unsubscribe();
      });
  }

  getOperatingHours() {
    const start_date = this.getFormattedDate(
      this.rangeSelectedDay === true ? this.selectedDay : this.selectedMonth
    );

    const end_date = this.getFormattedDate(
      this.rangeSelectedDay === true
        ? new Date(
            this.selectedDay.getUTCFullYear(),
            this.selectedDay.getMonth(),
            this.selectedDay.getDate() + 1
          )
        : new Date(
            this.selectedMonth.getUTCFullYear(),
            this.selectedMonth.getMonth() + 1,
            0
          )
    );

    const subs = this.dashboardService
      .getOperatingHours(this.building.idbuildings, start_date, end_date)
      .subscribe(result => {
        this.operatingHours = [result[0].start_time, result[0].end_time];
        subs.unsubscribe();
      });
  }

  getFormattedDate(date: Date) {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}`;
  }

  getDate() {
    let start_date = new Date();
    let end_date = new Date();

    if (this.rangeSelectedDay == true) {
      start_date = this.selectedDay;
      end_date = new Date(
        this.selectedDay.getUTCFullYear(),
        this.selectedDay.getMonth(),
        this.selectedDay.getDate() + 1
      );
    } else {
      start_date = this.selectedMonth;
      end_date = new Date(
        this.selectedMonth.getUTCFullYear(),
        this.selectedMonth.getMonth() + 1,
        0
      );
    }

    return {
      start_date: this.toUtc(start_date),
      end_date: this.toUtc(end_date)
    };
  }

  getSortedCircuitList() {
    const { selector } = this.sortOptions[this.selectedSort];
    const list = structuredClone(this.circuit_list);
    list.sort((a, b) => selector(b) - selector(a));
    this.circuit_list = list;

    this.getSortedAssets();
    this.getSortedPanels();
    this.getChartsOptions();
  }

  getSortedAssets() {
    const { selector } = this.sortOptions[this.selectedSort];

    this.assets = Object.entries(
      this.circuit_list.reduce<Record<string, RecordKeyVal>>((acc, curr) => {
        const name = curr.circuit_category_name.trim();

        if (!acc[name]) {
          acc[name] = { value: 0, color: curr.circuit_category_colour };
        }

        acc[name].value += selector(curr);

        return acc;
      }, {})
    ).sort((a, b) => b[1].value - a[1].value);
  }

  getSortedPanels() {
    const { selector } = this.sortOptions[this.selectedSort];

    this.panels = Object.entries(
      this.circuit_list.reduce<Record<string, RecordKeyVal>>((acc, curr) => {
        const name = curr.panel_name.trim();

        if (!acc[name]) {
          acc[name] = { value: 0, color: curr.circuit_category_colour };
        }

        acc[name].value += selector(curr);

        return acc;
      }, {})
    ).sort((a, b) => b[1].value - a[1].value);
  }

  toKwH(value: number, fixed = 1) {
    return new Intl.NumberFormat(undefined, {
      maximumFractionDigits: fixed
    }).format(value / this.DEMONINATOR);
  }

  toPercentage(value: number) {
    return (
      (value / this.sortOptions[this.selectedSort].getTotal()) *
      100
    ).toFixed(1);
  }
  toCircuitPercentage(circuitUsage: CircuitUsage) {
    let value = 0;
    if (this.selectedSort === 0) {
      value = circuitUsage.total_usage;
    } else if (this.selectedSort === 1) {
      value = circuitUsage.total_on_hours_usage;
    } else if (this.selectedSort === 2) {
      value = circuitUsage.total_off_hours_usage;
    }
    return (
      (value / this.sortOptions[this.selectedSort].getTotal()) *
      100
    ).toFixed(1);
  }

  rangeSelected(event: any) {
    if (event.target.id == 'rangeSelectorDay' && !this.rangeSelectedDay) {
      this.rangeSelectedDay = true;
      this.rangeSelectedWeek = false;
      this.rangeSelectedMonth = false;
      document.getElementById('rangeSelectorDay')?.classList.add('selected');
      document
        .getElementById('rangeSelectorWeek')
        ?.classList.remove('selected');
      document
        .getElementById('rangeSelectorMonth')
        ?.classList.remove('selected');
      this.intervalSelected = '60m';

      this.getAll();
    }
    // else if (event.target.id == 'rangeSelectorWeek'){
    //   this.rangeSelectedDay = false;
    //   this.rangeSelectedWeek = true;
    //   this.rangeSelectedMonth = false;
    //   document.getElementById('rangeSelectorWeek')?.classList.add('selected');
    //   document.getElementById('rangeSelectorDay')?.classList.remove('selected');
    //   document.getElementById('rangeSelectorMonth')?.classList.remove('selected');
    // }
    else if (
      event.target.id == 'rangeSelectorMonth' &&
      !this.rangeSelectedMonth
    ) {
      this.rangeSelectedDay = false;
      this.rangeSelectedWeek = false;
      this.rangeSelectedMonth = true;
      document.getElementById('rangeSelectorMonth')?.classList.add('selected');
      document
        .getElementById('rangeSelectorWeek')
        ?.classList.remove('selected');
      document.getElementById('rangeSelectorDay')?.classList.remove('selected');
      this.intervalSelected = '60m';

      this.getAll();
    }
  }

  selectPreviousMonth() {
    const currentDate = this.selectedMonth;

    if (currentDate.getUTCFullYear() == 2022 && currentDate.getMonth() == 7)
      return;

    this.selectedMonth = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getMonth() - 1
    );
  }

  selectNextMonth() {
    const currentDate = this.selectedMonth;
    const now = new Date();

    if (currentDate.getMonth() == now.getMonth() - 1) return;

    this.selectedMonth = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getMonth() + 1
    );
  }

  selectPreviousDay() {
    const currentDate = this.selectedDay;

    if (
      currentDate.getUTCFullYear() == 2022 &&
      currentDate.getMonth() == 7 &&
      currentDate.getDate() == 1
    )
      return;

    this.selectedDay = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - 1
    );
  }

  selectNextDay() {
    const currentDate = this.selectedDay;
    const now = new Date();

    // current date should not be greater than today
    if (
      currentDate.getUTCFullYear() == now.getFullYear() &&
      currentDate.getMonth() == now.getMonth() &&
      currentDate.getDate() == now.getDate()
    )
      return;

    this.selectedDay = new Date(
      currentDate.getUTCFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    );
  }

  selectPrevious() {
    if (this.rangeSelectedDay === true) {
      this.selectPreviousDay();
    } else {
      this.selectPreviousMonth();
    }

    this.getAll();
  }

  selectNext() {
    if (this.rangeSelectedDay === true) {
      this.selectNextDay();
    } else {
      this.selectNextMonth();
    }

    this.getAll();
  }

  toUtc(date: Date) {
    return new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
  }

  get formattedDate(): Date {
    return this.rangeSelectedDay ? this.selectedDay : this.selectedMonth;
  }

  get dateFormat(): string {
    return this.rangeSelectedDay ? 'dd MMM yyyy' : 'LLLL YYYY';
  }

  handleSortChange(i: number) {
    if (this.selectedSort !== i) {
      this.selectedSort = i;
      this.getSortedCircuitList();
    }
  }

  getDateSlice(date: string | undefined, index: number) {
    return Number(date?.split(':')[index] ?? '');
  }

  getAmOrPm(date: string) {
    return this.getDateSlice(date, 0) < 12 ? 'AM' : 'PM';
  }

  getChartsOptions() {
    const options =
      this.sidetabOptions[this.selectedSidetabOptions].getOptions();

    const series = options
      .map(d => this.toPercentage(d[1].value))
      .map(ele => parseFloat(ele.toString()));

    this.chartOptions = {
      series: series,
      dataLabels: { enabled: false },
      legend: { show: false },
      chart: { type: 'donut', width: '100%' },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                label: '',
                formatter: () => 'Text you want'
              }
            }
          }
        }
      },
      colors: options.map(d => d[1].color),
      labels: options.map(d => d[0]),
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: { width: 10 },
            legend: { position: 'bottom' }
          }
        }
      ],
      initialize: true,
      stroke: { show: false }
    };
  }

  getAll() {
    this.getCircuitUsageList();
    this.getOperatingHours();
  }

  handleSidetabOptionsChange(i: number) {
    if (i !== this.selectedSidetabOptions) {
      this.selectedSidetabOptions = i;
      this.getChartsOptions();
    }
  }

  openCircuitModal(circuitUsage: CircuitUsage) {
    this.dashboardService.getCircuitCategory(this.building.idbuildings).subscribe(categories => {
      categories = JSON.parse(JSON.stringify(categories.results));
      this.dashboardService.getCircuitInfo(circuitUsage.id).subscribe(result => {
        const circuit = JSON.parse(JSON.stringify(result));
        circuit.connected = true;
        const dialogRef = this.dialog.open(CircuitInfoComponent, {
          data: {
            circuit: circuit,
            meter_name: circuit.panel.meter_name ?? '',
            groupedCircuits: [],
            panel_name: circuitUsage.panel_name,
            buildingId: this.building.idbuildings,
            circuitCategories: categories,
            panel: circuit.panel,
            panels$: of(this.panels),
            customToFixed: customToFixed,
            combinePhases: false
          }
        });
      });
    });
    
    
  }

  ngOnInit(): void {}
}
