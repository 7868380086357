import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Facilities } from 'src/app/models/account-settings/facility';
import { SettingService } from 'src/app/services/settings-service';
import { DataService } from 'src/app/services/data-sharing-service';
import { User } from 'src/app/models/account-settings/user';
import { GeneralInformationUser } from 'src/app/models/account-settings/generalInfo';
import { NgToastService } from 'ng-angular-popup';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-faciliy-users',
  templateUrl: './faciliy-users.component.html',
  styleUrls: ['./faciliy-users.component.scss']
})
export class FaciliyUsersComponent implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;

  image: any;
  file: File;
  facility: Facilities;
  shared: string ;
  users: User[];
  facilityUsers: User[];
  isloading1: boolean = true;
  isloading2: boolean = true;
  IsNewUserAdding: boolean = false;
  imageLoadedSuccessfully: boolean = true;
  newUser: GeneralInformationUser = { 'account_type': 0, 'email': '', 'first_name': '', 'last_name': '', 'password': '', 'mobile_number': '', 'position': '', 'image': null, 'company_id': '', 'is_superuser': false };
  myForm: FormGroup;
  selectedNewUser: number;
  paramBuilding_id:any;
  constructor(private settingService: SettingService, private dataService: DataService, private toast: NgToastService,private router:ActivatedRoute) { 
    this.router.queryParams.subscribe((data:any)=>{
      this.paramBuilding_id = data.building_id
    })
  }

  isDisplay(): Boolean {
    return ((!this.isloading1) && (!this.isloading2));
  }

  closOverlay() {
    this.IsNewUserAdding = false;
    this.newUser = { 'account_type': 0, 'email': '', 'first_name': '', 'last_name': '', 'password': '', 'mobile_number': '', 'position': '', 'image': null, 'company_id': '', 'is_superuser': false };
  }

  showUserPopUp(user?: User) {
    this.settingService.addFacilityUserAccess(this.shared || this.paramBuilding_id, this.selectedNewUser).subscribe((data: any) => {
      this.getUsers();
    });
    // if (user) {
    //   this.newUser = { 'account_type': 0, 'email': user.email, 'first_name': user.first_name, 'last_name': user.last_name, 'password': '', 'mobile_number': user.mobile_number, 'position': user.position, 'image': null, 'company_id': '', 'is_superuser': false };
    //   this.image = user.image;
    //   this.imageLoadedSuccessfully = true;
    // }
    // else {
    //   this.newUser = { 'account_type': 0, 'email': '', 'first_name': '', 'last_name': '', 'password': '', 'mobile_number': '', 'position': '', 'image': null, 'company_id': '', 'is_superuser': false };
    //   this.image = '';
    //   this.imageLoadedSuccessfully = false;
    // }
    // this.IsNewUserAdding = true;
  }

  handleAddNewUser() {
    if (!this.newUser.first_name || !this.newUser.last_name || !this.newUser.email) {
      alert('first name, last name and email are required');
      return;
    }
    this.settingService.addNewUserTofacility(this.newUser, this.shared, this.file).subscribe((data: any) => {
      this.users = [...this.users, data]
      this.toast.success({ detail: 'Success', summary: 'User added successfully!', sticky: true, position: 'tr' })
    },
      (error) => {
        this.handleError(error);
      }
    );
    this.newUser = { 'account_type': 0, 'email': '', 'first_name': '', 'last_name': '', 'password': '', 'mobile_number': '', 'position': '', 'image': null, 'company_id': '', 'is_superuser': false };
    this.IsNewUserAdding = false;
  }

  handleImageError() {
    this.imageLoadedSuccessfully = false;
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target?.result as string;
        this.imageLoadedSuccessfully = true;
      };
      reader.readAsDataURL(file);
      this.file = file
      // this.uploadImage(file);
    }
  }

  // uploadImage(file: File): void {
  //   this.settingService.uploadUserImage(file).subscribe(
  //     response => {
  //       this.toast.success({ detail: 'Success', summary: 'Image updated successfully!', sticky: true, position: 'tr' })
  //       // Optionally, update the userInfo.image with the new image URL
  //     },
  //     error => {
  //       this.toast.error({ detail: 'Error', summary: 'Image update was unsuccessfull!', sticky: true, position: 'tr' })
  //       console.error('Error uploading image:', error);
  //     }
  //   );
  // }

  getUsers() {
    this.settingService.getUsersByBuildingId(this.shared || this.paramBuilding_id).subscribe((data: any) => {
      this.facilityUsers = data.results;
      this.settingService.getAllUsers().subscribe((data: any) => {
        this.users = data.results;
        this.users = this.users.filter((user) => {
          return this.facilityUsers.filter((facilityUser) => {
            return facilityUser.id === user.id;
          }).length === 0;
        });
        this.isloading1 = false;
      });
    });
  }


  ngOnInit(): void {
    
    this.myForm = new FormGroup({
      'first_name': new FormControl(null, Validators.required),
      'last_name': new FormControl(null, Validators.required),
      'email': new FormControl(null, [Validators.required, Validators.email])
    });
    this.shared = this.dataService.getClickedFacilityId();
    const facilityId = localStorage.getItem('facilityId') // Will be removing later
    this.settingService.getFacilityDetailById(this.shared || facilityId || this.paramBuilding_id).subscribe((data: any) => {
      this.facility = data;
      this.isloading2 = false;
    });
    this.getUsers();
    
  }

  private handleError(error: any): void {
    console.error('Error in component:', error);
    this.toast.error({ detail: 'Error', summary: 'An error occurred!', sticky: true, position: 'tr' });
  }

}
