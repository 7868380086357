import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardDataService } from '../../services/dashboard-data/dashboard-data.service';
import { NgToastService } from 'ng-angular-popup';
import { Panel } from 'src/app/models/panel';

@Component({
  selector: 'app-panel-meter-create-component',
  templateUrl: './panel-meter-create-component.component.html',
  styleUrls: ['./panel-meter-create-component.component.scss']
})
export class PanelMeterCreateComponent implements OnInit {
  render_dialog: boolean = true;
  page1: boolean = true;
  page2: boolean = false;
  page3: boolean = false;
  nextButtonText: string = 'Next';

  public panels: Panel[] = [];
  public secondaryPanelSet = new Set();

  public meterTypes = [
    {
      name: 'Single Asset',
      db_name: 'single_asset',
      disabled: true
    },
    {
      name: 'Electrical Panel / MCC Panel',
      subName: 'under 64 Circuits',
      db_name: 'electrical_panel_mcc_panel_under_64_circuits',
      disabled: false
    },
    {
      name: 'Electrical Panel / MCC Panel',
      subName: 'over 64 Circuits',
      db_name: 'electrical_panel_mcc_panel_over_64_circuits',
      disabled: false
    }
  ];

  public meterTypeSelected: number = 1;
  public primaryPanelSelected: number = 0;
  public secondaryPanelSelected: number | null = null;

  constructor(
    private toast: NgToastService,
    public dialogRef: MatDialogRef<PanelMeterCreateComponent>,
    private dashboardDataService: DashboardDataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data.panels.forEach(
      (d: Panel) =>
        d.secondary_panel && this.secondaryPanelSet.add(d.secondary_panel)
    );

    this.panels = this.data.panels.filter((d: Panel) => {
      return !d.meter_type && !this.secondaryPanelSet.has(d.panel_id);
    });
  }

  ngOnInit(): void {}

  closePopup() {
    this.dialogRef.close();
  }

  nextPage() {
    if (this.page1) {
      this.page1 = false;
      this.page2 = true;
    } else if (this.page2) {
      this.page2 = false;
      this.page3 = true;
      this.nextButtonText = 'Finish';
    } else if (this.page3) {
      if (this.panels[this.primaryPanelSelected] == undefined) {
        this.toast.error({
          detail: 'Error',
          summary: 'Please select a panel',
          sticky: true,
          position: 'tr'
        });
      } else {
        // this.createMeter(this.panelSelected, "Meter 1", "3");
        this.dashboardDataService
          .updatePanelMeterType(
            (this.panels[this.primaryPanelSelected] as Panel).panel_id,
            this.meterTypes[this.meterTypeSelected].db_name
            // this.secondaryPanelSelected
            //   ? (this.panels[this.secondaryPanelSelected] as Panel).panel_id
            //   : null
          )
          .subscribe(
            events => {
              this.toast.success({
                detail: 'Success',
                summary: 'Meter is configured',
                sticky: true,
                position: 'tr'
              });
              this.dialogRef.close();
            },
            (err: any) => {
              this.toast.error({
                detail: 'Error',
                summary: err.error[0],
                sticky: true,
                position: 'tr'
              });
            }
          );
      }
    }
  }

  handlePrimaryPanelSelectedChange(i: number) {
    this.primaryPanelSelected = i;

    if (this.secondaryPanelSelected === this.primaryPanelSelected) {
      this.secondaryPanelSelected = null;
    }
  }

  handleSecondaryPanelSelectedChange(i: number) {
    this.secondaryPanelSelected = i;
  }

  createMeter(panel_id: string, meter_name: string, meterPhase: string) {
    this.dashboardDataService
      .createMeter(panel_id, meter_name, meterPhase)
      .subscribe(
        events => {
          this.toast.success({
            detail: 'Success',
            summary: 'Meter is connected',
            sticky: true,
            position: 'tr'
          });
        },
        (err: any) => {
          this.toast.error({
            detail: 'Error',
            summary: err.error[0],
            sticky: true,
            position: 'tr'
          });
        }
      );
  }
}
