import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgToastService } from 'ng-angular-popup';
import { MeterInstallationService, MeterStatus, MeterData } from '../services/meter-installation.service';
import { finalize } from 'rxjs/operators';

interface SignalStrengthStatus {
  value: number;
  quality: 'excellent' | 'good' | 'poor' | 'critical';
  message: string;
}

interface LiveReadingsData {
  I1: number;
  I2: number;
  I3: number;
  U1: number;
  U2: number;
  U3: number;
}

@Component({
  selector: 'app-meter-installation',
  templateUrl: './meter-installation.component.html',
  styleUrls: ['./meter-installation.component.scss']
})
export class MeterInstallationComponent implements OnInit {
  installationForm: FormGroup;
  loading = false;
  currentStep = 0;
  stepStatus: { [key: string]: 'pending' | 'success' | 'failure' | null } = {
    connection: null,
    calibration: null,
    signalStrength: null,
    liveReadings: null
  };
  loadingSteps: { [key: string]: boolean } = {
    connection: false,
    calibration: false,
    signalStrength: false,
    liveReadings: false
  };
  signalStrength: SignalStrengthStatus | null = null;
  liveReadings: LiveReadingsData | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private meterService: MeterInstallationService,
    private toast: NgToastService
  ) {
    this.installationForm = this.formBuilder.group({
      meterSerialId: ['', [Validators.required, Validators.minLength(3)]]
    });
  }

  ngOnInit() {
    // Any initialization code
  }

  private showMessage(message: string, isError = false) {
    if (isError) {
      this.toast.error({ detail: 'Error', summary: message, duration: 5000 });
    } else {
      this.toast.success({ detail: 'Success', summary: message, duration: 3000 });
    }
  }

  private handleStepResponse(response: MeterStatus, step: string): boolean {
    this.stepStatus[step] = response.status as 'success' | 'failure' | 'pending';
    
    if (response.status === 'failure') {
      this.showMessage(response.message, true);
      return false;
    } else if (response.status === 'pending') {
      this.showMessage(response.message);
      return false;
    } else if (response.status === 'success') {
      this.showMessage(response.message);
      return true;
    }
    return false;
  }

  private resetSubsequentSteps(currentStep: keyof typeof this.stepStatus) {
    const steps: (keyof typeof this.stepStatus)[] = ['connection', 'calibration', 'signalStrength', 'liveReadings'];
    const currentIndex = steps.indexOf(currentStep);
    
    steps.slice(currentIndex + 1).forEach(step => {
      this.stepStatus[step] = null;
    });
  }

  checkConnection() {
    if (this.installationForm.invalid) {
      this.showMessage('Please enter a valid meter serial ID', true);
      return;
    }

    const meterSerialId = this.installationForm.get('meterSerialId')?.value;
    this.loadingSteps['connection'] = true;
    this.resetSubsequentSteps('connection');

    this.meterService.checkConnection(meterSerialId)
      .pipe(finalize(() => this.loadingSteps['connection'] = false))
      .subscribe({
        next: (response) => {
          if (this.handleStepResponse(response, 'connection')) {
            this.currentStep++;
          } else if (response.status === 'pending') {
            setTimeout(() => this.checkConnection(), 5000);
          }
        },
        error: (error) => {
          this.stepStatus['connection'] = 'failure';
          this.showMessage('Failed to check connection: ' + error.message, true);
        }
      });
  }

  calibrateMeter() {
    const meterSerialId = this.installationForm.get('meterSerialId')?.value;
    this.loadingSteps['calibration'] = true;
    this.resetSubsequentSteps('calibration');

    this.meterService.calibrateMeter(meterSerialId)
      .pipe(finalize(() => this.loadingSteps['calibration'] = false))
      .subscribe({
        next: (response) => {
          if (this.handleStepResponse(response, 'calibration')) {
            this.currentStep++;
          } else if (response.status === 'pending') {
            setTimeout(() => this.calibrateMeter(), 5000);
          }
        },
        error: (error) => {
          this.stepStatus['calibration'] = 'failure';
          this.showMessage('Failed to calibrate meter: ' + error.message, true);
        }
      });
  }

  checkSignalStrength() {
    const meterSerialId = this.installationForm.get('meterSerialId')?.value;
    this.loadingSteps['signalStrength'] = true;
    this.resetSubsequentSteps('signalStrength');
    this.signalStrength = null;

    this.meterService.checkSignalStrength(meterSerialId)
      .pipe(finalize(() => this.loadingSteps['signalStrength'] = false))
      .subscribe({
        next: (response) => {
          // Parse signal strength from response message
          const match = response.message.match(/Signal strength (\w+) \((\d+)%\)/);
          if (match) {
            const [, quality, value] = match;
            this.signalStrength = {
              value: parseInt(value),
              quality: this.getSignalQuality(parseInt(value)),
              message: response.message
            };
          }

          if (this.handleStepResponse(response, 'signalStrength')) {
            this.currentStep++;
          } else if (response.status === 'pending') {
            setTimeout(() => this.checkSignalStrength(), 5000);
          }
        },
        error: (error) => {
          this.stepStatus['signalStrength'] = 'failure';
          this.showMessage('Failed to check signal strength: ' + error.message, true);
        }
      });
  }

  private getSignalQuality(value: number): 'excellent' | 'good' | 'poor' | 'critical' {
    if (value >= 90) return 'excellent';
    if (value >= 70) return 'good';
    if (value >= 40) return 'poor';
    return 'critical';
  }

  getSignalColor(quality: 'excellent' | 'good' | 'poor' | 'critical'): string {
    switch (quality) {
      case 'excellent': return '#4caf50';  // Green
      case 'good': return '#2196f3';       // Blue
      case 'poor': return '#ff9800';       // Orange
      case 'critical': return '#f44336';   // Red
      default: return '#666666';           // Gray
    }
  }

  getLiveReadings() {
    const meterSerialId = this.installationForm.get('meterSerialId')?.value;
    this.loadingSteps['liveReadings'] = true;
    this.resetSubsequentSteps('liveReadings');
    this.liveReadings = null;

    this.meterService.getLiveReadings(meterSerialId)
      .pipe(finalize(() => this.loadingSteps['liveReadings'] = false))
      .subscribe({
        next: (response) => {
          try {
            // Try to parse the readings from the message
            const readings = response.readings;
            if (readings && typeof readings === 'object') {
              this.liveReadings = {
                I1: Number(readings.I1 || 0),
                I2: Number(readings.I2 || 0),
                I3: Number(readings.I3 || 0),
                U1: Number(readings.U1 || 0),
                U2: Number(readings.U2 || 0),
                U3: Number(readings.U3 || 0)
              };
              // Mark as success only if we got valid readings
              if (Object.values(this.liveReadings).some(value => value > 0)) {
                this.stepStatus['liveReadings'] = 'success';
                this.showMessage('Live readings received successfully');
              } else {
                this.stepStatus['liveReadings'] = 'failure';
                this.showMessage('Invalid readings received', true);
              }
            } else {
              this.stepStatus['liveReadings'] = 'failure';
              this.showMessage('Invalid readings format', true);
            }
          } catch (e) {
            console.warn('Could not parse live readings:', e);
            this.stepStatus['liveReadings'] = 'failure';
            this.showMessage('Failed to parse live readings', true);
          }

          if (response.status === 'pending') {
            setTimeout(() => this.getLiveReadings(), 5000);
          }
        },
        error: (error) => {
          this.stepStatus['liveReadings'] = 'failure';
          this.showMessage('Failed to get live readings: ' + error.message, true);
        }
      });
  }

  disconnectMeter() {
    const meterSerialId = this.installationForm.get('meterSerialId')?.value;
    this.loading = true;

    this.meterService.disconnectMeter(meterSerialId)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          if (response.status === 'success') {
            this.showMessage('Meter disconnected successfully');
            this.resetForm();
          } else {
            this.showMessage(response.message, true);
          }
        },
        error: (error) => {
          this.showMessage('Failed to disconnect meter: ' + error.message, true);
        }
      });
  }

  private resetForm() {
    this.installationForm.reset();
    Object.keys(this.stepStatus).forEach(key => {
      this.stepStatus[key] = null;
      this.loadingSteps[key] = false;
    });
    this.currentStep = 0;
  }

  retryStep(step: keyof typeof this.stepStatus) {
    const steps: (keyof typeof this.stepStatus)[] = ['connection', 'calibration', 'signalStrength', 'liveReadings'];
    const currentIndex = steps.indexOf(step);
    
    // Reset current and subsequent steps
    steps.slice(currentIndex).forEach(s => {
      this.stepStatus[s] = null;
      this.loadingSteps[s] = false;
    });

    // Set current step
    this.currentStep = currentIndex;

    // Trigger the appropriate action
    switch(step) {
      case 'connection':
        this.checkConnection();
        break;
      case 'calibration':
        this.calibrateMeter();
        break;
      case 'signalStrength':
        this.checkSignalStrength();
        break;
      case 'liveReadings':
        this.getLiveReadings();
        break;
    }
  }

  // Add helper method for formatting values
  formatValue(value: number, type: 'current' | 'voltage'): string {
    if (!value && value !== 0) return 'N/A';
    return type === 'current' 
      ? `${value.toFixed(1)} A`
      : `${value.toFixed(1)} V`;
  }

  onSubmit() {
    if (this.installationForm.invalid) {
      this.showMessage('Please enter a valid meter serial ID', true);
      return;
    }

    const meterSerialId = this.installationForm.get('meterSerialId')?.value;
    this.loading = true;

    // Reset all states
    Object.keys(this.stepStatus).forEach(key => {
      this.stepStatus[key] = null;
      this.loadingSteps[key] = false;
    });
    this.currentStep = 0;
    this.signalStrength = null;
    this.liveReadings = null;

    this.meterService.getMeterData(meterSerialId)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (data: MeterData) => {
          // Update connection status
          this.stepStatus['connection'] = data.connection.status;
          if (data.connection.status === 'success') {
            this.currentStep = 1;
          }

          // Update calibration status
          this.stepStatus['calibration'] = data.calibration.status;
          if (data.calibration.status === 'success') {
            this.currentStep = 2;
          }

          // Update signal strength status
          this.stepStatus['signalStrength'] = data.signalStrength.status;
          if (data.signalStrength.status === 'success' && data.signalStrength.value !== undefined) {
            this.signalStrength = {
              value: data.signalStrength.value,
              quality: this.getSignalQuality(data.signalStrength.value),
              message: data.signalStrength.message
            };
            this.currentStep = 3;
          }

          // Update live readings status
          this.stepStatus['liveReadings'] = data.liveReadings.status;
          if (data.liveReadings.status === 'success' && data.liveReadings.readings) {
            this.liveReadings = data.liveReadings.readings;
            this.currentStep = 4;
          }

          // If all statuses are pending, we'll use the current step-by-step flow
          const allPending = Object.values(this.stepStatus).every(status => status === 'pending');
          if (allPending) {
            this.currentStep = 0;
            this.checkConnection();
          }
        },
        error: (error) => {
          this.showMessage('Failed to get meter data: ' + error.message, true);
        }
      });
  }
} 