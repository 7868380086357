import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CompanyInformation } from 'src/app/models/account-settings/companyinfo';
import { SettingService } from '../../services/settings-service'
import { NgToastService } from 'ng-angular-popup';

@Component({
  selector: 'app-company-information',
  templateUrl: './company-information.component.html',
  styleUrls: ['./company-information.component.scss']
})
export class CompanyInformationComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(private settingService:SettingService, private toast: NgToastService,
    ) { }
  companyInfo:CompanyInformation ;
  companyUpdateInfo :CompanyInformation;
  iseditaddress : boolean = false;
  iseditpostal_code : boolean = false;
  iseditstate : boolean = false;
  
  toggleEdit(field:string): void {
    if (field=='address')  this.iseditaddress = !this.iseditaddress;
    if (field=='postal_code')  this.iseditpostal_code = !this.iseditpostal_code;
    if (field=='state')  this.iseditstate = !this.iseditstate;
   
  }

  isDisabled(field: string): boolean {
    if (field === 'address' &&  (this.iseditpostal_code || this.iseditstate )) return true;
    if (field === 'postal_code' &&  (this.iseditaddress || this.iseditstate )) return true;
    if (field === 'state' &&  (this.iseditpostal_code || this.iseditaddress )) return true;
  
    return false;
  }


  updateCompany(field:string , value:string):void
  {
    let reqbody = { [field]: value }
    this.settingService.updatecompany(reqbody).subscribe(
      success => {
        if (success) {
          this.companyInfo = this.companyUpdateInfo;
          this.toggleEdit(field);
          this.toast.success({ detail: 'Success', summary: 'Field updated successfully!', sticky: true, position: 'tr' })
        } else {
          this.toast.error({ detail: 'Error', summary: 'Field update was unsuccessfull!', sticky: true, position: 'tr' })
        }
      },
      error => {
        this.toast.error({ detail: 'Error', summary: 'Field update was unsuccessfull!', sticky: true, position: 'tr' })
        console.error('Error updating user:', error);
      }
    );
    
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.companyInfo.image = e.target?.result as string;
      };
      reader.readAsDataURL(file);
      this.uploadLogo(file);
    }
  }

  uploadLogo(file: File): void {
    this.settingService.uploadCompanyLogo(file).subscribe(
      response => {
        console.log('Image uploaded successfully:', response);
        this.toast.success({ detail: 'Success', summary: 'Image updated successfully!', sticky: true, position: 'tr' })
        // Optionally, update the userInfo.image with the new image URL
      },
      error => {
        this.toast.error({ detail: 'Error', summary: 'Image update was unsuccessfull!', sticky: true, position: 'tr' })
        console.error('Error uploading image:', error);
      }
    );
  }


  ngOnInit(): void {
    this.settingService.getCompanyInformation().subscribe((data: CompanyInformation) => {
     console.log(data);
     this.companyInfo = data;
     this.companyUpdateInfo = {...data};
    });
  }
}


