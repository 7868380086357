import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SettingService } from '../../services/settings-service'
import { GeneralInformationUser } from 'src/app/models/account-settings/generalInfo';
import { PasswordChangeComponent } from 'src/app/auth/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { NgToastService } from 'ng-angular-popup';
@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private settingService: SettingService,
    private dialog: MatDialog,
    private toast: NgToastService,
  ) { }
  userInfo: GeneralInformationUser;
  userupdateInfo: GeneralInformationUser;
  fieldValue: string = 'Initial Value';
  isEditingfirst_name: boolean = false;
  isEditinglast_name: boolean = false;
  isEditingposition: boolean = false;
  isEditingemail: boolean = false;
  isEditingmobile_number: boolean = false;
  isPasswordVisible: boolean = false;
  passwordStars: string = '';
  imageLoadedSuccessfully: boolean = true;


  toggleEdit(field: string): void {
    if (field == 'first_name') this.isEditingfirst_name = !this.isEditingfirst_name;
    if (field == 'last_name') this.isEditinglast_name = !this.isEditinglast_name;
    if (field == 'position') this.isEditingposition = !this.isEditingposition;
    if (field == 'email') this.isEditingemail = !this.isEditingemail;
    if (field == 'mobile_number') this.isEditingmobile_number = !this.isEditingmobile_number;

  }

  handleImageError() {
    this.imageLoadedSuccessfully = false;
  }

  updateUser(field: string, value: string): void {
    let requestBody = { [field]: value }
    this.settingService.updateUser(requestBody).subscribe(
      success => {
        if (success) {
          this.userInfo = this.userupdateInfo;
          this.toggleEdit(field);
          this.toast.success({ detail: 'Success', summary: 'Field updated successfully!', sticky: true, position: 'tr' })
        } else {
          this.toast.error({ detail: 'Error', summary: 'Field update was unsuccessfull!', sticky: true, position: 'tr' })
        }
      },
      error => {
        this.toast.error({ detail: 'Error', summary: 'Field update was unsuccessfull!', sticky: true, position: 'tr' })
        console.error('Error updating user:', error);
      }
    );

  }

  ngOnInit(): void {
    this.settingService.getGeneralInformation().subscribe((data: GeneralInformationUser) => {
      this.userInfo = data;
      this.userupdateInfo = { ...data };
      this.passwordStars = '*'.repeat(this.userInfo.password.length);
    });
  }

  isDisabled(field: string): boolean {
    if (field === 'first_name' && (this.isEditinglast_name || this.isEditingposition || this.isEditingemail || this.isEditingmobile_number)) return true;
    if (field === 'last_name' && (this.isEditingfirst_name || this.isEditingposition || this.isEditingemail || this.isEditingmobile_number)) return true;
    if (field === 'position' && (this.isEditinglast_name || this.isEditingfirst_name || this.isEditingemail || this.isEditingmobile_number)) return true;
    if (field === 'email' && (this.isEditinglast_name || this.isEditingposition || this.isEditingfirst_name || this.isEditingmobile_number)) return true;
    if (field === 'phone' && (this.isEditinglast_name || this.isEditingposition || this.isEditingfirst_name || this.isEditingemail)) return true;
    return false;
  }

  togglepassword(): void {
    this.isPasswordVisible = !this.isPasswordVisible;

  }

  openPasswordChangeDialog(): void {
    const dialogRef = this.dialog.open(PasswordChangeComponent, {
      width: '400px',
      position: { top: '50px', left: '50px' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The password change dialog was closed', result);
    });
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.userInfo.image = e.target?.result as string;
      };
      reader.readAsDataURL(file);
      this.uploadImage(file);
    }
  }

  uploadImage(file: File): void {
    this.settingService.uploadUserImage(file).subscribe(
      response => {
        this.toast.success({ detail: 'Success', summary: 'Image updated successfully!', sticky: true, position: 'tr' })
      },
      error => {
        this.toast.error({ detail: 'Error', summary: 'Image update was unsuccessfull!', sticky: true, position: 'tr' })
        console.error('Error uploading image:', error);
      }
    );
  }

}

