import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GeneralInformationUser } from '../models/account-settings/generalInfo';
import { CompanyInformation } from 'src/app/models/account-settings/companyinfo';
import { environment } from '../../environments/environment';
import { tap, catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Facilities } from '../models/account-settings/facility';
import { UserPayload } from '../models/account-settings/user-payload';
import { Building } from '../models/building';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private settingsurl = 'assets/dummy_data';
  private apiUrl = environment.backendBaseAddress;
  public companyId: string;
  public companyInfo: any;
  public generalInfo: any;
  public buildings: any;
  public userFacilities: any;
  public users: any;
  public facilities: any;
  constructor(private http: HttpClient) {}

  private getUserIdFromToken(): string | null {
    const token = localStorage.getItem('auth_token');

    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      return decodedToken.user_id;
    }

    return null;
  }

  getGeneralInformation(): Observable<GeneralInformationUser> {
    const userId = this.getUserIdFromToken();
    if (userId) {
      if (this.generalInfo != null) {
        return of(this.generalInfo);
      }
      return this.http
        .get<GeneralInformationUser>(`${this.apiUrl}/users/${userId}/`)
        .pipe(
          tap((userDetails: GeneralInformationUser) => {
            this.companyId = userDetails.company_id;
            console.log(
              '🚀 ~ SettingService ~ tap ~ this.companyId:',
              this.companyId
            );
            localStorage.setItem('companyId', this.companyId); //Quick fix for now
            this.generalInfo = userDetails;
          }),
          catchError(error => {
            console.error('Error fetching user details:', error);
            throw error; // Rethrow the error after handling
          })
        );
    } else {
      return new Observable<GeneralInformationUser>();
    }
  }

  getCompanyInformation(): Observable<CompanyInformation> {
    if (this.companyId) {
      if (this.companyInfo != null) {
        return of(this.companyInfo);
      } else {
        return this.http
          .get<CompanyInformation>(
            `${this.apiUrl}/companies/${this.companyId}/`
          )
          .pipe(
            tap((companyDetails: CompanyInformation) => {
              this.companyInfo = companyDetails;
            }),
            catchError(error => {
              console.error('Error fetching company details:', error);
              throw error; // Rethrow the error after handling
            })
          );
      }
    } else {
      return this.getGeneralInformation().pipe(
        tap((userDetails: GeneralInformationUser) => {
          this.companyId = userDetails.company_id;
        }),
        switchMap((userDetails: GeneralInformationUser) => {
          return this.http
            .get<CompanyInformation>(
              `${this.apiUrl}/companies/${userDetails.company_id}/`
            )
            .pipe(
              tap((companyDetails: CompanyInformation) => {
                this.companyInfo = companyDetails;
              }),
              catchError(error => {
                console.error('Error fetching company details:', error);
                throw error; // Rethrow the error after handling
              })
            );
        })
      );
    }
  }

  getBuildings(): Observable<any> {
    if (this.buildings != null) {
      return of(this.buildings);
    }
    return this.http.get<any>(`${this.apiUrl}/buildings/`).pipe(
      tap((facilities: any) => {
        this.buildings = facilities;
      }),
      catchError(error => {
        console.error('Error fetching buildings:', error);
        throw error; // Rethrow the error after handling
      })
    );
  }

  getFacilities(): Observable<any> {
    if (this.facilities != null) {
      return of(this.facilities);
    }
    return this.http.get<any>(`${this.apiUrl}/facility/`).pipe(
      tap((facilities: any) => {
        this.facilities = facilities;
      }),
      catchError(error => {
        console.error('Error fetching facilities:', error);
        throw error; // Rethrow the error after handling
      })
    );
  }

  getUsersByBuilding(buildingId: any): Observable<any> {
    if (this.userFacilities != null) {
      return of(this.userFacilities);
    }
    return this.http
      .get<any>(`${this.apiUrl}/users/?building_id=${buildingId}`)
      .pipe(
        tap((userFacilities: any) => {
          this.userFacilities = userFacilities;
        }),
        catchError(error => {
          console.error('Error fetching users:', error);
          throw error; // Rethrow the error after handling
        })
      );
  }

  updateUser(reqBody: any): Observable<boolean> {
    const userId = this.getUserIdFromToken();

    if (userId) {
      const url = `${this.apiUrl}/users/${userId}/`;

      return this.http.patch<any>(url, reqBody).pipe(
        map(response => {
          return true;
        }),
        catchError(error => {
          console.error('Error updating user details:', error);
          return of(false);
        })
      );
    } else {
      return of(false);
    }
  }

  updatecompany(reqbody: any, company_id?: string): Observable<boolean> {
    const companyId = company_id ?? this.companyId;

    if (!companyId) return of(false);

    const url = `${this.apiUrl}/companies/${companyId}/`;

    return this.http.patch<any>(url, reqbody).pipe(
      map(response => {
        return true;
      }),
      catchError(error => {
        console.error('Error updating company details:', error);
        return of(false);
      })
    );
  }

  uploadUserImage(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('image', file);
    const userId = this.getUserIdFromToken();
    const url = `${this.apiUrl}/users/${userId}/`;
    return this.http.patch(url, formData).pipe(
      catchError((error: any) => {
        console.error('Error uploading new user image:', error);
        throw error;
      })
    );
  }

  uploadCompanyLogo(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('image', file);
    const url = `${this.apiUrl}/companies/${this.companyId}/`;
    return this.http.patch(url, formData).pipe(
      catchError((error: any) => {
        console.error('Error uploading new company logo:', error);
        throw error;
      })
    );
  }

  uploadBuildingLogo(file: File, buildingId: any): Observable<any> {
    const formData = new FormData();
    formData.append('image', file);
    const url = `${this.apiUrl}/buildings/${buildingId}/`;
    return this.http.patch(url, formData).pipe(
      catchError((error: any) => {
        console.error('Error uploading new building logo:', error);
        throw error;
      })
    );
  }

  getUserDetailsById(userId: number): Observable<GeneralInformationUser> {
    return this.http.get<GeneralInformationUser>(
      `${this.apiUrl}/users/${userId}/`
    );
  }

  getAllUsers(): Observable<any> {
    if (this.users != null) {
      return of(this.users);
    }
    return this.http.get<any>(`${this.apiUrl}/users/`).pipe(
      tap((users: any) => {
        this.users = users;
      }),
      catchError(error => {
        console.error('Error fetching users:', error);
        throw error; // Rethrow the error after handling
      })
    );
  }

  getUsersByBuildingId(facilityId: any): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}/users/?building_id=${facilityId}`
    );
  }
  getBuildingsByUserId(userId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/buildings/?user_id=${userId}`);
  }
  removeFacilityUserAccess(
    facilityId: string,
    userId: number
  ): Observable<any> {
    return this.http
      .delete(
        `${this.apiUrl}/buildings/${facilityId}/user_access/?user_id=${userId}`
      )
      .pipe(
        catchError((error: any) => {
          console.error('Error adding new user:', error);
          throw error;
        })
      );
  }
  addFacilityUserAccess(facilityId: string, userId: number): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/buildings/${facilityId}/user_access/?user_id=${userId}`,
      {}
    );
  }

  addNewUserInfo(reqBody: UserPayload, file: File | null) {
    const companyId = localStorage.getItem('companyId');
    const payload = {
      ...reqBody,
      companyId: companyId,
      password: 'password'
    };
    const formData = new FormData();

    Object.keys(reqBody).forEach(key => {
      formData.append(key, reqBody[key]);
    });
    if (companyId !== null) {
      formData.append('companyId', companyId);
    }
    if (file) {
      formData.append('image', file);
    }
    formData.append('password', 'password');

    return this.http
      .post<any>(`${this.apiUrl}/users/`, file ? formData : payload)
      .pipe(
        catchError((error: any) => {
          console.error('Error adding new user:', error);
          throw error;
        })
      );
  }

  addNewUserTofacility(
    reqBody: GeneralInformationUser,
    facilityId: any,
    file: File
  ): Observable<any> {
    const companyId = localStorage.getItem('companyId');
    const payload = {
      ...reqBody,
      building_id: facilityId,
      companyId: companyId,
      password: 'password'
    };
    return this.http.post<any>(`${this.apiUrl}/users/`, payload).pipe(
      catchError((error: any) => {
        console.error('Error adding new user:', error);
        throw error;
      })
    );
  }

  addNewFacility(reqBody: any, file: File) {
    const formData = new FormData();
    for (const key in reqBody) {
      if (key === 'image') {
        formData.append('image', file);
      } else {
        formData.append(key, reqBody[key]);
      }
    }

    return this.http.post<any>(`${this.apiUrl}/buildings/`, formData).pipe(
      catchError((error: any) => {
        console.error('Error adding new facility:', error);
        throw error;
      })
    );
  }

  getFacilityDetailById(id: any): Observable<Facilities> {
    return this.http.get<Facilities>(`${this.apiUrl}/buildings/${id}/`);
  }

  getProvinceOptions(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/province_options/`);
  }

  updateFacility(reqBody: any, facilityId: string): Observable<any> {
    return this.http
      .patch<any>(`${this.apiUrl}/buildings/${facilityId}/`, reqBody)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          console.error('Error updating user details:', error);
          return error;
        })
      );
  }

  updateBuildingImage(buildingId: string, formData: any): Observable<any> {
    return this.http
      .patch<Building>(`${this.apiUrl}/buildings/${buildingId}/`, formData)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          console.error('Error updating user details:', error);
          return of(false);
        })
      );
  }

  updateCompanyImage(formData: any, company_id: string): Observable<any> {
    return this.http
      .patch<CompanyInformation>(
        `${this.apiUrl}/companies/${company_id}/`,
        formData
      )
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          console.error('Error updating user details:', error);
          return of(false);
        })
      );
  }

  getCompanyById(companyId: string) {
    return this.http.get<CompanyInformation>(
      `${this.apiUrl}/companies/${companyId}/`
    );
  }
}
