import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardDataService } from '../../services/dashboard-data/dashboard-data.service';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { NgToastService } from 'ng-angular-popup';
import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexStroke,
  ChartComponent
} from 'ng-apexcharts';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Panel } from 'src/app/models/panel';
import type { ECharts, EChartsOption } from 'echarts';
import { NgxEchartsDirective } from 'ngx-echarts';

import mqtt from 'mqtt';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};

type LiveLineChartOptions = {
  id: string;
  label: string;
  selector: (d: Panel) => number;
  symbol: string;
};

@Component({
  selector: 'app-panel-info-component',
  templateUrl: './panel-info-component.component.html',
  styleUrls: ['./panel-info-component.component.scss']
})
export class PanelInfoComponentComponent implements OnInit {
  @ViewChild('chart', { static: true }) chart: ChartComponent;
  @ViewChild(NgxEchartsDirective) ngxChart: NgxEchartsDirective;

  public chartOptions: Partial<ChartOptions> | any;
  public chartOptionsMeterAnalyticsData: Partial<ChartOptions> | any = {
    noData: {
      text: 'Loading...',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000000',
        fontSize: '14px',
        fontFamily: 'Helvetica'
      }
    }
  };

  activeId = 1;
  public fetchLive = this.data.fetch_live_meter;
  public panelInfo = this.data.panel;
  // public current_dialog_panel_id = this.data.panel_id;
  // public circuits: any = [];
  public circuitsStats: any = [];
  math = Math;
  public render_dialog: boolean = false;
  public slides: any = [];
  public panel_loading: any = false;
  public circuit_loading: any = false;
  public totalCost: number = 0;
  public totalEnergy: number = 0;
  public totalCostLineChart: number = 0;
  public totalEnergyLineChart: number = 0;
  public modelBarChart: NgbDateStruct = this.calendar.getToday();
  public modelLineChart: NgbDateStruct = this.calendar.getToday();
  public date: { year: number; month: number };
  public minDate: NgbDateStruct = { year: 2021, month: 1, day: 1 };
  public maxDate: NgbDateStruct = this.calendar.getToday();
  todo = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];

  public circuit_groups: { [key: string]: any[] } = {};
  public available_circuits_for_groups: any[] = [];
  public data_modified: boolean = false;
  public liveLineChartData: Panel[] = [];
  private panelsSubscription: Subscription | null = null;
  public currentLiveDataValue = '';

  public liveLineChartOptions: LiveLineChartOptions[] = [
    // {
    //   id: 'panel_power',
    //   label: 'Power Factor',
    //   selector: (d: Panel) => d.panel_power,
    //   symbol: 'pf'
    // },
    {
      id: 'panel_current',
      label: 'Amps',
      selector: (d: Panel) => d.panel_current,
      symbol: 'a'
    },
    {
      id: 'panel_volts',
      label: 'Voltage',
      selector: (d: Panel) => d.panel_volts,
      symbol: 'v'
    },
    {
      id: 'watts',
      label: 'Wattage',
      selector: (d: Panel) => {
        return d.panel_current * d.panel_volts;
      },
      symbol: 'w'
    }
  ];

  public liveConnectivityOptions = [
    { id: 'connectivity', label: 'Connectivity' }
  ];

  public isLiveConnectivitySelected = false;

  public selectedLiveLineChartOption: LiveLineChartOptions =
    this.liveLineChartOptions[this.liveLineChartOptions.length - 1];

  liveChartOptions: EChartsOption;
  lineChartOptions: EChartsOption;

  private barChartMeterMetrics: any[] = [];
  private lineChartMeterMetrics: any[] = [];
  public panelOverview: {
    label: string;
    id: string;
    getValue?: (p: Panel) => string | number;
    getChildren?: {
      label: string;
      getValue: (p: Panel) => string | number;
      getColor: (p: Panel) => string;
    }[];
    isEditable: boolean;
  }[] = [
    {
      label: 'Panel Name',
      id: 'panel_name',
      getValue: p => p.panel_name,
      isEditable: true
    },
    {
      label: 'Phase Type',
      id: 'meter_type',
      getValue: p => (p.phase_type ? p.phase_type + '-Phase' : ''),
      isEditable: false
    },
    {
      label: 'Location',
      id: 'location',
      getValue: (p: Panel) => p.location ?? '',
      isEditable: true
    },
    {
      label: 'Number of Circuits',
      id: 'number_circuits',
      getValue: p => p.number_circuits,
      isEditable: false
    },
    {
      label: 'Voltage',
      id: 'voltage_type',
      getValue: p => p.voltage_type ?? '',
      isEditable: true
    },
    {
      label: 'Voltage Reference',
      id: 'panel_volts',
      getChildren: [
        {
          label: 'Panel',
          getValue: p =>
            `${p.panel_name ?? ''} - ${p.voltage_refrence_type ?? ''}`,
          getColor: () => '#0D1758'
        },
        {
          label: 'Port V1',
          getValue: p => (!!p.panel_volts_ref?.[0] ? 'Active' : 'Not Set-up'),
          getColor: p => (!!p.panel_volts_ref?.[0] ? '#1FAB3D' : '#FF7F27')
        },
        {
          label: 'Port V2',
          getValue: p => (!!p.panel_volts_ref?.[1] ? 'Active' : 'Not Set-up'),
          getColor: p => (!!p.panel_volts_ref?.[1] ? '#1FAB3D' : '#FF7F27')
        },
        {
          label: 'Post V3',
          getValue: p => (!!p.panel_volts_ref?.[2] ? 'Active' : 'Not Set-up'),
          getColor: p => (!!p.panel_volts_ref?.[2] ? '#1FAB3D' : '#FF7F27')
        },
        {
          label: 'Voltage Source',
          getValue: p => p.panel_voltage,
          getColor: () => '#0D1758'
        }
      ],
      isEditable: false
    }
  ];

  public isEditing: null | string = null;

  public editedValue: Record<string, string> = {};

  public voltageTypeList: any[] = [];

  onDataZoomSubject = new Subject();

  private mqttClient: mqtt.MqttClient | null = null;
  public connectivity: null | ReturnType<typeof this.getNetworkMeta> = null;

  constructor(
    private toast: NgToastService,
    public dialogRef: MatDialogRef<PanelInfoComponentComponent>,
    private dashboardDataService: DashboardDataService,
    private calendar: NgbCalendar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // this.panel_loading = true;
    // this.dashboardDataService.getPanelsOverview(this.current_dialog_panel_id).subscribe((panels: any) => {
    //   this.panelInfo = panels.results.length > 0 ? panels.results[0] : {}
    //   this.panel_loading = false;
    const imgs: any[] = [];

    this.dashboardDataService.getVoltageType().subscribe((result: any) => {
      this.voltageTypeList = result.results;
    });

    if (this.panelInfo.panel_images.length > 0) {
      this.panelInfo.panel_images.forEach((element: any) => {
        imgs.push({
          src: element?.image,
          name: element?.image?.split('/').pop()
        });
      });
      this.slides = imgs;
    }

    this.editedValue['voltage_type'] = this.panelInfo.voltage_type ?? '';

    this.panelInfo?.panel_circuits?.forEach((element: any) => {
      if (
        element.circuit_group != null &&
        element.circuit_group != undefined &&
        element.circuit_group != ''
      ) {
        if (this.circuit_groups[element.circuit_group] == undefined) {
          this.circuit_groups[element.circuit_group] = [];
        }
        this.circuit_groups[element.circuit_group].push(element);
      } else {
        this.available_circuits_for_groups.push(element);
      }
    });

    this.dashboardDataService
      .getCircuitsOverview(this.panelInfo.panel_id)
      .subscribe((response: any) => {
        this.render_dialog = true;
        this.circuitsStats = response?.data?.circuits_category_stats;

        let circuit_series: any = [];
        let labels: any = [];
        let colors: any = [];

        this.circuitsStats?.forEach((element: any) => {
          circuit_series.push(element.percentage);
          labels.push(element.circuit_category_name);
          colors.push(element.circuit_category_colour);
        });

        this.chartOptions = {
          series: circuit_series,
          dataLabels: { enabled: false },
          legend: { show: false },
          chart: { type: 'donut', width: '200px' },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                    show: true,
                    label: '',
                    formatter: () => 'Text you want'
                  }
                }
              }
            }
          },
          colors: colors,
          labels: labels,
          responsive: [
            {
              breakpoint: 480,
              options: { chart: { width: 10 }, legend: { position: 'bottom' } }
            }
          ]
        };
      });

    if (this.panelInfo.meter_name) {
      const today = new Date(
        this.modelBarChart.year,
        this.modelBarChart.month - 1,
        this.modelBarChart.day
      );

      this.fetchMeterAnalytics(this.panelInfo.meter_name, today, response => {
        this.lineChartMeterMetrics = response;
        // this.barChartMeterMetrics = response;

        this.plotLineChart();
        // this.plotBarChart();
      });

      this.dashboardDataService
        .fetchOldestMeterRecord(this.panelInfo.meter_name)
        .subscribe((response: any) => {
          if (response.length > 0) {
            const oldestRecord = new Date(response[0].timestamp);

            this.minDate = {
              year: oldestRecord.getFullYear(),
              month: oldestRecord.getMonth() + 1,
              day: oldestRecord.getDate()
            };
          }
        });
    }

    this.connectMqtt();
  }

  connectMqtt() {
    try {
      const { meter_name, panel_meter } = this.data.panel;

      if (!meter_name || panel_meter?.use_api) {
        return;
      }

      const options: mqtt.IClientOptions = {
        protocol: 'ws',
        username: 'sustain_dev',
        password: 'sustain_dev',
        clean: true,
        port: 15675,
        keepalive: 60,
        hostname: 'dev.opti-mized.com',
        path: '/ws',
        clientId: this.panelInfo.panel_id
      };

      if (window.location.protocol.includes('https')) {
        options.protocol = 'wss';
        options.port = 15676;
      }

      if (
        window.location.hostname.includes('facility.opti-mized.com') &&
        !window.location.hostname.includes('dev-')
      ) {
        options.hostname = 'live.opti-mized.com';
        options.username = 'iotdevice1';
        options.password = 'iotdevice1';
        delete options.path;
      }

      const client = mqtt.connect(options);

      this.mqttClient = client;

      const topic = `attr/${meter_name}`;

      client.on('connect', function () {
        client.publish(
          `cmd/${meter_name}`,
          JSON.stringify({ name: 'get_nw_data' })
        );

        client.subscribe(topic, function (err: any) {
          if (err) {
            console.error(err);
          }
        });
      });

      client.on(
        'message',
        (currentTopic: any, message: { toString: () => any }) => {
          if (currentTopic === topic) {
            const result = JSON.parse(message.toString());

            if (result.network_type) {
              this.connectivity = this.getNetworkMeta(result);
            }
          }
        }
      );

      client.on('error', function (error) {
        console.error('MQTT Connection error: ', error);
      });
    } catch (err) {
      console.error(err);
    }
  }

  drop(event: CdkDragDrop<string[]>, circuit_group: string) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if (
        event.container.id == 'circuit_group_target' &&
        event.container.data.length == 3
      ) {
        this.toast.warning({
          detail: 'Warning',
          summary: 'Circuit group can only have 3 circuits!',
          sticky: false,
          position: 'tr'
        });
        return;
      }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      let circuit_id = (event.container.data[event.currentIndex] as any)['id'];
      this.dashboardDataService
        .updateCircuitGroup(circuit_id, circuit_group)
        .subscribe(
          (response: any) => {
            this.data_modified = true;
          },
          (error: any) => {
            this.toast.error({
              detail: 'Error',
              summary: 'Circuit group update failed!',
              sticky: false,
              position: 'tr'
            });
          }
        );
    }
  }

  circuitGroupCreate() {
    const inputElement = <HTMLInputElement>(
      document.getElementById('circuitGroupCreateButton')
    );
    this.circuit_groups[inputElement?.value] = [];
    if (inputElement) {
      inputElement.value = '';
      inputElement.blur();
    }
  }

  plotBarChart() {
    this.totalCost = 0;
    this.totalEnergy = 0;

    let hourConstant = 1; // for minute, this would be 60, this could be conditional based on minute or hour
    const categories = Array.from({ length: 24 * hourConstant }, _ => '');
    categories[0 * hourConstant] = '12 AM';
    categories[3 * hourConstant] = '3 AM';
    categories[6 * hourConstant] = '6 AM';
    categories[9 * hourConstant] = '9 AM';
    categories[12 * hourConstant] = 'Noon';
    categories[15 * hourConstant] = '3 PM';
    categories[18 * hourConstant] = '6 PM';
    categories[21 * hourConstant] = '9 PM';
    categories[23 * hourConstant] = '12 AM';

    const data = Array.from({ length: 24 * hourConstant }, _ => '0');

    this.barChartMeterMetrics.forEach((element: any, index) => {
      // const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // const timestampWithTimezone = new Date(
      //   element.timestamp
      // ).toLocaleTimeString('en-US', {
      //   timeZone: userTimezone,
      //   hour12: false
      // });

      // const minute = parseInt(timestampWithTimezone.split(':')[0]);
      element.total_power = (element.total_power ?? 0) / (1000 * 3600);
      data[index] = element.total_power.toFixed(2);
      this.totalCost += element.total_power * 0.12;
      this.totalEnergy += element.total_power;
    });

    this.chartOptionsMeterAnalyticsData = {
      series: [{ name: 'hourly', data: data }],
      dataLabels: { enabled: false },
      legend: { show: false },
      chart: { type: 'bar' },
      responsive: [
        {
          breakpoint: 480,
          options: { chart: { width: 10 }, legend: { position: 'bottom' } }
        }
      ],
      xaxis: {
        categories: categories,
        labels: {
          rotate: 0
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 0
        }
      },
      fill: {
        colors: ['#1FAB3D']
      }
    };
  }

  plotLineChart() {
    let totalCostLineChart = 0;
    let totalEnergyLineChart = 0;

    if (!this.lineChartMeterMetrics.length) return;

    const date = new Date(this.lineChartMeterMetrics[0].timestamp);
    const localDateString = date.toLocaleDateString();

    const minuteSplit = Math.abs(date.getTimezoneOffset() / 60)
      .toString()
      .split('.');
    const m = minuteSplit.length > 1 ? Number(minuteSplit[1]) * 6 : `00`;

    const label: string[] = Array.from({ length: 24 }).map((_, i) => {
      const h = i.toString().length > 1 ? i : `0${i}`;
      return `${h}:${m}:00`;
    });
    const value: number[] = Array.from({ length: 60 * 24 }).fill(0) as number[];

    let x = 0;
    let y = 0;

    let max = -Infinity;

    for (let i = 0; i < value.length; i++) {
      const curr = this.lineChartMeterMetrics[x];
      const currTime = new Date(curr.timestamp).toLocaleTimeString('en-GB');

      if (currTime === label[y]) {
        const power = (curr.total_power ?? 0) / 3600000;
        if (power > max) max = power;
        value[i] = power;
        totalCostLineChart += power * 0.12;
        totalEnergyLineChart += power;
        x++;
      }

      if (x === this.lineChartMeterMetrics.length) break;

      y++;
    }

    this.totalCostLineChart = totalCostLineChart;
    this.totalEnergyLineChart = totalEnergyLineChart;

    this.lineChartOptions = {
      xAxis: {
        type: 'category',
        data: label.map(d => `${localDateString}\n${d}`),
        boundaryGap: false,
        axisLine: { lineStyle: { color: '#6E7079', width: 1 } },
        axisLabel: {
          interval: 'auto',
          show: true,
          margin: 8,
          fontSize: 12
        }
      },
      yAxis: {
        type: 'value',
        alignTicks: false,
        boundaryGap: [0, 0],
        minorTick: { show: false },
        animationEasing: 'linear',
        max: Math.max(max, 0),
        min: 0,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 1,
            type: 'dotted'
          }
        },
        axisLabel: {
          formatter: (value: number) => {
            return `${this.customToFixed(value, 4)}kWh`;
          }
        }
      },
      series: [
        {
          data: value,
          type: 'line',
          showSymbol: false,
          smooth: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 3,
            type: 'solid'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(31, 171, 61, 1)' },
                { offset: 0.3, color: 'rgba(138, 223, 156, 1)' },
                { offset: 1, color: 'rgba(255, 255, 255, 1)' }
              ]
            }
          },
          universalTransition: { divideShape: 'clone' }
        }
      ],
      stateAnimation: { duration: 300, easing: 'cubicOut' },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          label: { backgroundColor: '#505765' },
          axis: 'y',
          animationEasingUpdate: 'exponentialOut',
          animationDurationUpdate: 200,
          crossStyle: { color: '#999', width: 1, type: 'dashed' },
          lineStyle: { color: '#1FAB3D' },
          shadowStyle: { color: '#1FAB3D' }
        },
        borderColor: '#1FAB3D',
        show: true,
        showContent: true,
        z: 60,
        triggerOn: 'mousemove|click',
        displayMode: 'single',
        renderMode: 'auto',
        backgroundColor: '#fff',
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        shadowOffsetX: 1,
        shadowOffsetY: 2,
        borderRadius: 4,
        borderWidth: 1,
        textStyle: { color: '#666', fontSize: 14 },
        formatter: (params: any) => {
          return `${this.customToFixed(params[0].value, 3)}kWh`;
        }
      },
      dataZoom: {
        type: 'slider',
        show: true,
        realtime: true,
        start: 0,
        end: 100,
        filterMode: 'filter',
        bottom: 5
      }
    };
  }

  fetchMeterChartAnalyticsData(meter_name: string, start_date: Date) {
    this.chartOptionsMeterAnalyticsData = {
      noData: {
        text: 'Loading...',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: '#000000',
          fontSize: '14px',
          fontFamily: 'Helvetica'
        }
      }
    };

    this.fetchMeterAnalytics(meter_name, start_date, response => {
      this.barChartMeterMetrics = response;
      this.plotBarChart();
    });
  }

  fetchLineChartMeterAnalytics(meter_name: string, start_date: Date) {
    this.fetchMeterAnalytics(meter_name, start_date, response => {
      this.lineChartMeterMetrics = response;
      this.plotLineChart();
    });
  }

  fetchMeterAnalytics(
    meter_name: string,
    start_date: Date,
    cb: (response: any) => void
  ) {
    const end_date = new Date(start_date);
    // add 1 day to the end_date
    end_date.setDate(end_date.getDate() + 1);
    const start_date_str = new Intl.DateTimeFormat('en-CA').format(start_date);  // en-CA gives YYYY-MM-DD
    const end_date_str = new Intl.DateTimeFormat('en-CA').format(end_date);  // en-CA gives YYYY-MM-DD
    const subs = this.dashboardDataService
      .fetchMeterAnalyticsData(
        meter_name,
        start_date_str,
        'hour',
        end_date_str
      )
      .subscribe(response => {
        cb(response);

        subs.unsubscribe();
      });
  }

  onBarChartDateSelect(ngbDate: any) {
    const jsDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    this.fetchMeterChartAnalyticsData(this.panelInfo.meter_name, jsDate);
  }

  onLineChartDateSelect(ngbDate: any) {
    const jsDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    this.fetchLineChartMeterAnalytics(this.panelInfo.meter_name, jsDate);
  }

  onDataZoomUpdate(event: any): void {
    const { start, end } = event;

    const startSlice = Math.floor(
      this.lineChartMeterMetrics.length * (start / 100)
    );
    const endSlice = Math.ceil(this.lineChartMeterMetrics.length * (end / 100));

    const arr = this.lineChartMeterMetrics.slice(startSlice, endSlice);

    let totalCostLineChart = 0;
    let totalEnergyLineChart = 0;

    for (const x of arr) {
      const power = x.total_power / 3600000;
      totalCostLineChart += power * 0.12;
      totalEnergyLineChart += power;
    }

    this.totalCostLineChart = totalCostLineChart;
    this.totalEnergyLineChart = totalEnergyLineChart;
  }

  onChartReady(myCharts: ECharts) {
    myCharts.on('dataZoom', e => this.onDataZoomSubject.next(e));

    this.onDataZoomSubject
      .pipe(debounceTime(80))
      .subscribe(event => this.onDataZoomUpdate(event));
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.panelsSubscription = this.data.panels$.subscribe((panels: Panel[]) => {
      const panel = panels.find(
        d => d.panel_name === this.panelInfo.panel_name
      );

      if (panel) this.liveLineChartData.push({ ...panel });

      this.updateCurrentLiveDataValue();
    });
  }

  ngOnDestroy(): void {
    this.panelsSubscription?.unsubscribe();
    this.panelsSubscription = null;

    if (this.mqttClient) {
      this.mqttClient.end();
      this.mqttClient = null;
    }
  }

  updateCurrentLiveDataValue() {
    const data: number[] = [];

    for (const x of this.liveLineChartData) {
      const value = this.selectedLiveLineChartOption.selector(x);
      data.push(value);
    }

    this.liveChartOptions = {
      xAxis: {
        type: 'category',
        data: Array.from(Array(data.length).keys()),
        boundaryGap: false,
        axisLine: { lineStyle: { color: '#ECE9F1', width: 1 } },
        axisLabel: { show: false },
        axisPointer: { show: false }
      },
      yAxis: {
        type: 'value',
        alignTicks: false,
        boundaryGap: [0, 0],
        minorTick: { show: false },
        animationEasing: 'linear',
        min: 0,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 1,
            type: 'dotted'
          }
        },
        axisLabel: {
          formatter: (value: number) => {
            return `${this.customToFixed(value)}${
              this.selectedLiveLineChartOption.symbol
            }`;
          }
        }
      },
      series: [
        {
          data,
          type: 'line',
          showSymbol: false,
          smooth: true,
          lineStyle: {
            color: '#1FAB3D',
            width: 3,
            type: 'solid'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: 'rgba(31, 171, 61, 1)' },
                { offset: 0.3, color: 'rgba(138, 223, 156, 1)' },
                // { offset: 0.5, color: 'rgba(138, 223, 156, 1)' },
                // { offset: 0.7, color: 'rgba(165, 235, 180, 1)' },
                { offset: 1, color: 'rgba(255, 255, 255, 1)' }
              ]
            }
          },
          universalTransition: { divideShape: 'clone' }
        }
      ],
      stateAnimation: { duration: 300, easing: 'cubicOut' },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          label: { backgroundColor: '#505765' },
          axis: 'y',
          animationEasingUpdate: 'exponentialOut',
          animationDurationUpdate: 200,
          crossStyle: { color: '#999', width: 1, type: 'dashed' },
          lineStyle: { color: '#1FAB3D' },
          shadowStyle: { color: '#1FAB3D' }
        },
        borderColor: '#1FAB3D',
        show: true,
        showContent: true,
        z: 60,
        triggerOn: 'mousemove|click',
        displayMode: 'single',
        renderMode: 'auto',
        backgroundColor: '#fff',
        shadowBlur: 10,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        shadowOffsetX: 1,
        shadowOffsetY: 2,
        borderRadius: 4,
        borderWidth: 1,
        textStyle: { color: '#666', fontSize: 14 },
        formatter: (params: any) => {
          return `${this.customToFixed(
            params[0].value,
            this.selectedLiveLineChartOption.id === 'watts' ? 1 : 3
          )}${this.selectedLiveLineChartOption.symbol}`;
        }
      }
    };

    this.currentLiveDataValue =
      this.customToFixed(
        this.selectedLiveLineChartOption.selector(
          this.liveLineChartData[this.liveLineChartData.length - 1]
        ),
        this.selectedLiveLineChartOption.id === 'watts' ? 1 : 3
      ) + this.selectedLiveLineChartOption.symbol;
  }

  toggleLiveChartOption(): void {
    this.updateCurrentLiveDataValue();
  }

  customToFixed(value: string | number, precision = 1) {
    return this.data.customToFixed(value, precision);
  }

  active1 = 40;
  //   public slides = [
  //     { src: '/assets/images/carousel/ignite-ui-angular-indigo-design.png' },
  //     { src: '/assets/images/carousel/slider-image-chart.png' },
  //     { src: '/assets/images/carousel/ignite-ui-angular-charts.png' }
  // ];

  closePopup() {
    // this.fetchLive = false;
    this.dialogRef.close(this.data_modified);
  }

  sortData(circuits: any) {
    return circuits?.sort(
      (a: any, b: any) => a.circuit_number - b.circuit_number
    );
  }

  handleLiveLineChartOptionChange($event: Event, option: LiveLineChartOptions) {
    if (this.isLiveConnectivitySelected) {
      this.isLiveConnectivitySelected = false;
    }

    if (option.id !== this.selectedLiveLineChartOption.id) {
      this.selectedLiveLineChartOption = option;
    }
  }

  toggleEdit(e: string | null) {
    if (e !== this.isEditing) {
      if (e !== null) {
        this.editedValue[e] = this.panelInfo[e];
      }

      this.isEditing = e;
    }
  }

  updatePanelInfo(id: string) {
    if (this.editedValue[id] !== this.panelInfo[id]) {
      const subs = this.dashboardDataService
        .updatePanelInfo(this.panelInfo.panel_id, {
          [id]: this.editedValue[id]
        })
        .subscribe(
          () => {
            this.toast.success({
              detail: 'Success',
              summary: `Successfully updated Panel Info.`,
              sticky: true,
              position: 'tr'
            });

            this.panelInfo[id] = this.editedValue[id];

            subs.unsubscribe();
          },
          (err: any) => {
            this.toast.error({
              detail: 'Error',
              summary: err.error[0],
              sticky: true,
              position: 'tr'
            });

            subs.unsubscribe();
          }
        );
    }

    this.toggleEdit(null);
  }

  handleValueChange($event: Event, id: string) {
    this.editedValue[id] = ($event.target as HTMLInputElement).value;
  }

  updateVoltageType() {
    this.dashboardDataService
      .updateMeterVoltageType(
        this.panelInfo.meter_id,
        this.editedValue['voltage_type']
      )
      .subscribe(() => {
        this.toast.success({
          detail: 'Success',
          summary: `Voltage type updated.`,
          sticky: true,
          position: 'tr'
        });

        this.panelInfo.voltage_type = this.editedValue['voltage_type'];
      });
  }

  getNetworkMeta({
    network_params,
    network_type
  }: {
    network_params: { rssi?: number; ssid?: string };
    network_type: string;
  }) {
    const { rssi, ssid } = network_params;

    if (!rssi) {
      return {
        fill: '#ED1C24',
        text: 'Unusable',
        wifiStrength: 0,
        value: rssi ?? 0,
        ssid: ssid ?? '-',
        network_type
      };
    }

    if (rssi >= -30) {
      return {
        fill: '#1FAB3D',
        text: 'Excellent',
        wifiStrength: 3,
        value: rssi,
        ssid,
        network_type
      };
    } else if (rssi >= -60) {
      return {
        fill: '#1FAB3D',
        text: 'Very Good',
        wifiStrength: 3,
        value: rssi,
        ssid,
        network_type
      };
    } else if (rssi >= -70) {
      return {
        fill: '#3649A8',
        text: 'Okay',
        wifiStrength: 2,
        value: rssi,
        ssid,
        network_type
      };
    } else if (rssi >= -90) {
      return {
        fill: '#FF7F27',
        text: 'Not Good',
        wifiStrength: 1,
        value: rssi,
        ssid,
        network_type
      };
    } else {
      return {
        fill: '#ED1C24',
        text: 'Unusable',
        wifiStrength: 0,
        value: rssi,
        ssid,
        network_type
      };
    }
  }
}
