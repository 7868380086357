<div class="dt-main">
  <app-sidebar></app-sidebar>
  <div class="dt-content-wrapper overflow-y-scroll">
    <div class="dt-content">
      <div class="col-md-12 spc-padding">
        <h2 class="pageHeading">
          <span class="main-chevron">
            <svg
              width="10"
              height="15"
              viewBox="0 0 10 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.666748 7.5L7.91675 14.75L9.6205 13.0463L4.08633 7.5L9.6205 1.95375L7.91675 0.250003L0.666748 7.5Z"
                fill="#0D1758"
              ></path>
            </svg>
          </span>
          Reports
        </h2>
        <div class="bg-lightgrey overflow-y-scroll">
          <h3 class="subheading">Alerts</h3>
          <div class="row">
            <!-- <div class="col-md-3 mb-4">
              <div class="card border-radius5 p-3">
                <div class="cardupper">
                  <img src="assets/images/onoffalert.png" alt="onoffalert"/>
                </div>
                <div class="cardfoot border-top">
                  <a href="javascript: void(0);" >On/Off Alerts <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-md-3 mb-4">
              <div class="card border-radius5 p-3">
                <div class="cardupper">
                  <img src="assets/images/consumption.png" alt="consumption"/>
                </div>
                <div class="cardfoot border-top">
                  <a href="javascript: void(0);" >Consumption <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                </div>
              </div>
            </div> -->
            <!--
            <div class="col-md-3 mb-4">
              <div class="card border-radius5 p-3">
                <div class="cardupper">
                  <img src="assets/images/equipment.png" alt="equipment"/>
                </div>
                <div class="cardfoot border-top">
                  <a href="javascript: void(0);" >Equipment <img src="assets/images/expand_right.svg" alt="arrow"/></a>
                </div>
              </div>
            </div> -->

            <div class="col-md-6 mb-4">
              <div class="card border-radius5 p-3">
                <div class="cardupper">
                  <svg
                  width="56"
                  height="56"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_7390_19160)">
                    <path
                      d="M7.76562 27.9995C7.76562 19.4815 13.0037 12.2926 20.2649 9.29113H20.266L22.5957 5.10645C12.313 7.51204 4.48438 16.7723 4.48438 27.9995C4.48438 38.5644 11.472 47.5818 21.1879 50.5119L21.4351 47.1454C13.3577 44.3797 7.76562 36.741 7.76562 27.9995ZM34.8115 5.48707L34.5644 8.85363C42.6417 11.6193 48.2339 19.2579 48.2339 27.9995C48.2339 36.5092 43.002 43.7039 35.7347 46.7079H35.7336L33.4039 50.8926C43.6867 48.487 51.5151 39.2267 51.5151 27.9995C51.5151 17.4349 44.5275 8.41712 34.8115 5.48707Z"
                      fill="#0D1758"
                    />
                    <path
                      d="M43.6327 24.118C43.4879 23.8716 43.2812 23.6673 43.0331 23.5253C42.785 23.3834 42.5041 23.3087 42.2183 23.3087H30.2136L31.795 1.76088C31.8224 1.38825 31.7218 1.01745 31.5097 0.709792C31.2977 0.402136 30.9871 0.176062 30.6291 0.0689614C30.2712 -0.0382537 29.8873 -0.0200206 29.5412 0.120642C29.195 0.261305 28.9072 0.515963 28.7255 0.842461L12.3478 30.2521C12.2088 30.5018 12.1374 30.7835 12.1407 31.0693C12.1441 31.3551 12.222 31.6351 12.3668 31.8815C12.5117 32.1279 12.7184 32.3322 12.9664 32.4742C13.2145 32.6161 13.4954 32.6908 13.7812 32.6908H25.786L24.2044 54.2388C24.1771 54.6114 24.2777 54.9822 24.4898 55.2899C24.7018 55.5975 25.0125 55.8235 25.3705 55.9306C26.1139 56.1533 26.9028 55.8239 27.274 55.1571L43.6516 25.7476C43.7907 25.4978 43.8621 25.2161 43.8588 24.9303C43.8554 24.6445 43.7775 24.3645 43.6327 24.118Z"
                      fill="#FFC90E"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_7390_19160">
                      <rect
                        width="56"
                        height="56"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
                </div>
                <div class="cardfoot border-top">
                  <a>
                    Explore Asset Health
                    <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="card border-radius5 p-3">
                <div class="cardupper">
                  <img
                    src="assets/images/asset_intelligence.png"
                    alt="connectivity"
                  />
                </div>
                <div class="cardfoot border-top">
                  <a routerLink="/insights/asset-intelligence">
                    Asset Intelligence
                    <img
                      src="assets/images/expand_right.svg"
                      alt="arrow"
                  /></a>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
